import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


type Props = {
    className: string;
    chartHeight: string;
    data?: DataItem[];
    title: string;
    activity: string;
    columns: { [key: string]: any; };
    scale: string;
    days: number;
    doctype: string;
    showChart?: boolean;
};

interface DataItem {

    brand_name: string;
    date_of_event: string;
    date_received: string;
    event_type: string;
    generic_name: string;
    id: string;
    pma_number: string;
    product_code: string;
    product_problems: string;

    report_number: string;

    device_report_product_code: string;
    patient_problems: string;

    expiration_date_of_device?: string;
    device_operator?: string;
    openfda_regulation_number?: string;
    openfda_device_class?: string;
    openfda_medical_specialty_description?: string;
    openfda_device_name?: string;

    device_sequence_number?: string;
}

// const processDataForAdvisoryCommitteeVsDays = (data) => {
//     const committeeCounts = data.reduce((acc, item) => {
//         const committee = item.openfda_medical_specialty_description;
//         const count = acc.get(committee) || 0;
//         acc.set(committee, count + 1);
//         return acc;
//     }, new Map());

//     // Directly convert the Map to series data suitable for Highcharts
//     const seriesData = Array.from(committeeCounts, ([name, count]) => ({
//         name,
//         data: [count] // Highcharts expects an array of data even for a single value
//     }));

//     return { seriesData };
// };
const processDataForAdvisoryCommitteeVsDays = (data) => {
    const seriesData = data.filter(item => item.category === 'specialty').map(item => ({
        name: item.code, // Use 'code' as the name
        data: [item.count] // Use 'count' directly
    })).sort((a, b) => a.name.localeCompare(b.name));
    console.log(seriesData);
    return { seriesData };
};

const processDataForClassVsDays = (data) => {
    const seriesData2 = data.filter(item => item.category === 'device_class').map(item => ({
        name: item.code, // Use 'code' as the name
        data: [item.count] // Use 'count' directly
    })).sort((a, b) => a.name.localeCompare(b.name));
    console.log(seriesData2);
    return { seriesData2 };
};

const MixedWidgetAdverse: React.FC<Props> = ({ className, chartHeight, title, days, doctype, showChart }) => {
    // Step 1: Group by Program
    const [data, setData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        setLoading(true);
        axios.get(`reg/v1/fda/preference/adverse/graph?days=${days}`)
            .then(response => {
                setLoading(false);
                setData(response.data); // Assuming response.data is an array of DataItem objects
            })
            .catch(error => { console.error('Error fetching data:', error); setLoading(false); });
    }, [days, doctype]);
    const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);


    const chartOptions = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Specialty'
            },
            labels: {
                enabled: false // This will hide the x-axis labels
            },
            width: '90%',
        },
        yAxis: {
            min: 1.0,
            type: 'logarithmic',
            title: {
                text: 'Adverse Events'
            },

        },
        plotOptions: {

            series: {
                borderWidth: 0,

                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,


            },
        },
        credits: {
            enabled: false
        }, legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart
            margin: 4,
            itemMarginBottom: 1,


        }, colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        tooltip: {
            useHTML: true, // Enable HTML in the tooltip
            formatter: function (this: Highcharts.PointLabelObject) {
                // Custom formatting for the tooltip content
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            },
            style: {
                width: '100px' as any, // Set a fixed width for the tooltip
                whiteSpace: 'normal' // Allow text to wrap within the tooltip
            }
        },
        series: seriesData
    };

    const { seriesData2 } = processDataForClassVsDays(data);

    const chartOptions2 = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Device Class'
            },
            labels: {
                enabled: false // This will hide the x-axis labels
            },
            width: '90%',
        },
        yAxis: {
            min: 1.0,
            type: 'logarithmic',
            title: {
                text: 'Adverse Events'
            },

        },
        plotOptions: {

            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,


            },
        },
        credits: {
            enabled: false
        }, legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart

            margin: 4,
            itemMarginBottom: 1,


        }, colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        tooltip: {
            useHTML: true, // Enable HTML in the tooltip
            formatter: function (this: Highcharts.PointLabelObject) {
                // Custom formatting for the tooltip content
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            },
            style: {
                width: '100px' as any, // Set a fixed width for the tooltip
                whiteSpace: 'normal' // Allow text to wrap within the tooltip
            }
        },
        series: seriesData2
    };


    return (
        <div>
            <div className='row g-5 justify-content-center mt-1 mb-4'>
                <div className={showChart ? "col-6" : "col"}>
                    <div className={`card ${className} `} >
                        {showChart && (
                            <div className="card-header border-0 py-5 d-flex justify-content-center">
                                <h3 className='card-title align-items-center justify-center flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>{doctype} by Specialty</span>
                                </h3>
                            </div>
                        )}
                        {loading ? (
                            <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                <span className='mb-3'>Loading...</span>
                            </div>
                        ) : data.length > 0 ? (
                            <div className='card-body d-flex flex-column'>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptions}
                                />
                            </div>
                        ) : (
                            <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                <span className='mb-3 fs-3' >No graph data available</span>
                            </div>
                        )}
                    </div>
                </div>
                {showChart &&
                    <div className="col-6">
                        <div className={`card ${className} `} >
                            <div className='card-header border-0 py-5 d-flex justify-content-center'>
                                <h3 className='card-title align-items-center justify-center flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>{doctype} by Device Class</span>
                                </h3>
                            </div>
                            {loading ? (
                                <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                    <span className='mb-3'>Loading...</span>
                                </div>
                            ) : data.length > 0 ? (
                                <div className='card-body d-flex flex-column'>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions2}
                                    />
                                </div>
                            ) : (
                                <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                                    <span className='mb-3 fs-3'>No graph data available</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export { MixedWidgetAdverse };