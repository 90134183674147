import React from 'react';
// import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
const DocumentTable = ({ loading, error, filteredDocuments, DOCUMENTS_PER_PAGE, currentPage, handleViewButtonPDFClick, formatDate, formatDate2 }) => {

    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <table className="table">
                <tbody>
                    {loading ? (
                        <tr>
                        </tr>
                    ) : filteredDocuments && filteredDocuments.length > 0 ?
                        (
                            filteredDocuments.slice((currentPage - 1) * DOCUMENTS_PER_PAGE, currentPage * DOCUMENTS_PER_PAGE).map((document, index) => (
                                <tr key={document.iddocument}>
                                    <td colSpan="6">
                                        {/* ...rest of the code */}
                                        <div className="card card-custom card-flush" style={{ borderTop: `6px solid ${index % 2 === 0 ? '#0088cc' : '#0088cc'}` }}>
                                            <div className="card-header " >
                                                <h5 className="card-title ">   </h5>
                                                <div className="card-toolbar">
                                                    {/* Download button */}
                                                    <a href={document.fileUrl1} className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center text-primary" style={{ paddingLeft: 0 }}>
                                                        Download
                                                    </a>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        disabled={!document.s3_pdf_link}
                                                        onClick={() => handleViewButtonPDFClick(document.iddocument)}
                                                    >
                                                        View PDF
                                                    </Button>


                                                </div>
                                            </div>
                                            <div className="card-body card-scroll pt-0 ">
                                                {/* Document content */}

                                                <div className="d-flex align-items-center">
                                                    <h4 className="card-title  pb-2" style={{ color: '#014f86' }}>Name: <span className="" style={{ color: '#0077b6' }}> {document.document_title.replace(/;/g, ':').split(':')[0]}</span></h4>
                                                </div>


                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#014f86' }}>Document Title: <span className="" style={{ color: '#0077b6' }}>{document.document_title}</span></h5>

                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}>Document Type: <span className="" style={{ color: '#c5736d' }}>{document.documentType}</span></h5>
                                                    <span className="card-title  pb-2 px-2" style={{ color: '#AA4A44' }}>|</span>
                                                    <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}>Agency: <span className="" style={{ color: '#c5736d' }}>{document.agencyId}</span></h5>
                                                    <span className="card-title  pb-2 px-2" style={{ color: '#AA4A44' }}>|</span>
                                                    <h5 className="card-title pb-2 " style={{ color: '#AA4A44' }}>Program: <span className="" style={{ color: '#c5736d' }}>{document.program ? document.program : "None"}</span></h5>

                                                </div>


                                                {/* <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#014f86' }}>Document ID: <span className="" style={{ color: '#0077b6' }}>{document.id}</span></h5>
                                                    <span className="card-title  pb-2 px-2" style={{ color: '#006633' }}>|</span>
                                                    <h5 className="card-title pb-2 " style={{ color: '#014f86' }}>Docket ID: <span className="" style={{ color: '#0077b6' }}>{document.docketId}</span></h5>

                                                </div> */}
                                                {/* <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2"style={{ color: '#004080' }}>Page Count: <span className=""style={{ color: '#4da6ff' }}>{document.page_count}</span></h5>


                                                </div> */}



                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title text-gray-700 pb-2">Posted Date: <span className="text-gray-600">{formatDate2(document.postedDate) + ' (ET)'}</span></h5>
                                                    <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                                    <h5 className="card-title pb-2 text-gray-700">Effective Date: <span className="text-gray-600">{document.effective_date !== null ? (formatDate2(document.effective_date) + ' (ET)') : 'None'}</span></h5>

                                                </div>



                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : error ? (
                            <tr>
                                <td colSpan="7">
                                    {error.response && error.response.status === 404 ? (
                                        <div className="alert alert-danger" role="alert" >Debarment list not found.</div>
                                    ) : (
                                        <div className="alert alert-danger" role="alert">Error: {error.message}</div>
                                    )}
                                </td>
                            </tr>
                        ) : (

                            filteredDocuments !== null && filteredDocuments.length === 0 && !loading && !error && (
                                <tr>
                                    <td colSpan="7">
                                        <div className="d-flex ms-10 mb-5">
                                            <h2>No documents found with selected filters. Please try again with other filters.</h2>
                                        </div>
                                    </td>
                                </tr>
                            )

                        )
                    }
                </tbody>
            </table>
            {/* {filteredDocuments.length === 0 && !loading && !error && (
                <div className="d-flex ms-10 mb-5">
                    <h3>No documents found with selected filters. Please try again with other filters.</h3>
                </div>
            )} */}
        </div >
    );
};

export default DocumentTable;


