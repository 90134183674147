import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { KTIcon } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';

const UploadDocumentModal = ({ isOpen, onClose, setIsUploaded, setMessage }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const checkbox1Ref = useRef<HTMLInputElement>(null);
    const checkbox2Ref = useRef<HTMLInputElement>(null);
    const checkbox3Ref = useRef<HTMLInputElement>(null);
    const checkbox4Ref = useRef<HTMLInputElement>(null);
    const checkbox5Ref = useRef<HTMLInputElement>(null);
    const checkbox6Ref = useRef<HTMLInputElement>(null);
    const [alert, setAlert] = useState('');
    const { currentUser } = useAuth();
    const MAX_FILE_SIZE = 100 * 1024 * 1024;

    const uploadDocumentSchema = Yup.object().shape({
        document: Yup.mixed().required('An uploaded file is required.'),
        documentName: Yup.string().required('Document name is required.').max(100),
        description: Yup.string().max(500),
    });

    useEffect(() => {
        setShowModal(isOpen);
        // if (!isOpen) {
        //     checkbox1Ref.current && (checkbox1Ref.current.checked = false);
        //     checkbox2Ref.current && (checkbox2Ref.current.checked = false);
        //     checkbox3Ref.current && (checkbox3Ref.current.checked = false);
        //     checkbox4Ref.current && (checkbox4Ref.current.checked = false);
        //     checkbox5Ref.current && (checkbox5Ref.current.checked = false);
        //     checkbox6Ref.current && (checkbox6Ref.current.checked = false);
        //     fileName && setFileName('');
        // }
    }, [isOpen]);

    const closeModal = () => {
        setShowModal(false);
        onClose();
    }

    const handleUploadClick = async (values: any) => {
        if (values.document) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', values.document);
            formData.append('name', values.documentName);
            formData.append('description', values.description);

            axios.post(`/reg/v1/document/mydocuments/${currentUser?.id}`, formData)
                .then(response => {
                    setLoading(false);
                    setMessage('Document uploaded successfully!')
                    // console.log(response);
                })
                .catch(error => {
                    setLoading(false);
                    setMessage(`Failed to upload document: \n ${error.response.data.message}`,)
                    console.error('There was an error!', error);
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            document: null,
            documentName: '',
            description: '',
        },
        validationSchema: uploadDocumentSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setAlert('');
            if (await formik.validateForm() && formik.isValid) {
                try {
                    await handleUploadClick(values);
                    formik.resetForm();
                    setFileName('');
                    setIsUploaded(true);
                    closeModal();
                } catch (error) {
                    console.error('Failed to upload document:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        },
    });

    return (
        <>
            {/* <button type="button" className="btn btn-primary" onClick={openModal}>Support</button>
            <button type="button" className="btn btn-primary" onClick={openModal}>Feedback</button> */}
            <div
                className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-700px' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* begin::Modal content */}
                    <div className='modal-content position-relative'>
                        {/* begin::Modal header */}
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Upload Document</h2>
                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-organizations-modal-action='close'
                                onClick={closeModal}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <form onSubmit={formik.handleSubmit} className="col-lg-12">
                            <div className='modal-body scroll-y' style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                <div className='card-body pt-0 pb-0  mx-5 mx-xl-15'>
                                    <div>
                                        <div className="row align-items-center">
                                            <label htmlFor='documentName' className="col-1 mt-4">
                                                <h4 className="required">Title:</h4>
                                            </label>
                                            <div className="col-9 offset-2">
                                                <input
                                                    id='documentName'
                                                    name='documentName'
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    placeholder="Document Name"
                                                    className="form-control mt-3"
                                                    value={formik.values.documentName}
                                                    maxLength={100}
                                                    style={{ backgroundColor: '#f5f5f5' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-8 ">
                                            {formik.errors.documentName && formik.touched.documentName ? (
                                                <div className="text-danger mb-3 col-6 offset-3 text-start">{formik.errors.documentName}</div>
                                            ) : null}
                                            <p className={formik.errors.documentName ? 'col-3 text-end' : 'col-12 text-end'}>{formik.values.documentName?.length || 0}/100</p>
                                        </div>
                                        <div className="row">
                                            <label htmlFor='description' className="col-1">
                                                <h4>Description:</h4>
                                            </label>
                                            <div className="col-9 offset-2">
                                                <textarea
                                                    id='description'
                                                    name='description'
                                                    placeholder="Document Description"
                                                    className="form-control"
                                                    rows={7}
                                                    maxLength={500}
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                    style={{ resize: 'none', overflow: 'auto', backgroundColor: '#f5f5f5' }}
                                                />
                                            </div>
                                        </div>
                                        <p className="mt-3 mb-6 col-12 text-end">{formik.values.description?.length || 0}/500</p>
                                        <div className="row">
                                            <label htmlFor='document' className="col-1">
                                                <h4 className="required mt-3">Browse:</h4>
                                            </label>
                                            <div className="col-9 offset-2">
                                                <input
                                                    id='document'
                                                    name='document'
                                                    type='file'
                                                    onChange={(event) => {
                                                        setLoading(true);
                                                        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                                                            const file = event.currentTarget.files[0];
                                                            if (file.size > MAX_FILE_SIZE) {
                                                                setAlert('File size exceeds the 100MB limit. Please select a smaller file.');
                                                                setFileName('');
                                                                setLoading(false);
                                                                return;
                                                            }
                                                            setAlert('')
                                                            formik.setFieldValue('document', file);
                                                            formik.validateField('document');
                                                            setFileName(file.name);
                                                        }
                                                        setLoading(false);
                                                    }}
                                                    className="form-control mb-10"
                                                    accept=".txt,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    required
                                                    style={{ position: 'absolute', left: '-9999px' }} // Hide the input off-screen
                                                />
                                                <button type="button" className="btn btn-primary" style={{ width: '35%' }} onClick={() => {
                                                    const inputElement = document.getElementById('document') as HTMLInputElement;
                                                    if (inputElement) {
                                                        inputElement.value = '';
                                                        inputElement.click();
                                                    }
                                                }}>
                                                    Browse
                                                </button>
                                                {alert !== '' ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{alert}</div>
                                                ) : formik.errors.document &&
                                                    formik.touched.document ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{formik.errors.document}</div>
                                                ) : (
                                                    <p className="mt-3 mb-5">{fileName}</p>
                                                )}
                                            </div>
                                            <div className="row mt-3 mb-10">
                                                <label htmlFor='description' className="col-10">
                                                    <h4>Generate the Following:</h4>
                                                </label>
                                            </div>
                                            <div className="row offset-1" style={{ marginLeft: '10%' }}>
                                                <div className="col-4 d-flex flex-column justify-content-end">
                                                    <div className="form-check mb-16 d-flex">
                                                        <input ref={checkbox1Ref} className="form-check-input" type="checkbox" id="checkbox1" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox1" style={{ fontSize: '1.2rem' }}>Summary</label>
                                                    </div>
                                                    <div className="form-check mb-16 d-flex">
                                                        <input ref={checkbox2Ref} className="form-check-input mr-2" type="checkbox" id="checkbox2" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox2" style={{ fontSize: '1.2rem' }}>FAQ</label>
                                                    </div>
                                                    <div className="form-check mb-16 d-flex">
                                                        <input ref={checkbox3Ref} className="form-check-input mr-2" type="checkbox" id="checkbox3" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox3" style={{ fontSize: '1.2rem' }}>Action Items</label>
                                                    </div>
                                                    <div className="form-check mb-5 d-flex">
                                                        <input ref={checkbox4Ref} className="form-check-input mr-2" type="checkbox" id="checkbox4" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox4" style={{ fontSize: '1.2rem' }}>Impact</label>
                                                    </div>
                                                </div>
                                                <div className="col-1 d-flex align-items-center justify-content-center">
                                                    <div style={{ borderLeft: '1px solid #000', height: '110%' }}></div>
                                                </div>
                                                <div className="col-4 offset-1 d-flex flex-column align-items-start">
                                                    <div className="form-check mb-3 d-flex align-items-center">
                                                        <input ref={checkbox5Ref} className="form-check-input mr-2" type="checkbox" id="checkbox5" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>Translation</label>
                                                    </div>
                                                    <label className="form-check-label ms-10 mb-3 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>From:</label>
                                                    <div className="ms-10 mb-6">
                                                        <select className="form-select form-select-sm mb-3">
                                                            <option value="">Select...</option>
                                                            <option value="Arabic">Arabic</option>
                                                            <option value="Czech">Czech</option>
                                                            <option value="German">German</option>
                                                            <option value="English">English</option>
                                                            <option value="Spanish">Spanish</option>
                                                            <option value="Finnish">Finnish</option>
                                                            <option value="French">French</option>
                                                            <option value="Hindi">Hindi</option>
                                                            <option value="Italian">Italian</option>
                                                            <option value="Japanese">Japanese</option>
                                                            <option value="Korean">Korean</option>
                                                            <option value="Dutch">Dutch</option>
                                                            <option value="Sinhala">Sinhala</option>
                                                            <option value="Chinese">Chinese</option>
                                                            <option value="Hebrew">Hebrew</option>
                                                            <option value="Portuguese">Portuguese</option>
                                                            <option value="Swedish">Swedish</option>
                                                        </select>
                                                    </div>
                                                    <label className="form-check-label ms-10 mb-3 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>To:</label>
                                                    <div className="ms-10 mb-7">
                                                        <select className="form-select form-select-sm mb-3">
                                                            <option value="">Select...</option>
                                                            <option value="Arabic">Arabic</option>
                                                            <option value="Czech">Czech</option>
                                                            <option value="German">German</option>
                                                            <option value="English">English</option>
                                                            <option value="Spanish">Spanish</option>
                                                            <option value="Finnish">Finnish</option>
                                                            <option value="French">French</option>
                                                            <option value="Hindi">Hindi</option>
                                                            <option value="Italian">Italian</option>
                                                            <option value="Japanese">Japanese</option>
                                                            <option value="Korean">Korean</option>
                                                            <option value="Dutch">Dutch</option>
                                                            <option value="Sinhala">Sinhala</option>
                                                            <option value="Chinese">Chinese</option>
                                                            <option value="Hebrew">Hebrew</option>
                                                            <option value="Portuguese">Portuguese</option>
                                                            <option value="Swedish">Swedish</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-check mb-5 d-flex align-items-center">
                                                        <input ref={checkbox6Ref} className="form-check-input mr-2" type="checkbox" id="checkbox6" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox6" style={{ fontSize: '1.2rem' }}>Video Training</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-10 mx-5 mx-xl-16">
                                <button
                                    type="button"
                                    className="btn btn-secondary col-3 offset-5"
                                    onClick={(e) => {
                                        closeModal();
                                        setFileName('');
                                        checkbox1Ref.current && (checkbox1Ref.current.checked = false);
                                        checkbox2Ref.current && (checkbox2Ref.current.checked = false);
                                        checkbox3Ref.current && (checkbox3Ref.current.checked = false);
                                        checkbox4Ref.current && (checkbox4Ref.current.checked = false);
                                        checkbox5Ref.current && (checkbox5Ref.current.checked = false);
                                        checkbox6Ref.current && (checkbox6Ref.current.checked = false);
                                        formik.resetForm();
                                    }}
                                >
                                    Discard
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success ms-10 col-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setAlert('');
                                        formik.handleSubmit(e as any);
                                    }}
                                >
                                    {loading ? 'Loading...' : 'Upload'}
                                </button>
                            </div>
                        </form>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div >
                {/* end::Modal dialog */}
            </div >
            {/* begin::Modal Backdrop */}
            {showModal && <div className='modal-backdrop fade show'></div>}
            {/* end::Modal Backdrop */}
        </>
    );
};

export default UploadDocumentModal;