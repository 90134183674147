const FDAFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters
}) => {
    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5' style={{ maxHeight: '260px', overflow: 'auto' }}>


                <div className='mb-7'>
                    <label className='form-label fs-6 fw-bold'>Data Source:</label>
                    <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-hide-search='true'
                        onChange={(e) => onFilterChange('data_source', e.target.value)}
                        value={filters.data_source}
                    >
                        <option value="">All</option>
                        <option value="510k">510K</option>
                        <option value="pma">PMA</option>
                        <option value="recall">Recall</option>
                        <option value="enforcement">Enforcement</option>
                        <option value="classification">Classification</option>
                    </select>
                </div>

                <div className='mb-7'>
                    <label className='form-label fw-bold'>Number of Records Range:</label>
                    <div className='d-flex justify-content-between'>
                        <input
                            type='text'
                            className='form-control form-control-solid me-1'
                            placeholder='Lower Limit'
                            value={filters.minRecords}
                            onChange={(e) => onFilterChange('minRecords', e.target.value)}

                        />
                        <input
                            type='text'
                            className='form-control form-control-solid ms-1'
                            placeholder='Upper Limit'
                            value={filters.maxRecords}
                            onChange={(e) => onFilterChange('maxRecords', e.target.value)}

                        />
                    </div>
                </div>

                <div>
                    <label className='form-label fw-bold'>Run Date Range:</label>
                    <input
                        type='date'
                        className='form-control form-control-solid my-2'
                        placeholder='Start Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                    <input
                        type='date'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>
            </div>

            <div className='px-7 py-5 mb-2'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FDAFilter;