import { KTIcon } from '../../../_metronic/helpers'
import React, { useState, useRef } from 'react';
import { createAvatar } from '@dicebear/core';
import { icons } from '@dicebear/collection';
import Modal from 'react-bootstrap/Modal';
import { updateAvatar } from '../../../_metronic/redux/account/actions';
import axios from 'axios';
import { useAuth } from '../../modules/auth';
import { useSelector, useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
// import Cookies from 'js-cookie';

interface RootState {
    account: {
        avatarUrl: string;
    };
}

const ProfilePicture = (props) => {
    const { auth } = useAuth();
    const avatar_display = useSelector((state: RootState) => state.account.avatarUrl);
    const [image, setImage] = useState(avatar_display !== 'none' ? avatar_display : toAbsoluteUrl('/media/avatars/blank.png'));
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [avatarListVisible, setAvatarListVisible] = useState(false);
    const avatars = Array.from({ length: 54 }, (_, i) => createAvatar(icons, { seed: `${i}` }));
    const fileInputRef = useRef<HTMLInputElement>(null);
    // const csrf_cookies = Cookies.get('csrf_access_token');
    // const userId = auth?.userid;
    const dispatch = useDispatch();
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleProfilePictureChange = () => {
        // console.log("opening files")
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
        setDropdownOpen(!dropdownOpen);
    };


    const uploadImage = async (file, userId) => {
        const formData = new FormData();
        formData.append('file', file);
        // console.log(formData);
        try {
            const response = await axios.post(`/reg/v1/user/profile/picture/${userId}`, formData);
            return response.data;
        } catch (error: any) {
            // console.error(error.response.statusText);
            throw error;
        }
    };

    const handleFileChange = async (event) => {
        // console.log("image click")
        const file = event.target.files[0];
        const reader = new FileReader();
        // console.log(file);
        reader.onloadend = () => {
            setImage(reader.result as string);
            dispatch(updateAvatar(reader.result as string));
        };
        if (file) {
            reader.readAsDataURL(file);
            try {
                // console.log("uploading image")
                await uploadImage(file, auth?.userid);
            } catch (error) {
                console.error('Image upload failed:', error);
            }
        }
    };

    const handleAvatarSelection = () => {
        setAvatarListVisible(true);
        setDropdownOpen(!dropdownOpen);
    };

    const svgToBlob = (svg, type = 'image/png') => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = `data:image/svg+xml,${encodeURIComponent(svg)}`;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject('Could not get canvas context');
                    return;
                }
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(resolve, type);
            };

            img.onerror = reject;
        });
    };

    const handleAvatarClick = async (avatar) => {
        const avatarUrl = `data:image/svg+xml,${encodeURIComponent(avatar.toString())}`;
        setImage(avatarUrl);
        dispatch(updateAvatar(avatarUrl));
        setAvatarListVisible(false);
        try {
            const blob = await svgToBlob(avatar.toString());
            await uploadImage(blob, auth?.userid);
            // console.log(response);
        } catch (error) {
            console.error('Avatar upload failed:', error);
        }
    };

    return (
        <div>
            <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src={image} alt='M' style={{ backgroundColor: 'gray' }} />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                    <button className="btn btn-sm position-absolute bottom-0 end-0" onClick={toggleDropdown}><KTIcon iconName='pencil' className='fs-1 text-white' /></button>
                </div>
                <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" style={{ display: 'none' }} />
                {dropdownOpen && (
                    <div className="dropdown-menu show">
                        <button className="dropdown-item" onClick={handleProfilePictureChange}>Upload Image</button>
                        <button className="dropdown-item" onClick={handleAvatarSelection}>Select Avatar</button>
                    </div>
                )}

                {avatarListVisible && (
                    <div>
                        <Modal show={avatarListVisible} onHide={() => setAvatarListVisible(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Select Avatar</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {avatars.map((avatar, i) => (
                                    <img key={i}
                                        src={`data:image/svg+xml,${encodeURIComponent(avatar.toString())}`}
                                        alt="Avatar"
                                        onClick={() => handleAvatarClick(avatar)}
                                        style={{ width: '50px', height: '50px' }}
                                    />
                                ))}
                            </Modal.Body>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
}
export default ProfilePicture;