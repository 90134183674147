import { KTIcon } from '../../../_metronic/helpers';

export function DocketInfo({ selectedDocketDetails, currentUser, handleSubscribe, formatDate, generateAbstract, generateKeywords }) {
    return (
        <div className="card card-custom">
            <div className="card-header" style={{ backgroundColor: '#ccedff' }}>
                <div className="card-title m-0 d-flex align-items-center">
                    <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 me-3 text-primary' />
                    <h2 className="d-md-block text-wrap" style={{ color: "#4d4d4d", margin: '0', padding: '0', marginRight: '10px', marginTop: '1px' }}>
                        Details for Docket ID: {selectedDocketDetails?.id}
                    </h2>
                </div>


                <div className="card-title ">


                    <div className="d-flex justify-content-end align-items-center " sytle={{ marginTop: '1px' }}>
                        {selectedDocketDetails?.userid === currentUser?.id ? (
                            <button
                                className="btn btn-secondary h-26px  w-140px btn-sm d-flex align-items-center justify-content-center"
                                onClick={() => handleSubscribe(selectedDocketDetails.iddocket, selectedDocketDetails.userid === currentUser?.id)}


                            >
                                Unsubscribe
                            </button>
                        ) : (
                            <button
                                className="btn btn-primary h-26px  w-140px btn-sm d-flex align-items-center justify-content-center"
                                onClick={() => handleSubscribe(selectedDocketDetails.iddocket, selectedDocketDetails.userid === currentUser?.id)}
                            >
                                <i className="bi bi-check-circle-fill"></i>  Subscribe
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-body card-scroll  " >
                {/* Document content */}
                <div className="mb-3">
                    <div className="flex ">
                        <span className="fw-bold">Title:</span>
                        <span className="fw-normal mx-2">{selectedDocketDetails?.title}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="flex">
                        <span className="fw-bold">Type:</span>
                        <span className="fw-normal mx-2">{selectedDocketDetails?.docketType}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="flex">
                        <span className="fw-bold">Date modified:</span>
                        <span className="fw-normal mx-2">{formatDate(selectedDocketDetails?.modifyDate)}</span>

                    </div>
                </div>

                <div className="mb-3">
                    <div className="flex ">
                        <span className="fw-bold">Program:</span>
                        <span className="fw-normal mx-2">{selectedDocketDetails?.program ? selectedDocketDetails.program : "No program specified"}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="flex ">
                        <span className="fw-bold">Number of documents:</span>
                        <span className="fw-normal mx-2">{selectedDocketDetails?.doc_count}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="flex ">
                        <span className="fw-bold">Number of comments:</span>
                        <span className="fw-normal mx-2">{selectedDocketDetails?.comment_count}</span>
                    </div>
                </div>
                {/* <div className="mb-3">
                    <div className="fw-bold">Abstract:</div>
                    {selectedDocketDetails?.dkAbstract ? (
                        <div className="fw-normal "> {selectedDocketDetails?.dkAbstract}</div>
                    ) : (
                        <div className="d-flex align-items-center">
                            <span className="fw-normal mx-2">Not available</span>
                            <button className="btn mx-4 btn-light-primary btn-active-light btn-sm h-25px  d-flex align-items-center justify-content-center" onClick={generateAbstract} style={{ border: `2px solid var(--bs-primary)` }}>
                                Generate
                            </button>
                        </div>
                    )}
                </div> */}
                <div className="mb-3">
                    <div className="fw-bold">Abstract:</div>
                    {selectedDocketDetails?.dkAbstract ? (
                        <div className="fw-normal "> {selectedDocketDetails?.dkAbstract}</div>
                    ) : (
                        <div className="fw-normal">Not available</div>
                    )}
                </div>
                {/* <div className="mb-3">
                    <div className="fw-bold">Keywords:</div>
                    {selectedDocketDetails?.keywords ? (
                        <div className="fw-normal ">{selectedDocketDetails.keywords}</div>
                    ) : (
                        <div className="d-flex align-items-center">
                            <span className="fw-normal mx-2">Not available</span>
                            <button className="btn mx-2 btn-light-primary btn-active-light btn-sm h-25px  d-flex align-items-center justify-content-center" onClick={generateKeywords} style={{ border: `2px solid var(--bs-primary)` }}>
                                Generate
                            </button>
                        </div>
                    )}
                </div> */}
                <div className="mb-3">
  <div className="fw-bold">Keywords:</div>
  {selectedDocketDetails?.keywords ? (
    <div className="fw-normal">{selectedDocketDetails.keywords}</div>
  ) : (
    <div className="fw-normal">Not available</div>
  )}
</div>





            </div>
        </div>

    );
}
