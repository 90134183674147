import axios, { AxiosResponse } from 'axios'
import { ID, Response } from "../../../../../../../_metronic/helpers"
import { User, UsersQueryResponse } from './_models'
import Cookies from 'js-cookie';

// const API_URL = process.env.REACT_APP_THEME_API_URL

const USER_URL = `/reg/v1/admin/user`
// const GET_USERS_URL = `/reg/v1/admin/orgusers/`; // Update the URL
// let orgid = "1";

const getUsers = (query: string): Promise<UsersQueryResponse> => {

  const GET_USERS_URL = `/reg/v1/superadmin/users`;
  const searchParam = new URLSearchParams(query).get("search")?.toLowerCase();
  const filterParamActive = new URLSearchParams(query).get("filter_isactive");
  const filterParamLocked = new URLSearchParams(query).get("filter_locked");
  const sortParam = new URLSearchParams(query).get("sort") || 'fullname';
  const orderParam = new URLSearchParams(query).get("order") || 'asc';
  const orgParam = new URLSearchParams(query).get("filter_orgname");
  const roleParam = new URLSearchParams(query).get("filter_role");

  const filterParamAsNumber = filterParamActive ? parseInt(filterParamActive, 10) : null;
  const filterParamLockedAsNumber = filterParamLocked ? parseInt(filterParamLocked, 10) : null;
  const filterParamOrgAsNumber = orgParam ? parseInt(orgParam, 10) : null;
  const filterParamRoleAsNumber = roleParam ? parseInt(roleParam, 10) : null;

  return axios
    .get(`${GET_USERS_URL}`) // Append the query string if needed
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      if (!searchParam && filterParamAsNumber === null && filterParamLockedAsNumber === null && filterParamOrgAsNumber === null && sortParam === null && orderParam === null && filterParamRoleAsNumber === null) {
        // If searchParam is not provided, return the entire data without filtering
        const data = d.data;
        return data;
      }
      const data = d.data as User[];

      let filteredData = data;

      if (filterParamAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_active === filterParamAsNumber);
      }
      if (filterParamLockedAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_locked === filterParamLockedAsNumber);
      }

      if (filterParamOrgAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.organization === filterParamOrgAsNumber);
      }

      if (filterParamRoleAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.role === filterParamRoleAsNumber);
      }

      if (searchParam) {
        // Filter the data based on searchParam if it's not null
        filteredData = filteredData.filter((user) => {
          const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
          return (
            fullName.includes(searchParam) ||
            user.email?.toLowerCase().includes(searchParam) ||
            user.org_name?.toLowerCase().includes(searchParam)
          );
        });
      }

      if (sortParam && orderParam) {
        // Sort the data based on the sortParam and orderParam
        filteredData = filteredData.sort((a, b) => {
          let aValue = a[sortParam];
          let bValue = b[sortParam];

          if (sortParam === 'fullname' && typeof aValue === 'string' && typeof bValue === 'string') {
            // Case-insensitive sorting for full names
            const aValueLower = aValue.toLowerCase();
            const bValueLower = bValue.toLowerCase();

            if (aValueLower === bValueLower) {
              // If full names are equal, sort by email
              aValue = a.email;
              bValue = b.email;
            }
          }

          if (orderParam === 'asc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in ascending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              if (aValueLower < bValueLower) {
                return -1;
              } else if (aValueLower > bValueLower) {
                return 1;
              } else {
                return 0;
              }
            } else {
              // Regular sorting for non-string values in ascending order
              return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            }
          } else if (orderParam === 'desc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in descending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              if (aValueLower > bValueLower) {
                return -1;
              } else if (aValueLower < bValueLower) {
                return 1;
              } else {
                return 0;
              }
            } else {
              // Regular sorting for non-string values in descending order
              return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            }
          }
          return 0;
        });
      }



      return {
        data: filteredData, // Wrap the filtered data in a `data` property
      };



    });
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => {
      const data = response.data;

      return data[0];
    })

}

const createUser = (user: User, logout: Function, navigate: Function): Promise<any> => {

  const data = {
    first_name: user.first_name,
    last_name: user.last_name,
    title: user.title,
    suffix: user.suffix === "" ? null : user.suffix,
    email: user.email,
    organization: user.organization === 0 ? null : user.organization,
    adminnotes: "",
    is_active: 1,
    is_locked: 0,
    is_registered: 0,
    is_first_time_login: 1,
    role: user.role
  };
  const jsonData = JSON.stringify(data);
  const csrfToken = Cookies.get('csrf_access_token');

  return axios
    .post(`/reg/v1/admin/user`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const result = response.data[0].code;
        // console.log(response.data[0]);
        // console.log('User created', user.user_group);
        return result;
      } else {
        // Handle the case where the response status is not 200 or 201 (e.g., handle errors)
        console.log('Update failed');
      }
    }).catch((error: any) => {
      // Handle any errors that may occur during the get request
      if (error?.response?.status === 401) {
        console.log('Before logout');
        // logout();
        console.log('After logout, before navigate');
        // navigate('/auth/login');
        console.log('After navigate');
      } else if (error?.response?.data?.error) {
        console.error("Error creating user", error.response.data.error);
      } else {
        console.error("Error creating user", error);
        throw error;
      }
    });

}

const updateUser = (user: User, logout: Function, navigate: Function): Promise<User | undefined> => {

  const data = {
    columns: {
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      suffix: user.suffix,
      email: user.email,
      phone: user.phone,
      organization: user.organization,
      adminnotes: user.adminnotes,
      is_locked: user.is_locked,
      is_registered: user.is_registered,
      is_deleted: user.is_deleted,
      is_active: user.is_active,
      is_inrecovery: user.is_inrecovery,
      role: Number(user.role)
    }
  };

  const jsonData = JSON.stringify(data);
  // console.log('User data', jsonData);

  return axios
    .patch(`${USER_URL}/${user.id}`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const updatedUser = response.data;

        return updatedUser;
      } else {
        // Handle the case where the response status is not 200 (e.g., handle errors)
        console.log('Error updating user: HTTP Status', response.status);

      }
    })
    .then((response: Response<User>) => response.data)
    .catch((error: any) => {
      if (error?.response?.status === 401) {
        logout();
        navigate('/auth/login');
      } else {
        console.error("Error updating user:", error);
      }
      return undefined;
      // Re-throw the error for handling in your component
    });
}

const activateUser = (userId: ID, status: number): Promise<void> => {
  return axios.patch(`/reg/v1/admin/user/${userId}`, { columns: { is_active: status } })
    .then((response) => { })
}

const lockUser = (userId: ID, status: number): Promise<void> => {
  return axios.patch(`/reg/v1/admin/user/${userId}`, { columns: { is_locked: status } })
    .then((response) => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const deletePromises = userIds.map((userId) => {
    return activateUser(userId, 0);
  });

  // Use Promise.all to wait for all delete requests to complete
  return Promise.all(deletePromises)
    .then((results) => {
      // All delete requests have completed

    })
    .catch((error) => {
      // Handle any errors that may occur during the delete requests
      console.error("Error deleting selected users:", error);
    });
}

export { getUsers, activateUser, lockUser, deleteSelectedUsers, getUserById, createUser, updateUser }