import React, { FC } from 'react'
import Guidance from './Guidance'

const GuidanceWrapper: FC = () => {
    return (
        <>
          
            <Guidance />
        </>
    )
}

export default GuidanceWrapper
