import React, { useState, useEffect, useCallback } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import DocketFilter from './DocketFilter';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { DocketsListPagination } from './DocketsListPagination';
import DocketsTable from './DocketsTable';
import SearchBar from './SearchBar';
import DocketCharts from './DocketCharts';
import ImportantAgencyDates from './ImportantAgencyDates';
import { Tab, Tabs } from 'react-bootstrap';
//import { is } from 'date-fns/locale';

const fetchData = async (url, setData) => {
    try {
        const response = await axios.get(url);
        if (response.status === 200 || response.status === 201) {
            setData(response.data);
        } else {
            console.log(`Request failed with status code ${response.status}`);
        }
    } catch (error) {
        console.log(error);
    }
};

const Docket = () => {

    const [dockets, setDockets] = useState([]);
    const [filteredDockets, setFilteredDockets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
    const [searchInput, setSearchInput] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    const [agencies, setAgencies] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(0)
    const [isChecked, setIsChecked] = useState(false);
    // const [checkedActive, setCheckedActive] = useState(true);
    // const [isTableVisible, setTableVisible] = useState(false);
    // const [isChartsVisible, setChartsVisible] = useState(false);
    const [impDates, setImpDates] = useState([]);
    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    const DOCKETS_PER_PAGE = 10;
    const [filters, setFilters] = useState({
        docket: '',
        status: '',
        startDate: '',
        endDate: '',
    });
   
    const [agenciesLoaded, setAgenciesLoaded] = useState(false);

   

    const fetchImpDatesData = useCallback(() => fetchData('reg/v1/home/dashboard/impdates', (data) => {
        setImpDates(data);
    }), []);

    

    const fetchAgencies = useCallback(() => {
        // Make an API call to fetch My Agencies data
        axios.get("/reg/v1/agency/").then((response) => {
            //console.log("response", response)
            // Assuming the API response contains an array of agencies
            const { data } = response;
            if (data.length > 0) {
                setAgencies(data);
                //console.log("data", data)
                // If there are agencies, set the first agency as the default
                setSelectedAgency(0);
            }
        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error("Error fetching My Agencies:", error);
            }
        });
    }, [navigate, logout]); // navigate is a dependency here

    useEffect(() => {
        //console.log('Docket component mounted');
        Promise.all([fetchAgencies(), fetchImpDatesData()])
            .then(() => setAgenciesLoaded(true))
            .catch(error => console.error(error));
    }, [fetchAgencies, fetchImpDatesData]);

    const fetchDockets = useCallback(async () => {

        try {
            const { data } = await axios.get(`/reg/v1/docket/agency/${currentUser?.organization}/${selectedAgency}/${currentUser?.id}`);
            if (Array.isArray(data)) {
                const sortedDockets = data.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                setDockets(sortedDockets)
                setFilteredDockets(sortedDockets);

                // setFilteredDockets(sortedDockets);
                setError(null); // Clear any previous errors
                setIsLoading(false); // Move this line here
            } else {
                setError('No dockets found');
            }
        } catch (error) {
            
            if (error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                setError('Unable to fetch dockets');
                setIsLoading(false);
            }
            // Don't set isLoading to false here
        }
    }, [currentUser, selectedAgency, navigate, logout]);

    useEffect(() => {
        if (currentUser && (selectedAgency !== null && selectedAgency !== undefined)) {
            fetchDockets();
        }
    }, [fetchDockets, currentUser, selectedAgency]);

    const sortDockets = useCallback(() => {
        setFilteredDockets(prevDockets => {
            const sortedDockets = [...prevDockets];
            sortedDockets.sort((a, b) => {
                if (sortConfig.key === 'isSubscribed') {
                    const aIsSubscribed = a.userid === currentUser?.id;
                    const bIsSubscribed = b.userid === currentUser?.id;
                    if (aIsSubscribed && !bIsSubscribed) return sortConfig.direction === 'asc' ? -1 : 1;
                    if (!aIsSubscribed && bIsSubscribed) return sortConfig.direction === 'asc' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                    return 0;
                }
            });
            return sortedDockets;
        });
    }, [sortConfig, currentUser]);

    useEffect(() => {
        if (sortConfig.key) {
            sortDockets();
        }
    }, [sortConfig, sortDockets, dockets]);

    useEffect(() => {
        // If the search input is empty, set filteredDockets to all dockets
        if (searchInput.trim() === '') {
            setFilteredDockets(dockets);
        } else {
            // Otherwise, filter dockets based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = dockets.filter((docket) => {
                return (
                    (docket.id && docket.id.toLowerCase().includes(searchTerm)) ||
                    (docket.title && docket.title.toLowerCase().includes(searchTerm)) ||
                    (docket.docketType && docket.docketType.toLowerCase().includes(searchTerm))
                );
            });
            setFilteredDockets(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);



    useEffect(() => {
        if (isChecked) {
            setFilteredDockets(dockets.filter(docket => docket.userid === currentUser?.id));
        } else {
            setFilteredDockets(dockets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked, currentUser?.id, dockets]);





    const applyFilters = () => {
        let filtered = dockets.filter((docket) =>
            (!filters.docket || docket.id.toLowerCase().includes(filters.docket.toLowerCase())) &&
            (!filters.status || docket.docketType === filters.status) &&
            (!filters.startDate || new Date(docket.modifyDate).getTime() >= new Date(filters.startDate).getTime()) &&
            (!filters.endDate || new Date(docket.modifyDate).getTime() <= new Date(filters.endDate).getTime())
        );
        setFilteredDockets(filtered);
    };
    const handleFilterChange = (key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));

    };
    const handleApplyFilters = () => {
        applyFilters();
    };
    const handleResetFilters = () => {
        // Reset the filters state
        setFilters({
            docket: '',
            status: '',
            startDate: '',
            endDate: '',
        });
        // Reset the filtered dockets
        setFilteredDockets(dockets);
    };


    const handleHeaderClick = (key) => {
        let newDirection = 'asc';

        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            newDirection = 'desc';
        }

        setSortConfig({ key, direction: newDirection });
    }



    // changing date-time format from international to local
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };


    const handleAgencyChange = (event) => {
        setSelectedAgency(event.target.value);
        
    };
    const handleSubscribe = async (docketId, subscribed) => {
        let endpoint;
        if (subscribed) {
            endpoint = `/reg/v1/docket/${currentUser?.id}/${docketId}/unsubscribe`;
        } else {
            endpoint = `/reg/v1/docket/${currentUser?.id}/${docketId}/subscribe`;
        }

        try {
            await axios.get(endpoint);

            setDockets(prevDockets => {
                return prevDockets.map(docket => {
                    if (docket.iddocket === docketId) {
                        return { ...docket, userid: subscribed ? null : currentUser?.id };
                    }
                    return docket;
                });
            });
            setFilteredDockets(prevFilteredDockets => {
                return prevFilteredDockets.map(docket => {
                    if (docket.iddocket === docketId) {
                        return { ...docket, userid: subscribed ? null : currentUser?.id };
                    }
                    return docket;
                });
            });

        } catch (error) {
            console.error('Error subscribing/unsubscribing:', error);
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                errorMessage = 'An error occurred on the server. Please try again.';
            }
            setAlert({ message: errorMessage, type: 'danger' });
            scrollToTop();
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto' 
        });
    };

    const totalPages = Math.ceil(filteredDockets.length / DOCKETS_PER_PAGE);

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid  px-5 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                            <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary ' />Dockets
                        </h1>
                    </div>
                </div>
              
                <Tabs defaultActiveKey="dockets" id="uncontrolled-tab-example" className="mb-1">
                    <Tab eventKey="dockets" title="Dockets">
                        <div className="d-flex flex-column-auto  align-items-center  m-3 " style={{ paddingTop: '10px' }}>
                            <div className="d-flex flex-column-auto ms-4">
                                <div className="d-flex align-items-center " >
                                    <span className=" fw-bold fs-6 flex-shrink-0 pe-4 d-none d-md-block " style={{ color: '#3699ff' }}>
                                        Agencies:
                                    </span>
                                    <select
                                        className="form-select form-select-sm w-100px form-select-solid "
                                        data-control="select2"
                                        data-placeholder="Select Agency"
                                        value={selectedAgency || 0}
                                        onChange={handleAgencyChange} // Call handleAgencyChange on change
                                        style={{ backgroundColor: '#f1faff', height: '40px' }}
                                    >
                                        {/* <option value="">Select Agency</option> */}
                                        <option value={0}>All</option>
                                        {Array.isArray(agencies) && agencies.length > 0 &&  // Check if agencies array is not empty
                                            agencies.map((agency) => (
                                                <option key={agency.id} value={agency.id} >
                                                    {agency.short_name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex flex-column-auto mx-6">
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input className='form-check-input' type='checkbox' value='2' defaultChecked={false} onChange={e => setIsChecked(e.target.checked)} />
                                    <span className='form-check-label fw-bold fs-6' style={{ color: '#3699ff' }}>My subscriptions</span>
                                </label>
                            </div>
                            <SearchBar
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                            />
                            <div className="d-flex flex-column-auto  ms-4   ">
                                <button
                                    type='button'
                                    className='btn btn-light-primary btn-active-light  d-flex align-items-center justify-content-center '
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >

                                    <KTIcon iconName='filter' className='fs-2' /> Filter

                                </button>
                                <DocketFilter
                                    filters={filters}
                                    onFilterChange={handleFilterChange}
                                    onApplyFilters={handleApplyFilters}
                                    onResetFilters={handleResetFilters}
                                    dockets={dockets}
                                />
                            </div>


                            {/* <div className="d-flex flex-column-auto">
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input' type='checkbox' value='2' checked={checkedActive} onChange={e => setCheckedActive(e.target.checked)} />
                            <span className='form-check-label fw-bold fs-6' style={{ color: '#3699ff' }}>Active Dockets</span>
                        </label>
                    </div> */}
                        </div>

                        <div className="d-flex flex-column flex-row-fluid p-2">

                            {alert.message && (
                                <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                                    {alert.message}
                                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                                </div>
                            )}
                            {isLoading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : error ? (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            ) : (
                                <DocketsTable
                                    filteredDockets={filteredDockets}
                                    currentPage={currentPage}
                                    DOCKETS_PER_PAGE={DOCKETS_PER_PAGE}
                                    handleHeaderClick={handleHeaderClick}
                                    sortConfig={sortConfig}
                                    handleSubscribe={handleSubscribe}
                                    currentUser={currentUser}
                                    formatDate={formatDate}
                                />
                            )}
                            <DocketsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />


                        </div>
                    </Tab>
                    <Tab eventKey="insights" title="Insights">
                        {agenciesLoaded ? (
                            <DocketCharts
                                colors={colors}
                                agencies={agencies}
                                selectedAgency={agencies.find(agency => agency.id === Number(selectedAgency))?.short_name}
                                dockets={dockets}
                            />
                        ) : (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span>Loading...</span>
                                </div>
                            </div>
                        )}
                    </Tab>

                    <Tab eventKey="importantdates" title="Important Dates">
                        {agenciesLoaded ? (
                            <ImportantAgencyDates
                                impDates={impDates}
                                agency={agencies.find(agency => agency.id === Number(selectedAgency))?.short_name}
                                formatDate={formatDate}
                            />
                        ) : (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span>Loading...</span>
                                </div>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div >

    );
};

export default Docket;