import { FC, useState, useEffect, useCallback } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import WizardsPage from '../pages/wizards/WizardsPage'
import axios from 'axios';


const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser, logout } = useAuth();
  const [isSessionValid, setIsSessionValid] = useState<boolean | null>(null);


  const fetchSession = useCallback(async () => {
    if (currentUser) {
      try {
        await axios.get('/reg/v1/home/');
        setIsSessionValid(true);
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setIsSessionValid(false);
          logout();
        }
      }
    }
  }, [currentUser, logout]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);
  // console.log(isSessionValid)

  // If currentUser has not been checked, return the SplashScreen

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path='setup-user/' element={<WizardsPage />} />
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser && isSessionValid !== false ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/home' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }