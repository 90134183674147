import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import ViewSDKClient from './ViewSDKClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../modules/auth';
function FullWindow({ url }) {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchAndDisplayPDF = async () => {
            try {
                const presignedUrl = url;

                const viewSDKClient = new ViewSDKClient();
                viewSDKClient.ready().then(() => {
                    viewSDKClient.previewFileUsingUrl('pdf-div', presignedUrl, { showAnnotationTools: false, showThumbnails: false, showDownloadPDF: true });
                });
                setAlert({ message: '', type: '' });
            } catch (error) {
                if (error?.response?.status === 401 || error?.response?.status === 400) {
                    setAlert({ message: 'Document is not available', type: 'danger' });
                } else {
                    console.log(error);
                    setAlert({ message: 'Error fetching PDF', type: 'danger' });
                }
            }
        };

        fetchAndDisplayPDF();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout, navigate, url]);

    return (
        <div style={{ height: '90vh' }}>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div id="pdf-div" className="full-window-div" style={{ width: '100%', height: '95%' }}>
                {/* Add CSS styles for full window display */}
            </div>
        </div>
    );
}

export default FullWindow;