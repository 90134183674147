import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
// import { useState } from 'react';

export function DocketDetailsHeader({ agencies, selectedAgency, handleAgencyChange, id, handleDocketChange, loading }) {
    // const [showActive, setShowActive] = useState(true); // Add state for checkbox

    // const handleCheckboxChange = () => { // Add function to handle checkbox changes
    //     setShowActive(!showActive);
    // };
    return (
        <>
        <div className='d-flex align-items-center '>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '225px' }}>
                    <Link
                        to={{
                            pathname: "/docket",
                        }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Return to Dockets List
                    </Link>
                </div>
            </div>
            </div >
            <div className="d-flex align-items-center mt-6 mx-6 "  >
                <span className=" fw-bold  fs-6 flex-shrink-0 pe-4 d-none d-md-block" style={{ color: '#3699ff' }}>
                    Agencies:
                </span>
                <select
                    className="form-select form-select-sm w-100px form-select-solid me-6"
                    data-control="select2"
                    data-placeholder="Select Agency"
                    value={selectedAgency.short_name}
                    onChange={handleAgencyChange} // Call handleAgencyChange on change
                    style={{ backgroundColor: '#f1faff', height: '40px', fontSize: '12px' }} // Adjust the height here
                >
                    {Array.isArray(agencies) && agencies.length > 0 &&  // Check if agencies array is not empty
                        agencies.map((agency) => (
                            <option key={agency.id} value={agency.short_name} >
                                {agency.short_name}
                            </option>
                        ))
                    }
                </select>
            
            {/* <div className="d-flex align-items-center" >
                <span className="fs-6 fw-bold  flex-shrink-0 pe-4 d-none d-md-block" style={{ color: '#3699ff' }}>
                    Switch to Docket:
                </span>
                <select
                    className="form-select form-select-sm w-400px form-select-solid me-6"
                    data-control="select2"
                    data-placeholder="Select Docket"
                    value={id}
                    onChange={handleDocketChange}
                    style={{ backgroundColor: '#f1faff', height: '40px' }}
                >
                    {Array.isArray(dockets) && dockets.length > 0 ? (
                        dockets.map((docket) => (
                            <option key={docket.id} value={docket.id}>
                                {docket.id} : {docket.title && docket.title.length > 100
                                    ? docket.title.substring(0, 100) + "..."
                                    : docket.title}
                            </option>
                        ))
                    ) : (
                        <option value="" disabled>
                            No Dockets Found for Selected Agency
                        </option>
                    )}
                </select>
            </div> */}
            <div className="d-flex align-items-center position-relative my-1">
                <span className="fs-6 fw-bold flex-shrink-0 pe-4 d-none d-md-block" style={{ color: '#3699ff' }}>
                    Switch to Docket ID:
                </span>
                <div>
                    <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-4 mt-3' />
                    <input
                        type="search"
                        className="form-control form-control-solid w-200px ps-12"
                        placeholder="Enter Docket ID"
                        value={id}
                        onChange={handleDocketChange}
                        style={{ backgroundColor: '#f1faff', paddingLeft: '100px', height: '40px' }}
                    />
                </div>
            </div>
            </div>
            {/* <div className="d-flex flex-column-auto" style={{ marginLeft: '30px' }}>
                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' checked={showActive} onChange={handleCheckboxChange} />
                    <span className='form-check-label fw-bold fs-6' style={{ color: '#3699ff' }}>Show Active Documents</span>
                </label>
            </div> */}
            {
                loading &&
                <div style={{ marginLeft: '20px', marginTop: '1px' }}>
                    <div className="spinner-border text-primary fs-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
        
        </>
    );
}