import { FC, useState, } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, } from '../../../../../../../_metronic/helpers'
import { initialUser, User } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { createUser, updateUser } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../modules/auth'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

type Props = {
  isUserLoading: boolean
  user: User
}

const groupOptions = [
  { value: 4000, label: 'Device', isDisabled: false },
  { value: 5000, label: 'Health', isDisabled: true },
  { value: 6000, label: 'Pharma', isDisabled: true },
];


const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
  first_name: Yup.string()
    .required('First name is required'),
  last_name: Yup.string()
    .required('Last name is required'),
  organization: Yup.number().nullable().min(1, 'Organization is required').required('Organization is required'),
  role: Yup.number().nullable().required('Role is required'),
  // user_group: Yup.array().of(Yup.number()).min(1, 'Role is required'),
})

const UserEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { currentUser } = useAuth();

  const [userForEdit] = useState<User>({
    ...user,
    first_name: user.first_name || initialUser.first_name,
    last_name: user.last_name || initialUser.last_name,
    email: user.email || initialUser.email,
    title: user.title || initialUser.title,
    adminnotes: user.adminnotes || initialUser.adminnotes,
    organization: user.organization || initialUser.organization,
    suffix: user.suffix || initialUser.suffix,
    user_group: user.user_group || initialUser.user_group,
    role: user.role || initialUser.role
  })

  const roleOptions = [
    { value: 1, label: 'User' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Lexim Admin' },
  ].filter(option => !(option.value === 3 && userForEdit.role !== 3 && currentUser?.role !== 3));


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      // console.log("values", { ...values, role: Number(values.role) })
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values, logout, navigate)
        } else {
          await createUser(values, currentUser?.organization || 1, logout, navigate)
        }
        cancel(true);
      } catch (ex: any) {
        setModalMessage(ex.response?.data[0]?.message || 'Error occurred'!);
        setShowModal(true);

      } finally {
        setSubmitting(true)
        // cancel(true)
      }

    },
  })


  return (
    <>
      <form
        id='kt_modal_add_user_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          {/* <div className='fv-row mb-7'> */}
          {/* begin::Label */}
          {/* <label className='d-block fw-bold fs-6 mb-5'>Avatar</label> */}
          {/* end::Label */}

          {/* begin::Image input */}
          {/* <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            > */}
          {/* begin::Preview existing avatar */}
          {/* <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${userAvatarImg}')` }}
              ></div> */}
          {/* end::Preview existing avatar */}

          {/* begin::Label */}
          {/* <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label> */}
          {/* end::Label */}

          {/* begin::Cancel */}
          {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
          {/* end::Cancel */}

          {/* begin::Remove */}
          {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
          {/* end::Remove */}
          {/* </div> */}
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
          {/* </div> */}
          {/* end::Input group */}
          {/* 
          <div className="fv-row mb-7">
            <label className=' fw-bold fs-6 mb-2'>Title</label>

            <select
              {...formik.getFieldProps('title')}
              name='title'
              className={clsx(
                'form-select form-select-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.title && formik.errors.title },
                { 'is-valid': formik.touched.title && !formik.errors.title }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            >
              <option value="">Select a Title</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Ms.">Ms.</option>
              <option value="Miss">Miss</option>
            </select>

            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div> */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}

            <label className='required fw-bold fs-6 mb-2'>First Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='First name'
              {...formik.getFieldProps('first_name')}
              type='text'
              name='first_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                {
                  'is-valid': formik.touched.first_name && !formik.errors.first_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formik.errors.first_name}</span>
                </div>
              </div>
            )}
          </div>


          <div className="fv-row mb-7">
            <label className='required fw-bold fs-6 mb-2'>Last Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Last name'
              {...formik.getFieldProps('last_name')}
              type='text'
              name='last_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                {
                  'is-valid': formik.touched.last_name && !formik.errors.last_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formik.errors.last_name}</span>
                </div>
              </div>
            )}

          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading || !!user.email}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-7">
            <label className='required fw-bold fs-6 mb-2'>Role</label>

            <select
              {...formik.getFieldProps('role')}
              name='role'
              className={clsx(
                'form-select form-select-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.role && formik.errors.role },
                { 'is-valid': formik.touched.role && !formik.errors.role },
                { 'text-muted bg-light': formik.isSubmitting || isUserLoading || (userForEdit.role === 3 && currentUser?.role !== 3) }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading || (userForEdit.role === 3 && currentUser?.role !== 3)}
            >
              {roleOptions.map((role) => (
                <option key={role.value} value={Number(role.value)}>
                  {role.label}
                </option>
              ))}
            </select>

            {formik.touched.role && formik.errors.role && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formik.errors.role}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Groups</label>
            {/* end::Label */}
            {/* begin::Groups */}
            <div >
              <Select
                isMulti
                className='fw-semi-bold mb-3 mb-lg-0 fs-5'
                placeholder='Select Groups'
                options={groupOptions}
                value={formik.values.user_group?.map(group => ({ value: group, label: groupOptions.find(option => option.value === group)?.label || '' }))}
                onChange={selectedOptions => {
                  const selectedGroupIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                  formik.setFieldValue('user_group', selectedGroupIds);
                }}
                isDisabled={formik.isSubmitting || isUserLoading}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: '#f7f7f7', // slightly lighter gray
                    border: 'none', // remove border
                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(105, 147, 255, 0.25)' : 'none',
                    minHeight: '43px'
                  }),
                  placeholder: base => ({
                    ...base,
                    color: '#92929f',
                  }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
            {/* end::Groups */}
            {formik.touched.user_group && formik.errors.user_group && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formik.errors.user_group}</span>
                </div>
              </div>
            )}
          </div>



          {/* <div className='fv-row mb-7'>

            <label className=' fw-bold fs-6 mb-2'>Admin notes</label>
            <input
              placeholder='Add notes for user'
              {...formik.getFieldProps('adminnotes')}
              type='text'
              name='adminnotes'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.adminnotes && formik.errors.adminnotes },
                {
                  'is-valid': formik.touched.adminnotes && !formik.errors.adminnotes,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.adminnotes && formik.errors.adminnotes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.adminnotes}</span>
                </div>
              </div>
            )}
          </div> */}


          {/* end::Input */}

          {/* end::Input group */}

          {/* begin::Input group */}

          {/* <div className="fv-row mb-7">
            <label className=' fw-bold fs-6 mb-2'>Deactivate user</label>

            <select
              {...formik.getFieldProps('is_active')}
              name='is_active'
              className={clsx(
                'form-select form-select-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.title && formik.errors.title },
                { 'is-valid': formik.touched.title && !formik.errors.title }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            >
              
            </select>

            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div> */}

          {/* <div className='mb-7'>
          
            <label className='required fw-bold fs-6 mb-5'>Role</label>
            
            <div className='d-flex fv-row'>
              
              <div className='form-check form-check-custom form-check-solid'>
              
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Administrator'
                  id='kt_modal_update_role_option_0'
                  checked={formik.values.role === 'Administrator'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                  <div className='fw-bolder text-gray-800'>Administrator</div>
                  <div className='text-gray-600'>
                    Best for business owners and company administrators
                  </div>
                </label>
                
              </div>
             
            </div>
            
            <div className='separator separator-dashed my-5'></div>
           
            <div className='d-flex fv-row'>
            
              <div className='form-check form-check-custom form-check-solid'>
                
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Developer'
                  id='kt_modal_update_role_option_1'
                  checked={formik.values.role === 'Developer'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                  <div className='fw-bolder text-gray-800'>Developer</div>
                  <div className='text-gray-600'>
                    Best for developers or people primarily using the API
                  </div>
                </label>
           
              </div>
             
            </div>
           
            <div className='separator separator-dashed my-5'></div>
         
            <div className='d-flex fv-row'>
             
              <div className='form-check form-check-custom form-check-solid'>
              
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Analyst'
                  id='kt_modal_update_role_option_2'
                  checked={formik.values.role === 'Analyst'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-bolder text-gray-800'>Analyst</div>
                  <div className='text-gray-600'>
                    Best for people who need full access to analytics data, but don't need to update
                    business settings
                  </div>
                </label>
              
              </div>
            
            </div>
           
            <div className='separator separator-dashed my-5'></div>
      
            <div className='d-flex fv-row'>
             
              <div className='form-check form-check-custom form-check-solid'>
            
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Support'
                  id='kt_modal_update_role_option_3'
                  checked={formik.values.role === 'Support'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
            
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_3'>
                  <div className='fw-bolder text-gray-800'>Support</div>
                  <div className='text-gray-600'>
                    Best for employees who regularly refund payments and respond to disputes
                  </div>
                </label>
            
              </div>
        
            </div>
      
            <div className='separator separator-dashed my-5'></div>
   
            <div className='d-flex fv-row'>
    
              <div className='form-check form-check-custom form-check-solid'>
              
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  id='kt_modal_update_role_option_4'
                  value='Trial'
                  checked={formik.values.role === 'Trial'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
          
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_4'>
                  <div className='fw-bolder text-gray-800'>Trial</div>
                  <div className='text-gray-600'>
                    Best for people who need to preview content data, but don't need to make any
                    updates
                  </div>
                </label>
               
              </div>
            
            </div>
         
          </div> */}

        </div>
        <div className='d-flex justify-content-between px-3 pt-10 pb-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-lg btn-light'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-lg btn-primary'
            data-kt-users-modal-action='submit'
          //  disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            {!formik.isSubmitting && 'Submit'}
            {/* <span className='indicator-label'>Submit</span> */}
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      </form>
      <Modal show={showModal} onHide={() => {
        setShowModal(false);
        cancel(true)
      }} dialogClassName="modal-dialog-centered">
        <Modal.Header closeButton />


        <Modal.Body className="d-flex justify-content-center fs-2">

          <div className="d-flex align-items-center ">

            {modalMessage}
            <i className="bi bi-x-circle text-danger fs-1 ms-2 me-2"></i>
          </div>

        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => {
            setShowModal(false);
            cancel(true)

          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { UserEditModalForm }