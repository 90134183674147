import React from 'react';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
const DocumentTable = ({ loading, error, filteredDocuments, DOCUMENTS_PER_PAGE, currentPage, trackedDocuments, handleTrackDocument, handleViewButtonPDFClick, handleViewButtonDOCClick, handleAttachmentClick, formatDate, formatDate2, programs, searchInput }) => {
    const paginatedDocuments = !loading && searchInput.trim() === ""
        ? filteredDocuments.slice((currentPage - 1) * DOCUMENTS_PER_PAGE, currentPage * DOCUMENTS_PER_PAGE)
        : filteredDocuments;
    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <table className="table">
                <tbody>
                    {loading ? (
                        <tr>
                        </tr>
                    ) : filteredDocuments && filteredDocuments.length > 0 ?
                        (
                            paginatedDocuments.map((document, index) => (
                                <tr key={document.iddocument}>
                                    <td colSpan="6">
                                        {/* ...rest of the code */}
                                        <div className="card card-custom card-flush" style={{ borderTop: `6px solid ${index % 2 === 0 ? '#0088cc' : '#0088cc'}` }}>
                                            <div className="card-header " >
                                                <h5 className="card-title ">   </h5>
                                                {/* <div className="card-toolbar"> */}
                                                {/* Download button */}
                                                {/* <a href={document.fileUrl1} className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center text-primary" style={{ paddingLeft: 0 }}>
                                                        Download
                                                    </a>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        disabled={!document.s3_pdf_link}
                                                        onClick={() => handleViewButtonPDFClick(document.iddocument)}
                                                    >
                                                        View PDF
                                                    </Button> */}
                                                {/* <div className='me-4 '>{trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1) ? (
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Untrack</Tooltip>}
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                        <button
                                                        {...triggerHandler}
                                                            className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                            onClick={() => handleTrackDocument(document.iddocument)}
                                                        >
                                                            <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                                                        </button>
                                                          )}
                                                          </OverlayTrigger>
                                                    ) : (
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Track</Tooltip>}
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                        <button
                                                        {...triggerHandler}
                                                            className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                            onClick={() => handleTrackDocument(document.iddocument)}
                                                        >
                                                            <i  ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                                                        </button>
                                                        )}
                                                        </OverlayTrigger>
                                                    )}</div> */}

                                                {/* </div> */}
                                            </div>
                                            <div className="card-body card-scroll pt-0 ">
                                                {/* Document content */}

                                                <h4 className="card-title pb-1" style={{ color: '#0063cc' }}>
                                                    {/* <Link to={`/document/${document.id}`} className="text-decoration-underline" style={{ color: '#0063cc' }}>{document.document_title}</Link> */}
                                                    <h4 style={{ color: '#014f86' }}>Company Name: <span className="" style={{ color: '#0077b6' }}> {document.company_name}</span></h4>
                                                </h4>


                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}>Subject: <span className="" style={{ color: '#c5736d' }}>
                                                        <Link to={document.warning_letter_url} className="text-decoration-underline" style={{ color: '#c5736d' }} target="_blank" rel="noopener noreferrer">
                                                            {document.subject}
                                                        </Link>
                                                    </span>
                                                    </h5>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title pb-2 " style={{ color: '#AA4A44' }}>Issuing Office: <span className="" style={{ color: '#c5736d' }}>{document.issuing_office !== null ? document.issuing_office : 'None'}</span></h5>
                                                </div>

                                                {/* <div className="d-flex align-items-center">
                                                    <h5 className="card-title pb-2" style={{ color: '#014f86' }}>Document ID: <span className="" style={{ color: '#0077b6' }}>{document.id}</span></h5>
                                                    <span className="card-title pb-2 px-2" style={{ color: '#006633' }}>|</span>
                                                    <h5 className="card-title pb-2" style={{ color: '#014f86' }}>Docket ID: <span className="" style={{ color: '#0077b6' }}>{document.docketId}</span></h5>
                                                </div> */}
                                                {/* <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2"style={{ color: '#004080' }}>Page Count: <span className=""style={{ color: '#4da6ff' }}>{document.page_count}</span></h5>


                                                </div> */}



                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title text-gray-700 pb-2">Posted Date: <span className="text-gray-600">{document.posted_date !== null ? formatDate(document.posted_date) : 'None'}</span></h5>
                                                    <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                                    <h5 className="card-title pb-2 text-gray-700">Letter Issue Date: <span className="text-gray-600">{document.letter_issue_date !== null ? formatDate(document.letter_issue_date) : 'None'}</span></h5>
                                                    {document.closeout_letter !== null && (
                                                        <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                                    )}
                                                    {document.closeout_letter !== null && (
                                                        <h5 className="card-title pb-2 text-gray-700">Closeout Letter: <span className="text-gray-600">{formatDate(document.closeout_letter)}</span></h5>
                                                    )}
                                                </div>



                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : error ? (
                            <tr>
                                <td colSpan="7">
                                    {error.response && error.response.status === 404 ? (
                                        <div className="alert alert-danger" role="alert" >No documents found with selected filters. Please try again with other filters.</div>
                                    ) : (
                                        <div className="alert alert-danger" role="alert">Error: {error.message}</div>
                                    )}
                                </td>
                            </tr>
                        ) : (

                            filteredDocuments !== null && filteredDocuments.length === 0 && !loading && !error && (
                                <tr>
                                    <td colSpan="7">
                                        <div className="d-flex ms-10 mb-5">
                                            <h2>No documents found with selected filters. Please try again with other filters.</h2>
                                        </div>
                                    </td>
                                </tr>
                            )

                        )
                    }
                </tbody>
            </table>
            {/* {filteredDocuments.length === 0 && !loading && !error && (
                <div className="d-flex ms-10 mb-5">
                    <h3>No documents found with selected filters. Please try again with other filters.</h3>
                </div>
            )} */}
        </div>
    );
};

export default DocumentTable;


