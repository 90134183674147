// MyDocumentsToolbar.tsx
import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import SearchBar from './SearchBar';
import DocumentFilter from './DocumentFilter';
import UploadDocumentModal from './UploadDocumentModal';

const MyDocumentsToolbar = ({
    isLoading,
    handleOpenModal,
    isModalOpen,
    handleCloseModal,
    setIsUploaded,
    searchInput,
    setSearchInput,
    filters,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters,
    setMessage
}) => {
    return (
        <div className="d-flex flex-wrap gap-3 flex-row-fluid align-items-center px-8 mt-3 mb-7">
            <div className="col-7">
                <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
            </div>
            <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName='filter' className='fs-2' /> Filter
                </button>
                <DocumentFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                />
            </div>
            <div className="ms-4 d-flex flex-column-auto d-flex align-items-center py-1">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    onClick={handleOpenModal}
                >
                    <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Documents
                </button>
                <UploadDocumentModal isOpen={isModalOpen} onClose={handleCloseModal} setIsUploaded={setIsUploaded} setMessage={setMessage} />
            </div>
        </div >
    );
};

export default MyDocumentsToolbar;