import React from 'react';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {
    // const navigate1 = useNavigate();
    return (
        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('k_pma_number');
                                }
                            }}
                        >
                            K/PMA Number
                            <span className={`sorting-icon ${sortConfig.key === 'k_pma_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'k_pma_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('product_code');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('firm_name');
                                }
                            }}
                        >
                            Firm Name
                            <span className={`sorting-icon ${sortConfig.key === 'firm_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'firm_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '25%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('establishment_type');
                                }
                            }}
                        >
                            Establishment Type
                            <span className={`sorting-icon ${sortConfig.key === 'establishment_type' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'establishment_type' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('code');
                                }
                            }}
                        >
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-center ${sortConfig.key !== 'state_code' ? 'pe-3' : ''}`} style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('state_code');
                                }
                            }}
                        >
                            State
                            <span className={`sorting-icon ${sortConfig.key === 'state_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'state_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('country_code');
                                }
                            }}
                        >
                            Country
                            <span className={`sorting-icon ${sortConfig.key === 'country_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'country_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-end" style={{ whiteSpace: 'nowrap', width: '16%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('product_release_date');
                                }
                            }}
                        >
                            Product Release Date
                            <span className={`sorting-icon ${sortConfig.key === 'product_release_date' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_release_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '10%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort ? (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {currentDevices.length === 0 ? (
                            <tr>
                                <td colSpan={8} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                            </tr>
                        ) : (
                            currentDevices.map((device, index) => (
                                <tr className='align-items-center bg-hover-light-primary'
                                    onClick={(e) => { e.stopPropagation(); navigate(`/device-intelligence/registration-details`, { state: { device: device, searchTerm: searchTerm, currentPage: currentPage } }); }}
                                >
                                    <td className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {device.k_pma_number !== "0" ? device.k_pma_number : "No K/PMA number"}
                                    </td>
                                    <td className="text-center text-dark text-wrap fw-semibold pe-5 fs-6" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/classification/${device.product_code}`} style={{ color: 'primary' }} target="_blank" >
                                            {device.product_code}
                                        </Link>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device.firm_name}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device.establishment_type}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device.code}
                                        </a>
                                    </td>

                                    <td>
                                        <a href='/device-intelligence/registration' className={`text-center text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'state_code' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {/* {new Date(device.state_code).toLocaleDateString()} */}
                                            {device.state_code}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className='text-center text-dark text-wrap fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {device.country_code}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/registration' className={`text-end text-dark text-wrap fw-semibold d-block fs-6 ${sortConfig.key === 'product_release_date' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(device.product_release_date).toLocaleDateString()}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-primary text-wrap fw-semibold fs-6' style={{ cursor: 'default' }}
                                        onClick={(e) => { e.stopPropagation(); navigate(`/device-intelligence/registration-details`, { state: { device: device, searchTerm: searchTerm, currentPage: currentPage } }); }}>
                                        {/* <Link to={{
                                            pathname: `/device-intelligence/registration-details`,
                                            state: { device: device }
                                        }} target="_blank" style={{ color: 'primary' }}>
                                            Details
                                        </Link> */}
                                        <span style={{ color: 'primary', cursor: 'pointer' }}>Details</span>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;