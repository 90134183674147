import React from 'react';
import clsx from 'clsx';

const UsersListPagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const PAGINATION_PAGES_COUNT = 5;

  const sliceLinks = (totalPages, currentPage) => {
    let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

    if (pageLinks.length > PAGINATION_PAGES_COUNT) {
      let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
      let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

      if (start < 0) {
        end -= start;
        start = 0;
      }

      if (end >= pageLinks.length) {
        start -= end - pageLinks.length + 1;
        end = pageLinks.length - 1;
      }

      pageLinks = pageLinks.slice(start, end + 1);
    }

    return pageLinks;
  }

  const paginationLinks = sliceLinks(totalPages, currentPage);

  return (
    <div className='row mt-5 mb-3'>
      <div className='d-flex align-items-center justify-content-center'>
        <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
          <ul className='pagination justify-content-center'>
            <li className={clsx('page-item', { 'disabled': currentPage === 1 })}>
              <button onClick={() => setCurrentPage(1)} style={{ cursor: 'pointer' }} className='page-link'>First</button>
            </li>
            {paginationLinks.map((page) => (
              <li key={page} className={clsx('page-item', { 'active': currentPage === page })}>
                <button onClick={() => setCurrentPage(page)} style={{ cursor: 'pointer', backgroundColor: currentPage === page ? '#1a88ff' : 'initial' }} className='page-link'>{page}</button>
              </li>
            ))}
            <li className={clsx('page-item', { 'disabled': currentPage === totalPages })}>
              <button onClick={() => setCurrentPage(totalPages)} style={{ cursor: 'pointer' }} className='page-link'>Last</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { UsersListPagination }