// MyDocumentsTable.tsx
import React from 'react';
import MyDocumentsTableHeader from './MyDocumentsTableHeader';
import MyDocumentsTableRow from './MyDocumentsTableRow';

const MyDocumentsTable = ({
    filteredFiles,
    sortConfig,
    handleHeaderClick,
    startIndex,
    endIndex,
    navigate,
    editingFile,
    setEditingFile,
    setName,
    setDescription,
    name,
    description,
    currentUser,
    updateDocument,
    fetchFiles,
    setDeleteConfirmation,
    setDocumentToDelete
}) => {

    return (
        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                } as React.CSSProperties}>
                <MyDocumentsTableHeader sortConfig={sortConfig} handleHeaderClick={handleHeaderClick} />
                <tbody>
                    {filteredFiles.length === 0 ? (
                        <tr>
                            <td
                                colSpan={5}
                                className='text-dark fw-semibold fs-5'
                                style={{ textAlign: 'center' }}
                            >
                                No records found
                            </td>
                        </tr>
                    ) : (
                        filteredFiles
                            .sort((a, b) => {
                                let aValue = a[sortConfig.key];
                                let bValue = b[sortConfig.key];

                                if (sortConfig.key === 'name') {
                                    aValue = a.name;
                                    bValue = b.name;
                                }

                                if (aValue < bValue) {
                                    return sortConfig.direction === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return sortConfig.direction === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .slice(startIndex, endIndex)
                            .map((uploadedFile, index) => (
                                <MyDocumentsTableRow
                                    key={index}
                                    uploadedFile={uploadedFile}
                                    navigate={navigate}
                                    editingFile={editingFile}
                                    setEditingFile={setEditingFile}
                                    setName={setName}
                                    setDescription={setDescription}
                                    name={name}
                                    description={description}
                                    currentUser={currentUser}
                                    updateDocument={updateDocument}
                                    fetchFiles={fetchFiles}
                                    setDeleteConfirmation={setDeleteConfirmation}
                                    setDocumentToDelete={setDocumentToDelete}
                                />
                            ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default MyDocumentsTable;