import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { useEffect, useState, useRef } from 'react';
// import {useRef} from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import DevicesFilter from './DevicesFilter';
import { DevicesListPagination } from './DevicesListPagination';
import DevicesTable from './DevicesTable';
import Cookies from 'js-cookie';
import { MixedWidgetPMA } from '../Home/MixedWidgetPMA';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// import Select from 'react-select';

const PMA = () => {
    const [devices, setDevices] = React.useState([]);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state ? location.state.searchTerm : '');
    const [isLoading, setIsLoading] = useState(true);
    const interests = useSelector(state => state.account.interests);
    // const [selectedState, setSelectedState] = useState(['interests', ...interests.committees]);
    // const [allAdvisoryCommittees, setAllAdvisoryCommittees] = useState([]);
    // const [advisoryCommittees, setAdvisoryCommittees] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    const [totalPages, setTotalPages] = useState(0);
    // const itemsPerPage = 10;
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [sortConfig, setSortConfig] = useState({ key: 'decision_date', direction: 'asc' });
    // const [filterPmaNumber, setFilterPmaNumber] = useState('');
    // const [filterProductCode, setFilterProductCode] = useState('');
    // const [filterSupplementNumber, setFilterSupplementNumber] = useState('');
    // const [filterApplicant, setFilterApplicant] = useState('');
    // const [filterAdvisoryCommitteeDescription, setFilterAdvisoryCommitteeDescription] = useState('');
    const [filterDecisionDateRange, setFilterDecisionDateRange] = useState(Cookies.get('selectedDays') || '30');
    const [loadSort, setLoadSort] = useState(false);
    const [committees, setCommittees] = useState({});

    const [days, setDays] = useState(parseInt(Cookies.get('selectedDays'), 10) || 0);
    const firstRenderDays = useRef(true);
    const firstRenderSearchTerm = useRef(true);
    // const [specialityOptions, setSpecialityOptions] = useState([]);
    // const filter = false;

    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    // const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);
    // const handleDeviceClassChange = (event) => {
    //     const { value, checked } = event.target;
    //     let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
    //     setSelectedDeviceClasses(currentClasses => {
    //         if (checked) {
    //             if (!currentClasses.includes(newValue)) {
    //                 return [...currentClasses, newValue];
    //             }
    //             return currentClasses;
    //         } else {
    //             return currentClasses.filter(item => item !== newValue);
    //         }
    //     });
    // };

    // const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    // const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    // const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);

    // const handleSpecialityChange = (event) => {
    //     const { value, checked } = event.target;

    //     // Handle "All" option separately
    //     if (value === 'All') {
    //         if (checked) {
    //             setSelectedSpecialities(specialityOptions); // Select all options
    //         }
    //         else {
    //             setSelectedSpecialities([]); // Deselect all options
    //         }
    //     } else {
    //         setSelectedSpecialities((prevSelectedSpecialities) => {
    //             if (checked && !prevSelectedSpecialities.includes(value)) {
    //                 // If selecting an option and all other options are selected, add "All" to the selection
    //                 const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
    //                 return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
    //             } else if (!checked) {
    //                 // If deselecting an option, also remove "All" from the selection
    //                 return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
    //             }
    //             return prevSelectedSpecialities;
    //         });
    //     }
    // };

    // const dropdownRef = useRef(null);
    // const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (dropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [dropdownOpen]);


    // const dropdownRef2 = useRef(null);
    // const handleClickOutsideSpeciality = (event) => {
    //     if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
    //         setSpecialityDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (specialityDropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutsideSpeciality);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     };
    // }, [specialityDropdownOpen]);

    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);


    // const handleApplyFilters = () => {
    //     setCurrentPage(1);
    // };

    // const handleResetFilters = () => {
    //     setFilterPmaNumber('');
    //     setFilterProductCode('');
    //     setFilterSupplementNumber('');
    //     setFilterApplicant('');
    //     setFilterAdvisoryCommitteeDescription('');
    //     setFilterDecisionDateRange('30');
    //     setCurrentPage(1);
    // };

    // if (filter) {
    //     handleApplyFilters();
    //     handleResetFilters();
    // }

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        Cookies.set('selectedDays', filterDecisionDateRange);
    }, [filterDecisionDateRange]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    // const [custom, setCustom] = useState(false);
    const navigate = useNavigate();
    // useEffect(() => {
    //     const fetchDevices = async () => {
    //         setIsLoading(true);
    //         try {
    //             const response = await axios.get(`/reg/v1/fda/pma/by_days/365`);
    //             let fetchedDevices = response.data;
    //             console.log("fetchedDevices", fetchedDevices);
    //             fetchedDevices.sort((a, b) => {
    //                 let aValue = a[sortConfig.key];
    //                 let bValue = b[sortConfig.key];

    //                 if (sortConfig.key === 'decision_date') {
    //                     aValue = new Date(aValue).getTime();
    //                     bValue = new Date(bValue).getTime();
    //                 } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    //                     aValue = aValue.toLowerCase();
    //                     bValue = bValue.toLowerCase();
    //                 }

    //                 if (aValue < bValue) {
    //                     return sortConfig.direction === 'asc' ? -1 : 1;
    //                 }
    //                 if (aValue > bValue) {
    //                     return sortConfig.direction === 'asc' ? 1 : -1;
    //                 }
    //                 return 0;
    //             });

    //             setDevices(fetchedDevices);
    //         } catch (error) {
    //             console.log('Error fetching devices:', error);
    //         }
    //         setIsLoading(false);
    //     };
    //     fetchDevices();
    //     // eslint-disable-next-line
    // }, []);
    useEffect(() => {
        if (firstRenderDays.current) {
            firstRenderDays.current = false;
            return;
        }
        setSearchTerm('');
        setCurrentPage(1);
    }, [days]);

    useEffect(() => {
        if (firstRenderSearchTerm.current) {
            firstRenderSearchTerm.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setCurrentPage(1);
            if (searchTerm.trim() !== '') {
                setSortConfig({ key: '', direction: '' });
            } else {
                setSortConfig({ key: 'decision_date', direction: 'asc' });
            }
        }, 0);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            const fetchNewDevices = async () => {
                setIsLoading(true);
                setLoadSort(true);
                try {
                    if (searchTerm.trim() === '') {
                        const response = await axios.get(`/reg/v1/fda/preference/pma/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}&days=${days}`);
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.pma_count / Math.max(1, itemsPerPage)));
                        }
                    } else {
                        const response = await axios.post(`/reg/v1/search/search/pma?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchTerm}`);
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch new devices:", error);
                } finally {
                    setIsLoading(false);
                    setLoadSort(false);
                }
            };

            fetchNewDevices();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, days, sortConfig.direction, sortConfig.key, searchTerm]);


    // useEffect(() => {
    //     setLoadSort(true);
    //     let sortedDevices = [...devices];
    //     sortedDevices = sortedDevices.sort((a, b) => {
    //         let aValue = a[sortConfig.key];
    //         let bValue = b[sortConfig.key];

    //         if (sortConfig.key === 'decision_date') {
    //             aValue = new Date(aValue).getTime();
    //             bValue = new Date(bValue).getTime();
    //         } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    //             aValue = aValue.toLowerCase();
    //             bValue = bValue.toLowerCase();
    //         }

    //         if (aValue < bValue) {
    //             return sortConfig.direction === 'asc' ? -1 : 1;
    //         }
    //         if (aValue > bValue) {
    //             return sortConfig.direction === 'asc' ? 1 : -1;
    //         }
    //         return 0;
    //     });
    //     setDevices(sortedDevices);
    //     setLoadSort(false);
    //     // eslint-disable-next-line
    // }, [sortConfig]);

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [filterPmaNumber, filterDecisionDateRange, interests, filterProductCode, filterSupplementNumber, filterApplicant, filterAdvisoryCommitteeDescription]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // const matchesSearchTerm = (device, searchTerms) =>
    //     searchTerms.some(term =>
    //         device.supplement_number?.toLowerCase().includes(term) ||
    //         device.pma_number?.toLowerCase().includes(term) ||
    //         device.advisory_committee_description?.toLowerCase().includes(term) ||
    //         device.applicant?.toLowerCase().includes(term) ||
    //         device.product_code?.toLowerCase().includes(term) ||
    //         device.generic_name?.toLowerCase().includes(term) ||
    //         device.trade_name?.toLowerCase().includes(term)
    //     );

    // const matchesCommittee = (device, committees) =>
    //     committees.some(committee => {
    //         const match = committee.match(/\(([^)]+)\)/);
    //         const code = match ? match[1] : committee;
    //         return code === device.advisory_committee_description;
    //     });

    // const filteredDevices = devices.filter(device => {
    //     const searchTerms = searchTerm.toLowerCase().split(/[\s,]+/).filter(Boolean);
    //     const matchesSearch = !searchTerm || matchesSearchTerm(device, searchTerms);
    //     const matchesInterests = (selectedSpecialities.length === 0 || matchesCommittee(device, selectedSpecialities)) &&
    //         (selectedDeviceClasses.length === 0 || selectedDeviceClasses.includes(device.openfda_device_class));
    //     // Apply filters
    //     const matchesPmaNumber = !filterPmaNumber || device.pma_number.toLowerCase().includes(filterPmaNumber.toLowerCase());
    //     const matchesProductCode = !filterProductCode || device.product_code.toLowerCase().includes(filterProductCode.toLowerCase());
    //     const matchesSupplementNumber = !filterSupplementNumber || device.supplement_number.toLowerCase().includes(filterSupplementNumber.toLowerCase());
    //     const matchesApplicant = !filterApplicant || device.applicant.toLowerCase().includes(filterApplicant.toLowerCase());
    //     const matchesAdvisoryCommittee = !filterAdvisoryCommitteeDescription || device.advisory_committee_description.toLowerCase().includes(filterAdvisoryCommitteeDescription.toLowerCase());
    //     const currentDate = new Date();
    //     const decisionDate = new Date(device.decision_date);
    //     decisionDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
    //     const matchesDecisionDateRange = !filterDecisionDateRange || (decisionDate >= new Date(Date.now() - Number(filterDecisionDateRange) * 24 * 60 * 60 * 1000));
    //     return matchesInterests && matchesSearch && matchesPmaNumber && matchesProductCode && matchesSupplementNumber && matchesApplicant && matchesAdvisoryCommittee && matchesDecisionDateRange;
    // });

    // useEffect(() => {
    //     setTotalPages(Math.ceil(filteredDevices.length / Math.max(1, itemsPerPage)));
    //     if (currentPage > totalPages) {
    //         setCurrentPage(1);
    //     }
    // }, [filteredDevices, currentPage, totalPages, itemsPerPage]);

    const handleHeaderClick = (key) => {
        let direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };

    // const currentDevices = filteredDevices.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage));


    // const processDataForAdvisoryCommitteeVsDays = (data) => {
    //     const committeeCounts = data.reduce((acc, item) => {
    //         const committee = item.advisory_committee_description; // Assuming 'committee' is the field name
    //         if (!acc[committee]) {
    //             acc[committee] = 0;
    //         }
    //         acc[committee]++;
    //         return acc;
    //     }, {});

    //     // Convert the counts object to an array suitable for Highcharts
    //     const seriesData = Object.entries(committeeCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));
    //     const categories = [...new Set(data.map(item => item.review_advisory_committee))];
    //     return { seriesData, categories };
    // };

    // const AdvisoryCommitteeVsDaysChart = ({ data }) => {

    //     const { seriesData, categories } = processDataForAdvisoryCommitteeVsDays(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             title: {
    //                 text: 'Specialty'
    //             },
    //             categories: categories,
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: [{
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Pre-Market Approvals'
    //             },
    //         }],
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`}>
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Pre-Market Approvals by Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };


    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <KTIcon iconName='devices' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                    <span>Pre-Market Approvals</span>
                                    {isLoading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div className="d-flex flex-row-fluid px-10 align-items-center">
                        <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    </div>
                    {committees && interests.device_classes && (
                        <div className="d-flex flex-row-fluid px-10">
                            {Object.keys(committees).length > 0 && (
                                <div style={{ position: 'relative', paddingRight: '7px' }}>
                                    <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                        {Object.entries(committees).map(([key, value], index) => (
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {value}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{index > 0 && ', '}{key}</span>
                                            </OverlayTrigger>
                                        ))}
                                    </h6>
                                    {interests.device_classes.length > 0 && (
                                        <div style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '5%',
                                            bottom: '5%',
                                            width: '1px',
                                            backgroundColor: '#4d4d4d'
                                        }}></div>
                                    )}
                                </div>
                            )}
                            {interests.device_classes.length > 0 && (
                                <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                    <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* <div className="d-flex flex-column px-10 align-items-start">
                    {(filterPmaNumber || filterProductCode || filterSupplementNumber || filterApplicant || filterAdvisoryCommitteeDescription || filterDecisionDateRange) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterPmaNumber && <li><h6 className="d-md-block text-wrap">PMA Number: {filterPmaNumber}</h6></li>}
                                {filterProductCode && <li><h6 className="d-md-block text-wrap">Product Code: {filterProductCode}</h6></li>}
                                {filterSupplementNumber && <li><h6 className="d-md-block text-wrap">Supplement Number: {filterSupplementNumber}</h6></li>}
                                {filterApplicant && <li><h6 className="d-md-block text-wrap">Applicant: {filterApplicant}</h6></li>}
                                {filterAdvisoryCommitteeDescription && <li><h6 className="d-md-block text-wrap">Advisory Committee Description: {filterAdvisoryCommitteeDescription}</h6></li>}
                                {filterDecisionDateRange && <li><h6 className="d-md-block text-wrap">Decision Date Range: Past {filterDecisionDateRange === '365' ? '1 Year' : `${filterDecisionDateRange} Days`}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div> */}
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto ms-6 mt-4">
                    <ul className='nav mb-4'>

                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 30 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(30); setDays(30); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                30 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 90 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(90); setDays(90); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                90 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 180 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(180); setDays(180); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                180 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 365 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(365); setDays(365); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                1 Year
                            </button>
                        </li>
                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 0 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(0); setDays(0); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                All
                            </button>
                        </li>
                    </ul>

                    {/* <div className='mb-4 dropdown ms-10' ref={dropdownRef}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Device Class
                        </button>
                        {dropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-10' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleSpecialityDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Specialty
                        </button>
                        {specialityDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {specialityOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div> */}
                </div>

                <MixedWidgetPMA
                    className='card-xxl-stretch pt-3'
                    chartHeight='240px'
                    days={days}
                    doctype={'PMAs'}
                    activity={"all"}
                    scale="logarithmic"
                    showChart={true}
                />


                {/* {filteredDevices.length > 0 && (
                    <div className='row g-5 justify-content-center mt-1 mb-4'>
                        <div className='col-8'>
                            <AdvisoryCommitteeVsDaysChart data={filteredDevices} />
                        </div> */}
                {/* <div className='col-xxl-4'>
                            <DeviceClassVsAdvisoryCommitteeChart data={filteredDevices} />
                        </div>
                        <div className='col-xxl-4'>
                            <MyChartComponent data={filteredDevices} />
                        </div> */}
                {/* </div>
                )} */}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-2`}>
                    <div className="col-7">
                        <div className=" flex-column-auto align-items-center">
                            <div className="d-flex align-items-center position-relative my-1" >
                                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                                <input
                                    type="search"
                                    id="form1"
                                    className="form-control form-control-solid w-100 ps-14 border border-1"
                                    placeholder="Search PMAs"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onSubmit={(event) => event.preventDefault()}
                                    maxLength={200}
                                    style={{ backgroundColor: '#f1faff' }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DevicesFilter
                            filterPmaNumber={filterPmaNumber}
                            setFilterPmaNumber={setFilterPmaNumber}
                            filterProductCode={filterProductCode}
                            setFilterProductCode={setFilterProductCode}
                            filterSupplementNumber={filterSupplementNumber}
                            setFilterSupplementNumber={setFilterSupplementNumber}
                            filterApplicant={filterApplicant}
                            setFilterApplicant={setFilterApplicant}
                            filterAdvisoryCommitteeDescription={filterAdvisoryCommitteeDescription}
                            setFilterAdvisoryCommitteeDescription={setFilterAdvisoryCommitteeDescription}
                            filterDecisionDateRange={filterDecisionDateRange}
                            setFilterDecisionDateRange={setFilterDecisionDateRange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div> */}
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="ms-15 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
            </div>
            {totalPages >= 1 && (
                <DevicesTable
                    isLoading={loadSort}
                    currentDevices={devices}
                    handleHeaderClick={handleHeaderClick}
                    sortConfig={sortConfig}
                    navigate={navigate}
                    searchTerm={searchTerm}
                    currentPage={currentPage}
                />
            )}


            {totalPages >= 1 && (
                <div className="d-flex justify-content-center">

                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>

                    <DevicesListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </div>
            )}
        </div>
    );
}
export default PMA;
