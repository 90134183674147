import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import { isNotEmpty, } from '../../../../../../../_metronic/helpers'
import { initialOrganization, Organization } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { OrganizationsListLoading } from '../components/loading/OrganizationsListLoading'
import { createOrganization } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'

type Props = {
  isOrganizationLoading: boolean
  Organization: Organization
}

// type FormValues = {
//   name?: string;
//   website?: string;
//   industry?: string;
//   firstName?: string;
//   lastName?: string;
//   email?: string;
// };

const editOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  website: Yup.string()
    .required('Website is required'),
})

const editOrganizationSchemaStep2 = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
})

const OrganizationCreateModalForm: FC<Props> = ({ Organization, isOrganizationLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [step, setStep] = useState(1);
  // const [formValues, setFormValues] = useState<FormValues>({});

  const [OrganizationForEdit] = useState<Organization>({
    ...Organization,
    name: Organization.name || initialOrganization.name,
    website: Organization.website || initialOrganization.website,
    industry: Organization.industry || initialOrganization.industry,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const nextForm = async () => {
    const errors = await formikStep1.validateForm();
    if (Object.keys(errors).length === 0) {
      setStep(step + 1);
    } else {
      // Touch all fields so the error messages are shown
      formikStep1.setTouched({
        name: true,
        website: true,
        // Add all other fields here...
      });
    }
  };

  const handleFinalSubmit = async () => {
    try {
      const organization = {
        name: formikStep1.values.name,
        website: formikStep1.values.website,
        industry: formikStep1.values.industry,
      };
      const user = {
        first_name: formikStep2.values.firstName,
        last_name: formikStep2.values.lastName,
        email: formikStep2.values.email,
        role: 2,
        title: "",
        suffix: "",
        phone: "",
        adminnotes: ""
      };
      // console.log(organization, user)
      await createOrganization(organization, user);
    } catch (ex) {
      console.error(ex);
    } finally {
      cancel(true);
    }
  };

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  //const OrganizationAvatarImg = toAbsoluteUrl(`/media/${OrganizationForEdit.avatar}`)

  const formikStep1 = useFormik({
    initialValues: OrganizationForEdit,
    validationSchema: editOrganizationSchema,
    onSubmit: () => {
      // setFormValues(prevValues => ({ ...prevValues, ...values }));
      nextForm();
    },
  })

  const formikStep2 = useFormik({
    initialValues: { firstName: '', lastName: '', email: '' },
    validationSchema: editOrganizationSchemaStep2,
    onSubmit: (values) => {
      // setFormValues(prevValues => ({ ...prevValues, ...values }));
      // console.log(values)
    },
  })

  return (
    <>
      {step === 1 && (
        <form id='kt_modal_edit_Organization_form' className='form' onSubmit={formikStep1.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_Organization_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_Organization_header'
            data-kt-scroll-wrappers='#kt_modal_add_Organization_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Organization Name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Organization Name'
                {...formikStep1.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formikStep1.touched.name && formikStep1.errors.name },
                  {
                    'is-valid': formikStep1.touched.name && !formikStep1.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formikStep1.isSubmitting || isOrganizationLoading}
              />
              {formikStep1.touched.name && formikStep1.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formikStep1.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Website</label>

              <input
                placeholder='Website'
                {...formikStep1.getFieldProps('website')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formikStep1.touched.website && formikStep1.errors.website },
                  {
                    'is-valid': formikStep1.touched.website && !formikStep1.errors.website,
                  }
                )}
                type='website'
                name='website'
                autoComplete='off'
                disabled={formikStep1.isSubmitting || isOrganizationLoading}
              />
              {/* end::Input */}
              {formikStep1.touched.website && formikStep1.errors.website && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formikStep1.errors.website}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input group */}
          </div>
          {/* end::Scroll */}

          {/* begin::Input group */}
          <div className="fv-row mb-5">
            <label className='fw-bold fs-6 mb-2'>Industry</label>

            <select
              {...formikStep1.getFieldProps('industry')}
              name='industry'
              className={clsx(
                'form-select form-select-solid mb-3 mb-lg-0',
                { 'is-invalid': formikStep1.touched.industry && formikStep1.errors.industry },
                { 'is-valid': formikStep1.touched.industry && !formikStep1.errors.industry }
              )}
              autoComplete='off'
              disabled={formikStep1.isSubmitting || isOrganizationLoading}
            >
              <option value="">Select an Industry</option>
              <option value="Medical Devices">Medical Devices</option>
            </select>

            {formikStep1.touched.industry && formikStep1.errors.industry && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block mt-3 ms-3'>
                  <span role='alert'>{formikStep1.errors.industry}</span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Actions */}
          <div className='text-center pt-5'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-industrys-modal-action='cancel'
              disabled={formikStep1.isSubmitting || isOrganizationLoading}
            >
              Discard
            </button>

            <button
              type='button'
              onClick={nextForm}
              className='btn btn-primary me-3'
              disabled={formikStep1.isSubmitting || isOrganizationLoading}
            >
              Next
            </button>
          </div>
          {/* end::Actions */}
        </form>
      )}

      {step === 2 && (
        <form id='kt_modal_edit_Organization_form' className='form' onSubmit={formikStep2.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_Organization_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_Organization_header'
            data-kt-scroll-wrappers='#kt_modal_add_Organization_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Admin First Name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='First Name'
                {...formikStep2.getFieldProps('firstName')}
                type='text'
                name='firstName'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formikStep2.touched.firstName && formikStep2.errors.firstName },
                  {
                    'is-valid': formikStep2.touched.firstName && !formikStep2.errors.firstName,
                  }
                )}
                autoComplete='off'
                disabled={formikStep2.isSubmitting || isOrganizationLoading}
              />
              {formikStep2.touched.firstName && formikStep2.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formikStep2.errors.firstName}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Admin Last Name</label>

              <input
                placeholder='Last Name'
                {...formikStep2.getFieldProps('lastName')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formikStep2.touched.lastName && formikStep2.errors.lastName },
                  {
                    'is-valid': formikStep2.touched.lastName && !formikStep2.errors.lastName,
                  }
                )}
                type='text'
                name='lastName'
                autoComplete='off'
                disabled={formikStep2.isSubmitting || isOrganizationLoading}
              />
              {/* end::Input */}
              {formikStep2.touched.lastName && formikStep2.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formikStep2.errors.lastName}</span>
                </div>
              )}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='fv-row mb-5'>
              <label className='required fw-bold fs-6 mb-2'>Admin Email Address</label>

              <input
                placeholder='Email Address'
                {...formikStep2.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formikStep2.touched.email && formikStep2.errors.email },
                  {
                    'is-valid': formikStep2.touched.email && !formikStep2.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formikStep1.isSubmitting || isOrganizationLoading}
              />
              {/* end::Input */}
              {formikStep2.touched.email && formikStep2.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formikStep2.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Input group */}
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-5'>
            <button
              type='button'
              onClick={() => setStep(step - 1)}
              className='btn btn-light me-3'
              data-kt-industrys-modal-action='cancel'
              disabled={formikStep1.isSubmitting || isOrganizationLoading}
            >
              Back
            </button>

            <button
              type='button'
              onClick={handleFinalSubmit}
              className='btn btn-primary'
              data-kt-industrys-modal-action='submit'
              disabled={isOrganizationLoading || formikStep2.isSubmitting || !formikStep2.isValid || !formikStep2.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formikStep2.isSubmitting || isOrganizationLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
      )}
      {(formikStep1.isSubmitting || formikStep2.isSubmitting || isOrganizationLoading) && <OrganizationsListLoading />}
    </>
  )
}

export { OrganizationCreateModalForm }