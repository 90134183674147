import React from 'react';
import { MixedWidget6, MixedWidget14, MixedWidget15 } from '../../../_metronic/partials/widgets';

const DocketCharts = ({ colors, agencies, selectedAgency, dockets }) => {
    
    return (
        <div style={{ paddingTop: '15px' }}>
            <div className='row gy-5 g-xl-12'>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget14
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        agency={selectedAgency}
                        chartHeight='275px'
                        cardHeight="400px"
                        labels={['Rulemaking', 'Non-Rulemaking']}
                        title="Dockets"

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget14
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        agency={selectedAgency}
                        chartHeight='275px'
                        cardHeight="400px"
                        labels={['Proposed Rule', 'Rule', 'Notice', 'Supporting & Related Material', 'Other']}
                        title="Documents"

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget14
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        agency={selectedAgency}
                        chartHeight='275x'
                        cardHeight="400px"
                        labels={['Anonymous', 'Organization', 'Individual']}
                        title="Comments"

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget6
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        agency={selectedAgency}
                        chartHeight='275px'
                        cardHeight="400px"
                        labels={['Subscribed', 'Not Subscribed']}
                        docketsdata={dockets}
                        title="Dockets by Subscription"

                    />
                </div>
            </div>

            <div className="row gy-5 g-xl-12">
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget15
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        chartHeight='350px'
                        cardHeight="400px"
                        labels={['Rulemaking', 'Non-Rulemaking']}
                        agency={selectedAgency}
                        title={'Dockets by Type'}

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget15
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        chartHeight='350px'
                        cardHeight="400px"
                        labels={['Proposed Rule', 'Rule', 'Notice', 'Supporting & Related Material', 'Other']}
                        agency={selectedAgency}
                        title={'Documents by Type'}

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget15
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        chartHeight='350px'
                        cardHeight="400px"
                        labels={['Anonymous', 'Organization', 'Individual']}
                        agency={selectedAgency}
                        title={`Comments by Type`}

                    />
                </div>
                <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                    <MixedWidget15
                        className='card-xxl-stretch mb-xl-3'
                        chartColor={colors}
                        chartHeight='350px'
                        cardHeight="400px"
                        labels={['Active', 'Inactive']}
                        agency={selectedAgency}
                        title={'Dockets by Activity'}

                    />
                </div>
            </div>
        </div>
    );
};

export default DocketCharts;