import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import {
    MixedWidget9,
    MixedWidget11,
    MixedWidget10,
    ListsWidget26
} from '../../../_metronic/partials/widgets'

const HistoricalActivityCharts = ({ colors, data }) => (
    <div className="pb-5 pt-3">
        <div className="pb-2 pt-7 text-center">
            <h1 className="d-flex align-items-center justify-content-center">
                <span style={{ position: 'relative', top: '2px' }}>
                    <KTIcon iconName='chart-simple' className='fs-1 me-2' />
                </span>
                Historical Activity
                {!(data.length > 0) && (
                    <div className="spinner-border text-primary ms-3" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )}
            </h1>
        </div>
        {data.length > 0 ? (
            <div>
                <div className='row g-5 g-xl-10 mb-n4 '>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-12'>

                        <ListsWidget26
                            className='h-md-100 mb-5 mb-xl-10'
                            data={data}
                        />
                    </div>

                </div>
                <div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mt-1 mb-4 px-15'>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='200px'
                                data={data}
                                title="Total Documents By Agency"
                                columns={{
                                    "Total Documents": "doc_total"
                                }}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='200px'
                                data={data}
                                title="Total Comments By Agency"
                                columns={{
                                    "Total Comments": "comment_total",
                                    "Comment Attachments": "comment_attach_total"
                                }}
                                activity={"all"}
                            />
                        </div>
                    </div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-5 px-15'>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='200px'
                                data={data}
                                title="CMS Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting": "supporting_total",
                                    "Other": "other_total"
                                }}
                                agency="CMS"
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='200px'
                                data={data}
                                title="FDA Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting": "supporting_total",
                                    "Other": "other_total"
                                }}
                                agency="FDA"
                                activity={"all"}
                            />
                        </div>
                    </div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-5 px-15'>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='300px'
                                data={data}
                                title="Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting Materials": "supporting_total",
                                    "Other": "other_total"
                                }}
                                agency={"CMS"}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='300px'
                                data={data}
                                title="Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting Materials": "supporting_total",
                                    "Other": "other_total"
                                }}
                                agency={"FDA"}
                                activity={"all"}
                            />
                        </div>
                    </div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-1 px-15'>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='300px'
                                data={data}
                                title="Comments By Type"
                                columns={{
                                    "Anonymous": "anon_total",
                                    "Organization": "org_total",
                                    "Individual": "individual_total",
                                }}
                                agency={"CMS"}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-6 pe-10'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='300px'
                                data={data}
                                title="Comments By Type"
                                columns={{
                                    "Anonymous": "anon_total",
                                    "Organization": "org_total",
                                    "Individual": "individual_total",
                                }}
                                agency={"FDA"}
                                activity={"all"}
                            />
                        </div>
                    </div>
                </div>

            </div>
        ) : (
            <div style={{ height: '500px' }}></div>
        )}
    </div>
);

export default HistoricalActivityCharts;