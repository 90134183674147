import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import PdfViewer from './PdfViewer';

const PdfWrapper: FC = () => {
    
    const [searchParams] = useSearchParams();
    const documentId = searchParams.get('id');

    return (
        <> 
            <PdfViewer documentId={documentId} />
        </>
    );
}

export default PdfWrapper;
