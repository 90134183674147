import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Import axios
import { KTIcon } from '../../../_metronic/helpers';
import DocumentFilter from './DocumentFilter';
import { useAuth } from '../../modules/auth';
import SearchBar from './SearchBar';
// import { Link } from 'react-router-dom';
import DocumentTable from './DocumentTable';
import { useNavigate, useLocation } from 'react-router-dom';
import SortFilter from './SortFilter';
// import clsx from 'clsx';
import { DocumentsListPagination } from './DocumentsListPagination';
// import { MixedWidget11, MixedWidget10 } from '../../../_metronic/partials/widgets';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MixedWidgetWarning } from '../Home/MixedWidgetWarning';
// import Fuse from 'fuse.js';

const WarningLetters = () => {
    // Define your docket details or fetch them from an API

    const [documents, setDocuments] = useState(null);
    const { documentType } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const moment = require('moment-timezone');
    const location = useLocation();
    const stateData = location.state || { agency: '', period_code: 0 };
    const [filteredDocuments, setFilteredDocuments] = useState(null);
    const [filterDocument, setFilterDocument] = useState('');
    const [filterProgram, setFilterProgram] = useState('');
    const [filterStartDate, setFilterStartDate] = useState(stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '');
    const [filterEndDate, setFilterEndDate] = useState('');
    // const [filterTracked, setFilterTracked] = useState(documentType ? true : false);
    const [filterSubject, setFilterSubject] = useState(stateData.agency || '');
    const [searchInput, setSearchInput] = useState('');
    const { currentUser, logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'posted_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [trackedDocuments, setTrackedDocuments] = useState([]);
    const interests = useSelector(state => state.account.interests);
    // const limit = 18446744073709551615; // Set your desired limit here
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    // const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    // const [data, setData] = useState([]);
    const [isInsightsVisible, setIsInsightsVisible] = useState(false);

    // const fetchData = useCallback(() => {
    //     axios.get(`reg/v1/home/insights/documents`)
    //         .then(response => {
    //             const sortedData = response.data;
    //             setData(sortedData);
    //         })
    //         .catch(error => {
    //             if (error.response && error.response.status === 401) {
    //                 logout();
    //                 navigate('/auth/login')
    //             }
    //             console.error('There was an error!', error);
    //         });

    // }, [logout, navigate]);

    // useEffect(() => {
    //     Promise.all([
    //         fetchData()
    //     ]).then(() => { }).catch((error) => {
    //         console.error(error.message);
    //     });
    // }, [fetchData]);


    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);


    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment(timestamp);

        return date.tz("America/New_York").format('YYYY-MM-DD');
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp);
        return date.tz("America/New_York").format('YYYY-MM-DD');
    }, [moment]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        try {
            // Attempt to load cached documents from localStorage
            const cachedDocuments = localStorage.getItem('warning_letters');
            let sortedData;

            if (cachedDocuments) {
                sortedData = JSON.parse(cachedDocuments);
            } else {
                const response = await axios.get(`/reg/v1/warning_letter/fda_warning_letters_by_program/`);
                sortedData = [...response.data.data];
                try {
                    localStorage.setItem('warning_letters', JSON.stringify(sortedData));
                }
                catch (e) {
                    if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                        console.error("LocalStorage quota exceeded.");
                        // Optionally, implement fallback storage or notify the user
                    } else {
                        console.error(e);; // Re-throw the error if it's not a QuotaExceededError
                    }
                }
            }

            let filteredData = sortedData;

            filteredData.sort((a, b) => new Date(b.posted_date) - new Date(a.posted_date));

            // const newPrograms = interests.programs.map(program => {
            //     const match = program ? program.match(/^[^(]*/) : undefined;
            //     return match && match[0] ? match[0].trim() : program;
            // });

            // if (interests.agencies.length > 0 || newPrograms.length > 0) {
            //     filteredData = filteredData.filter(document =>
            //         (newPrograms.length === 0 || newPrograms.includes(document.issuing_office))
            //     );
            // }

            // if (filterSubject) {
            //     filteredData = filteredData.filter((document) =>
            //         document.agencyId.toLowerCase().includes(filterSubject.toLowerCase())
            //     );
            // }



            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filteredData = filteredData.filter((document) => {
                    const modifyDateTimestamp = new Date(document.posted_date).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }

            // Cache the filtered documents in localStorage

            setDocuments(filteredData);
            setFilteredDocuments(filteredData);
            setError(null);
        } catch (error) {
            setDocuments(null);
            setFilteredDocuments(null);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [filterStartDate]);

    // const fetchDocuments = useCallback(async () => {
    //     setLoading(true);
    //     // console.log("Loaded Interests:", interests);
    //     // Make a GET request to fetch documents data
    //     try {
    //         const response = await axios.get(`/reg/v1/document/all/notice/${limit}/0`);
    //         let sortedData = [...response.data];
    //         let filteredData = documentType ? sortedData.filter(document => document.documentType === 'Notice') : sortedData;
    //         const newPrograms = interests.programs.map(program => {
    //             const match = program ? program.match(/\((.*?)\)/) : undefined;
    //             return match && match.length > 1 ? match[1] : program;
    //         });
    //         if (interests.agencies.length > 0 || newPrograms.length > 0) {
    //             filteredData = filteredData.filter(document =>
    //                 (interests.agencies.length === 0 || interests.agencies.includes(document.agencyId)) &&
    //                 (newPrograms.length === 0 || newPrograms.includes(document.program))
    //             );
    //         }
    //         setDocuments(filteredData);
    //         setFilteredDocuments(filteredData);
    //         setError(null);
    //     } catch (error) {
    //         setDocuments(null);
    //         setFilteredDocuments(null);
    //         setError(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }, [documentType, interests]);

    const applyFilters = useCallback(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterDocument) {
                filtered = filtered.filter((document) =>
                    document.company_name.toLowerCase().includes(filterDocument.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.issuing_office.toLowerCase().includes(filterProgram.toLowerCase()));
            }
            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.posted_date).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filterEndDate) {
                const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.posted_date).getTime();
                    return modifyDateTimestamp <= endTimestamp
                });
            }

            if (filterSubject) {
                filtered = filtered.filter((document) =>
                    document.subject.toLowerCase().includes(filterSubject.toLowerCase())
                );
            }

            setFilteredDocuments(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDocument, filterProgram, filterStartDate, filterEndDate, filterSubject]);

    const handleApplyFilters = useCallback(() => {
        applyFilters();
        //setCurrentPage(1);
    }, [applyFilters]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchInput.trim() !== '') {
                setLoading(true);
                try {
                    // Make a GET request to fetch documents data if no cache is found
                    const response = await axios.post(`/reg/v1/search/search/warning-letter?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchInput}`);
                    if (response.data) {
                        setDocuments(response.data);
                        setFilteredDocuments(response.data);
                        setTotalPages(Math.ceil(response.data[0]?.search_code / Math.max(1, itemsPerPage)));
                    }
                } catch (error) {
                    console.error("Error fetching documents:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setDocuments([]);
                setFilteredDocuments([]);
                fetchDocuments();
                // fetchTrackedDocuments();
            }
            handleApplyFilters();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDocuments, interests, searchInput, itemsPerPage, handleApplyFilters, currentPage]);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);

    // useEffect(() => {
    //     if (!loading && documents?.length > 0) {
    //         const delayDebounceFn = setTimeout(() => {
    //             if (searchInput.trim() === '') {
    //                 setFilteredDocuments(documents);
    //             } else {

    //                 const options = {
    //                     includeScore: true,
    //                     // Add other options based on your requirements
    //                     keys: [
    //                         //     {
    //                         //     name: 'company_name',
    //                         //     weight: 0.5,
    //                         //     getFn: (item, path) => {
    //                         //         // Normalize by removing hyphens and converting to lowercase for comparison
    //                         //         const normalizedItemId = item.company_name.replace(/-/g, '').toLowerCase();
    //                         //         const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
    //                         //         if (normalizedItemId.includes(normalizedSearchInput)) {
    //                         //             return [item.company_name]; // Return the 'id' in an array if it matches after normalization
    //                         //         }
    //                         //         return []; // Return an empty array if not a match
    //                         //     }
    //                         // }, {
    //                         //     name: 'subject',
    //                         //     weight: 0.4,
    //                         //     getFn: (item, path) => {
    //                         //         // Normalize by removing hyphens and converting to lowercase for comparison
    //                         //         const normalizedItemId = item.subject.replace(/-/g, '').toLowerCase();
    //                         //         const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
    //                         //         if (normalizedItemId.includes(normalizedSearchInput)) {
    //                         //             return [item.subject]; // Return the 'id' in an array if it matches after normalization
    //                         //         }
    //                         //         return []; // Return an empty array if not a match
    //                         //     }



    //                         // }, 
    //                         {
    //                             name: 'company_name',
    //                             weight: 0.4 // Added weight for document_title
    //                         }, {
    //                             name: 'subject',
    //                             weight: 0.3 // Added weight for dk_title
    //                         }, 'issuing_office', 'warning_letter_url',
    //                         {
    //                             name: 'dateFields',
    //                             weight: 0.1,
    //                             getFn: (item, path) => {
    //                                 // Assuming formatDate and formatDate2 return dates in 'YYYY-MM-DD' format
    //                                 let postedDate = formatDate2(item.posted_date);
    //                                 let issueDate = formatDate2(item.letter_issue_date);


    //                                 // Assuming searchInput is the date in 'YYYY-MM-DD' format
    //                                 const normalizedSearchInput = searchInput.trim();

    //                                 let matches = [];
    //                                 // Check if any of the date fields include the search input and add them to matches array
    //                                 if (postedDate?.includes(normalizedSearchInput)) {
    //                                     matches.push(postedDate);
    //                                 }
    //                                 if (issueDate?.includes(normalizedSearchInput)) {
    //                                     matches.push(issueDate);
    //                                 }

    //                                 return matches; // Return an array of matching date strings
    //                             }
    //                         }
    //                     ],
    //                     ignoreLocation: true, // This helps in making the search fuzzier
    //                     threshold: 0.3, // Adjust this threshold to make the search stricter or fuzzier
    //                 };

    //                 // Create a new instance of Fuse with the documents and options
    //                 const fuse = new Fuse(documents, options);

    //                 // Perform the search
    //                 const result = fuse.search(searchInput.trim());

    //                 // Map the result to get the original documents
    //                 const filteredDocuments = result.map(resultItem => resultItem.item);

    //                 setFilteredDocuments(filteredDocuments);


    //             }
    //         }, 500) // delay in ms

    //         return () => clearTimeout(delayDebounceFn) // this will clear the timeout if the component is unmounted or if the dependencies change
    //     }
    // }, [searchInput, documents, loading, formatDate, formatDate2]);

    useEffect(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterDocument) {
                filtered = filtered.filter((document) =>
                    document.company_name.toLowerCase().includes(filterDocument.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.issuing_office.toLowerCase().includes(filterProgram.toLowerCase()));
            }
            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.posted_date).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filterEndDate) {
                const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.posted_date).getTime();
                    return modifyDateTimestamp <= endTimestamp
                });
            }

            if (filterSubject) {
                filtered = filtered.filter((document) =>
                    document.subject.toLowerCase().includes(filterSubject.toLowerCase())
                );
            }

            setFilteredDocuments(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDocument, filterProgram, filterStartDate, filterEndDate, filterSubject, loading]);


    const handleResetFilters = () => {
        // Clear filter state variables
        setFilterDocument('');
        setFilterStartDate('');
        setFilterEndDate('');
        setFilterSubject('');
        setFilterProgram('');
        // setFilterTracked(false);
        setFilteredDocuments(documents);
    };




    const handleTrackDocument = (id) => {
        // setLoading(true);
        axios.post(`/reg/v1/document/track/${currentUser?.id}/${id}`)
            .then((response) => {
                setAlert({ message: response.data.message, type: 'success' });
                // fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {
                            // console.log('Toggling track for document:', doc);
                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });

                // setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    setError(error);
                }
                // setLoading(false);
            });
        // fetchTrackedDocuments();
    }

    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`;
        window.open(url, '_blank');

    };
    const handleViewButtonDOCClick = (documentId) => {
        const url = `/viewtxt?id=${documentId}`;
        window.open(url, '_blank');
    };

    const handleAttachmentClick = (id, filetype, type) => {

        const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
        window.open(url, '_blank');
    };

    // const handleAttachmentClick2 = (url) => {
    //     window.open(url, '_blank');
    // };

    const onApplySort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }

        let sortedDocuments = [...filteredDocuments];
        if (sortConfig.direction === 'asc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setFilteredDocuments(sortedDocuments);
    };
    // Define onResetSort function
    const onResetSort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }
        let sortedDocuments = [...filteredDocuments];
        sortedDocuments.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'posted_date', direction: 'desc' });
        setFilteredDocuments(sortedDocuments);

    };
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        if (searchInput.trim() === '') {
            setTotalPages(Math.ceil(filteredDocuments?.length / Math.max(1, itemsPerPage)));
            // setCurrentPage(1);
        }
    }, [filteredDocuments, itemsPerPage, searchInput]);

    useEffect(() => {
        const trimmedSearchInput = searchInput.trim();
        if (trimmedSearchInput === '' || trimmedSearchInput !== '') {
            setCurrentPage(1);
        }
    }, [searchInput]);

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='document' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span>{documentType ? 'My Items - ' : ''}Warning Letters</span>
                                {loading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center justify-content-between">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    <button type="button" className="btn btn-sm btn-light-primary btn-active-light border border-1" onClick={() => setIsInsightsVisible(!isInsightsVisible)}>
                        <KTIcon iconName='chart-simple' className='fs-2' />
                        {isInsightsVisible ? 'Hide' : 'Show'} Insights
                    </button>
                </div>
                {interests.agencies && programs &&
                    <div className="d-flex flex-row-fluid px-10">
                        {interests.agencies.length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                                {Object.keys(programs).length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {Object.keys(programs).length > 0 && (
                            <div style={{ paddingLeft: interests.agencies.length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-md-block text-wrap mt-1`}>Programs: {' '}
                                    {Object.entries(programs).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                            </div>
                        )}
                    </div>
                }
                <div className="d-flex flex-column px-10 align-items-start">
                    {(filterDocument || filterProgram || filterStartDate || filterEndDate || filterSubject) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterDocument && <li><h6 className="d-md-block text-wrap">Document Title: {filterDocument}</h6></li>}
                                {filterSubject && <li><h6 className="d-md-block text-wrap">Agency: {filterSubject}</h6></li>}
                                {filterProgram && <li><h6 className="d-md-block text-wrap">Program: {filterProgram}</h6></li>}
                                {(filterStartDate || filterEndDate) && (
                                    <li>
                                        <h6 className="d-md-block text-wrap">
                                            Comment Date {filterStartDate && filterEndDate ? 'Range' : (filterStartDate ? 'Start' : 'End')}: {' '}
                                            {filterStartDate ? filterStartDate : ''}
                                            {filterStartDate && filterEndDate ? ' to ' : ''}
                                            {filterEndDate ? filterEndDate : ''}
                                        </h6>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                {/* {isInsightsVisible && (
                    <div className='row g-5 gx-xxl-14 justify-content-center mt-1 mb-4 px-10'>
                        <div className='col-xxl-4'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="Total Documents By Agency"
                                columns={{
                                    "Total Documents": "doc_total"
                                }}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="FDA Documents By Type"
                                columns={{
                                    "Other": "other_total"
                                }}
                                agency="FDA"
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="CMS Documents By Type"
                                columns={{
                                    "Other": "other_total"
                                }}
                                agency="CMS"
                                activity={"all"}
                            />
                        </div>
                    </div>
                )} */}

                {isInsightsVisible && (
                    <div className='row g-5 gx-xxl-14 justify-content-center mt-1 mb-4 px-10'>
                        <div className='col-7'>
                            <MixedWidgetWarning
                                className='card-xxl-stretch pt-3'
                                chartHeight='275px'
                                days={0}
                                doctype={'Warning Letters'}
                                activity={"all"}
                                scale="logarithmic"
                                showChart={true}
                            />
                        </div>
                    </div>
                )}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                    <div className="col-7">
                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DocumentFilter
                            filterDocument={filterDocument}
                            setFilterDocument={setFilterDocument}
                            filterProgram={filterProgram}
                            setFilterProgram={setFilterProgram}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterSubject={filterSubject}
                            setFilterSubject={setFilterSubject}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                        </button>
                        <SortFilter
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                        <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>

                {/* <div className="d-flex flex-column-auto  align-items-center mt-4 mx-5 p-3">


                    <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />

                    <div className="d-flex flex-column-auto  ms-4    ">
                        <button
                            type='button'
                            className='btn btn-light-primary    btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >

                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <DocumentFilter
                            filterDocument={filterDocument}
                            setFilterDocument={setFilterDocument}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterTracked={filterTracked}
                            filterSubject={filterSubject}
                            setfilterSubject={setfilterSubject}
                            setFilterTracked={setFilterTracked}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}

                        />
                    </div>

                    <div className="d-flex flex-column-auto  ms-4    ">
                        <button
                            type='button'
                            className='btn btn-light-primary    btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >

                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort

                        </button>
                        <SortFilter
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                </div> */}
                <DocumentTable loading={loading} error={error} filteredDocuments={filteredDocuments} currentPage={currentPage}
                    DOCUMENTS_PER_PAGE={Math.max(itemsPerPage, 1)} trackedDocuments={trackedDocuments} handleTrackDocument={handleTrackDocument}
                    handleViewButtonPDFClick={handleViewButtonPDFClick} handleViewButtonDOCClick={handleViewButtonDOCClick}
                    handleAttachmentClick={handleAttachmentClick} formatDate={formatDate} formatDate2={formatDate2}
                    programs={programs} searchInput={searchInput}
                />

                {/* {filteredDocuments.length === 0 && !loading && (
                    <div className="d-flex ms-10 mb-5">
                        <h3>No documents found with selected filters. Please try again with other filters.</h3>
                    </div>
                )} */}
                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">

                        <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                            <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                            <input
                                type="number"
                                value={itemsPerPage}
                                onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                className='form-control form-control-solid ms-2 border border-1'
                                style={{ width: '90px', backgroundColor: '#f1faff' }}
                            />
                        </div>
                        <DocumentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }

            </div>

        </div>



    );
};

export default WarningLetters;






