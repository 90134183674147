import { UPDATE_AVATAR, UPDATE_INTERESTS, UPDATE_ORGANIZATION, UPDATE_ADDONS, LOGOUT, UPDATE_ORGANIZATION_LOGO } from './types';

export function updateAvatar(url) {
  return { type: UPDATE_AVATAR, url };
}

export function updateInterests(interests) {
  return { type: UPDATE_INTERESTS, interests };
}

export function updateOrganization(organization) {
  return { type: UPDATE_ORGANIZATION, organization };
}

export function updateAddons(addons) {
  return { type: UPDATE_ADDONS, addons };
}

export function logout() {
  return { type: LOGOUT };
}

export function updateOrganizationLogo(logo) {
  return { type: UPDATE_ORGANIZATION_LOGO, logo };
}