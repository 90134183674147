import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';
import axios from 'axios';

type Props = {
    className: string;
    chartHeight: string;
    data?: DataItem[];
    title: string;
    activity: string;
    columns: { [key: string]: any; };
    scale: string;
    days: number;
    doctype: string;
};

interface DataItem {
    agencyId: string | null;
    att_count: number | null;
    attachments: Array<any> | null;
    authors: string | null;
    comm_count: number | null;
    commentEndDate: number | null;
    commentStartDate: number | null;
    docAbstract: string | null;
    doc_sentiment: number | null;
    docketId: string | null;
    documentType: string | null;
    document_title: string | null;
    fileUrl1: string | null;
    id: string | null;
    iddocument: number | null;
    is_starred: boolean | null;
    modifyDate: string | null;
    objectId: string | null;
    page_count: number | null;
    postedDate: string | null;
    program: string | null;
    reasonWithdrawn: string | null;
    receiveDate: string | null;
    s3_pdf_link: string | null;
    s3_text_link: string | null;
    startEndPage: string | null;
    subject: string | null;
    track: string | null;
    track_date: string | null;
    userid: string | null;
    [key: string]: any;
    category: string;
}

const MixedWidgetGuidances: React.FC<Props> = ({ className, chartHeight, days, doctype }) => {
    // Step 1: Group by Program

    const [data, setData] = useState<DataItem[]>([]);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        axios.get(`reg/v1/document/preference/document/graph/${doctype}?days=${days}`)
            .then(response => {
                setData(response.data); // Assuming response.data is an array of DataItem objects
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [days, doctype]);
    const programDataSeries: Highcharts.SeriesOptionsType[] = data
        .filter(item => item.category === 'program')
        .map(item => ({
            name: item.name || 'Unknown', // Use 'Unknown' for items without a name
            data: [{ y: item.total_docs }],
            type: 'column' as const, // Explicitly declare the series type as 'column'
        }))
        .sort((a, b) => a.name.localeCompare(b.name));


    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        xAxis: {
            type: 'category',
            // Set categories to program names
            title: {
                text: 'Programs',
            },
            labels: {
                enabled: false // This will hide all labels on the xAxis
            },
            width: '90%',

        },
        title: {
            text: ''
        },
        yAxis: {
            type: 'logarithmic',
            title: {
                text: 'Documents',
            },
            // Adds minor ticks to make the scale clearer

        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart
            width: '80%',
            margin: 5,
            itemMarginBottom: 1,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,
            },

        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                // Assuming 'this' refers to the point or series with access to its name and y (value)
                // Customize this string to match your desired tooltip format
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            }
        },
        colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        series: programDataSeries, // Use the new series array
    };

    return (
        <div className={`card ${className} `} >

            <div className='card-body  d-flex flex-column '>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </div>
    );
};

export { MixedWidgetGuidances };