import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import { useSelector } from 'react-redux'
import axios from 'axios';
import Select from 'react-select'
import { useAuth } from '../../../modules/auth'
const orgInterestsSchema = Yup.object().shape({
    regions: Yup.array(),
    agencies: Yup.array(),
    programs: Yup.array(),
    device_classes: Yup.array(),
    committees: Yup.array()
})

type OptionType = {
    value: string;
    label: string;
    id: number;
    is_active: boolean;
    preference_type: number;
};

type FormValues = {
    regions: string[];
    agencies: string[];
    programs: string[];
};

const Configuration: React.FC = () => {

    const [loading, setLoading] = useState(true);
    const [agenciesOptions, setAgenciesOptions] = useState<OptionType[]>([]);
    const [programsOptions, setProgramsOptions] = useState<OptionType[]>([]);
    const [regionsOptions, setRegionsOptions] = useState<OptionType[]>([]);
    // const avatarUrl = useSelector((state: any) => state.account.avatarUrl);
    const { currentUser } = useAuth();

    const formik = useFormik<FormValues>({
        initialValues: {
            regions: [],
            agencies: [],
            programs: [],
        },
        validationSchema: orgInterestsSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                // console.log(values);
                const programs = values.programs.map(program => {
                    const match = program ? program.match(/\((.*?)\)/) : undefined;
                    return match && match.length > 1 ? match[1] : program;
                });
                // console.log(programs);

                const requests = [
                    axios.patch('/reg/v1/user/regions', values.regions),
                    axios.patch('/reg/v1/user/agencies', values.agencies),
                    axios.patch('/reg/v1/user/fdaprograms', programs)
                ];

                const responses = await Promise.all(requests);

                if (responses.every(response => response.status === 200 || response.status === 201)) {
                    alert('Active Status for Preference Options Updated Successfully');
                }
            } catch (error) {
                // Handle network errors or exceptions here.
                console.error('Update failed:', error);
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        const fetchRegions = axios.get('/reg/v1/user/regions')
            .then(response => {
                const options = response.data.map(region => ({
                    value: region.region_code,
                    label: region.region_code,
                    active: region.is_active,
                    id: region.region_id, // Save the id
                    preference_type: 1 // Set focus_area to 2 for agencies
                }));
                return options;
            });
        const fetchAgencies = axios.get('/reg/v1/user/agencies')
            .then(response => {
                const options = response.data.map(agency => ({
                    value: agency.short_name,
                    label: agency.short_name,
                    active: agency.is_active,
                    id: agency.id, // Save the id
                    focus_area: 2 // Set focus_area to 2 for agencies
                }));
                return options;
            });

        const fetchPrograms = axios.get('/reg/v1/user/fdaprograms')
            .then(response => {
                const options = response.data.map(program => ({
                    value: program.code,
                    label: `${program.program} (${program.code})`,
                    active: program.is_active,
                    id: program.program_id, // Save the id
                    focus_area: 3 // Set focus_area to 3 for programs
                }));
                return options;
            });

        if (!currentUser?.organization) {
            console.error('currentUser or currentUser.organization is undefined');
            return;
        }

        Promise.all([fetchAgencies, fetchPrograms, fetchRegions])
            .then(([agenciesOptions, programsOptions, regionsOptions]) => {
                // Fetch the initial values when the component mounts
                setAgenciesOptions(agenciesOptions);
                setProgramsOptions(programsOptions);
                setRegionsOptions(regionsOptions);

                const initialValues = {
                    regions: regionsOptions
                        .filter(region => region.active === 1)
                        .map(region => region.label),
                    agencies: agenciesOptions
                        .filter(agency => agency.active === 1)
                        .map(agency => agency.label),
                    programs: programsOptions
                        .filter(program => program.active === 1)
                        .map(program => program.label),
                };

                formik.setValues(initialValues);
                setLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [currentUser?.organization]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className="row">
                <div
                    className='col-sm card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_pref_config'
                    aria-expanded='true'
                    aria-controls='kt_pref_config'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0 me-3'>Preferences</h3>
                        {loading && <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </div>

                </div>

            </div>

            <div id='kt_pref_config' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        {/* <div className="col-sm" >
              <div className='card-body d-flex justify-content-end py-0 px-0'>
                <button type='submit' className='btn btn-primary no-collapse' disabled={loading} >
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div> */}

                        <div className='row mb-6'>
                            <label className='col-lg-2 col-form-label  fw-bold fs-6'>Regions</label>

                            <div className='col-lg-9'>
                                <Select
                                    isMulti
                                    className=' fw-bold mb-3 mb-lg-0'
                                    placeholder='Select Regions'
                                    options={regionsOptions.filter(option => !formik.values.regions.includes(option.label))}
                                    value={formik.values.regions ? formik.values.regions.map(region => ({ value: region, label: region })) : []}
                                    onChange={selectedOptions => {
                                        const selectedRegionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                        formik.setFieldValue('regions', selectedRegionIds);
                                    }}
                                    isDisabled={false}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                                    }}
                                    menuPortalTarget={document.body}
                                />
                                {formik.touched.regions && formik.errors.regions && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.regions}</div>
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                <span className=''>Agencies</span>
                            </label>

                            <div className='col-lg-9 fv-row'>
                                <Select
                                    isMulti
                                    className=' fw-bold mb-3 mb-lg-0'
                                    placeholder='Agencies of Interest'
                                    options={agenciesOptions.filter(option => !formik.values.agencies.includes(option.label))}
                                    value={formik.values.agencies ? formik.values.agencies.map(agency => ({ value: agency, label: agency })) : []}
                                    onChange={selectedOptions => {
                                        const selectedAgencyLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                                        formik.setFieldValue('agencies', selectedAgencyLabels);
                                    }}
                                    isDisabled={false}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                                    }}
                                    menuPortalTarget={document.body}
                                />
                                {formik.touched.agencies && formik.errors.agencies && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.agencies}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                <span className=''>Programs</span>
                            </label>

                            <div className='col-lg-9 fv-row'>
                                <Select
                                    isMulti
                                    className=' fw-bold mb-3 mb-lg-0'
                                    placeholder='Programs of Interest'
                                    options={programsOptions.filter(option => !formik.values.programs.includes(option.label))}
                                    value={formik.values.programs ? formik.values.programs.map(program => ({ value: program, label: program })) : []}
                                    onChange={selectedOptions => {
                                        const selectedProgramLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                                        formik.setFieldValue('programs', selectedProgramLabels);
                                    }}
                                    isDisabled={false}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                                    }}
                                    menuPortalTarget={document.body}
                                />
                                {formik.touched.programs && formik.errors.programs && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.programs}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Update Status'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>


            </div>
        </div>
    )
}

export { Configuration }