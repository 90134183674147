const RegDetailsFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters,
    objType
}) => {
    return (
        <div className="dropdown dropdown-menu w-250px w-md-300px" aria-labelledby="dropdownMenuButton">
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5' style={{ maxHeight: '275px', overflow: 'auto' }}>

                <div className='mb-7'>
                    <label className='form-label fw-bold'>
                        {objType === 1 ? 'Docket Title' :
                            (objType === 2 || objType === 8) ? 'Document Title' :
                                (objType === 4 || objType === 5) ? 'Attachment Title' :
                                    (objType === 3) ? 'Comment Title' : 'Title'}:
                    </label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder={objType === 1 ? 'Docket Title' :
                            (objType === 2 || objType === 8) ? 'Document Title' :
                                (objType === 4 || objType === 5) ? 'Attachment Title' :
                                    (objType === 3) ? 'Comment Title' : 'Title'}
                        value={filters.title}
                        onChange={(e) => onFilterChange('title', e.target.value)}

                    />
                </div>

                <div className='mb-7'>
                    <label className='form-label fw-bold'>
                        {objType === 1 ? 'Docket ID' :
                            (objType === 2 || objType === 5 || objType === 8) ? 'Document ID' :
                                (objType === 3 || objType === 4) ? 'Comment ID' : 'ID'}:
                    </label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder="FDA-2023-****"
                        value={filters.id}
                        onChange={(e) => onFilterChange('id', e.target.value)}
                    />
                </div>

                {(objType === 1 || objType === 2 || objType === 3 || objType === 8) && (
                    <div className='mb-7'>
                        <label className='form-label fw-bold'>
                            {objType === 1 ? 'Docket Type' :
                                (objType === 2 || objType === 8) ? 'Document Type' : 'Category'}:
                        </label>
                        <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder={objType === 1 ? 'Docket Type' :
                                (objType === 2 || objType === 8) ? 'Document Type' : 'Category'}
                            value={filters.type}
                            onChange={(e) => onFilterChange('type', e.target.value)}
                        />
                    </div>
                )}

                <div>
                    <label className='form-label fw-bold'>Program:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CDRH, CBER, etc.'
                        value={filters.program}
                        onChange={(e) => onFilterChange('program', e.target.value)}

                    />
                </div>

            </div>

            <div className='px-7 py-5 mb-2'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegDetailsFilter;