import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Users } from './Users'
import { ProfileHeader2 } from './ProfileHeader'
import { Settings } from './Settings'
import { OrgInterests } from './OrgInterests'
import { ProfileUpdateContext } from './ProfileUpdateContext'
import { useState } from 'react'
import GroupWrapper from './GroupWrapper'
import AddOnsWrapper from './AddOnsWrapper'
import Feedback from './Feedback/Feedback'
import Announcements from './Announcement'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Admin',
    path: '/admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  return (
    <ProfileUpdateContext.Provider value={{ isProfileUpdated, setIsProfileUpdated }}>
      <ProfileHeader2 />
      <Routes>
        {/* <Route
      element={
        <>
          <ProfileHeader2 />
          <Outlet />
        </>
      }
    > */}
        <Route
          path='users'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
              <Users />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Settings</PageTitle>
              <Settings />
            </>
          }
        />
        <Route
          path='preferences'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Organization Preferences</PageTitle>
              <OrgInterests />
            </>
          }
        />
        <Route
          path='add-ons'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Add Ons</PageTitle>
              <AddOnsWrapper />
            </>
          }
        />
        <Route
          path='groups'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Group Management</PageTitle>
              <GroupWrapper />
            </>
          }
        />
        <Route
          path='support'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Support</PageTitle>
              <Feedback />
            </>
          }
        />
        <Route
          path='announcements'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}> Announcements</PageTitle>
              <Announcements />
            </>
          }
        />

        <Route index element={<Navigate to='/admin/users' />} />
        {/* </Route> */}
      </Routes>
    </ProfileUpdateContext.Provider>

  );
};

export default ProfilePage