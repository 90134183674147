import React, { useEffect } from 'react';
import axios from 'axios';
import ViewSDKClient from './ViewSDKClient';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
function FullWindow({ documentId, type }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { logout } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchAndDisplayPDF = async () => {
            try {
                // Fetch the PDF data from the backend
                const response = await axios.get(`/reg/v1/${type}/attachment/${documentId}`, { responseType: 'arraybuffer' });
                const fileData = new Uint8Array(response.data);
                const filePromise = Promise.resolve(fileData);

                // Create an instance of ViewSDKClient and configure the viewer
                const viewSDKClient = new ViewSDKClient();
                viewSDKClient.ready().then(() => {
                    viewSDKClient.previewFileUsingFilePromise("pdf-div", filePromise, type, { embedMode: "FULL_WINDOW", defaultViewMode: "FIT_PAGE", showAnnotationTools: false, showThumbnails: false, showDownloadPDF: true });
                });
            } catch (error) {
                if (error?.response?.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.log('Error fetching PDF:', error);
                }
            }
        };

        fetchAndDisplayPDF();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId, type, logout, navigate]);

    return (
        <div>
            <div id="pdf-div" className="full-window-div" style={{ position: 'absolute', width: '80%', height: '85%' }}>
                {/* Add CSS styles for full window display */}
            </div>
        </div>
    );
}

export default FullWindow;