import React from 'react';
import clsx from 'clsx';

const DocketsListPagination = ({ totalPages, currentPage, setCurrentPage }) => {
    const PAGINATION_PAGES_COUNT = 6;

    const sliceLinks = (totalPages, currentPage) => {
        let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

        if (pageLinks.length > PAGINATION_PAGES_COUNT) {
            const start = currentPage - Math.floor(PAGINATION_PAGES_COUNT / 2);
            const end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2);
            pageLinks = pageLinks.slice(Math.max(start, 0), Math.min(end, pageLinks.length));
        }

        return pageLinks;
    }

    const paginationLinks = sliceLinks(totalPages, currentPage);

    return (
        <div className='row mt-4 mb-4'>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        <li className={clsx('page-item', { 'disabled': currentPage === 1 })}>
                            <button onClick={() => setCurrentPage(1)} style={{ cursor: 'pointer' }} className='page-link'>First</button>
                        </li>
                        {paginationLinks.map((page) => (
                            <li key={page} className={clsx('page-item', { 'active': currentPage === page })}>
                                <button onClick={() => setCurrentPage(page)} style={{ cursor: 'pointer' }} className='page-link'>{page}</button>
                            </li>
                        ))}
                        <li className={clsx('page-item', { 'disabled': currentPage === totalPages })}>
                            <button onClick={() => setCurrentPage(totalPages)} style={{ cursor: 'pointer' }} className='page-link'>Last</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { DocketsListPagination }