import { FC } from 'react'

type Props = {
  industry?: string
}

const OrganizationIndustryCell: FC<Props> = ({ industry }) => (
  <div className='badge badge-light-primary fw-bolder'>{industry}</div>
)

export { OrganizationIndustryCell }
