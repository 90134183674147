/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from 'react';
import axios from 'axios';
type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  labels: string[];
  title: string;
  agency: string;
  cardHeight: string;
}

// const MixedWidget14: React.FC<Props> = ({ className, chartColor, chartHeight, docketsdata, title }) => {

const MixedWidget6: React.FC<Props> = ({ className, chartHeight = "350px", title, labels = [], chartColor, agency = 'CMS', cardHeight }) => {
  const [data, setData] = useState<number[]>([]);

  //console.log('labels:', labels);
  //console.log('data:', data);
  //console.log(agency);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get('home/dashboard/docket')
      .then(response => {
        // handle success
        const agencyData = response.data.find(row => row.short_name === agency);
        //console.log("agencyData", agencyData)
        if (agencyData) {
          const newData = [agencyData['total_subscribed_90'], agencyData['total_dockets_90'] - agencyData['total_subscribed_90']];
          setData(newData);
          //console.log("newdata", newData)
        }
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  }, [agency]);

  useEffect(() => {
    if (labels.length > 0 && data.length === 0) {
      setData(labels.flatMap(() => [Math.floor(Math.random() * 25)]));
      setIsLoading(false);
    }
  }, [labels, data]);

  if (isLoading) {
    return <div>Loading...</div>; // Replace this with your actual loading component
  }

  // if (data.length !== labels.length) {
  //   return null; // or return a loading spinner, or some other placeholder component
  // }

  const chartData: ApexOptions = {
    chart: {
      type: 'bar',
      height: parseInt(chartHeight), // Ensure that chartHeight is converted to a number
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',


      },
    },
    dataLabels: {
      enabled: false,

    },
    xaxis: {
      categories: [agency],
    },
    colors: chartColor,
  };

  const series = labels.map((label, index) => ({
    name: label,
    data: [data[index]],
  }));

  if (!series || series.length === 0) {
    return null; // or return a loading spinner, or some other placeholder component
  }



  return (
    <div className={`card ${className}`} style={{ height: cardHeight }}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          {/* <span className='text-muted fw-semibold fs-7'>Latest trends</span> */}
        </h3>


      </div>

      <div className='card-body d-flex flex-column'>

        <ReactApexChart options={chartData} series={series} type='bar' height={parseInt(chartHeight)} />


      </div>

    </div>
  )
}


export { MixedWidget6 }