import React, { FC } from 'react'
import Home from './HomePage'
const HomePageWrapper: FC = () => {
    return (
        <>
            <Home />
        </>
    )
}

export default HomePageWrapper
