import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
// import Question from "./Question";
import { KTIcon } from "../../../_metronic/helpers";
import XmlFileViewer from "./XmlFileViewer";

const Title42 = () => {
    const [searchInput, setSearchInput] = useState('');
    // const [searchQuestion, setSearchQuestion] = useState('');
    const [expandCollapse, setExpandCollapse] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const expandAll = () => {
        setIsLoading(true);
        setTimeout(() => {
            const detailsElements = document.querySelectorAll('details');
            detailsElements.forEach(details => {
                details.open = true;
            });
            setIsLoading(false);
        }, 50);
    };

    const collapseAll = () => {
        setIsLoading(true);
        setTimeout(() => {
            const detailsElements = document.querySelectorAll('details');
            detailsElements.forEach(details => {
                details.open = false;
            });
            setIsLoading(false);
        }, 50);
    };

    useEffect(() => {
        if (expandCollapse === 'expand') {
            expandAll();
        } else if (expandCollapse === 'collapse') {
            collapseAll();
        }
    }, [expandCollapse]);

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            setSearchInput(event.target.value);
        }
    };

    return (
        <div>
            <div className="d-flex flex-column px-5 py-0" >
                <div className="d-flex flex-column-auto mb-2 mt-2 ">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName='book-open' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>Title 42: Public Health</span>
                            {isLoading && (
                                <div className="spinner-border text-primary ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </div>
                    </h1>
                </div>
            </div>
            <div className='row mt-0'>
                <div className='col-md-5 d-flex me-15'>
                    <SearchBar
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        handleSearch={handleSearch}
                    />
                </div>
                <div className='col-md-6 d-flex align-items-center justify-content-end px-0 p-5'>
                    <div className='d-flex align-items-center ms-2 mt-1'>
                        <h4 style={{ color: "#4d4d4d" }}>Amendment Date:</h4>
                    </div>
                    <div className='d-flex align-items-center ms-2'>
                        <select className="form-select text-muted" aria-label="Amendment Date" style={{ backgroundColor: '#f1faff', border: '1px solid #e0e0e0' }} defaultValue="1">
                            <option value="1">March 12, 2024</option>
                            <option value="2">March 6, 2024</option>
                            <option value="3">February 27, 2024</option>
                            <option value="4">Jan. 22, 2024</option>
                            <option value="5">Dec. 22, 2023</option>
                            <option value="6">Nov. 21, 2023</option>
                        </select>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex justify-content-between w-50" >

                <Question
                    searchQuestion={searchQuestion}
                    setSearchQuestion={setSearchQuestion}
                />
            </div> */}
            <div className="card p-3 mx-auto mb-10 text-wrap overflow-hidden"
                style={{
                    borderRadius: '10px',
                    boxShadow: 'none',
                    height: 'calc(100vh - 170px)',
                    border: 'none'
                }}>
                <XmlFileViewer
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    searchInput={searchInput}
                    expandAll={expandAll}
                    collapseAll={collapseAll}
                    setExpandCollapse={setExpandCollapse}
                />
            </div>
        </div>
    )
}
export default Title42;
