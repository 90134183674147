import React from 'react';

const DocketSelector = ({ dockets, selectedDocket, handleDocketChange }) => {
    return (
        <div className="d-flex flex-column-auto ms-2">
            <div className="d-flex align-items-center " >
                <span className=" fw-bold fs-6 flex-shrink-0 pe-4 d-none d-md-block " style={{ color: '#3699ff' }}>
                    My Dockets:
                </span>
                <select
                    className="form-select form-select-sm w-140px form-select-solid"
                    data-control="select2"
                    data-placeholder="Select Docket"
                    value={selectedDocket || ""}
                    onChange={handleDocketChange}
                    style={{ backgroundColor: '#f1faff', height: '40px' }}
                >
                    <option value="">Select Docket</option>
                    {Array.isArray(dockets) && dockets.length > 0 &&
                        dockets.map((docket) => (
                            <option key={docket.id} value={docket.id}>
                                {docket.id}
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    );
};

export default DocketSelector;