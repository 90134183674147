import React, { useEffect, useState, useRef} from 'react';
import { KTIcon } from '../../../_metronic/helpers'
import SearchBar from './SearchBar';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

function TextFileViewer({id}) {
    const [content, setContent] = useState('');
    useEffect(() => {
        fetch(`/files/${id}.txt`)
        .then(response => response.text())
        .then(data => {
            setContent(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    });

    return (
        <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100"
            style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}>
            {content}
        </div>
    );
}

const HouseBills = () => {
    const { id } = useParams();
    const [searchInput, setSearchInput] = useState(id||'');
    const highlightedRef = useRef(null);
    useEffect(() => {
        if (highlightedRef.current) {
            highlightedRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [searchInput]);
    const [bills, setBills] = useState([
            {
                id: "1328",
                billnumber: "H.R.1328",
                type: "H.R",
                title: "Medical Device Nonnvisual Accessibility Act of 2023",
                Status:"INTRODUCED IN HOUSE",
                Commitee:"House Committee On Energy And Commerce, Subcommittee On Health",
                LatestAction:"03-10-2023",
                part: "US Congress (118th)",
                textData:"Bill Text",
                summary: "<p><strong>Medical Device Nonvisual Accessibility Act of </strong><b>2023</b></p> <p>This bill requires certain medical devices with a digital interface to meet nonvisual accessibility standards established by the Food and Drug Administration (FDA). </p> <p>Specifically, the FDA must establish regulations to require a Class II or Class III medical device with a digital interface to provide a blind or low-vision user access to the same services and information, and generally the same ease of use, as provided to a user who is not low-vision or blind. (The FDA classifies medical devices in three classes. Class I devices are low-risk, Class II devices are moderate-risk, and Class III devices are high-risk.)</p> <p>A Class II or Class III medical device that does not meet the nonvisual accessibility standards established under this bill may not be sold. However, the FDA may waive the bill's requirements for a device upon a showing that applying the standards would fundamentally alter the nature of the device or create an undue hardship for the manufacturer. The bill also does not apply to devices that are intended solely for use by a health care provider or in a setting outside the home.</p>",
                track: false
            },
            {
                id: "3273",
                billnumber: "H.R.3273",
                type: "H.R",
                title: "Medical Device Electronic Labeling Act",
                Status:"REFERRED TO COMMITTEE",
                Commitee:"House Committee On Energy And Commerce, Subcommittee On Health",
                LatestAction:"05-26-2023",
                part: "US Congress (118th)",
                textData:"Bill Text",
                summary: " <p><b>Eliminate DEI in the Military Act</b></p> <p>This bill prohibits the use of federal funds for diversity, equity, and inclusion activities (e.g., training) of the Armed Forces, national service academies, or the Department of Defense.</p>",
                track: false
            },
            {
                id:"3807",
                billnumber: "H.R.3807",
                type: "H.R",
                title: "Medical Device Shortage Reduction Act of 2023",
                Status:"REFERRED TO SUBCOMMITTEE",
                Commitee:"House Committee On Energy And Commerce, Subcommittee On Health",
                LatestAction:"06-09-2023",
                part: "US Congress (118th)",
                textData:"Bill Text",
                summary: "Summary is not available",
                track: false
            },
    ]);

    const handleTrack = (billnumber) => {
        setBills(prevBills => prevBills.map(bill => 
            bill.billnumber === billnumber ? { ...bill, track: !bill.track } : bill
        ));
    };

    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                <KTIcon iconName='book-square' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' style={{ backgroundColor: "#d9f2d9" }} /> House Bills
            </h1>
            <SearchBar searchInput={searchInput} setSearchInput={setSearchInput}/>
                <table className="table">
                    <tbody>
                    {bills.map((bill) => (
                    <div key={bill.billnumber} className="card card-custom mb-3" 
                        ref = {bill.billnumber ===searchInput? highlightedRef : null}
                        style={{ borderRadius: '10px' }}
                    >
                        <div className="card-header d-flex justify-content-between align-items-center"
                            style= {{backgroundColor: bill.billnumber ===searchInput? "#d9f2d9" : "#f1faff"}}
                        >
                            <h2 className="card-title">
                                {bill.billnumber} 
                            </h2>
                            <button 
                                className={`btn ${bill.track ? 'btn-success' : 'btn-primary'} h-26px w-140px btn-sm d-flex align-items-center justify-content-center`}
                                onClick={()=>handleTrack(bill.billnumber)}> 
                                <i className="bi bi-check-circle-fill"></i>
                                    {bill.track ? 'Tracking' : 'Track'}
                            </button>                            
                        </div>
                        <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="">
                            <Tab eventKey="details" title="Details">
                                <div className="card-body" style={{ fontSize: '15px' }}>
                                    <p style={{ color: 'mediumblue' }}>{bill.Status}</p>
                                    <p>Type: {bill.type}</p>
                                    <h3>Title: {bill.title}</h3>
                                    <p>Committee: {bill.Commitee}</p>
                                    <p>Latest Action Date: {bill.LatestAction}</p>
                                    <p className='text-muted'>{bill.part}</p>
                                </div>
                            </Tab>
                            <Tab eventKey="summary" title="Summary">
                                {/* <SummaryViewer billnumber={bill.billnumber}></SummaryViewer> */}
                                <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100 "
                                    style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}
                                    dangerouslySetInnerHTML={{ __html: bill.summary }}>
                                </div>
                            </Tab>
                            <Tab eventKey="text" title="Text">
                                <div className="font-monospace p-3 w-100 text-wrap mx-auto vh-100 "
                                    style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}>
                                        <TextFileViewer id={bill.id}/>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    ))}
                    </tbody>
                </table>
        </div>
    );
};

export default HouseBills;