import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';
import axios from 'axios';

type Props = {
    className: string;
    chartHeight: string;
    title: string;
    activity: string;

    scale: string;
    days: number;
    doctype: string;
};

interface DataItem {
    agencyId: string;
    id: string;
    document_title: string;
    modifyDate: string;
    program: string;
    category: string;
}

const MixedWidgetRegulatory: React.FC<Props> = ({ className, chartHeight, title, days, doctype, activity = "", scale = "logarithmic" }) => {
    // Step 1: Group by Program

    const [data, setData] = useState<DataItem[]>([]);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        axios.get(`reg/v1/document/preference/document/graph/${doctype}?days=${days}`)
            .then(response => {
                // Log the response data
                setData(response.data); // Assuming response.data is an array of DataItem objects
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [days, doctype]); // Empty dependency array means this effect runs once on mount

    const groupedByCategory = data.reduce<Record<string, any[]>>((acc, item) => {
        const category = item.category || 'Unknown'; // Handle items without a category
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(item);
        return acc;
    }, {});

    // Filter for agencies and prepare series data
    const agenciesData = (groupedByCategory['agency'] || []).sort((a, b) => a.name.localeCompare(b.name));
    const series: Highcharts.SeriesOptionsType[] = agenciesData.map((agency) => ({
        name: agency.name,
        data: [{ y: agency.total_docs }],
        type: 'column',
    }));


    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,
        },
        xAxis: {
            type: 'category',
            // Set categories to program names
            title: {
                text: 'Agencies',
            },
            labels: {
                enabled: false // This will hide all labels on the xAxis
            },
            width: '90%',

        },
        title: {
            text: ''
        },
        yAxis: {
            type: 'logarithmic',
            title: {
                text: 'Documents',
            },
            // Adds minor ticks to make the scale clearer

        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false, // Set to true if you need the legend to float over the chart
            margin: 5,
            itemMarginBottom: 1,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.PointLabelObject) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                // pointPlacement: -0.1,
            },

        },
        credits: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                // Assuming 'this' refers to the point or series with access to its name and y (value)
                // Customize this string to match your desired tooltip format
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            }
        },
        colors: [
            '#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
            '#FF9655', '#FFF263', '#6AF9C4'
        ],
        series: series, // Use the new series array
    };

    return (
        <div className={`card ${className} `} >

            <div className='card-body d-flex flex-column '>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </div>
    );
};

export { MixedWidgetRegulatory };