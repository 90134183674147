const FeedbackFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters
}) => {
    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5' style={{ maxHeight: '200px', overflow: 'auto' }}>

                <div className='mb-4'>
                    <label className='form-label fw-bold'>Subject:</label>
                    <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-hide-search='true'
                        onChange={(e) => onFilterChange('subject', e.target.value)}
                        value={filters.subject}
                    >
                        <option value="">All</option>
                        <option value="Question">Question</option>
                        <option value="Bug">Bug</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div className='mb-4'>
                    <label className='form-label fw-bold'>Resolution:</label>
                    <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-hide-search='true'
                        onChange={(e) => onFilterChange('resolution', e.target.value)}
                        value={filters.resolution}
                    >
                        <option value="">All</option>
                        <option value="Open">Open</option>
                        <option value="Under Review">Under Review</option>
                        <option value="Closed">Closed</option>
                    </select>
                </div>

                <div className='mb-4'>
                    <label className='form-label fw-bold'>Posted Date Range:</label>
                    <input
                        type='date'
                        className='form-control form-control-solid'
                        placeholder='Start Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                    <input
                        type='date'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>
            </div>

            <div className='px-7 py-5 mb-2'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackFilter;