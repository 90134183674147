import React from "react";
import { AddOns } from "./AddOns";
const AddOnsWrapper = () => {
    return (
        <>
            <AddOns />
        </>
    )
}

export default AddOnsWrapper;