import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
type Document = {
    agencyId: string;
    id: string;
    document_title: string;
    modifyDate: string;
    program: string;
}

interface TableRowDocumentsProps {
    items: Document[];
}

const TableRowDocuments: React.FC<TableRowDocumentsProps> = React.memo(({ items }) => {
    const navigate = useNavigate();
    return (
        <>
            {items?.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-gray-600 fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (items?.map((item: Document, index) => (
                <tr className='bg-hover-light-primary' key={index} onClick={() => { navigate(`/document/${item.id}`, { state: { prevPage: 'home' } }) }}>
                    <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                        {item.agencyId}
                    </td>

                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.program}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6 mt-2'>
                        <Link to={`/document/${item.id}`} className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'>{item.document_title}</Link>
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {new Date(item.modifyDate).toLocaleDateString()}
                    </td>

                </tr>
            )))}
        </>
    );
});

export default TableRowDocuments;