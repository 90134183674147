import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import TableRowPMA from './TableRowPMA';
import axios from 'axios';
type Document = {

    advisory_committee_description: string;
    applicant: string;
    decision_date: string;
    generic_name: string;
    openfda_device_class_id: string;
    pma_number: string;
    product_code: string;
    recall_count: string;
    state: string;
    supplement_number: string;
    trade_name: string;
}
type Props = {
    days: number;
}
const TablesWidgetPMA: React.FC<Props> = ({ days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState<Document[]>([]);
    const [itemsPerPage] = useState(10);


    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('decision_date')

    const [count, setCount] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const response = await axios.get(`reg/v1/fda/preference/pma/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`);
                const totalCount = response.data.length > 0 ? response.data[0].pma_count : 0;
                setCount(totalCount);
                const warningLetters = response.data;
                setDetails(warningLetters);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder]);

    const currentItems = details;

    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);

    }, [days])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);



    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };

    const PAGINATION_PAGES_COUNT = 5;

    const sliceLinks = (totalPages, currentPage) => {
        let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

        if (pageLinks.length > PAGINATION_PAGES_COUNT) {
            let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
            let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

            if (start < 0) {
                end -= start;
                start = 0;
            }

            if (end >= pageLinks.length) {
                start -= end - pageLinks.length + 1;
                end = pageLinks.length - 1;
            }

            pageLinks = pageLinks.slice(start, end + 1);
        }

        return pageLinks;
    }

    const paginationLinks = sliceLinks(totalPages, currentPage);

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card `}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        PMAs
                                        {loading && <span className='spinner-border text-gray-600 align-middle ms-2'></span>}

                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                 {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                            </div>
                        </div>
                        {/* end::Header */}
                        <div className='card-body px-8 '>
                            {/* begin::Table container */}
                            <div className='table-responsive '>
                                {/* begin::Table */}
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-1 gy-3 ' style={{ width: '100% !important' }}>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bolder text-gray-600 fs-5'>
                                            <th style={{ minWidth: '12%', width: '12%' }} onClick={() => handleSort('pma_number')}>
                                                PMA Number
                                                <span className={`sorting-icon ${sortcol === 'pma_number' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'pma_number' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            {/* <th className ='min-w-150px'>ID</th> */}
                                            <th style={{ minWidth: '20%', width: '20%' }} onClick={() => handleSort('trade_name')}>
                                                Device Name
                                                <span className={`sorting-icon ${sortcol === 'trade_name' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'trade_name' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '18%', width: '18%' }} onClick={() => handleSort('applicant')}>
                                                Company
                                                <span className={`sorting-icon ${sortcol === 'applicant' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'applicant' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('advisory_committee_description')}>
                                                Specialty
                                                <span className={`sorting-icon ${sortcol === 'advisory_committee_description' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'advisory_committee_description' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('product_code')}>
                                                Product Code
                                                <span className={`sorting-icon ${sortcol === 'product_code' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'product_code' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('openfda_device_class_id')}>
                                                Device Class
                                                <span className={`sorting-icon ${sortcol === 'openfda_device_class_id' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'openfda_device_class_id' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            {/* <th className='min-w-120px'>Program </th> */}
                                            <th style={{ minWidth: '32%', width: '32%' }} className='text-end' onClick={() => handleSort('decision_date')}>
                                                Decision Date
                                                <span className={`sorting-icon ${sortcol === 'decision_date' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'decision_date' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            {/* <th className='min-w-120px'>Actions </th> */}


                                            {/* <th className='min-w-60px text-end'>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (

                                            <tr>
                                                <td>
                                                    <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>
                                                    </span>
                                                </td>
                                            </tr>

                                        ) : (

                                            <TableRowPMA items={currentItems} />

                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 &&
                    <div className='card-footer border-1 p-0 ' >
                        <div className='row mt-4 mb-4'>
                            <div className='d-flex justify-content-center'>
                                <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                    <ul className='pagination justify-content-center'>
                                        <li className={clsx('page-item', { 'disabled': currentPage === 1 })}>
                                            <button onClick={() => setCurrentPage(1)} style={{ cursor: 'pointer' }} className='page-link'>First</button>
                                        </li>
                                        {paginationLinks.map((page) => (
                                            <li key={page} className={clsx('page-item', { 'active': currentPage === page })}>
                                                <button onClick={() => setCurrentPage(page)} style={{ cursor: 'pointer' }} className='page-link'>{page}</button>
                                            </li>
                                        ))}
                                        <li className={clsx('page-item', { 'disabled': currentPage === totalPages })}>
                                            <button onClick={() => setCurrentPage(totalPages)} style={{ cursor: 'pointer' }} className='page-link'>Last</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export { TablesWidgetPMA }
