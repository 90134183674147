/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../app/modules/auth';
import { useNavigate } from 'react-router-dom';
type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  labels: string[];
  title: string;
  agency: string;
  cardHeight: string;
}

// const MixedWidget14: React.FC<Props> = ({ className, chartColor, chartHeight, docketsdata, title }) => {

const MixedWidget14: React.FC<Props> = ({ className, chartHeight = "250px", title, labels = [], chartColor, agency = 'CMS', cardHeight }) => {
  const [data, setData] = useState<number[]>([]);
  const [url, setUrl] = useState<string>("");
  const [columns, setColumns] = useState<string[]>([])

  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (title === 'Dockets') {
      setUrl(`reg/v1/home/dashboard/docket`)
      setColumns(['total_rulemaking_90', 'total_nonrulemaking_90'])
    }
    else if (title === 'Documents') {
      setUrl(`reg/v1/home/dashboard/doc`)
      setColumns(['total_proposed_90', 'total_rule_90', 'total_notice_90', 'total_supporting_90', 'total_other_90'])
    }
    else if (title === 'Comments') {
      setUrl(`reg/v1/home/dashboard/com`)
      setColumns(['total_anonymous_90', 'total_org_90', 'total_individual_90'])
    }

  }, [title, agency]);

  useEffect(() => {
    if (url) {
      axios.get(url)
        .then(response => {
          // handle success
          const agencyData = response.data.find(row => row.short_name === agency);

          if (agencyData) {
            const newData = columns.map(column => agencyData[column]);
            setData(newData);

          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            logout();
            navigate('/auth/login');
          } else {

            console.log(error);
          }
        });
    }
  }, [agency, url, columns, logout, navigate]);



  if (data.length !== labels.length) {
    return null; // or return a loading spinner, or some other placeholder component
  }

  const chartData: ApexOptions = {
    chart: {
      type: 'bar',
      height: parseInt(chartHeight), // Ensure that chartHeight is converted to a number
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',


      },
    },
    dataLabels: {
      enabled: false,

    },
    xaxis: {
      categories: [agency],
    },
    colors: chartColor,
  };

  const series = labels.map((label, index) => ({
    name: label,
    data: [data[index]],
  }));

  if (!series || series.length === 0) {
    return null; // or return a loading spinner, or some other placeholder component
  }



  return (
    <div className={`card ${className}`} style={{ height: cardHeight }}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title} By Type</span>

          {/* <span className='text-muted fw-semibold fs-7'>Latest trends</span> */}
        </h3>


      </div>

      <div className='card-body d-flex flex-column'>

        <ReactApexChart options={chartData} series={series} type='bar' height={parseInt(chartHeight)} />


      </div>

    </div>
  )
}


export { MixedWidget14 }