import React, { FC, useEffect } from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import SearchBar from './SearchBar';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import Networkgraph from 'highcharts/modules/networkgraph';
// import { Multiselect } from 'multiselect-react-dropdown';


const marks = [
    {
        value: 0,
        label: '30 Days',
    },
    {
        value: 25,
        label: '90 Days',
    },
    {
        value: 50,
        label: '180 Days',
    },
    {
        value: 75,
        label: '1 Year',
    },
    {
        value: 100,
        label: 'All Time',
    }
];

// interface CommitteeOption {
//     name: string;
// }

Networkgraph(Highcharts);

const DevicesHome: FC = () => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [committeeFilter] = React.useState(["CV", "EN", "MN"]);
    const [deviceClassFilter] = React.useState([1, 2, 3]);
    const [productCodeFilter] = React.useState([]);
    const [specialtyFilter] = React.useState([]);
    const [patientFilter] = React.useState([]);
    const [countryFilter] = React.useState(["USA"]);
    const [chartData, setChartData] = React.useState<string[][] | null>(null);
    const [options, setOptions] = React.useState<{} | null>(null);
    // const [dropdownVisible, setDropdownVisible] = React.useState(false);
    // const [selectedOptions, setSelectedOptions] = React.useState<CommitteeOption[]>([]);
    // const committeeOptions = useMemo(() => [
    //     { name: 'AN' },
    //     { name: 'CV' },
    //     { name: 'CH' },
    //     { name: 'DE' },
    //     { name: 'EN' },
    //     { name: 'GU' },
    //     { name: 'HO' },
    //     { name: 'HE' },
    //     { name: 'IM' },
    //     { name: 'MG' },
    //     { name: 'MI' },
    //     { name: 'NE' },
    //     { name: 'OB' },
    //     { name: 'OP' },
    //     { name: 'OR' },
    //     { name: 'PA' },
    //     { name: 'PM' },
    //     { name: 'RA' },
    //     { name: 'SU' },
    //     { name: 'TX' },
    //     { name: 'UN' },
    // ], []);

    // useEffect(() => {
    //     const selected = committeeOptions.filter(option => committeeFilter.includes(option.name));
    //     setSelectedOptions(selected);
    // }, [committeeFilter, committeeOptions]);

    // const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

    // const onSelect = (selectedList, selectedItem) => {
    //     setCommitteeFilter(selectedList.map(item => item.name));
    // };

    // const onRemove = (selectedList, removedItem) => {
    //     setCommitteeFilter(selectedList.map(item => item.name));
    // };



    useEffect(() => {
        setLoading(true);
        // Fetch data and set it to chartData state variable
        setChartData([
            ['Manufacturer/Distributer', 'Products'],
            ['Manufacturer/Distributer', 'Registrations'],
            ['Manufacturer/Distributer', 'PMA Numbers'],
            ['Manufacturer/Distributer', 'K-Numbers'],
            ['Manufacturer/Distributer', 'Adverse Events'],
            ['Products', 'Classifications'],
            ['Adverse Events', 'Recalls'],
            ['Recalls', 'Enforcement Reports']
        ]);
        setLoading(false);
    }, []);

    useEffect(() => {
        setLoading(true);
        // Custom event logic
        Highcharts.addEvent(Highcharts.Series, 'afterSetOptions', function (e) {
            // Asserting e as any to access the options property
            const event = e as any;
            // Define a custom basic rainbow color palette
            let colors = [
                "#C0392B",
                "#F7931B",
                "#FFD700",
                "#00A300",
                "#4169E1",
                "#9B59B6",
                "#008080",
                "#E03C82",
                "#29ABCA"
            ]
            const nodes = {};
            const colorIndexMap = {};

            const getColorForNode = (nodeId) => {
                if (colorIndexMap[nodeId] === undefined) {
                    let colorIndex = Object.keys(colorIndexMap).length % colors.length;
                    colorIndexMap[nodeId] = colorIndex;
                }
                return colors[colorIndexMap[nodeId]];
            };

            if (this.type === 'networkgraph' && event.options.id === 'language-tree' && event.options.data) {
                event.options.data.forEach(function (link) {
                    try {
                        if (Array.isArray(link) && link.length === 2) {
                            const color1 = getColorForNode(link[0]);
                            const color2 = getColorForNode(link[1]);

                            if (!nodes[link[0]]) {
                                nodes[link[0]] = {
                                    id: link[0],
                                    marker: { radius: 50 },
                                    color: color1
                                };
                            }
                            if (!nodes[link[1]]) {
                                nodes[link[1]] = {
                                    id: link[1],
                                    marker: { radius: 30 },
                                    color: color2
                                };
                            }
                        } else {
                            console.error('Invalid link format', link);
                        }
                    } catch (error) {
                        console.error('Error processing link:', link, error);
                    }
                });

                event.options.nodes = Object.keys(nodes).map(id => nodes[id]);
            }
        });

        setOptions({
            chart: {
                type: 'networkgraph',
                marginTop: 20
            },
            title: {
                text: ''
            },
            plotOptions: {
                networkgraph: {
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: true,
                        integration: 'verlet',
                        linkLength: 150
                    }
                }
            },
            series: [{
                id: 'language-tree',
                marker: { radius: 30 },
                dataLabels: {
                    enabled: true,
                    textPath: { enabled: false },
                    linkFormat: '',
                    allowOverlap: true
                },
                data: chartData
            }]
        });
        setLoading(false);
    }, [chartData]);


    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <i className="fas fa-sitemap fw-bold fs-1 mx-3 text-primary"></i>
                                    <span>Device Intelligence</span>
                                    {loading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="mt-3 ms-10" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <div className="ms-20 me-15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '15px', alignSelf: 'flex-start' }}>
                        <div style={{ textAlign: 'center' }}> {/* Removed marginRight */}
                            <h4 style={{ color: "#4d4d4d" }}>Time Range (Days)</h4>
                        </div>
                        <Box sx={{ width: 250 }}>
                            <Slider
                                aria-label="Time Range (Days)"
                                defaultValue={0}
                                getAriaValueText={() => ''}
                                step={null}
                                valueLabelDisplay="off"
                                marks={marks}
                            />
                        </Box>
                    </div>
                    <div className="ms-10 col-6 text-wrap">
                        <SearchBar searchInput={searchTerm} setSearchInput={setSearchTerm} />
                    </div>
                </div>
                <div className="mt-3 row justify-content-center">
                    {/* {!dropdownVisible ? ( */}
                    <div style={{ width: '26%' }} className="me-7">
                        <div className={`alert ${committeeFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Committees: {committeeFilter.join(', ')}
                        </div>
                    </div>
                    {/* ) : (
                        <Multiselect
                            options={committeeOptions}
                            selectedValues={selectedOptions}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="name"
                            showCheckbox={true}
                        />
                    )} */}
                    <div style={{ width: '26%' }} className="px-1 me-7">
                        <div className={`alert ${deviceClassFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Device Classes: {deviceClassFilter.join(', ')}
                        </div>
                    </div>
                    <div style={{ width: '26%' }} className="me-15">
                        <div className={`alert ${productCodeFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Product Codes: {productCodeFilter.join(', ')}
                        </div>
                    </div>
                </div>
                <div className="mt-3 row justify-content-center">
                    <div style={{ width: '26%' }} className="me-7">
                        <div className={`alert ${specialtyFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Specialties: {specialtyFilter.join(', ')}
                        </div>
                    </div>
                    <div style={{ width: '26%' }} className="px-1 me-7">
                        <div className={`alert ${patientFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Patient Problems: {patientFilter.join(', ')}
                        </div>
                    </div>
                    <div style={{ width: '26%' }} className="me-15">
                        <div className={`alert ${countryFilter.length > 0 ? 'bg-dark text-light' : 'alert-dark'} d-flex align-items-center p-2`}>
                            Countries: {countryFilter.join(', ')}
                        </div>
                    </div>
                </div>
            </div>
            {!loading && options && chartData && chartData.length > 0 && (
                <div className="mb-15 d-flex justify-content-center align-items-center">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </div>
            )}

        </div>
    )
}

export default DevicesHome;