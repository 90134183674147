import { UPDATE_AVATAR, UPDATE_INTERESTS, UPDATE_ORGANIZATION, UPDATE_ADDONS, LOGOUT, UPDATE_ORGANIZATION_LOGO } from './types';

const initialState = {
  avatarUrl: localStorage.getItem('avatarUrl') || '',
  organizationLogo: localStorage.getItem('organizationLogo') || '',
  interests: localStorage.getItem('interests')
    ? JSON.parse(localStorage.getItem('interests'))
    : {
      regions: [],
      agencies: [],
      industries: [],
      committees: [],
      device_classes: []
    },
  organization: {
    avatar: '',
    name: '',
    primaryuser: '',
    fullname: '',
    industry: '',
    phone: '',
    website: '',
    country: '',
    language: '',
    timeZone: '',
    allowMarketing: false,
    address: '',
    city: '',
    state: '',
    region: '',
    zip: '',
  },
  addons: localStorage.getItem('addons')
    ? JSON.parse(localStorage.getItem('addons'))
    : {
      translation: false,
      videoTraining: false,
    }

};

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AVATAR:
      localStorage.setItem('avatarUrl', action.url);
      return { ...state, avatarUrl: action.url };
    case UPDATE_INTERESTS:
      localStorage.setItem('interests', JSON.stringify(action.interests));
      return { ...state, interests: action.interests };
    case UPDATE_ORGANIZATION:
      return { ...state, organization: action.organization };
    case UPDATE_ADDONS:
      return { ...state, addons: action.addons };
    case LOGOUT:
      // Clear any necessary local storage items
      localStorage.removeItem('avatarUrl');
      localStorage.removeItem('interests');
      localStorage.removeItem('addons');
      localStorage.removeItem('organizationLogo');
      localStorage.clear();
      // Return a new state object
      return {
        ...initialState,
        avatarUrl: '',
        organizationLogo: '',
        organization: { ...initialState.organization },
        interests: JSON.parse(JSON.stringify(initialState.interests)), // Deep copy
        addons: { ...initialState.addons }
      };
    case UPDATE_ORGANIZATION_LOGO:
      localStorage.setItem('organizationLogo', action.organizationLogo);
      return { ...state, organizationLogo: action.organizationLogo };
    default:
      return state;
  }
}

export default accountReducer;