import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import { KTIcon } from "../../../../../_metronic/helpers";
import FeedbackToolbar from "./FeedbackToolbar";
import { ResolveFeedbackModal } from "./ResolveFeedbackModal";
import { useAuth } from "../../../../modules/auth";

interface FeedbackItem {
    id: number;
    create_date: number;
    feedback: string;
    subject: string;
    lexim_response: string | null;
    modified_date: number;
    resolution: string | null;
    resolution_date: number | null;
    response_acknowledged: boolean | null;
    userid: number;
    viewed_by_lexim: number;
    lexim_viewed_date: number | null;
    user_full_name: string;
}
const Feedback = () => {
    const [feedbacks, setFeedbacks] = useState<FeedbackItem[]>([]);
    const [currentFeedbacks, setCurrentFeedbacks] = useState<FeedbackItem[]>([]);
    const [filteredFeedbacks, setFilteredFeedbacks] = useState<FeedbackItem[]>([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [currentFeedback, setCurrentFeedback] = useState<FeedbackItem | null>(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        subject: '', // new filter for subject
        resolution: '', // new filter for resolution
    });
    const { currentUser } = useAuth();

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }, [currentPage]);

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = feedbacks;

        if (filters.subject !== "") {
            filteredData = filteredData.filter(feedback =>
                feedback.subject.toLowerCase() === filters.subject.toLowerCase()
            );
        }

        if (filters.resolution !== "") {
            filteredData = filteredData.filter(feedback =>
                feedback.resolution && feedback.resolution === filters.resolution
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate).getTime();
            filteredData = filteredData.filter(feedback =>
                new Date(feedback.create_date).getTime() >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate).getTime();
            filteredData = filteredData.filter(feedback =>
                new Date(feedback.create_date).getTime() <= endDate
            );
        }

        setFilteredFeedbacks(filteredData);
        setCurrentPage(1);
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: '',
            endDate: '',
            subject: '',
            resolution: ''
        });
        setCurrentPage(1);
    };

    const fetchFeedback = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/reg/v1/admin/feedback/${currentUser?.organization}`);
            const sortedFeedbacks = response.data.sort((a: FeedbackItem, b: FeedbackItem) => b.create_date - a.create_date);
            setFeedbacks(sortedFeedbacks); // set the sorted feedbacks to feedbacks state
            setFilteredFeedbacks(sortedFeedbacks); // set the sorted feedbacks to filteredFeedbacks state
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFeedback();
        // eslint-disable-next-line
    }, []);

    const resolveFeedback = (id: number) => {
        const feedback = feedbacks.find(feedback => feedback.id === id);
        setCurrentFeedback(feedback || null);
        setDisplayModal(true);
    };

    useEffect(() => {
        setTotalPages(Math.ceil(filteredFeedbacks.length / itemsPerPage));
    }, [filteredFeedbacks, itemsPerPage]);

    useEffect(() => {
        setCurrentFeedbacks(filteredFeedbacks.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredFeedbacks, currentPage, itemsPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedFeedbacks = [...filteredFeedbacks].sort((a, b) => {
            if (sortConfig.key === 'resolution') {
                let order = ['Open', 'Under Review', 'Closed'];
                if (sortConfig.direction === 'desc') {
                    order = order.reverse();
                }
                const aIndex = a.resolution ? order.indexOf(a.resolution) : -1;
                const bIndex = b.resolution ? order.indexOf(b.resolution) : -1;
                return aIndex - bIndex;
            } else {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            }
        });
        setFilteredFeedbacks(sortedFeedbacks);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);

    useEffect(() => {
        let filteredData = feedbacks;

        if (searchInput !== "") {
            filteredData = filteredData.filter(feedback =>
                feedback.subject.toLowerCase().includes(searchInput.toLowerCase()) ||
                feedback.feedback.toLowerCase().includes(searchInput.toLowerCase()) ||
                feedback.user_full_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                feedback.resolution?.toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        if (filters.subject !== "") {
            filteredData = filteredData.filter(feedback =>
                feedback.subject.toLowerCase() === filters.subject.toLowerCase()
            );
        }

        if (filters.resolution !== "") {
            filteredData = filteredData.filter(feedback =>
                feedback.resolution && feedback.resolution === filters.resolution
            );
        }

        setFilteredFeedbacks(filteredData);
        // eslint-disable-next-line
    }, [searchInput, filters]);

    const closeModal = () => {
        setDisplayModal(false);
        setCurrentFeedback(null);
    };

    return (
        <div>
            <FeedbackToolbar
                isLoading={loading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
            />
            {!loading &&
                <div className='table-responsive mb-5'>
                    <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                        style={tableStyle}>
                        <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                            <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '11%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                                    Posted Date
                                    <span className={`sorting-icon ${sortConfig.key === 'create_date' ? sortConfig.direction : ''}`}>
                                        {' '}<i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                    </span>
                                </th>
                                <th style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('subject')}>
                                    Subject
                                    <span className={`sorting-icon ${sortConfig.key === 'subject' ? sortConfig.direction : ''}`}>
                                        {' '}<i className={`bi ${sortConfig.key === 'subject' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                    </span>
                                </th>
                                <th style={{ whiteSpace: 'nowrap', width: '40%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('feedback')}>
                                    Feedback
                                    <span className={`sorting-icon ${sortConfig.key === 'feedback' ? sortConfig.direction : ''}`}>
                                        {' '}<i className={`bi ${sortConfig.key === 'feedback' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                    </span>
                                </th>
                                <th style={{ whiteSpace: 'nowrap', width: '11%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('user_full_name')}>
                                    Posted By
                                    <span className={`sorting-icon ${sortConfig.key === 'user_full_name' ? sortConfig.direction : ''}`}>
                                        {' '}<i className={`bi ${sortConfig.key === 'user_full_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                    </span>
                                </th>
                                <th style={{ whiteSpace: 'nowrap', width: '7%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('resolution')}>
                                    Status
                                    <span className={`sorting-icon ${sortConfig.key === 'resolution' ? sortConfig.direction : ''}`}>
                                        {' '}<i className={`bi ${sortConfig.key === 'resolution' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                    </span>
                                </th>
                                <th className='text-end pe-15' style={{ whiteSpace: 'nowrap', width: '7%' }}>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        {loading ? (
                            <tbody className='text-gray-600 fw-bold'>
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            Loading...
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody className='text-gray-600 fw-bold'>
                                {currentFeedbacks.length === 0 ? (
                                    <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                        <td colSpan={6} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                    </tr>
                                ) : (
                                    currentFeedbacks.map((feedback) => (
                                        <tr key={feedback.id}>
                                            <td className="ps-10" style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{new Date(feedback.create_date).toLocaleDateString()}</td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{feedback.subject}</td>
                                            <td style={{ whiteSpace: 'normal', textAlign: 'left' }}>{feedback.feedback}</td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{feedback.user_full_name}</td>
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                                                <div className={`badge ${feedback.resolution === 'Open' ? 'badge-success' : feedback.resolution === 'Under Review' ? 'badge-danger' : 'badge-warning'} fw-bolder`}>
                                                    {feedback.resolution}
                                                </div>
                                            </td>
                                            <td className="text-end" style={{ whiteSpace: 'nowrap' }}>
                                                <button className="btn" style={{ float: 'right' }} onClick={() => resolveFeedback(feedback.id)}>
                                                    {feedback.resolution !== "Closed" ? <KTIcon className="fs-2" iconName="pencil" /> : <i className="fas fs-2 fa-ellipsis-h"></i>}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        )}
                    </table>
                </div>
            }
            {(!loading && totalPages > 1) &&
                <div className='card-footer mb-5'>
                    <div className='row'>
                        <div className='d-flex justify-content-center mt-5 mb-5'>
                            <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                <ul className='pagination justify-content-center'>
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className='page-link' onClick={prevPage} disabled={currentPage === 1}>
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                                        <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                            <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button className='page-link' onClick={nextPage} disabled={currentPage === totalPages}>
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {currentFeedback && (
                <ResolveFeedbackModal
                    feedback={currentFeedback}
                    displayModal={displayModal}
                    onClose={closeModal}
                    fetchFeedback={fetchFeedback}
                />
            )}
        </div>
    );
};
export default Feedback;