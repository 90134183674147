import { FC, useState } from 'react'
// import clsx from 'clsx'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { KTIcon } from '../../../_metronic/helpers'
import ReactMarkdown from 'react-markdown';

interface QAInterfaceProps {
    documentid: string;
}
interface QAPair {
    question: string;
    answer: string;
    error: boolean;
}
const QAInterface: FC<QAInterfaceProps> = () => {
    const [question, setQuestion] = useState<string>('')
    const [qaPairs, setQAPairs] = useState<QAPair[]>([])

    const askQuestion = async () => {
        const currentQuestion = question;
        // Add the question to qaPairs with a placeholder answer
        setQAPairs(prevQAPairs => [{ question: currentQuestion, answer: 'Thinking ...', error: false }, ...prevQAPairs])
        setQuestion('')
        try {
            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/answer-titles-query/21`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(currentQuestion)
            })

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    return;
                }

                text += new TextDecoder().decode(value);

                // Update the placeholder answer with the current text
                setQAPairs(prevQAPairs => {
                    const newQAPairs = [...prevQAPairs]
                    newQAPairs[0].answer = text;
                    return newQAPairs
                })

                // Recursively read the next chunk
                await readStream();
            }

            await readStream();

            setQuestion('')
        } catch (error) {
            // Update the placeholder answer with the error message
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs]
                newQAPairs[0].answer = (error as Error).message
                newQAPairs[0].error = true
                return newQAPairs
            })
        }
    }


    return (
        <div className='card-body' style={{ maxHeight: 'calc(100vh - 300px)', flex: 1 }}>
            <div className='d-flex position-relative'>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Please ask a question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            askQuestion();
                            e.preventDefault(); // Prevent form submission
                        }
                    }}
                    style={{ paddingRight: '30px' }} // Make room for the icon
                />
                <button onClick={askQuestion} className='position-absolute end-0 top-50 translate-middle-y' style={{ background: 'none', border: 'none' }}>
                    <KTIcon
                        iconName='arrow-right'
                        iconType="duotone"
                        className='fw-bold fs-1 mx-2'
                    />
                </button>
            </div>
            <div className='mb-5 mt-2' style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>

                {qaPairs.map((qaPair, index) => (
                    <div key={index} className='d-flex flex-column mt-3'>
                        <div className='d-flex justify-content-start w-100 mt-2 mb-2'>
                            <div className={`p-4 fw-bold rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-info text-dark'}`} style={{ maxWidth: '70%', wordWrap: 'break-word' }}>
                                {qaPair.answer === 'Thinking ...' ?
                                    <>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </>

                                    :
                                    <ReactMarkdown>{qaPair.answer}</ReactMarkdown>}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end w-100 mt-2 mb-2 '>
                            <div className='p-4 bg-light-primary text-dark fw-bold rounded' style={{ maxWidth: '70%', wordWrap: 'break-word' }}>
                                {qaPair.question}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export { QAInterface }