import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../modules/auth';
import { DocketDetailsHeader } from './DocketDetailsHeader';
import { DocketInfo } from './DocketInfo';
import { Documents } from './Documents';
import ImportantDocketDates from './ImportantDocketDates';
import DocketDetailsCharts from './DocketDetailsCharts';
//import { KTIcon } from '../../../_metronic/helpers';



const DocketDetails = () => {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [agencies, setAgencies] = useState([]);
    //const [alert, setAlert] = useState({ message: '', type: '' });
    const [documents, setDocuments] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState('1')
    const [dockets, setDockets] = useState([]);
    const { currentUser } = useAuth();
    //const [selectedDocket, setSelectedDocket] = useState(id)
    const setSelectedDocket = useState(id)[1];
    const navigate = useNavigate();
    const [selectedDocketDetails, setSelectedDocketDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [impDates, setImpDates] = useState([]);
    const { logout } = useAuth();

    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];

    const fetchData = useCallback(async (url, setData) => {
        try {
            const response = await axios.get(url);
            if (response.status === 200 || response.status === 201) {
                setData(response.data);
            }
        } catch (error) {
            if (error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
               console.log(error);
            }
        }
    }, [logout, navigate]);
    const fetchImpDatesData = useCallback(() => fetchData('reg/v1/home/dashboard/impdates', (data) => {
        setImpDates(data);
    }), [fetchData]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch agencies
                const agencyResponse = await axios.get("/reg/v1/agency/");
                const agencies = agencyResponse.data;
                if (agencies.length > 0) {
                    setAgencies(agencies);
                }

                // Fetch docket details
                const agencyName = id.slice(0, 3).toUpperCase();
                const agencyObject = agencies.find(agency => agency.short_name === agencyName);
                if (agencyObject) {
                    setSelectedAgency(agencyObject);

                    const docketResponse = await axios.get(`/reg/v1/docket/agency/${currentUser?.organization}/${agencyObject.id}/${currentUser?.id}`);
                    const dockets = docketResponse.data;
                    const docket = dockets.find(docket => docket.id === id);
                    setDockets(dockets);
                    setSelectedDocket(docket);

                    const docketDetailsResponse = await axios.get(`/reg/v1/docket/${docket.iddocket}`);
                    const docketDetails = docketDetailsResponse.data;
                    let newDocketDetails = docketDetails['0'];
                    setSelectedDocketDetails(newDocketDetails);

                    // Fetch documents
                    const documentResponse = await axios.get(`/reg/v1/document/${newDocketDetails.iddocket}`);
                    const documents = documentResponse.data;
                    setDocuments(documents);
                } else {
                    console.error("Agency not found:", agencyName);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error("Error fetching data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchImpDatesData();
    }, [id, currentUser, setSelectedDocket, fetchImpDatesData, logout, navigate]);

    const generateAbstract = () => {
        const newAbstract = "Generated abstract";
        setSelectedDocketDetails(prevState => ({ ...prevState, dkAbstract: newAbstract }));
    };

    const generateKeywords = () => {
        // Generate an abstract for the docket
        const newKeywords = "Generated keywords";
        setSelectedDocketDetails(prevState => ({ ...prevState, keywords: newKeywords }));
    };

    // changing date-time format from international to local
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };

    const formatDate2 = (timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return '';
        }
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const handleAgencyChange = (event) => {
        const agencyName = event.target.value;
        const selectAgency = agencies.find(agency => agency.short_name === agencyName);

        const fetchDockets = async (agencyId) => {
            try {
                const response = await axios.get(`/reg/v1/docket/agency/${currentUser?.organization}/${agencyId}/${currentUser?.id}`);
                const { data } = response;
                if (data.length > 0) {
                    return data; // Return the dockets
                }
            } catch (error) {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error("Error fetching dockets: ", error);
                }
            }
        };

        if (selectAgency) {
            fetchDockets(selectAgency.id).then((newDockets) => {
                if (newDockets && newDockets.length > 0) {
                    const newSelectedDocket = newDockets[0].id;
                    const newSelectedDocketDetails = newDockets.find(docket => docket.id === newSelectedDocket);

                    setSelectedAgency(selectAgency);
                    setDockets(newDockets);
                    setSelectedDocket(newSelectedDocket);
                    setSelectedDocketDetails(newSelectedDocketDetails);
                    navigate(`/docket/${newSelectedDocket}`);
                }
            });
        }
    };

    const handleDocketChange = (event) => {
        const newSelectedDocket = event.target.value;
        setSelectedDocket(newSelectedDocket);
        const newSelectedDocketDetails = dockets.find(docket => docket.id === newSelectedDocket);

        if (newSelectedDocketDetails) {
            setSelectedDocketDetails(newSelectedDocketDetails);
            setError(null); // Clear any previous error
        } else {
            setError(`No Docket Found with ID: ${newSelectedDocket}`);
        }
        navigate(`/docket/${newSelectedDocket}`);
    }

    const handleSubscribe = async (docketId, subscribed) => {
        let endpoint;
        if (subscribed) {
            endpoint = `/reg/v1/docket/${currentUser?.id}/${docketId}/unsubscribe`;
        } else {
            endpoint = `/reg/v1/docket/${currentUser?.id}/${docketId}/subscribe`;
        }

        try {
            const response = await axios.get(endpoint);
            if (response.status === 200) {
                setSelectedDocketDetails(prevDetails => {
                    return { ...prevDetails, userid: subscribed ? null : currentUser?.id };
                });
            } else {
                console.log('An error occurred on the server. Please try again.');
            }

        } catch (error) {
            console.error('Error subscribing/unsubscribing:', error);
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                if (error.response) {
                    errorMessage = 'An error occurred on the server. Please try again.';
                }
                console.log(errorMessage);
                //setAlert({ message: errorMessage, type: 'danger' });
                window.scrollTo({ top: 0, behavior: 'auto' });
            }
        }
    };

    const handleStarDocument = (id) => {
        axios.get(`/reg/v1/document/star/${currentUser?.id}/${id}`)
            .then((response) => {
                // Fetch documents
                axios.get(`/reg/v1/document/${selectedDocketDetails?.iddocket}`)
                    .then((response) => {
                        const { data } = response;
                        //console.log("documents", data)
                        setDocuments(data);
                    })
                    .catch((error) => {
                        console.error("Error fetching documents: ", error);
                    });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                setError(error);
                }
            });
    };

    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`;
        window.open(url, '_blank');

    };
    const handleViewButtonDOCClick = (documentId) => {
        const url = `/viewtxt?id=${documentId}`;
        window.open(url, '_blank');
    };

    const handleAttachmentClick = (id, filetype, type) => {
        const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
        window.open(url, '_blank');
    };

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                {error && <div className="alert alert-danger">{error}</div>}
                <DocketDetailsHeader
                    agencies={agencies}
                    selectedAgency={selectedAgency}
                    handleAgencyChange={handleAgencyChange}
                    id={id}
                    handleDocketChange={handleDocketChange}
                    loading={loading}
                />
                {/* <hr style={{ borderColor: '#000', borderWidth: '0.5px' }} /> */}

                <div style={{ paddingTop: '15px' }}>

                    <DocketInfo
                        selectedDocketDetails={selectedDocketDetails}
                        currentUser={currentUser}
                        handleSubscribe={handleSubscribe}
                        formatDate={formatDate}
                        generateAbstract={generateAbstract}
                        generateKeywords={generateKeywords}
                    />

                    <DocketDetailsCharts selectedAgency={selectedAgency} documents={documents} colors={colors} />
                    <ImportantDocketDates impDates={impDates} docket={id} formatDate={formatDate} />

                    <div className="d-flex flex-row-fluid flex-center mx-10 mt-6">
                        <div className="flex-column flex-row-fluid  flex-center  ">
                            <>
                                <h2 className="  d-md-block text-wrap" style={{ color: "#4d4d4d" }}> Documents List:</h2>
                            </>
                        </div>
                    </div>


                    <Documents
                        documents={documents}
                        handleViewButtonPDFClick={handleViewButtonPDFClick}
                        handleViewButtonDOCClick={handleViewButtonDOCClick}
                        handleStarDocument={handleStarDocument}
                        formatDate={formatDate}
                        formatDate2={formatDate2}
                        handleAttachmentClick={handleAttachmentClick}
                    />
                </div>

            </div >
        </div>
    );
};

export default DocketDetails;