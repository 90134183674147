import React, { useState, useEffect, useCallback } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import CommentFilter from './CommentFilter';
import { useAuth } from '../../modules/auth';
import { CommentsListPagination } from './CommentsListPagination';
import CommentsTable from './CommentsTable';
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';
import DocketSelector from './DocketSelector';
import DocumentSelector from './DocumentSelector';
// import { MixedWidget14, MixedWidget15 } from '../../../_metronic/partials/widgets';

const AllComments = () => {

    const [dockets, setDockets] = useState([]);
    const [filteredComments, setFilteredComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    //const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
    const [searchInput, setSearchInput] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const { currentUser, logout } = useAuth();
    const [selectedDocket, setSelectedDocket] = useState('')

    const [comments, setComments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState('')
    const [documents, setDocuments] = useState([]);
    const [selected, setSelected] = useState({ document: null, documentIndex: "" });


    const COMMENTS_PER_PAGE = 10;
    const [filters, setFilters] = useState({
        docket: '',
        status: '',
        startDate: '',
        endDate: '',
    });
    const navigate = useNavigate();


    const fetchAgenciesAndDockets = useCallback(async () => {
        setIsLoading(true);
        try {
            // Fetch agencies
            const agencyResponse = await axios.get("/reg/v1/agency/");
            const agencies = agencyResponse.data;

            // Fetch dockets for each agency
            const promises = agencies.map((agency) =>
                axios.get(`/reg/v1/docket/agency/${currentUser?.organization}/${agency.id}/${currentUser?.id}`)
            );

            const responses = await Promise.all(promises);

            // Extract dockets from each response and combine into one array
            const allDockets = responses.flatMap(response => response.data);

            // Sort the dockets
            const sortedDockets = allDockets.sort((a, b) => {
                if (a.id < b.id) return -1;
                if (a.id > b.id) return 1;
                return 0;
            });

            setDockets(sortedDockets);
            if (sortedDockets.length > 0) {
                //console.log(sortedDockets)
                //setSelectedDocket(sortedDockets[1].id);
                //console.log("sortedDockets[0].id:", sortedDockets[0].id)
            }
            //setError(null); // Clear any previous errors
            setIsLoading(false); // Move this line here
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                //setError('Unable to fetch agencies or dockets');
                setIsLoading(false);
            }
        }
    }, [currentUser, navigate, logout]);

    useEffect(() => {
        if (currentUser) {
            fetchAgenciesAndDockets();
        }
    }, [fetchAgenciesAndDockets, currentUser]);

    const fetchDocuments = useCallback((idDocket) => {
        setIsLoading(true);
        axios.get(`/reg/v1/document/${idDocket}`).then((response) => {
            const { data } = response;
            setDocuments(data);
            // if (data.length > 0) {
            //     setSelectedDocument(data[0]);
            // }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error("Error fetching documents:", error);
            }
        });
    }, [navigate, logout]);

    useEffect(() => {
        if (currentUser && selectedDocket) {
            const idDocket = dockets.find(docket => docket.id === selectedDocket)?.iddocket;
            if (idDocket) {
                fetchDocuments(idDocket);
            }
        }
        else {
            setDocuments([]);
            setComments([]); // Clear comments when "Select Docket" is selected
            //setSelectedDocument(null);
        }
    }, [currentUser, selectedDocket, dockets, fetchDocuments]);

    const fetchComments = useCallback((documentId) => {
        if (!documentId || !selectedDocket) {
            setFilteredComments([]);
            //setFilteredComments([]);
            return;
        }
        setIsLoading(true);
        axios.get(`/reg/v1/comment/${documentId}`).then((response) => {
            const { data } = response;
            if (data && data.length > 0) {
                setComments(data);
                //console.log("Comments:", data)
                setFilteredComments(data); // Set filteredComments here
            } else {
                //console.log("No comments found.")
                setComments([]);
                setFilteredComments([]); // And here
            }
            setIsLoading(false);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            } else {
                console.error("Error fetching comments:", error);
            }
            setIsLoading(false);
        });
    }, [selectedDocket, navigate, logout]);

    useEffect(() => {
        //console.log("selectedDocument:", selectedDocument);
        if (selectedDocument) {
            fetchComments(selectedDocument.id);
            // console.log("selectedDocket", selectedDocket)
            // console.log("selectedDocument", selectedDocument)
        }
        else {
            setSelectedDocument(null);
            //setComments([]);
        }
    }, [selectedDocument, fetchComments, selectedDocket]);

    const sortComments = useCallback(() => {
        setFilteredComments(prevDockets => {
            const sortedDockets = [...prevDockets];
            sortedDockets.sort((a, b) => {
                if (sortConfig.key === 'isSubscribed') {
                    const aIsSubscribed = a.userid === currentUser?.id;
                    const bIsSubscribed = b.userid === currentUser?.id;
                    if (aIsSubscribed && !bIsSubscribed) return sortConfig.direction === 'asc' ? -1 : 1;
                    if (!aIsSubscribed && bIsSubscribed) return sortConfig.direction === 'asc' ? 1 : -1;
                    return 0;
                } else {
                    const aValue = a[sortConfig.key];
                    const bValue = b[sortConfig.key];

                    // If both values are null, they are equal
                    if (aValue === null && bValue === null) return 0;

                    // If aValue is null, it's greater than bValue when sorting in ascending order
                    // and less than bValue when sorting in descending order
                    if (aValue === null) return sortConfig.direction === 'asc' ? 1 : -1;

                    // If bValue is null, it's greater than aValue when sorting in ascending order
                    // and less than aValue when sorting in descending order
                    if (bValue === null) return sortConfig.direction === 'asc' ? -1 : 1;

                    // If neither value is null, compare them as usual
                    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
                    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
                    return 0;
                }
            });
            return sortedDockets;
        });
    }, [sortConfig, currentUser]);

    useEffect(() => {
        if (sortConfig.key) {
            sortComments();
        }
    }, [sortConfig, sortComments, comments]);

    useEffect(() => {
        // If the search input is empty, set filteredDockets to all dockets
        if (searchInput.trim() === '') {
            setFilteredComments(comments);
        } else {
            // Otherwise, filter dockets based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = comments.filter((comment) => {
                return (
                    (comment.id && comment.id.toLowerCase().includes(searchTerm)) ||
                    (comment.firstName && comment.firstName.toLowerCase().includes(searchTerm)) ||
                    (comment.lastName && comment.lastName.toLowerCase().includes(searchTerm)) ||
                    (comment.organization && comment.organization.toLowerCase().includes(searchTerm)) ||
                    (comment.comment && comment.comment.toLowerCase().includes(searchTerm)) ||
                    (comment.category && comment.category.toLowerCase().includes(searchTerm))
                );
            });
            setFilteredComments(filtered);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);



    // useEffect(() => {
    //     if (isChecked) {
    //         setFilteredComments(dockets.filter(comment => comment.userid === currentUser?.id));
    //     } else {
    //         setFilteredComments(dockets);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isChecked, currentUser?.id, dockets]);


    const applyFilters = () => {
        let filtered = comments.filter((comment) =>
            (!filters.comment || comment.id.toLowerCase().includes(filters.comment.toLowerCase())) &&
            (!filters.status || comment.docketType === filters.status) &&
            (!filters.startDate || new Date(comment.modifyDate).getTime() >= new Date(filters.startDate).getTime()) &&
            (!filters.endDate || new Date(comment.modifyDate).getTime() <= new Date(filters.endDate).getTime())
        );
        setFilteredComments(filtered);
    };
    const handleFilterChange = (key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));

    };
    const handleApplyFilters = () => {
        applyFilters();
    };
    const handleResetFilters = () => {
        // Reset the filters state
        setFilters({
            docket: '',
            status: '',
            startDate: '',
            endDate: '',
        });
        // Reset the filtered dockets
        setFilteredComments(comments);
    };

    const handleHeaderClick = (key) => {
        let newDirection = 'asc';

        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            newDirection = 'desc';
        }

        setSortConfig({ key, direction: newDirection });
    }



    // changing date-time format from international to local
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };


    const handleDocketChange = (event) => {
        setSelectedDocket(event.target.value);
        setSelected({ document: null, documentIndex: "" });
        setSelectedDocument('');
        setFilteredComments([]);
    };

    const handleDocumentChange = (event) => {
        const selectedIndex = event.target.value;
        if (selectedIndex !== "") {
            const selectedDocument = documents[selectedIndex];
            setSelectedDocument(selectedDocument);
            setSelected({ document: selectedDocument, documentIndex: selectedIndex });
        } else {
            setSelected({ document: null, documentIndex: "" });
        }
    };


    const totalPages = Math.ceil(filteredComments.length / COMMENTS_PER_PAGE);

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid  px-5 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap d-flex align-items-center justify-content-center" style={{ color: "#4d4d4d" }}>
                            <KTIcon iconName='notepad' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary' style={{ transform: 'translateY(60px)' }} />
                            {selectedDocket && selectedDocument ?
                                `Comments for Document: ${selectedDocument.id}` :
                                `Select a docket and document from the dropdown to get started`
                            }
                        </h1>
                        {isLoading &&
                            <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
                                <div className="spinner-border text-primary fs-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>



                <div className="d-flex flex-column-auto  align-items-center  m-3 ">
                    <DocketSelector dockets={dockets} selectedDocket={selectedDocket} handleDocketChange={handleDocketChange} />
                    <DocumentSelector documents={documents} selected={selected} handleDocumentChange={handleDocumentChange} />
                    <SearchBar
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                    />
                    <div className="d-flex flex-column-auto  ms-4  ">
                        <button
                            type='button'
                            className='btn btn-light-primary  btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >

                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <CommentFilter
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                            dockets={dockets}
                        />
                    </div>


                    {/* <div className="d-flex flex-column-auto mx-6">
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input' type='checkbox' value='2' defaultChecked={false} onChange={e => setIsChecked(e.target.checked)} />
                            <span className='form-check-label fw-bold fs-6' style={{ color: '#3699ff' }}>My subscriptions</span>
                        </label>
                    </div> */}
                    {/* <div className="d-flex flex-column-auto">
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input' type='checkbox' value='2' checked={checkedActive} onChange={e => setCheckedActive(e.target.checked)} />
                            <span className='form-check-label fw-bold fs-6' style={{ color: '#3699ff' }}>Active Documents</span>
                        </label>
                    </div> */}
                </div>

                <div className="d-flex flex-column flex-row-fluid p-2">

                    {alert.message && (
                        <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                            {alert.message}
                            <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                        </div>
                    )}

                    <CommentsTable
                        filteredComments={filteredComments}
                        currentPage={currentPage}
                        COMMENTS_PER_PAGE={COMMENTS_PER_PAGE}
                        handleHeaderClick={handleHeaderClick}
                        sortConfig={sortConfig}
                        // handleSubscribe={handleSubscribe}
                        currentUser={currentUser}
                        formatDate={formatDate}
                    />
                    {/* )} */}
                    <CommentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />


                </div>
            </div >
        </div >

    );
};

export default AllComments;