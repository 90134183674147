import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';

const EnforcementsDetails = () => {
    const { id } = useParams();
    const [device, setDevice] = useState([]);
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDevice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/fda/enforcement/${id}`);
                setDevice(response.data[0]);
                // console.log(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log('Error fetching document:', error);
                setIsLoading(false);
            }
        };
        fetchDevice();
    }, [id]);

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={{
                            pathname: "/device-intelligence/enforcements"
                        }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Enforcements List
                    </Link>
                </div>
            </div>

            <div className='card mt-4 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>Recall Number: <Link to={`/device-intelligence/recalls/${device.recall_number}`} target="_blank">{device.recall_number}</Link></h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Enforcement Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {device.status && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Status</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.status}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.recalling_firm && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Recalling Firm</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.recalling_firm}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.product_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Type</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.product_type}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.classification && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Class</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">
                                                    {device.classification === 'Class I' ? '1' : device.classification === 'Class II' ? '2' : device.classification === 'Class III' ? '3' : device.classification}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {device.product_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Description</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.product_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.product_quantity && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Quantity</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.product_quantity}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.reason_for_recall && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Reason for Recall</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.reason_for_recall}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.voluntary_mandated && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Initiated By</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.voluntary_mandated}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.initial_firm_notification && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Initial Firm Notification</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.initial_firm_notification}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.distribution_pattern && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Distribution Pattern</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.distribution_pattern}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device.event_id && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event ID</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.event_id}</span>
                                            </div>
                                        </div>
                                    )}
                                    {(device.recall_initiation_date || device.center_classification_date || device.termination_date || device.report_date) && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Dates</span>
                                            </div>
                                            <div className="col-10">
                                                <ul className="list-unstyled">
                                                    {device.recall_initiation_date && <li><span className="card-title fw-bold text-gray-600 fs-5 ">Recall Initiation Date: {new Date(device.recall_initiation_date).toLocaleDateString()}</span></li>}
                                                    {device.center_classification_date && <li><span className="card-title fw-bold text-gray-600 fs-5 ">Center Classification Date: {new Date(device.center_classification_date).toLocaleDateString()}</span></li>}
                                                    {device.termination_date && <li><span className="card-title fw-bold text-gray-600 fs-5 ">Termination Date: {new Date(device.termination_date).toLocaleDateString()}</span></li>}
                                                    {device.report_date && <li><span className="card-title fw-bold text-gray-600 fs-5 ">Report Date: {new Date(device.report_date).toLocaleDateString()}</span></li>}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default EnforcementsDetails;
