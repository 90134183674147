import React from 'react';

const CommentFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters,
}) => {
    return (
        <div>
            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
            </div>
            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>

                <div className='mb-4'>
                    <label className='form-label fw-bold' htmlFor='dateRange'>
                        Comment Date Range:
                    </label>
                    <input
                        type='date'
                        id='startDate'
                        className='form-control form-control-solid my-2'
                        placeholder='Start Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                    <input
                        type='date'
                        id='endDate'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>

                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CommentFilter;