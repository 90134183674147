import React, { FC } from 'react'
import Debarment from './Debarment'

const DebarmentWrapper: FC = () => {
    return (
        <>

            <Debarment />
        </>
    )
}

export default DebarmentWrapper
