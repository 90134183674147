import axios, { AxiosResponse } from 'axios'
import { AuthModel, User, UserInterests, UserAddons } from './_models'
import { ID, Response } from "../../../../_metronic/helpers"


const API_URL = process.env.REACT_APP_API_URL

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const USER_URL = `/reg/v1/user/user`
export const LOGIN_URL = `/logina`
export const LOGOUT_URL = `/logout`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

export function LogOut() {
  return axios.get(LOGOUT_URL)

}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  title: string,
  suffix: string
) {
  return {
    email,
    first_name: firstname,
    last_name: lastname,
    title,
    suffix
  };
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  const data = new FormData();
  data.append('email', email);
  return axios.post(REQUEST_PASSWORD_URL, data)
}

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }

export function getUserById(id: ID): Promise<User | undefined> {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => {
      const user = response.data[0];
      return user;
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
}

export async function getUserInterests(userId: ID): Promise<UserInterests> {

  const focusAreas = (await axios.get(`/reg/v1/user/preference/${userId}`)).data;

  const selectedRegions: string[] = [];
  const selectedAgencies: string[] = [];
  const selectedPrograms: string[] = [];
  const selectedCommittees: string[] = [];
  const selectedClasses: number[] = [];

  focusAreas.forEach(({ preference_type, preference_text }) => {
    switch (preference_type) {
      case 1: // Regions
        selectedRegions.push(preference_text);
        break;
      case 2: // Agencies
        selectedAgencies.push(preference_text);
        break;
      case 3: // Programs
        selectedPrograms.push(preference_text);
        break;
      case 4:
        selectedClasses.push(parseInt(preference_text));
        break;
      case 5:
        selectedCommittees.push(preference_text);
        break;
      default:
        break;
    }
  });

  return {
    regions: selectedRegions,
    agencies: selectedAgencies,
    programs: selectedPrograms,
    committees: selectedCommittees,
    device_classes: selectedClasses
  };
}

export async function getUserAddons(userId: ID): Promise<UserAddons> {
  return axios.get(`/reg/v1/user/profile/${userId}`)
    .then(response => {
      const data = response.data[0];
      // console.log(data);
      return {
        translation: !!Number(data.has_translation),
        videoTraining: !!Number(data.has_videotraining)
      };
    });
}