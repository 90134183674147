import React, { useState, useEffect, useContext } from 'react'
import { IOrgInterests } from './setting/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import { useSelector } from 'react-redux'
import axios from 'axios';
import Select from 'react-select'
import { useAuth } from '../../../modules/auth'
import { ProfileUpdateContext } from './ProfileUpdateContext'
const orgInterestsSchema = Yup.object().shape({
  regions: Yup.array().min(1, 'Please select at least one region.'),
  agencies: Yup.array().min(1, 'Please select at least one agency.'),
  programs: Yup.array().min(1, 'Please select at least one program.'),
  device_classes: Yup.array().min(1, 'Please select at least one device class.'),
  committees: Yup.array().min(1, 'Please select at least one committee.')
});

type OptionType = {
  value: string;
  label: string;
  id: number;
  isDisabled: boolean;
  preference_type: number;
};


const OrgInterests: React.FC = () => {

  const [loading, setLoading] = useState(true);
  const [agenciesOptions, setAgenciesOptions] = useState<OptionType[]>([]);
  const [programsOptions, setProgramsOptions] = useState<OptionType[]>([]);
  const [committeeOptions, setCommitteeOptions] = useState<OptionType[]>([]);
  const [regionsOptions, setRegionsOptions] = useState<OptionType[]>([]);
  const [initialValues, setInitialValues] = useState([]);
  // const avatarUrl = useSelector((state: any) => state.account.avatarUrl);
  const { currentUser } = useAuth();

  const { setIsProfileUpdated } = useContext(ProfileUpdateContext);

  const formik = useFormik<IOrgInterests>({
    initialValues: {
      regions: [],
      agencies: [],
      programs: [],
      device_classes: [] as number[],
      committees: []
    },
    validationSchema: orgInterestsSchema,
    onSubmit: async (values) => {
      setLoading(true);

      // console.log(values)

      try {

        //console.log("values:, ", values)
        const data = {
          preferences: [
            ...values.regions.flatMap(region => {
              const selectedRegion = regionsOptions.find(option => option.label === region);
              return selectedRegion ? [[1, selectedRegion.id]] : [];
            }),
            ...values.agencies.flatMap(agency => {
              const selectedAgency = agenciesOptions.find(option => option.label === agency);
              return selectedAgency ? [[2, selectedAgency.id]] : [];
            }),
            ...values.programs.flatMap(program => {
              const selectedProgram = programsOptions.find(option => option.label === program);
              return selectedProgram ? [[3, selectedProgram.id]] : [];
            }),
            ...formik.values.device_classes.map(option => [4, option]),
            ...values.committees.flatMap(committee => {
              const selectedCommittee = committeeOptions.find(option => option.label === committee);
              return selectedCommittee ? [[5, selectedCommittee.id]] : [];
            })
          ]
        };
        const result = data.preferences;
        // console.log("result:, ", result);


        const removedData = initialValues.filter(([focusArea, id]) => !result.some(([rFocusArea, rId]) => rFocusArea === focusArea && rId === id));
        const addData = result.filter(([rFocusArea, rId]) => !initialValues.some(([focusArea, id]) => rFocusArea === focusArea && rId === id));

        //console.log('removedData:', removedData);
        //console.log('addData:', addData);
        if (addData.length > 0) {
          const jsonData = JSON.stringify({ preferences: addData });
          const response = await axios.post(`/reg/v1/admin/org/preference/${currentUser?.organization}`, jsonData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.status === 200 || response.status === 201) {
            // Request was successful, update the state or perform any other actions
            // you want to take when the update is successful.
            alert('Organization updated successfully');
            setIsProfileUpdated(true);
            fetchInitialValues();
          } else {
            // Handle errors if the response status is not 200.
            alert('Update failed');
            console.error('Update failed:', response.data);
          }
        }
        // console.log('addData:', addData)
        // console.log('removedData:', removedData)
        if (removedData.length > 0) {
          const jsonData = JSON.stringify({ preferences: removedData });
          const response = await axios.delete(`/reg/v1/admin/org/preference/${currentUser?.organization}`, {
            data: jsonData,
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.status === 200 || response.status === 201) {
            // Request was successful, update the state or perform any other actions
            // you want to take when the update is successful.
            alert('Organization updated successfully');
            setIsProfileUpdated(true);
            fetchInitialValues();
          } else {
            // Handle errors if the response status is not 200.
            alert('Update failed');
            console.error('Update failed:', response.data);
          }
        }

      } catch (error) {
        // Handle network errors or exceptions here.
        console.error('Update failed:', error);
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchInitialValues = async () => {
    axios.get(`/reg/v1/admin/org/preference/${currentUser?.organization}`)
      .then(response => {
        const focusAreas = response.data;
        const focusAreaPairs = focusAreas.map(({ preference_type, preference_id }) => [preference_type, preference_id]);
        setInitialValues(focusAreaPairs);
        //console.log('focusAreas:', focusAreaPairs);

        const selectedRegions: string[] = [];
        const selectedAgencies: string[] = [];
        const selectedPrograms: string[] = [];
        const selectedCommittees: string[] = [];
        const selectedClasses: number[] = [];

        focusAreas.forEach(({ preference_type, preference_text }) => {
          switch (preference_type) {
            case 1: // Regions
              selectedRegions.push(preference_text);
              break;
            case 2: // Agencies
              selectedAgencies.push(preference_text);
              break;
            case 3: // Programs
              selectedPrograms.push(preference_text);
              break;
            case 4:
              selectedClasses.push(parseInt(preference_text, 10));
              break;
            case 5:
              selectedCommittees.push(preference_text);
              break;
            default:
              break;
          }
        });

        const initialValues = {
          regions: selectedRegions,
          agencies: selectedAgencies,
          programs: selectedPrograms,
          device_classes: selectedClasses,
          committees: selectedCommittees
        };

        formik.setValues(initialValues);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error
      });
  }

  useEffect(() => {
    const fetchRegions = axios.get('/reg/v1/user/regions')
      .then(response => {
        const options = response.data.map(region => ({
          value: region.region_code,
          label: region.region_code,
          id: region.region_id, // Save the id
          isDisabled: region.is_active === 0,
          preference_type: 1 // Set focus_area to 2 for agencies
        }));
        options.sort((a, b) => a.isDisabled - b.isDisabled);
        return options;
      });
    const fetchAgencies = axios.get('/reg/v1/user/agencies')
      .then(response => {
        const options = response.data.map(agency => ({
          value: agency.short_name,
          label: agency.short_name,
          id: agency.id, // Save the id
          isDisabled: agency.is_active === 0,
          focus_area: 2 // Set focus_area to 2 for agencies
        }));
        options.sort((a, b) => a.isDisabled - b.isDisabled);
        return options;
      });

    const fetchPrograms = axios.get('/reg/v1/user/fdaprograms')
      .then(response => {
        const options = response.data.map(program => ({
          value: program.code,
          label: `${program.program} (${program.code})`,
          id: program.program_id, // Save the id
          isDisabled: program.is_active === 0,
          focus_area: 3 // Set focus_area to 3 for programs
        }));
        options.sort((a, b) => a.isDisabled - b.isDisabled);
        return options;
      });

    const fetchCommittees = axios.get('/reg/v1/fda/advisory_committee')
      .then(response => {
        const options = response.data.map(committee => ({
          value: committee.code,
          label: `${committee.description} (${committee.code})`,
          id: committee.id, // Save the id
          isDisabled: 0,
          focus_area: 5
        }));
        options.sort((a, b) => a.isDisabled - b.isDisabled);
        return options;
      });

    if (!currentUser?.organization) {
      console.error('currentUser or currentUser.organization is undefined');
      return;
    }

    fetchInitialValues();

    Promise.all([fetchAgencies, fetchPrograms, fetchCommittees, fetchRegions])
      .then(([agenciesOptions, programsOptions, committeeOptions, regionsOptions]) => {
        // Fetch the initial values when the component mounts
        setAgenciesOptions(agenciesOptions);
        setProgramsOptions(programsOptions);
        setCommitteeOptions(committeeOptions);
        setRegionsOptions(regionsOptions);
      })
      .catch(error => console.error('Error:', error));
  }, [currentUser?.organization]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className="row">
        <div
          className='col-sm card-header border-0 cursor-pointer'
          role='button'
          // data-bs-toggle='collapse'
          data-bs-target='#kt_account_org_interests'
          aria-expanded='true'
          aria-controls='kt_account_org_interests'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0 me-3'>Organization Preferences</h3>
            {loading && <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
          </div>

        </div>

      </div>

      <div id='kt_account_org_interests' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/* <div className="col-sm" >
              <div className='card-body d-flex justify-content-end py-0 px-0'>
                <button type='submit' className='btn btn-primary no-collapse' disabled={loading} >
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='required col-lg-3 col-form-label  fw-bold fs-6'>Regions</label>

              <div className='col-lg-5'>
                <Select
                  isMulti
                  className=' fw-bold mb-3 mb-lg-0'
                  placeholder='Select Regions'
                  options={regionsOptions.filter(option => !formik.values.regions.includes(option.label))}
                  value={formik.values.regions ? formik.values.regions.map(region => ({ value: region, label: region })) : []}
                  onChange={selectedOptions => {
                    const selectedRegionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                    formik.setFieldValue('regions', selectedRegionIds);
                  }}
                  isDisabled={false}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                  }}
                  menuPortalTarget={document.body}
                />
                {formik.touched.regions && formik.errors.regions && (
                  <div className='fv-plugins-message-container ms-3 mt-2'>
                    <div className='fv-help-block'>{formik.errors.regions}</div>
                  </div>
                )}
              </div>

            </div>

            <div className='row mb-6'>
              <label className='required col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Agencies</span>
              </label>

              <div className='col-lg-5 fv-row'>
                <Select
                  isMulti
                  className=' fw-bold mb-3 mb-lg-0'
                  placeholder='Agencies of Interest'
                  options={agenciesOptions.filter(option => !formik.values.agencies.includes(option.label))}
                  value={formik.values.agencies ? formik.values.agencies.map(agency => ({ value: agency, label: agency })) : []}
                  onChange={selectedOptions => {
                    const selectedAgencyLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                    formik.setFieldValue('agencies', selectedAgencyLabels);
                  }}
                  isDisabled={false}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                  }}
                  menuPortalTarget={document.body}
                />
                {formik.touched.agencies && formik.errors.agencies && (
                  <div className='fv-plugins-message-container ms-3 mt-2'>
                    <div className='fv-help-block'>{formik.errors.agencies}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='required col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Programs</span>
              </label>

              <div className='col-lg-5 fv-row'>
                <Select
                  isMulti
                  className=' fw-bold mb-3 mb-lg-0'
                  placeholder='Programs of Interest'
                  options={programsOptions.filter(option => !formik.values.programs.includes(option.label))}
                  value={formik.values.programs ? formik.values.programs.map(program => ({ value: program, label: program })) : []}
                  onChange={selectedOptions => {
                    const selectedProgramLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                    formik.setFieldValue('programs', selectedProgramLabels);
                  }}
                  isDisabled={false}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                  }}
                  menuPortalTarget={document.body}
                />
                {formik.touched.programs && formik.errors.programs && (
                  <div className='fv-plugins-message-container ms-3 mt-2'>
                    <div className='fv-help-block'>{formik.errors.programs}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-8'>
              <label className='required col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Specialties</span>
              </label>

              <div className='col-lg-5 fv-row'>
                <Select
                  isMulti
                  className='fw-bold mb-3 mb-lg-0'
                  placeholder='Committees of Interest'
                  options={committeeOptions.filter(option => !formik.values.committees.includes(option.label))}
                  value={formik.values.committees ? formik.values.committees.map(committee => ({ value: committee, label: committee })) : []}
                  onChange={selectedOptions => {
                    const selectedCommitteeLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                    formik.setFieldValue('committees', selectedCommitteeLabels);
                  }}
                  isDisabled={false}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                  }}
                  menuPortalTarget={document.body}
                />
                {formik.touched.committees && formik.errors.committees && (
                  <div className='fv-plugins-message-container ms-3 mt-2'>
                    <div className='fv-help-block'>{formik.errors.committees}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='required col-lg-3 col-form-label fw-bold fs-6 mt-1'>
                <span className=''>Device Classes</span>
              </label>

              <div className='col-lg-6 fv-row mt-4'>
                <div className='row'>
                  <div className='col-lg-3 ms-1 ' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className='form-check-input me-2 mb-2'
                      type='checkbox'
                      id='class1'
                      name='device_classes'
                      value={1}
                      checked={formik.values.device_classes.includes(1)}
                      onChange={() => {
                        if (formik.values.device_classes.includes(1)) {
                          const nextValue = formik.values.device_classes.filter(value => value !== 1);
                          formik.setFieldValue('device_classes', nextValue);
                        } else {
                          const nextValue = [...formik.values.device_classes, 1];
                          formik.setFieldValue('device_classes', nextValue);
                        }
                      }}
                    />
                    <label className='form-label fw-bold' htmlFor="class1" style={{ fontSize: '1.3rem' }}>Class I</label>
                  </div>
                  <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className='form-check-input me-2 mb-2'
                      type='checkbox'
                      id='class2'
                      name='device_classes'
                      value={2}
                      checked={formik.values.device_classes.includes(2)}
                      onChange={() => {
                        if (formik.values.device_classes.includes(2)) {
                          const nextValue = formik.values.device_classes.filter(value => value !== 2);
                          formik.setFieldValue('device_classes', nextValue);
                        } else {
                          const nextValue = [...formik.values.device_classes, 2];
                          formik.setFieldValue('device_classes', nextValue);
                        }
                      }}
                    />
                    <label className='form-label fw-bold' htmlFor="class2" style={{ fontSize: '1.3rem' }}>Class II</label>
                  </div>
                  <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      className='form-check-input me-2 mb-2'
                      type='checkbox'
                      id='class3'
                      name='device_classes'
                      value={3}
                      checked={formik.values.device_classes.includes(3)}
                      onChange={() => {
                        if (formik.values.device_classes.includes(3)) {
                          const nextValue = formik.values.device_classes.filter(value => value !== 3);
                          formik.setFieldValue('device_classes', nextValue);
                        } else {
                          const nextValue = [...formik.values.device_classes, 3];
                          formik.setFieldValue('device_classes', nextValue);
                        }
                      }}
                    />
                    <label className='form-label fw-bold' htmlFor="class3" style={{ fontSize: '1.3rem' }}>Class III</label>
                  </div>
                </div>
                {formik.touched.device_classes && formik.errors.device_classes && (
                  <div className='fv-plugins-message-container ms-3 mt-n1'>
                    <div className='fv-help-block'>{formik.errors.device_classes}</div>
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>


      </div>
    </div>
  )
}

export { OrgInterests }