import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const ForecastWrapper: FC = () => {
    return (
        <div className="d-flex flex-column align-items-center">
            <h1 className="mt-5 text-primary">COMING SOON !</h1>
            <img src={toAbsoluteUrl('/media/illustrations/dozzy-1/19.png')} alt="Coming Soon" className="mt-3" />
        </div>

    )
}

export default ForecastWrapper;