import React from "react";
import AnnouncementList from "./Announcements/AnnouncementList";
const Announcements = () => {
    return (
        <div className='row g-5 g-xxl-8'>
            <AnnouncementList />
        </div>
    );
}
export default Announcements;
