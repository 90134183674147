import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/auth'; // Import your useAuth hook

const ProtectedRoute = ({ roles, children }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth(); // Get the user from your auth context

    React.useEffect(() => {
        if (!currentUser || !roles.includes(currentUser.role)) {
            navigate('/error/404');
        }
    }, [currentUser, navigate, roles]);

    return currentUser && roles.includes(currentUser.role) ? children : null;
};

export default ProtectedRoute;