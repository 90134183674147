import { combineReducers } from 'redux';
import agenciesReducer from './agencies/reducers';
import docketsReducer from './dockets/reducers';
import accountReducer from './account/reducers';
const rootReducer = combineReducers({
    agencies: agenciesReducer,
    dockets: docketsReducer,
    account: accountReducer,
    // Add other reducers as needed
});

export default rootReducer;