import React, { FC } from 'react'

import CalendarCards from './CalendarCards'

const CalendarWrapper: FC = () => {
    return (
        <>
            {/* <PageTitle breadcrumbs={[]}>Dockets</PageTitle> */}
            <CalendarCards />
        </>
    )
}

export default CalendarWrapper
