const StateSelector = ({ states, selectedState, handleStateChange }) => {
    return (
        <div className="d-flex align-items-center">
            <span className="fw-bold fs-6 flex-shrink-0 pe-4 d-none d-md-block" style={{ color:'#3699ff' }}>
                States:
            </span>
            <select
                className="form-select form-select-sm w-100px form-select-solid me-4"
                data-control="select2"
                data-placeholder="Select State"
                value={selectedState || ""}
                onChange={handleStateChange}
                style={{backgroundColor:'#f1faff', height: '40px' }}
            >
                <option value="">Select State</option>
                {Array.isArray(states) && states.length > 0 &&
                    states.map((state) => (
                        <option key={state.id} value={state.id}>
                            {state.name}
                        </option>
                    ))
                }
            </select>
        </div>
    );
};

export default StateSelector;