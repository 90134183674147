// import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useAuth } from '../../../../../app/modules/auth'
export function MenuInner() {
  // const intl = useIntl()
  const { currentUser } = useAuth()
  // console.log('MenuInnerComponent rendered');
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({ id: 'Home' })} to='/home' /> */}
      <MenuInnerWithSub
        title='Bookmarks'
        to='/myitems'
        menuPlacement='bottom-start'
        menuTrigger='click'
        fontIcon='bi-bookmarks-fill'
        hasArrow={true}
      >
        <MenuItem icon='document' to='/myitems/proposed-rules' title='Proposed Rules' hasArrow={true} />
        <MenuItem icon='file-added' to='/myitems/final-rules' title='Final Rules' hasArrow={true} />
        <MenuItem icon='folder' to='/myitems/guidances' title='Guidance' hasArrow={true} />
        {/* <MenuItem icon='document' to='/myitems/notice/track' title='Notices' hasArrow={true} /> */}
      </MenuInnerWithSub>

      {/* <MenuInnerWithSub title='Code of Regulations' to='/cor' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} icon='add-folder'>
        <MenuItem icon='folder' to='/cor/title-21' title='Title 21' hasArrow={true} />
        <MenuItem icon='folder' to='/cor/title-42' title='Title 42' hasArrow={true} />
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub title='Legislation' to='/legislation' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-book-fill'>
        <MenuItem icon='book-square' to='/acacomingsoon' title='Affordable Care Act' hasArrow={true} />
        <MenuItem icon='home-2' to='/sacomingsoon' title='Sunshine Act' hasArrow={true} />
        <MenuItem icon='geolocation-home' to='/iracomingsoon' title='Inflation Reduction Act' hasArrow={true} />
      </MenuInnerWithSub> */}

      <MenuInnerWithSub title='Insights' to='/reports' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-bar-chart-line-fill'>
        <MenuItem icon='chart' to='/insights' title='Insights' hasArrow={true} />
        {/* <MenuItem icon='chart' to='/insights/historical' title='Historical Insights' hasArrow={true} /> */}
        {/* <MenuItem icon='chart' to='/proposedcomingsoon' title='Proposed Rules' hasArrow={true} />
        <MenuItem icon='chart' to='/rulescomingsoon' title='Rules' hasArrow={true} />
        <MenuItem icon='chart' to='/commentcomingsoon' title='Comments' hasArrow={true} /> */}
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-stack'>
        <MenuItem icon='file-up' to='/apps/mydocuments' title='My Documents' hasArrow={true} />
        <MenuItem icon='printer' to='/apps/translation' title='Translate' hasArrow={true} />
        {/* <MenuItem icon='sort' to='/docdiffcomingsoon' title='Doc Diff' hasArrow={true} /> */}
        {/* <MenuItem icon='filter-search' to='/labelcomingsoon' title='Label Search' hasArrow={true} /> */}
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Resources' to='/links' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-link-45deg'>
        <MenuItem icon='geolocation' to='/links/USA' title='US' hasArrow={true} />
        <MenuItem icon='geolocation' to='/links/EU' title='EU' hasArrow={true} />
        <MenuItem icon='geolocation' to='/links/all' title='All' hasArrow={true} />
        {/* <MenuItem icon='share' to='/comingsoon' title='Other' hasArrow={true} /> */}
      </MenuInnerWithSub>

      {(currentUser?.role === 2 || currentUser?.role === 3) && (
        <MenuInnerWithSub to='/admin' title='Admin' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-person-fill-lock'>
          <MenuItem icon='profile-user' to='/admin/users' title='Users' hasArrow={true} />
          <MenuItem icon='gear' to='/admin/settings' title='Settings' hasArrow={true} />
          <MenuItem icon='category' to='/admin/preferences' title='Preferences' hasArrow={true} />
          <MenuItem icon='add-item' to='/admin/add-ons' title='Add-Ons' hasArrow={true} />
          <MenuItem fontIcon='bi-headset' to='/admin/support' title='Support' hasArrow={true} />
          <MenuItem icon='message-notif' to='/admin/announcements' title='Announcements' hasArrow={true} />
          {/* <MenuItem icon='bill' to='/billingcomingsoon' title='Billing' hasArrow={true} /> */}
        </MenuInnerWithSub>
      )}
      {(currentUser?.role === 3) && (
        <MenuInnerWithSub to='/super-admin' title='Lexim Admin' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true} fontIcon='bi-person-workspace'>
          <MenuItem fontIcon='bi-buildings-fill' to='/super-admin/organizations' title='Organizations' hasArrow={true} />
          <MenuItem icon='profile-user' to='/super-admin/users' title='Users' hasArrow={true} />
          <MenuItem icon='gear' to='/super-admin/settings' title='Settings' hasArrow={true} />
          <MenuItem fontIcon='bi-headset' to='/super-admin/support' title='Support' hasArrow={true} />
          <MenuItem icon='message-notif' to='/super-admin/announcements' title='Announcements' hasArrow={true} />
          <MenuItem icon='wrench' to='/super-admin/configurations' title='Configuration' hasArrow={true} />
          <MenuItem icon='shield' to='/super-admin/data-operations' title='Data Operations' hasArrow={true} />
          {/* <MenuItem icon='bill' to='/billingcomingsoon' title='Billing' hasArrow={true} /> */}
        </MenuInnerWithSub>
      )}
    </>
  )
}
