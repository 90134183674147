// Step 1: Import Highcharts and HighchartsReact
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';

type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  data?: DataItem[];
  title: string;
  activity: string;
  columns: { [key: string]: any; };
  agency: string;
};

interface DataItem {
  agency: string | null;
  period_code: string | null;
  [key: string]: any;
}

const MixedWidget9: React.FC<Props> = ({
  className,
  chartHeight,
  title,
  chartColor,
  data = [],
  agency = "",
  columns = {},
  activity = "",
}) => {
  const filteredData = data
    .filter((item) => item.agency === agency && item.period_code === activity)
    .sort((a, b) => a.agency!.localeCompare(b.agency!));

  const navigate = useNavigate();

  // Step 3: Prepare Highcharts Options
  const highchartsOptions = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
      },
      height: parseInt(chartHeight) - 50,
      marginTop: 0,
      spacingTop: 0,
      spacingBottom: 30,
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        innerSize: '60%', // Adjust to make the donut thicker or thinner
        depth: 45,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          alignTo: 'plotEdges',
          enabled: true,
          format: '<b>{point.name}</b>: {point.y:,.0f}',
          shadow: true,
          borderColor: 'transparent',
          borderWidth: 0,
        },
        softConnector: true,
        point: {
          events: {
            click: function (event) {
              let path = '';
              let stateData = {};
              let period = 0;
              const clickedSliceName = event.point.name;
              if (activity === 'month') {
                period = 30;
              }
              if (activity === 'quarter') {
                period = 90;
              }
              if (activity === '6month') {
                period = 180;
              }
              if (activity === 'year') {
                period = 365;
              }

              switch (clickedSliceName) {
                case 'Proposed Rules':
                  path = '/proposed-rules';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Final Rules':
                  path = '/final-rules';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Other':
                  path = '/guidances';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Notices':
                  path = '/notices';
                  stateData = { agency: agency, period_code: period };
                  break;
                default:
                  break;
              }

              if (path) {
                navigate(path, { state: stateData });
              }
            }
          }
        }
      }
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.name}</b>: {point.y:,.0f}' + (title.toLowerCase().includes("comments") ? ' comments' : ' documents'),
    },
    series: [
      {
        name: title,
        data: filteredData.map((item) => {
          return Object.keys(columns).map((key) => ({
            name: key,
            y: item[columns[key]],
            color: chartColor[Object.keys(columns).indexOf(key)] || '#000000', // Use chartColor or default to black
          }));
        }).flat(),
      },
    ],
  };

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  return (
    <div>
      {filteredData.map((item, index) => (
        <div className={`card ${className}`} style={{ height: `${parseInt(chartHeight) + 25}px` }} key={index}>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>{item.agency} {title}</span>
            </h3>
          </div>
          <div className='card-body d-flex flex-column mt-n13'>
            {/* Step 4: Update the Chart Component */}
            <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
          </div>
        </div>
      ))}
    </div>
  );
};

export { MixedWidget9 };