import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import { isNotEmpty, } from '../../../../../../../_metronic/helpers'
import { initialOrganization, Organization } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { OrganizationsListLoading } from '../components/loading/OrganizationsListLoading'
import { updateOrganization } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'

type Props = {
  isOrganizationLoading: boolean
  Organization: Organization
}

const editOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  website: Yup.string()
    .required('Website is required'),
})

const OrganizationEditModalForm: FC<Props> = ({ Organization, isOrganizationLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [OrganizationForEdit] = useState<Organization>({
    ...Organization,
    name: Organization.name || initialOrganization.name,
    primaryuser: Organization.primaryuser || initialOrganization.primaryuser,
    website: Organization.website || initialOrganization.website,
    industry: Organization.industry || initialOrganization.industry,
    is_partner: Organization.is_partner || initialOrganization.is_partner,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  //const OrganizationAvatarImg = toAbsoluteUrl(`/media/${OrganizationForEdit.avatar}`)

  const formik = useFormik({
    initialValues: OrganizationForEdit,
    validationSchema: editOrganizationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await updateOrganization(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_edit_Organization_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_Organization_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_Organization_header'
          data-kt-scroll-wrappers='#kt_modal_add_Organization_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Organization Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Organization Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isOrganizationLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          {/* <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Primary User ID</label>
            <input
              placeholder='Primary User ID'
              {...formik.getFieldProps('primaryuser')}
              type='text'
              name='primaryuser'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.primaryuser && formik.errors.primaryuser },
                {
                  'is-valid': formik.touched.primaryuser && !formik.errors.primaryuser,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isOrganizationLoading}
            />
            {formik.touched.primaryuser && formik.errors.primaryuser && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.primaryuser}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Website</label>

            <input
              placeholder='Website'
              {...formik.getFieldProps('website')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.website && formik.errors.website },
                {
                  'is-valid': formik.touched.website && !formik.errors.website,
                }
              )}
              type='website'
              name='website'
              autoComplete='off'
              disabled={formik.isSubmitting || isOrganizationLoading}
            />
            {/* end::Input */}
            {formik.touched.website && formik.errors.website && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.website}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>Industry</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Industry'
            {...formik.getFieldProps('industry')}
            type='text'
            name='industry'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.industry && formik.errors.industry },
              {
                'is-valid': formik.touched.industry && !formik.errors.industry,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isOrganizationLoading}
          />
          {formik.touched.industry && formik.errors.industry && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.industry}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {/* begin::Input group */}
        {/* <div className="fv-row mb-7">
          <label className='fw-bold fs-6 mb-2'>Is this Organization a Partner?</label>

          <select
            {...formik.getFieldProps('is_partner')}
            name='is_partner'
            className={clsx(
              'form-select form-select-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.is_partner && formik.errors.is_partner },
              { 'is-valid': formik.touched.is_partner && !formik.errors.is_partner }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isOrganizationLoading}
          >
            <option value='0'>Not a Partner</option>
            <option value='1'>Is a Partner</option>
          </select>

          {formik.touched.is_partner && formik.errors.is_partner && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.is_partner}</span>
              </div>
            </div>
          )}
        </div> */}
        {/* end::Input group */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-organizations-modal-action='cancel'
            disabled={formik.isSubmitting || isOrganizationLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-organizations-modal-action='submit'
            disabled={isOrganizationLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isOrganizationLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isOrganizationLoading) && <OrganizationsListLoading />}
    </>
  )
}

export { OrganizationEditModalForm }