import { SET_SELECTED_DOCKET, DOCKET_SUBSCRIPTION_UPDATED } from './types'; // Import the new action type

const initialState = {
    selectedDocket: {
        id: '', // Initially empty
        title: '', // Initially empty
        iddocket: null,
    },
    docketSubscriptionUpdated: false, // Add this line
};

const docketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DOCKET:
            return { ...state, selectedDocket: action.payload };
        case DOCKET_SUBSCRIPTION_UPDATED: // Add this case
            return { ...state, docketSubscriptionUpdated: !state.docketSubscriptionUpdated };
        default:
            return state;
    }
};

export default docketsReducer;