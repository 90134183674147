import React from 'react';
import clsx from 'clsx';

const FilesTablePagination = ({ totalPages, currentPage, setCurrentPage }) => {
    const PAGINATION_PAGES_COUNT = 5;

    const sliceLinks = (totalPages, currentPage) => {
        let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

        if (pageLinks.length > PAGINATION_PAGES_COUNT) {
            let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
            let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

            if (start < 0) {
                end -= start;
                start = 0;
            }

            if (end >= pageLinks.length) {
                start -= end - pageLinks.length + 1;
                end = pageLinks.length - 1;
            }

            pageLinks = pageLinks.slice(start, end + 1);
        }

        return pageLinks;
    }

    const paginationLinks = sliceLinks(totalPages, currentPage);

    return (
        <div className='d-flex justify-content-center mt-7 mb-7'>
            <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                <ul className='pagination justify-content-center'>
                    <li className={clsx('page-item', { 'disabled': currentPage === 1 })}>
                        <button onClick={() => setCurrentPage(1)} style={{ cursor: 'pointer' }} className='page-link'>First</button>
                    </li>
                    {paginationLinks.map((page) => (
                        <li key={page} className={clsx('page-item', { 'active': currentPage === page })}>
                            <button onClick={() => setCurrentPage(page)} style={{ cursor: 'pointer' }} className='page-link'>{page}</button>
                        </li>
                    ))}
                    <li className={clsx('page-item', { 'disabled': currentPage === totalPages })}>
                        <button onClick={() => setCurrentPage(totalPages)} style={{ cursor: 'pointer' }} className='page-link'>Last</button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export { FilesTablePagination }