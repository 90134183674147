import Modal from 'react-modal';

const DeleteConfirmationModal = ({ isOpen, onRequestClose, onDelete, documentToDelete }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Delete Confirmation Modal"
            style={{
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '450px',
                    height: '280px',
                    position: 'relative',
                    top: 'auto',
                    left: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    margin: '0 auto',
                    marginLeft: '625px',
                    borderRadius: '20px',
                    padding: '20px',
                    backgroundColor: '#ffffff',
                    fontFamily: '"Arial", sans-serif'
                }
            }}
        >
            <h2 className="mt-5 text-center" style={{ fontSize: '1.7em' }}>
                Are you sure you want to delete the following document?
            </h2>
            <h2 className="mt-2 text-center" style={{ fontSize: '1.7em', fontWeight: 'normal' }}>
                {documentToDelete?.name}
            </h2>
            <button
                className='btn btn-danger btn-md mt-3'
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onDelete(documentToDelete);
                }}
            >
                Confirm
            </button>
            <button
                className='btn btn-secondary btn-md mt-6'
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onRequestClose();
                }}
            >
                Cancel
            </button>
        </Modal>
    );
};

export default DeleteConfirmationModal;