import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import DataOpsToolbar from "./DataOpsToolbar";
import { Link, useNavigate } from "react-router-dom";

interface History {
    id: number;
    run_guid: string;
    agency: string;
    object_type: number;
    start_date: string;
    end_date: string;
    run_date: string;
    run_status: string;
    num_records: number;
    s3_link: string;
    create_date: Date;
}

const RegulatoryTable = () => {
    const [histories, setHistory] = useState<History[]>([]);
    const [currentHistory, setCurrentHistory] = useState<History[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'run_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const date = new Date();
    date.setDate(date.getDate() - 14);
    const twoWeeksAgo = date.toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        startDate: twoWeeksAgo,
        endDate: '',
        agency: '',
        status: '',
        objType: '',
        minRecords: '',
        maxRecords: ''
    });

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const typeNames = {
        1: 'Dockets',
        2: 'Documents',
        3: 'Comments',
        4: 'Comment Attachments',
        5: 'Document Attachments',
        8: 'Guidance Documents'
    };

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }, [currentPage]);

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = histories;
        if (filters.startDate !== "") {
            filteredData = filteredData.filter(history =>
                new Date(history.run_date) >= new Date(filters.startDate)
            );
        }
        if (filters.endDate !== "") {
            filteredData = filteredData.filter(history =>
                new Date(history.run_date) <= new Date(filters.endDate)
            );
        }
        if (filters.agency !== "") {
            filteredData = filteredData.filter(history =>
                history.agency.toLowerCase().includes(filters.agency.toLowerCase())
            );
        }
        if (filters.status !== "") {
            filteredData = filteredData.filter(history =>
                (history.run_status === filters.status)
            );
        }
        if (filters.objType !== "") {
            filteredData = filteredData.filter(history =>
                (history.object_type === parseInt(filters.objType, 10))
            );
        }
        if (filters.minRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.num_records >= parseInt(filters.minRecords, 10)
            );
        }
        if (filters.maxRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.num_records <= parseInt(filters.maxRecords, 10)
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: twoWeeksAgo,
            endDate: '',
            agency: '',
            status: '',
            objType: '',
            minRecords: '',
            maxRecords: ''
        });
        setCurrentPage(1);
    };

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/reg/v1/superadmin/run_history/${filters.startDate}`);
            if (filters.endDate !== "") {
                const endDate = new Date(filters.endDate);
                response.data = response.data.filter(history =>
                    new Date(history.create_date) <= endDate
                );
            }
            if (filters.agency !== "") {
                response.data = response.data.filter(history =>
                    history.agency.toLowerCase().includes(filters.agency.toLowerCase())
                );
            }
            if (filters.status !== "") {
                response.data = response.data.filter(history =>
                    (history.run_status === filters.status)
                );
            }
            if (filters.objType !== "") {
                response.data = response.data.filter(history =>
                    (history.object_type === parseInt(filters.objType, 10))
                );
            }
            if (filters.minRecords !== "") {
                response.data = response.data.filter(history =>
                    history.num_records >= parseInt(filters.minRecords, 10)
                );
            }
            if (filters.maxRecords !== "") {
                response.data = response.data.filter(history =>
                    history.num_records <= parseInt(filters.maxRecords, 10)
                );
            }
            const sortedHistory = response.data.sort((a: History, b: History) => {
                const startDateComparison = new Date(b.run_date).getTime() - new Date(a.run_date).getTime();
                if (startDateComparison !== 0) {
                    return startDateComparison;
                } else {
                    return new Date(b.run_date).getTime() - new Date(a.run_date).getTime();
                }
            });
            setHistory(sortedHistory);
            setFilteredHistory(sortedHistory);
        } catch (error) {
            console.error('Error fetching histories:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistory();
        // eslint-disable-next-line
    }, [filters.startDate, filters.endDate]);

    useEffect(() => {
        setTotalPages(Math.ceil(filteredHistory.length / itemsPerPage));
    }, [filteredHistory, itemsPerPage]);

    useEffect(() => {
        setCurrentHistory(filteredHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredHistory, currentPage, itemsPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedHistory = [...filteredHistory].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredHistory(sortedHistory);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);

    useEffect(() => {
        let filteredData = histories;

        if (filters.startDate !== "") {
            filteredData = filteredData.filter(history =>
                new Date(history.run_date) >= new Date(filters.startDate)
            );
        }
        if (filters.endDate !== "") {
            filteredData = filteredData.filter(history =>
                new Date(history.run_date) <= new Date(filters.endDate)
            );
        }
        if (filters.agency !== "") {
            filteredData = filteredData.filter(history =>
                history.agency.toLowerCase().includes(filters.agency.toLowerCase())
            );
        }
        if (filters.status !== "") {
            filteredData = filteredData.filter(history =>
                (history.run_status === filters.status)
            );
        }
        if (filters.objType !== "") {
            filteredData = filteredData.filter(history =>
                (history.object_type === parseInt(filters.objType, 10))
            );
        }
        if (filters.minRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.num_records >= parseInt(filters.minRecords, 10)
            );
        }
        if (filters.maxRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.num_records <= parseInt(filters.maxRecords, 10)
            );
        }

        if (searchInput !== "") {
            filteredData = filteredData.filter(history =>
                history.run_guid.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.agency.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.s3_link.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.num_records.toString().includes(searchInput) ||
                history.object_type.toString().includes(searchInput) ||
                parseDate(history.run_date).includes(searchInput)
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Number(year), Number(month) - 1, Number(day)); // Months are 0-based
        const formattedDay = String(date.getDate()).padStart(2, '0');
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const formattedYear = date.getFullYear();
        return `${formattedMonth}-${formattedDay}-${formattedYear}`;
    };

    return (
        <div>
            <div className="d-flex flex-column px-10 align-items-start">
                {(Object.values(filters).some(value => value !== '')) && (
                    <div className="mt-n2 mb-1">
                        <h5>Active Filters:</h5>
                        <ul>
                            {filters.agency !== '' && (
                                <li><h6 className="d-md-block text-wrap">Agency: {filters.agency}</h6></li>
                            )}
                            {filters.status !== '' && (
                                <li><h6 className="d-md-block text-wrap">Status: {filters.status === '1' ? 'Success' : 'Failure'}</h6></li>
                            )}
                            {filters.objType !== '' && (
                                <li>
                                    <h6 className="d-md-block text-wrap">
                                        Object Type: {typeNames[Number(filters.objType)]}
                                    </h6>
                                </li>
                            )}
                            {filters.minRecords !== '' && filters.maxRecords === '' && (
                                <li><h6 className="d-md-block text-wrap">Min Record Count: {filters.minRecords}</h6></li>
                            )}
                            {filters.minRecords === '' && filters.maxRecords !== '' && (
                                <li><h6 className="d-md-block text-wrap">Max Record Count: {filters.maxRecords}</h6></li>
                            )}
                            {filters.minRecords !== '' && filters.maxRecords !== '' && (
                                <li><h6 className="d-md-block text-wrap">Record Count Range: {filters.minRecords} - {filters.maxRecords}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">Run Date Range: {formatDate(filters.startDate)} to {formatDate(filters.endDate)}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate === '' && (
                                <li><h6 className="d-md-block text-wrap">Start Run Date: {formatDate(filters.startDate)}</h6></li>
                            )}
                            {filters.startDate === '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">End Run Date: {formatDate(filters.endDate)}</h6></li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <DataOpsToolbar
                isLoading={loading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                activeTab="regulatory"
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
                objType={0}
            />
            {!loading &&
                <div>
                    <div className='table-responsive mb-5'>
                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                            style={tableStyle}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '17%', cursor: 'pointer' }} onClick={() => handleHeaderClick('run_guid')}>
                                        Run GUID
                                        <span className={`sorting-icon ${sortConfig.key === 'run_guid' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'run_guid' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '14%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('object_type')}>
                                        Object Type
                                        <span className={`sorting-icon ${sortConfig.key === 'object_type' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'object_type' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('agency')}>
                                        Agency
                                        <span className={`sorting-icon ${sortConfig.key === 'agency' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'agency' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '11%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('num_records')}>
                                        Num Records
                                        <span className={`sorting-icon ${sortConfig.key === 'num_records' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'num_records' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '27%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('s3_link')}>
                                        S3 Link
                                        <span className={`sorting-icon ${sortConfig.key === 's3_link' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 's3_link' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '8%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('run_status')}>
                                        Status
                                        <span className={`sorting-icon ${sortConfig.key === 'run_status' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'run_status' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '10%', paddingLeft: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('run_date')}>
                                        Run Date
                                        <span className={`sorting-icon ${sortConfig.key === 'run_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'run_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className='text-end' style={{ whiteSpace: 'nowrap', width: '7%', paddingRight: '20px' }}>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody className='text-gray-600 fw-bold'>
                                    <tr>
                                        <td colSpan={8}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Loading...
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className='text-gray-600 fw-bold'>
                                    {currentHistory.length === 0 ? (
                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                            <td colSpan={8} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                        </tr>
                                    ) : (
                                        currentHistory.map((history) => (
                                            <tr key={history.id} className='align-items-center bg-hover-light-primary' onClick={() => { navigate(`/super-admin/data-operations/regulatory/${history.run_guid}/${history.object_type}`) }}>
                                                <td className="py-9 ps-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.run_guid}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left', paddingRight: '20px' }}>
                                                    <span className="badge badge-light-info">{typeNames[history.object_type]}</span>
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', paddingRight: '20px' }}>{history.agency}</td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', paddingRight: '20px' }}>{history.num_records}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.s3_link}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'center', paddingRight: '20px' }}>
                                                    {history.run_status === "1" ?
                                                        <span className="badge badge-success">Success</span> :
                                                        <span className="badge badge-danger">Failure</span>
                                                    }
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left', paddingLeft: '20px' }}>
                                                    <div dangerouslySetInnerHTML={{ __html: parseDate(history.run_date).replace(/,/, '<br />') }} />
                                                </td>
                                                <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default', paddingRight: '20px' }} onClick={(e) => e.stopPropagation()}>
                                                    <Link
                                                        to={{
                                                            pathname: `/super-admin/data-operations/regulatory/${history.run_guid}/${history.object_type}`,
                                                        }}
                                                    >
                                                        Details
                                                    </Link>
                                                </td>
                                            </tr>
                                        )))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {totalPages > 1 &&
                        <div className='card-footer mb-n7'>
                            <div className='row'>
                                <div className='d-flex justify-content-center mt-2'>
                                    <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                        <ul className='pagination justify-content-center'>
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className='page-link' onClick={prevPage} disabled={currentPage === 1}>
                                                    Previous
                                                </button>
                                            </li>
                                            {Array.from({ length: totalPages }, (_, i) => i + 1)
                                                .slice(
                                                    currentPage <= 3 ? 0 : currentPage - 3,
                                                    currentPage <= 3 ? 5 : currentPage + 2
                                                )
                                                .map(pageNumber => (
                                                    <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
                                                    </li>
                                                ))}
                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className='page-link' onClick={nextPage} disabled={currentPage === totalPages}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default RegulatoryTable;
