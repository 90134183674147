import React, { FC } from 'react'
import WarningLetters from './WarningLetters'

const WarningLettersWrapper: FC = () => {
    return (
        <>

            <WarningLetters />
        </>
    )
}

export default WarningLettersWrapper
