import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import axios from 'axios';


import TableRowDocuments from './TableRowDocuments';

type Document = {
    agencyId: string;
    id: string;
    document_title: string;
    modifyDate: string;
    program: string;
}
type Props = {

    doctype: string
    days: number;
}
const TablesWidget: React.FC<Props> = ({ doctype, days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [details, setDetails] = useState<Document[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('modifyDate')
    const [count, setCount] = useState(0)




    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`reg/v1/document/preference/document/days/pagination/${doctype}?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`);
                const totalCount = response.data.length > 0 ? response.data[0].document_count : 0;
                setCount(totalCount);
                const documents = response.data;
                setDetails(documents);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder, doctype]);
    const currentItems = details;

    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);
    }, [days, doctype])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);


    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };


    const PAGINATION_PAGES_COUNT = 5;

    const sliceLinks = (totalPages, currentPage) => {
        let pageLinks = Array.from({ length: totalPages }, (_, i) => i + 1);

        if (pageLinks.length > PAGINATION_PAGES_COUNT) {
            let start = currentPage - Math.ceil(PAGINATION_PAGES_COUNT / 2);
            let end = currentPage + Math.floor(PAGINATION_PAGES_COUNT / 2) - 1;

            if (start < 0) {
                end -= start;
                start = 0;
            }

            if (end >= pageLinks.length) {
                start -= end - pageLinks.length + 1;
                end = pageLinks.length - 1;
            }

            pageLinks = pageLinks.slice(start, end + 1);
        }

        return pageLinks;
    }

    const paginationLinks = sliceLinks(totalPages, currentPage);

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card `}>

                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >

                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        {doctype === 'Proposed Rule' ? 'Proposed Rules' : doctype === 'Rule' ? 'Final Rules' : doctype === 'Notice' ? 'Notices' : doctype}
                                        {loading && <span className='spinner-border text-gray-600 align-middle ms-2'></span>}

                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                 {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                            </div>
                        </div>

                        <div className='card-body px-8 '>

                            <div className='table-responsive '>

                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-1 gy-3 ' style={{ width: '100% !important' }}>

                                    <thead>
                                        <tr className='fw-bolder text-gray-600 fs-5'>
                                            <th style={{ minWidth: '10%', width: '10%' }} onClick={() => handleSort('agencyId')}>
                                                Agency
                                                <span className={`sorting-icon ${sortcol === 'agencyId' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'agencyId' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>

                                            <th style={{ minWidth: '10%', width: '10%' }} onClick={() => handleSort('program')}>
                                                Program
                                                <span className={`sorting-icon ${sortcol === 'program' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'program' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '74%', width: '74%' }} onClick={() => handleSort('document_title')}>
                                                Title
                                                <span className={`sorting-icon ${sortcol === 'document_title' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'document_title' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>
                                            <th style={{ minWidth: '16%', width: '16%' }} onClick={() => handleSort('modifyDate')}>
                                                Date
                                                <span className={`sorting-icon ${sortcol === 'modifyDate' ? sortorder : ''}`}>
                                                    {' '}<i className={`bi ${sortcol === 'modifyDate' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                </span>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (

                                            <tr>
                                                <td>
                                                    <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>

                                                    </span>
                                                </td>
                                            </tr>

                                        ) : (

                                            <TableRowDocuments items={currentItems} />

                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 &&
                    <div className='card-footer border-1 p-0 ' >
                        <div className='row mt-4 mb-4'>
                            <div className='d-flex justify-content-center'>
                                <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                    <ul className='pagination justify-content-center'>
                                        <li className={clsx('page-item', { 'disabled': currentPage === 1 })}>
                                            <button onClick={() => setCurrentPage(1)} style={{ cursor: 'pointer' }} className='page-link'>First</button>
                                        </li>
                                        {paginationLinks.map((page) => (
                                            <li key={page} className={clsx('page-item', { 'active': currentPage === page })}>
                                                <button onClick={() => setCurrentPage(page)} style={{ cursor: 'pointer' }} className='page-link'>{page}</button>
                                            </li>
                                        ))}
                                        <li className={clsx('page-item', { 'disabled': currentPage === totalPages })}>
                                            <button onClick={() => setCurrentPage(totalPages)} style={{ cursor: 'pointer' }} className='page-link'>Last</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export { TablesWidget }