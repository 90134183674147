//import internal from "stream"
import { ID, Response } from "../../../../../../../_metronic/helpers"
export type Organization = {
  id?: ID;
  name?: string;
  primaryuser?: number;
  fullname?: string;
  website?: string;
  industry?: string;
  address?: string;
  city?: string;
  state?: string;
  region?: string;
  country?: string;
  zip?: string;
  phone?: string;
  language?: string;
  is_deleted?: number;
  is_active?: number;
  start_date?: number;
  end_date?: Date;
  is_partner?: number;
  parent_id?: number;
  create_date?: number;
  modified_date?: number;
}

export type User = {
  first_name: string;
  last_name: string;
  title: string;
  suffix: string;
  email: string;
  phone: string;
  role: number;
  organization: string;
  adminnotes: string;
}

export type OrganizationsQueryResponse = Response<Array<Organization>>

export const initialOrganization: Organization = {
  name: "",
  primaryuser: 0,
  fullname: "",
  website: "",
  industry: "",
  address: "",
  city: "",
  state: "",
  region: "",
  country: "",
  zip: "",
  phone: "",
  language: "",
  is_deleted: 0,
  is_active: 1,
  start_date: 1695865085000,
  end_date: new Date("0000-00-00T00:00:00"),
  is_partner: 0,
  parent_id: 0,
  create_date: 1695865085000,
  modified_date: 1698781947000
}
