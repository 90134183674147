import React from 'react';



const DocketFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters
}) => {

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-4'>
                    <label className='form-label fw-bold'>Docket:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS-2023-****'
                        value={filters.docket}
                        onChange={(e) => onFilterChange('docket', e.target.value)}
                        maxLength="20"
                    />

                </div>
                <div className='mb-4'>
                    <label className='form-label fw-bold'>Docket Type:</label>

                    <div>
                        <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            value={filters.status}
                            onChange={(e) => onFilterChange('status', e.target.value)}
                        >

                            <option value=''>All</option>
                            <option value='Rulemaking'>Rulemaking</option>
                            <option value='Nonrulemaking'>Nonrulemaking</option>
                        </select>
                    </div>
                </div>
                <div className='mb-4'>
                    <label className='form-label fw-bold' htmlFor='dateRange'>
                        Modified Date Range:
                    </label>
                    <input
                        type='date'
                        id='startDate'
                        className='form-control form-control-solid my-2'
                        placeholder='Start Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                    <input
                        type='date'
                        id='endDate'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>



                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocketFilter;