import React, { useState, useEffect } from "react";
import { QAInterface } from "./QAInterface.tsx";
import clsx from "clsx";
function FileViewer({ setIsLoading, searchInput, expandAll, collapseAll, isLoading }) {
    const [content, setContent] = useState(null);
    const [originalContent, setOriginalContent] = useState('');
    // const [searchResults, setSearchResults] = useState([]);
    // const [isResultsExpanded, setIsResultsExpanded] = useState(true);
    // const [loading, setLoading] = useState(false);
    const isChatOpen = true;
    const [activeTab, setActiveTab] = useState('Document');


    useEffect(() => {
        fetch('/files/FDAPreamble.html')
            .then(response => response.text())
            .then(htmlData => {
                htmlData = htmlData.split('\n').map(line => line.trimEnd()).join('\n');
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(htmlData, 'text/html');
                const updatedContent = htmlDoc.documentElement.innerHTML;
                setContent(updatedContent);
                setOriginalContent(updatedContent);
                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [setIsLoading]);


    useEffect(() => {
        if (searchInput && searchInput.length > 1) {
            // setLoading(true);
            const timeoutId = setTimeout(() => {
                const regex = new RegExp(`(${searchInput})`, 'gi');
                const highlightedContent = originalContent.replace(regex, '<mark>$1</mark>');
                setContent(highlightedContent); // Update the content state with the highlighted text

                // setLoading(false);
            }, 500); // 500ms debounce time
            return () => clearTimeout(timeoutId);
        } else {
            // If search input is cleared, reset content to original
            setContent(originalContent);
        }
    }, [searchInput, originalContent]);

    useEffect(() => {
        // Scroll to the first highlighted text after content is updated
        const firstMark = document.querySelector('mark');
        if (firstMark) {
            const scrollableParent = getScrollableParent(firstMark);
            if (scrollableParent) {
                const markOffsetTop = firstMark.offsetTop;
                scrollableParent.scrollTo({ top: markOffsetTop - scrollableParent.offsetTop, behavior: 'smooth' });
            }
        }
    }, [content]);

    // Helper function to find the closest scrollable ancestor
    function getScrollableParent(element) {
        let parent = element.parentNode;
        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
            if (isScrollable && parent.scrollHeight > parent.clientHeight) {
                return parent;
            }
            parent = parent.parentNode;
        }
        return null;
    }

    return (
        <div className='card card-custom'>
            <div className='card-header card-header-stretch'>
                <ul
                    className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent flex-nowrap'
                    role='tablist'
                >
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'Document' })}
                            onClick={() => setActiveTab('Document')}
                            role='tab'
                        >
                            Document
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'LeximQ&A' })}
                            onClick={() => setActiveTab('LeximQ&A')}
                            role='tab'
                        >
                            Lexim Q&A
                        </h5>
                    </li>
                </ul>
            </div>
            <div className='card-body'>
                <div className='tab-content'>
                    <div className={clsx('tab-pane', { active: activeTab === 'Document' })}>
                        <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                            <div className={`card-body text-wrap col-6`}
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    whiteSpace: 'pre-wrap',
                                    boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                    maxHeight: 'calc(100vh)',
                                    flex: 1
                                }}
                                data-kt-scroll='true'
                                data-kt-scroll-height='auto'
                            >
                                {content ?
                                    <div dangerouslySetInnerHTML={{ __html: content }} className="fs-2 text-gray-900" /> :
                                    <p></p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={clsx('tab-pane', { active: activeTab === 'LeximQ&A' })}>
                        <div>
                            <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                                <div className={`card-body text-wrap col-6`}
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        maxHeight: 'calc(100vh)',
                                        flex: 2
                                    }}
                                    data-kt-scroll='true'
                                    data-kt-scroll-height='auto'
                                >
                                    {content ?
                                        <div dangerouslySetInnerHTML={{ __html: content }} className="fs-2 text-gray-900" /> :
                                        <p></p>
                                    }
                                </div>
                                <QAInterface chatIsOpen={isChatOpen} className='col-6' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileViewer;
