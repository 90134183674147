import React from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import FeedbackFilter from './FeedbackFilter';
import SearchBar from './SearchBar';


const FeedbackToolbar = ({
    isLoading,
    searchInput,
    setSearchInput,
    filters,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters
}) => {
    return (
        <div className="d-flex flex-wrap gap-4 flex-row-fluid px-5 mb-7 align-items-center">
            <div className="col-7">
                <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
            </div>
            <div className="d-flex flex-column-auto">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName='filter' className='fs-2' /> Filter
                </button>
                <FeedbackFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                />
            </div>
            {isLoading ?
                <div className="spinner-border spinner-border-lg text-primary ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                : null
            }
        </div>
    );
};

export default FeedbackToolbar;