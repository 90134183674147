import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
const DocxViewer = ({ documentId, type }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });

    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await axios.get(`/reg/v1/${type}/attachment/${documentId}`, { responseType: 'blob' });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.docx'); // or any other extension
                document.body.appendChild(link);
                link.click();

                setAlert({ message: 'Document downloaded successfully', type: 'success' });
            } catch (error) {
                if (error?.response?.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.log('Error fetching file:', error);
                    setAlert({ message: 'File not found', type: 'danger' });
                }
            }
        };

        fetchFile();
    }, [documentId, type, logout, navigate]);

    return (
        <div>
            {alert.message && (
                <div className={`alert alert-${alert.type}`}>
                    {alert.message}
                </div>
            )}
        </div>
    );
};

export default DocxViewer;