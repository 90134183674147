import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { OrganizationsListHeader } from './components/header/OrganizationsListHeader'
import { OrganizationsTable } from './table/OrganizationTable'
import { OrganizationEditModal } from './organization-edit-modal/OrganizationEditModal'
import { KTCard } from '../../../../../../_metronic/helpers'

const OrganizationsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <OrganizationsListHeader />
        <OrganizationsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OrganizationEditModal activeTab={itemIdForUpdate === 0 ? 'Create' : 'Modify'} />}
    </>
  )
}

const OrganizationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OrganizationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { OrganizationsListWrapper }
