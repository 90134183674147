import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [timer, setTimer] = useState(6)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      requestPassword(values.email)
        .then(response => {
          setTimer(6)
          const countdown = () => {
            setTimer((prevTimer) => {
              if (prevTimer <= 1) {
                clearInterval(interval)
                navigate('/auth/login')
                return 0
              } else {
                setStatus({
                  message: "We've sent you a temporary password in your email to login. Redirecting to the login screen in " + (prevTimer - 1) + " " + ((prevTimer - 1) === 1 ? "second" : "seconds") + "..."
                })
                return prevTimer - 1
              }
            })
          }
          countdown() // Call the function immediately
          const interval = setInterval(countdown, 1000) // Then set the interval
        })
        .catch(error => {
          console.error("Error: ", error)
          setSubmitting(false)
          setStatus({ message: "Error: " + error.toString() })
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {(formik.status && timer > 0) && (
        <div className={formik.status.message.toLowerCase().includes('error') ? 'mb-lg-7 alert alert-danger' : 'mb-lg-7 alert alert-success'}>
          <div className='alert-text fw-bold'>
            {formik.status.message}
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Back
          </button>
        </Link>{' '}
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary ms-5'>
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}