import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface } from "./QAInterface";

function XmlFileViewer({ setIsLoading, searchInput, expandAll, collapseAll, isLoading }) {
    const [content, setContent] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isResultsExpanded, setIsResultsExpanded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);

    useEffect(() => {
        fetch('/files/title42.html')
            .then(response => response.text())
            .then(htmlData => {
                // Parse HTML string and extract h1 and info contents
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(htmlData, 'text/html');
                const h1Element = htmlDoc.querySelector('h1');
                const infoElements = htmlDoc.querySelectorAll('.info');

                // Remove h1 and info elements from the rest of the content
                h1Element.remove();
                infoElements.forEach(el => el.remove());
                const updatedContent = htmlDoc.documentElement.innerHTML;
                setContent(updatedContent);

                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [setIsLoading]);

    const getPath = (element) => {
        let path = [];
        while (element) {
            const summary = element.querySelector('summary');
            if (summary) {
                let summaryText = summary.textContent;
                if (element.querySelector('details')) { // if there is a child 'details' element
                    summaryText = summaryText.replace('—', ' ')
                    summaryText = summaryText.split(' ').filter(word => word !== "").slice(0, 2).join(' '); // keep only the first two words
                    summaryText = summaryText.replace(/[.,/#!$%^&*;:{}=\-_`~()]$/, ""); // remove the last punctuation
                }
                path.unshift(summaryText);
            }
            element = element.parentElement.closest('details');
        }
        return path.reduce((prev, curr, i) => {
            return [...prev, i > 0 ? <i className="fas fa-arrow-right mx-2 text-dark" /> : null, curr];
        }, []);
    };

    useEffect(() => {
        if (searchInput) {
            setLoading(true);
            const timeoutId = setTimeout(() => {
                const allDetailsElements = document.querySelectorAll('details');
                const matchingElements = Array.from(allDetailsElements).filter(el => {
                    const summary = el.querySelector('summary');
                    const directTextContent = Array.from(el.childNodes).filter(node => node.nodeType === 3).map(node => node.textContent).join('');
                    if ((summary && summary.textContent.toLowerCase().includes(searchInput.toLowerCase())) || directTextContent.toLowerCase().includes(searchInput.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
                const matchingPaths = matchingElements.map(getPath);
                setSearchResults(matchingPaths);
                setLoading(false);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [searchInput, setIsLoading]);
    return (
        <div>
            <div className="d-flex align-items-center mb-4 justify-content-between">
                <div className="d-flex align-items-center" style={{ visibility: searchInput ? 'visible' : 'hidden' }}>
                    <h5 className="mt-2 mb-0 ms-5">
                        Found {searchResults.length} matching results for: {" "}
                        <span className="text-primary font-italic">{searchInput}</span>
                    </h5>
                    <button className="btn btn-sm btn-primary ms-4 mt-2" onClick={() => setIsResultsExpanded(!isResultsExpanded)}>
                        {isResultsExpanded ? 'Hide Results' : 'Show Results'}
                    </button>
                    {loading && <div className="spinner-border text-primary ms-4 mt-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </div>
                <div className="d-flex align-items-center ml-auto">
                    <div className="btn-group me-5 mt-2" role="group" aria-label="Expand and Collapse buttons">
                        <button type='button' className='btn btn-sm btn-primary border-end border-3' onClick={expandAll}>
                            <KTIcon iconName='plus' className='fs-1' />
                        </button>
                        <button type='button' className='btn btn-sm btn-primary' onClick={collapseAll}>
                            <KTIcon iconName='minus' className='fs-1' />
                        </button>
                    </div>
                    <button className="btn btn-sm btn-primary mt-2 me-5" onClick={() => {
                        const firstSummary = document.querySelector('summary');
                        if (firstSummary) {
                            firstSummary.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}>
                        <KTIcon iconName='arrow-up' className='fs-1' />
                        Scroll to Top
                    </button>
                    <button className="btn btn-sm btn-primary mt-2 me-11" onClick={() => {
                        setIsChatOpen(!isChatOpen);
                        // console.log('Chat button clicked, isChatOpen:', !isChatOpen);
                    }}>
                        <KTIcon iconName='messages' className='fs-1' />
                    </button>
                </div>
            </div>
            {searchInput && isResultsExpanded &&
                <div className="list-group list-group-flush mb-5" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                    {searchResults.map((result, index) => (
                        <div key={result.join('')} className="list-group-item mb-3" style={{ cursor: 'pointer', color: '#007bff' }} onClick={() => {
                            // Find the corresponding details element
                            const allDetailsElements = document.querySelectorAll('details');
                            const detailsElement = Array.from(allDetailsElements).find(el => {
                                const path = getPath(el);
                                return JSON.stringify(path) === JSON.stringify(result);
                            });
                            if (detailsElement) {
                                // Open the details element and all its parent details elements
                                let element = detailsElement;
                                while (element) {
                                    element.open = true;
                                    element = element.parentElement.closest('details');
                                }
                                // Scroll to the details element
                                detailsElement.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}>
                            {result}
                        </div>
                    ))}
                </div>
            }
            <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                <div className={`text-wrap ${isChatOpen ? 'col-7' : 'col-12 me-20'}`}
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        whiteSpace: 'pre-wrap',
                        flex: 1 // Add flex: 1 here
                    }}
                >
                    {/* {isLoading && (
                        <p>Loading...</p>
                    )} */}
                    {content ?
                        <div dangerouslySetInnerHTML={{ __html: content }} /> :
                        <p></p>
                    }
                </div>
                {isChatOpen &&
                    <QAInterface style={{ flex: 1 }} /> // Add flex: 1 here
                }
            </div>
        </div>
    );
}

export default XmlFileViewer;
