import React from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const DocketsTable = ({ filteredDockets, currentPage, DOCKETS_PER_PAGE, handleHeaderClick, sortConfig, handleSubscribe, currentUser, formatDate }) => {

    return (
    <div className="table-responsive">
        <table className="table table-hover table-rounded table-striped border gy-6 gs-6 align-middle dataTable no-footer" style={{
            '--bs-table-hover-bg': 'rgba(204, 236, 255, 1)',
            '--bs-table-striped-bg': 'rgba(241, 250, 255, 1)'
        }}>
            <thead className="text-start text-muted fw-bolder fs-7  gs-0" style={{ backgroundColor: '#80d2ff' }}>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">

                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('id')}>
                        ID
                        {sortConfig.key === 'id' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('title')}>
                        Title
                        {sortConfig.key === 'title' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('docketType')}>
                        Type
                        {sortConfig.key === 'docketType' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('modifyDate')}>
                        Modified
                        {sortConfig.key === 'modifyDate' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }} onClick={() => handleHeaderClick('doc_count')}>
                        Documents
                        {sortConfig.key === 'doc_count' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }} onClick={() => handleHeaderClick('comment_count')}>
                        Comments
                        {sortConfig.key === 'comment_count' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }} onClick={() => handleHeaderClick('doc_sentiment')}>
                        Sentiment
                        {sortConfig.key === 'doc_sentiment' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>

                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }} onClick={() => handleHeaderClick('isSubscribed')}>
                        Subscribe
                        {sortConfig.key === 'isSubscribed' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>

                </tr>
            </thead>
            <tbody >
                {filteredDockets
                    .slice((currentPage - 1) * DOCKETS_PER_PAGE, currentPage * DOCKETS_PER_PAGE)
                    .map((docket) => (
                        <tr key={docket.iddocket}>

                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`/docket/${docket.id}`} style={{ color: 'black' }}>
                                    {docket.id}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/docket/${docket.id}`} style={{ color: 'black' }}>
                                    {docket.title}
                                </Link>
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}> {docket.docketType}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{formatDate(docket.modifyDate)}</td>
                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}> {docket.doc_count}</td>
                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}> {docket.comment_count}</td>
                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                {docket.doc_sentiment === null ? (
                                    ''
                                ) : docket.doc_sentiment < -0.5 ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-top`}>Negative</Tooltip>}
                                    >
                                        <span><KTIcon iconName='arrow-down' iconType="solid" className=' fw-bold fs-1 text-danger' /></span>
                                    </OverlayTrigger>
                                ) : docket.doc_sentiment > 0.5 ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-top`}>Positive</Tooltip>}
                                    >
                                        <span><KTIcon iconName='arrow-up' iconType="solid" className=' fw-bold fs-1 text-success' /></span>
                                    </OverlayTrigger>
                                ) : (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-top`}>Neutral</Tooltip>}
                                    >
                                        <span><KTIcon iconName='minus' iconType="solid" className=' fw-bold fs-1 text-warning' /></span>
                                    </OverlayTrigger>
                                )}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <div className="d-flex justify-content-center ">
                                    {docket.userid === currentUser?.id ? (
                                        <button
                                            className="btn btn-secondary h-26px  w-140px btn-sm d-flex align-items-center justify-content-center"
                                            onClick={() => handleSubscribe(docket.iddocket, docket.userid === currentUser?.id)}
                                        >
                                            Unsubscribe
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-primary h-26px  w-140px btn-sm d-flex align-items-center justify-content-center"
                                            onClick={() => handleSubscribe(docket.iddocket, docket.userid === currentUser?.id)}
                                        >
                                            <i className="bi bi-check-circle-fill"></i>  Subscribe
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
        </div>
    );
};

export default DocketsTable;