import React from 'react';
// import { Link } from 'react-router-dom';
import { MixedWidget14, MixedWidget15 } from '../../../_metronic/partials/widgets';

const DocumentDetailsCard = ({ document, loading }) => {

    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];

    const moment = require('moment-timezone');
    const formatDate1 = (timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment(timestamp);

        return date.tz("America/New_York").format('YYYY-MM-DD HH:mm:ss');
    };
    const formatDate2 = (timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp);
        return date.tz("America/New_York").format('YYYY-MM-DD HH:mm:ss');
    };

    return (
        <div
        // className="card card-custom "
        >
            {/* <div className="card-header" style={{ backgroundColor: 'rgba(204, 229, 255, 1)' }}>
                <div className="card-title m-0 d-flex align-items-center">
                    <i className="bi bi-file-earmark-text me-3 fs-1 text-primary"></i>
                    <div className="d-flex align-items-center">
                        <h2 className="d-md-block text-wrap" style={{ color: "#4d4d4d", margin: '0', padding: '0', marginRight: '10px', marginTop: '1px' }}>
                            Details for Document ID: {document.id}
                        </h2> */}
            {/* {loading &&
                            <div className="spinner-border ms-4 mb-1 " role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        } */}
            {/* </div>
                </div>
            </div> */}

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" >
                    <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div
                // className="card-body card-scroll  " 
                >
                    {/* Document content */}
                    {/* <div className="mb-3">
                    <div className="flex ">
                        <span className="fw-bold">Title:</span>
                        <span className="fw-normal mx-2">{document?.title}</span>
                    </div>
                </div> */}
                    {/* <div className="mb-3">
                    <div className="flex">
                        <span className="card-title fw-bold fs-5 " style={{ color: '#0063cc' }}>Docket:</span>
                        <span className="fw-normal mx-2">
                            {document.docketId}
                
                        </span>
                    </div>
                </div> */}
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Agency</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{document?.agencyId}</span>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Type</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{document?.documentType}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Page Count</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{document?.pageCount}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Posted Date</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{formatDate2(document.postedDate) + ' (ET)'}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Comment Start Date</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{document.commentStartDate !== null ? (formatDate1(document.commentStartDate) + ' (ET)') : 'None'}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Comment End Date</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5 ">{document.commentEndDate !== null ? (formatDate1(document.commentEndDate) + ' (ET)') : 'None'}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>CFR Part</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5">{document?.cfrPart ? document.cfrPart : "No CFR Part"}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Subject</span>
                        </div>
                        <div className="col-10">
                            <span className="card-title fw-bold text-gray-600 fs-5" >{document?.subject ? document.subject : "No Subject Available"}</span>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-2">
                            <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Topics</span>
                        </div>
                        <div className="col-10">

                            <span className="card-title fw-bold text-gray-600 fs-5">{document?.topics ? document.topics : "No Topics Available"}</span></div>
                    </div>
                    <div className="mb-3">
                        <div className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}> Document Statistics</div>
                        <div className='row gy-5 g-xl-12'>
                            <div className='col-xxl-4' style={{ paddingTop: '15px' }}>
                                <MixedWidget14
                                    className='card-xxl-stretch mb-xl-3'
                                    chartColor={colors}
                                    agency={document?.agencyId}
                                    chartHeight='175x'
                                    cardHeight="300px"
                                    labels={['Anonymous', 'Organization', 'Individual']}
                                    title="Comments"

                                />
                            </div>
                            <div className='col-xxl-4' style={{ paddingTop: '15px' }}>
                                <MixedWidget15
                                    className='card-xxl-stretch mb-xl-3'
                                    chartColor={colors}
                                    chartHeight='240px'
                                    cardHeight="300px"
                                    agency={document?.agencyId}
                                    labels={['Anonymous', 'Organization', 'Individual']}
                                    title={`Comments by Type`}

                                />
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>

    );
};

export default DocumentDetailsCard;