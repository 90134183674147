import React from 'react';
import { Link } from 'react-router-dom';

const ImportantDocketDates = ({ impDates, docket, formatDate }) => {
    return (
        <div className="pb-3 pt-7">
            {impDates.filter(item => item.docketId === docket).length > 0 ? (
                <div>
                    <div className="d-flex flex-row-fluid flex-center mx-10 mb-6">
                        <div className="flex-column flex-row-fluid flex-center">
                            <h2 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                                {/* <span style={{ position: 'relative', top: '2px' }}>
                <KTIcon iconName='calendar-8' className='fs-1 me-2' />
            </span> */}
                                Important Dates for Docket {docket}:
                            </h2>
                        </div>
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                        <table className="table table-hover table-rounded table-striped  gy-7 gs-7 align-middle dataTable no-footer" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0" style={{ backgroundColor: '#66c9ff' }}>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                    <th style={{ whiteSpace: 'nowrap' }}>Document</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Type</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Comment Start Date</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Comment End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {impDates.filter(item => item.docketId === docket).map((item, index) => (
                                    <tr key={index}>

                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><Link to={`/document/${item.id}`} style={{ textDecoration: "none", color: "black" }}>{item.id}</Link></td>
                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{item.documentType}</td>
                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{formatDate(item.commentStartDate)}</td>
                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{formatDate(item.commentEndDate)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-row-fluid flex-center mx-10 mb-6">
                    <div className="flex-column flex-row-fluid flex-center">
                        <h2 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                            {/* <span style={{ position: 'relative', top: '2px' }}>
                <KTIcon iconName='calendar-8' className='fs-1 me-2' />
            </span> */}
                            No Important Dates for Docket {docket}
                        </h2>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ImportantDocketDates;