import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate }) => {
    return (

        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '11%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('recall_number')}>
                            Recall Number
                            <span className={`sorting-icon ${sortConfig.key === 'recall_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recall_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th style={{ whiteSpace: 'nowrap', width: '28%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('product_description')}>
                            Product Description
                            <span className={`sorting-icon ${sortConfig.key === 'product_description' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_description' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('recalling_firm')}>
                            Recalling Firm
                            <span className={`sorting-icon ${sortConfig.key === 'recalling_firm' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recalling_firm' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '10%', cursor: 'pointer' }} onClick={() => handleHeaderClick('classification')}>
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'classification' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'classification' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '10%', paddingRight: '20px' }} onClick={() => handleHeaderClick('product_type')}>
                            Product Type
                            <span className={`sorting-icon ${sortConfig.key === 'product_type' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_type' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th style={{ whiteSpace: 'nowrap', width: '14%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('voluntary_mandated')}>
                            Initiated By
                            <span className={`sorting-icon ${sortConfig.key === 'voluntary_mandated' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'voluntary_mandated' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '7%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('status')}>
                            Status
                            <span className={`sorting-icon ${sortConfig.key === 'status' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-end ${sortConfig.key !== 'fulldate' ? 'pe-2' : ''}`} style={{ whiteSpace: 'nowrap', width: '11%', cursor: 'pointer' }} onClick={() => handleHeaderClick('fulldate')}>
                            Classification Date
                            <span className={`sorting-icon ${sortConfig.key === 'fulldate' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'fulldate' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '7%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={6} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={6} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((enforcement, index) => (
                                <tr className='align-items-center bg-hover-light-primary' onClick={() => window.open(`/device-intelligence/enforcements/${enforcement.recall_number}`, '_blank', 'noopener,noreferrer')}>
                                    {/* <td style={{ verticalAlign: 'middle' }} className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {enforcement.recall_number}
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/enforcements' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {enforcement.product_description.length > 200 ? enforcement.product_description.substring(0, 200) + '...' : enforcement.product_description}
                                        </a>
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/enforcements' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {enforcement.recalling_firm}
                                        </a>
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/enforcements' className='text-center text-dark text-wrap fw-semibold d-block fs-6 align-items-center' onClick={(e) => e.preventDefault()}>
                                            {enforcement.classification === 'Class I' ? '1' : enforcement.classification === 'Class II' ? '2' : enforcement.classification === 'Class III' ? '3' : enforcement.classification}
                                        </a>
                                    </td>
                                    {/* <td>
                                        <a href='/enforcement' className='text-dark text-wrap fw-semibold d-block mb-1 fs-6' onClick={(e) => e.preventDefault()}>
                                            {enforcement.product_type}
                                        </a>
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/enforcements' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {enforcement.voluntary_mandated}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/enforcements' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {enforcement.status}
                                        </a>
                                    </td>
                                    <td className='text-end'>
                                        <a href='/device-intelligence/enforcements' className={`text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'fulldate' ? 'pe-1' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(enforcement.fulldate).toLocaleDateString()}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link
                                            to={`/device-intelligence/enforcements/${enforcement.recall_number}`} target="_blank" style={{ color: 'primary' }}
                                        >
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;