import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
const DocWrapper: FC = () => {
    const [searchParams] = useSearchParams();
    const documentId = searchParams.get('id');
    const [textData, setTextData] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
const navigate = useNavigate();
const {logout}= useAuth();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get(`reg/v1/document/viewtxta/${documentId}`);
    //             setTextData(response.data);
    //             setAlert({ message: '', type: '' });
    //         } catch (error) {
    //             setAlert({ message: 'Error: File not found', type: 'danger' });
    //         }
    //     };

    //     fetchData();
    // }, [documentId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`reg/v1/document/viewtxta/${documentId}`);
                if (response.headers['content-type'] === 'text/plain') {
                    setTextData(response.data);
                } else {
                    const url = response.data.url;

                    const textResponse = await axios.get(url);
                    const formattedText = textResponse.data.replace(/\n/g, '<br>').replace(/ /g, '&nbsp;');
                    setTextData(formattedText);

                }
                setAlert({ message: '', type: '' });
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                setAlert({ message: 'Error: File not found', type: 'danger' });
                }
            }
        };

        fetchData();
    }, [documentId, logout, navigate]);

    return (
        <>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            {/* <div className="text-monospace p-3 ">
                {/* <pre>{textData}</pre> */}
            {/* <iframe src={urldata} className="text-monospace p-3" title="Text File"></iframe> */}
            {/* </div> */}
            <div className="font-monospace p-3 w-100 text-wrap text-justify mx-auto " style={{ maxWidth: '800px' }} dangerouslySetInnerHTML={{ __html: textData }}></div>

        </>
    );
}

export default DocWrapper;