import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import DevicesFilter from './DevicesFilter';
import { DevicesListPagination } from './DevicesListPagination';
import DevicesTable from './DevicesTable';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import { Modal } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import Select from 'react-select';

const Enforcement = () => {
    const [devices, setDevices] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const interests = useSelector(state => state.account.interests);
    // const [selectedState, setSelectedState] = useState(['interests', ...interests.committees]);
    // const [allAdvisoryCommittees, setAllAdvisoryCommittees] = useState([]);
    // const [advisoryCommittees, setAdvisoryCommittees] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);

    const [sortConfig, setSortConfig] = useState({ key: 'fulldate', direction: 'asc' });
    const [filterStatus, setFilterStatus] = useState('');
    const [filterRecallNumber, setFilterRecallNumber] = useState('');
    // const [filterProductType, setFilterProductType] = useState('');
    const [filterClassification, setFilterClassification] = useState('');
    const [filterVoluntaryMandated, setFilterVoluntaryMandated] = useState('');
    const [filterDecisionDateRange, setFilterDecisionDateRange] = useState(Cookies.get('selectedDays') || '30');
    // const [showModal, setShowModal] = useState(false);
    // const [selectedEnforcement, setSelectedEnforcement] = useState(null);
    const [loadSort, setLoadSort] = useState(false);
    const [committees, setCommittees] = useState({});
    const [days, setDays] = useState(parseInt(Cookies.get('selectedDays'), 10) || 30);
    // const [specialityOptions, setSpecialityOptions] = useState([]);
    const filter = false;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);
    const classMapping = useMemo(() => ({
        "Class I": 1,
        "Class II": 2,
        "Class III": 3
    }), []);

    const handleDeviceClassChange = (event) => {
        const { value, checked } = event.target;
        let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
        setSelectedDeviceClasses(currentClasses => {
            if (checked) {
                if (!currentClasses.includes(newValue)) {
                    return [...currentClasses, newValue];
                }
                return currentClasses;
            } else {
                return currentClasses.filter(item => item !== newValue);
            }
        });
    };

    // const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    //const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    //const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);

    // const handleSpecialityChange = (event) => {
    //     const { value, checked } = event.target;

    //     // Handle "All" option separately
    //     if (value === 'All') {
    //         if (checked) {
    //             setSelectedSpecialities(specialityOptions); // Select all options
    //         }
    //         else {
    //             setSelectedSpecialities([]); // Deselect all options
    //         }
    //     } else {
    //         setSelectedSpecialities((prevSelectedSpecialities) => {
    //             if (checked && !prevSelectedSpecialities.includes(value)) {
    //                 // If selecting an option and all other options are selected, add "All" to the selection
    //                 const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
    //                 return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
    //             } else if (!checked) {
    //                 // If deselecting an option, also remove "All" from the selection
    //                 return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
    //             }
    //             return prevSelectedSpecialities;
    //         });
    //     }
    // };

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);


    // const dropdownRef2 = useRef(null);
    // const handleClickOutsideSpeciality = (event) => {
    //     if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
    //         setSpecialityDropdownOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     // Add when the dropdown is open and remove when closed
    //     if (specialityDropdownOpen) {
    //         document.addEventListener('mousedown', handleClickOutsideSpeciality);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     }

    //     // Cleanup
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsideSpeciality);
    //     };
    // }, [specialityDropdownOpen]);

    const handleApplyFilters = () => {
        setCurrentPage(1);
    };
    const handleResetFilters = () => {
        setFilterStatus('');
        setFilterRecallNumber('');
        // setFilterProductType('');
        setFilterClassification('');
        setFilterVoluntaryMandated('');
        setFilterDecisionDateRange('30');
        setCurrentPage(1);
    };

    if (filter) {
        handleApplyFilters();
        handleResetFilters();
    }

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        Cookies.set('selectedDays', filterDecisionDateRange);
    }, [filterDecisionDateRange]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);

    // const handleShow = (event) => {
    //     setSelectedEnforcement(event);
    //     setShowModal(true);
    // };

    // const handleClose = () => {
    //     setShowModal(false);
    //     setSelectedEnforcement(null);
    // };

    // const [custom, setCustom] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchDevices = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/fda/enforcement/by_days/365`);
                let fetchedDevices = response.data;

                fetchedDevices.sort((a, b) => {
                    let aValue = a[sortConfig.key];
                    let bValue = b[sortConfig.key];

                    if (sortConfig.key === 'fulldate') {
                        aValue = new Date(aValue).getTime();
                        bValue = new Date(bValue).getTime();
                    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                        aValue = aValue.toLowerCase();
                        bValue = bValue.toLowerCase();
                    }

                    if (aValue < bValue) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
                });

                setDevices(fetchedDevices);
            } catch (error) {
                console.log('Error fetching devices:', error);
            }
            setIsLoading(false);
        };
        fetchDevices();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoadSort(true);
        let sortedDevices = [...devices];
        sortedDevices = sortedDevices.sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'fulldate') {
                aValue = new Date(aValue).getTime();
                bValue = new Date(bValue).getTime();
            } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setDevices(sortedDevices);
        setLoadSort(false);
        // eslint-disable-next-line
    }, [sortConfig]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, filterStatus, filterRecallNumber, filterClassification, filterVoluntaryMandated, filterDecisionDateRange]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const matchesSearchTerm = (recall, searchTerms) =>
        searchTerms.some(term =>
            recall.recall_number?.toLowerCase().includes(term) ||
            recall.status?.toLowerCase().includes(term) ||
            recall.event_id?.toLowerCase().includes(term) ||
            recall.classification?.toLowerCase().includes(term) ||
            recall.voluntary_mandated?.toLowerCase().includes(term) ||
            recall.product_description?.toLowerCase().includes(term)
        );

    const filteredDevices = devices.filter(device => {
        const searchTerms = searchTerm.toLowerCase().split(/[\s,]+/).filter(Boolean);
        const matchesSearch = !searchTerm || matchesSearchTerm(device, searchTerms);
        const matchesInterests = selectedDeviceClasses === 0 || selectedDeviceClasses.includes(classMapping[device.classification]);
        // Apply filters
        const matchesStatus = !filterStatus || device.status.toLowerCase().includes(filterStatus.toLowerCase());
        const matchesRecallNumber = !filterRecallNumber || device.recall_number.toLowerCase().includes(filterRecallNumber.toLowerCase());
        // const matchesProductType = !filterProductType || device.product_type.toLowerCase().includes(filterProductType.toLowerCase());
        const matchesClassification = !filterClassification || device.classification.toLowerCase().includes(filterClassification.toLowerCase());
        const matchesVoluntaryMandated = !filterVoluntaryMandated || device.voluntary_mandated.toLowerCase().includes(filterVoluntaryMandated.toLowerCase());
        const currentDate = new Date();
        const decisionDate = new Date(device.fulldate);
        decisionDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
        const matchesDecisionDateRange = !filterDecisionDateRange || (decisionDate >= new Date(Date.now() - Number(filterDecisionDateRange) * 24 * 60 * 60 * 1000));
        return matchesInterests && matchesSearch && matchesStatus && matchesRecallNumber && matchesClassification && matchesVoluntaryMandated && matchesDecisionDateRange;
    });

    useEffect(() => {
        setTotalPages(Math.ceil(filteredDevices.length / Math.max(1, itemsPerPage)));
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [filteredDevices, currentPage, totalPages, itemsPerPage]);


    const handleHeaderClick = (key) => {
        let direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };

    const currentDevices = filteredDevices.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage));

    const processDataForDaysVsDeviceClass = (data) => {
        const deviceCounts = data.reduce((acc, item) => {
            const deviceclass = classMapping[item.classification];
            if (!acc[deviceclass]) {
                acc[deviceclass] = 0;
            }
            acc[deviceclass]++;
            return acc;
        }, {});

        const seriesData = Object.entries(deviceCounts).map(([name, count]) => ({
            name,
            data: [count] // Highcharts expects an array of data even for a single value
        }));


        return seriesData;

    };

    const MyChartComponent = ({ data }) => {
        const chartOptions = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: [{
                min: 0,
                title: {
                    text: 'Device Class'
                },
                labels: {
                    enabled: false // This will hide the x-axis labels
                },
            }],
            yAxis: {
                min: 0.1,
                type: 'logarithmic',
                title: {
                    text: 'Number of Enforcements'
                }
            },
            tooltip: {
                formatter: function () {
                    return `Class ${this.series.name}: ${this.y}`;
                }
            },
            series: processDataForDaysVsDeviceClass(data)
        };
        return (
            <div className={`card`} >
                <div className='card-header border-0 py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Enforcements by Device Class</span>
                    </h3>
                </div>
                <div className='card-body d-flex flex-column'>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                </div>
            </div >
        )
    };

    // const processDataForDeviceClassVsAdvisoryCommittee = (data) => {
    //     const result = {};
    //     data.forEach(item => {
    //         if (item.openfda_device_class && item.openfda_device_class > 0) {
    //             const deviceClass = `Class ${item.openfda_device_class}`;
    //             const committee = item.openfda_medical_specialty_description;
    //             if (!result[deviceClass]) {
    //                 result[deviceClass] = {};
    //             }
    //             if (!result[deviceClass][committee]) {
    //                 result[deviceClass][committee] = 0;
    //             }
    //             result[deviceClass][committee]++;
    //         }
    //     });

    //     const seriesData = Object.keys(result).map(deviceClass => ({
    //         name: deviceClass,
    //         data: Object.values(result[deviceClass]),
    //         stack: 'deviceClass'
    //     }));

    //     const categories = [...new Set(data.map(item => item.openfda_medical_specialty_description))];

    //     return { seriesData, categories };
    // };

    // const DeviceClassVsAdvisoryCommitteeChart = ({ data }) => {
    //     const { seriesData, categories } = processDataForDeviceClassVsAdvisoryCommittee(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'bar'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             categories: categories
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Enforcements'
    //             }
    //         },
    //         plotOptions: {
    //             bar: {
    //                 stacking: 'normal'
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Device Class vs. Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };

    // const extractAbbreviation = (description) => {
    //     for (const specialty of selectedSpecialities) {
    //         const [fullDescription, abbreviation] = specialty.match(/^(.*) \(([^)]+)\)$/).slice(1, 3);
    //         if (fullDescription.trim() === description.trim()) {
    //             return abbreviation;
    //         }
    //     }
    //     return null;
    // };

    // const processDataForAdvisoryCommitteeVsDays = (data) => {
    //     const committeeCounts = data.reduce((acc, item) => {
    //         //const committee = extractAbbreviation(item.openfda_medical_specialty_description);
    //         const committee = item.openfda_medical_specialty_description;
    //         if (!acc[committee]) {
    //             acc[committee] = 0;
    //         }
    //         acc[committee]++;
    //         return acc;
    //     }, {});

    //     // Convert the counts object to an array suitable for Highcharts
    //     const seriesData = Object.entries(committeeCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));
    //     // const categories = [...new Set(data.map(item => item.review_advisory_committee))];
    //     return { seriesData };
    // };

    // const AdvisoryCommitteeVsDaysChart = ({ data }) => {

    //     const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             title: {
    //                 text: 'Specialty'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Enforcements'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`}>
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Enforcement Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };

    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <KTIcon iconName='shield-search' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                    <span>Enforcements</span>
                                    {isLoading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div className="d-flex flex-row-fluid px-10 align-items-center">
                        <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    </div>
                    {committees && interests.device_classes && (
                        <div className="d-flex flex-row-fluid px-10">
                            {Object.keys(committees).length > 0 && (
                                <div style={{ borderRight: interests.device_classes.length > 0 ? '1px solid #4d4d4d' : 'none', paddingRight: '7px', height: '20px' }}>
                                    <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                        {Object.entries(committees).map(([key, value], index) => (
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {value}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{index > 0 && ', '}{key}</span>
                                            </OverlayTrigger>
                                        ))}
                                    </h6>
                                </div>
                            )}
                            {interests.device_classes.length > 0 && (
                                <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                    <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* <div className="d-flex flex-column px-10 align-items-start">
                    {(filterStatus || filterRecallNumber || filterClassification || filterVoluntaryMandated || filterDecisionDateRange) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterRecallNumber && <li><h6 className="d-md-block text-wrap">Recall Number: {filterRecallNumber}</h6></li>}
                                {filterClassification && <li><h6 className="d-md-block text-wrap">Classification: {filterClassification}</h6></li>}
                                {filterVoluntaryMandated && <li><h6 className="d-md-block text-wrap">Voluntary/Mandated: {filterVoluntaryMandated}</h6></li>}
                                {filterStatus && <li><h6 className="d-md-block text-wrap">Status: {filterStatus}</h6></li>}
                                {filterDecisionDateRange && <li><h6 className="d-md-block text-wrap">Classification Date Range: Past {filterDecisionDateRange === '365' ? '1 Year' : `${filterDecisionDateRange} Days`}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div> */}
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto ms-6 mt-4">
                    <ul className='nav mb-4'>
                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 30 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(30); setDays(30); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                30 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 90 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(90); setDays(90); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                90 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 180 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(180); setDays(180); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                180 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 365 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(365); setDays(365); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                1 Year
                            </button>
                        </li>
                    </ul>

                    <div className='mb-4 dropdown ms-10' ref={dropdownRef}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Device Class
                        </button>
                        {dropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                </li>
                            </ul>
                        )}
                    </div>

                    {/* <div className='mb-4 dropdown ms-10' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleSpecialityDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Specialty
                        </button>
                        {specialityDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {specialityOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div> */}
                </div>

                {filteredDevices.length > 0 && (
                    <div className='row g-5 justify-content-center mt-1 mb-4'>
                        {/* <div className='col-xxl-4'>
                            <AdvisoryCommitteeVsDaysChart data={filteredDevices} />
                        </div> */}
                        {/* <div className='col-xxl-4'>
                            <DeviceClassVsAdvisoryCommitteeChart data={filteredDevices} />
                        </div> */}
                        <div className='col-8'>
                            <MyChartComponent data={filteredDevices} />
                        </div>
                    </div>
                )}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-2`}>
                    <div className="col-7">
                        <div className=" flex-column-auto align-items-center">
                            <div className="d-flex align-items-center position-relative my-1" >
                                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                                <input
                                    type="search"
                                    id="form1"
                                    className="form-control form-control-solid w-100 ps-14 border border-1"
                                    placeholder="Search Enforcements"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onSubmit={(event) => event.preventDefault()}
                                    maxLength={200}
                                    style={{ backgroundColor: '#f1faff' }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DevicesFilter
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            filterRecallNumber={filterRecallNumber}
                            setFilterRecallNumber={setFilterRecallNumber}
                            // filterProductType={filterProductType}
                            // setFilterProductType={setFilterProductType}
                            filterClassification={filterClassification}
                            setFilterClassification={setFilterClassification}
                            filterVoluntaryMandated={filterVoluntaryMandated}
                            setFilterVoluntaryMandated={setFilterVoluntaryMandated}
                            filterDecisionDateRange={filterDecisionDateRange}
                            setFilterDecisionDateRange={setFilterDecisionDateRange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div> */}
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="ms-15 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
            </div>
            {!isLoading && (
                <DevicesTable
                    loadSort={loadSort}
                    currentDevices={currentDevices}
                    handleHeaderClick={handleHeaderClick}
                    sortConfig={sortConfig}
                    navigate={navigate}
                // handleShow={handleShow}
                />
            )}

            {!isLoading && totalPages >= 1 && (
                <div className="d-flex justify-content-center">

                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>

                    <DevicesListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </div>
            )}
            {/* <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="custom-modal-size"
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enforcement Details for Recall Number: {selectedEnforcement?.recall_number}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    {selectedEnforcement?.event_id && <p><strong>Event ID:</strong> {selectedEnforcement?.event_id}</p>}
                    {selectedEnforcement?.status && <p><strong>Status:</strong> {selectedEnforcement?.status}</p>}
                    <div className="col-12">
                        <strong>Event Dates:</strong>
                        <ul>
                            {selectedEnforcement?.recall_initiation_date && <li><strong>Recall Initiation Date:</strong> {new Date(selectedEnforcement?.recall_initiation_date).toLocaleDateString()}</li>}
                            {selectedEnforcement?.fulldate && <li><strong>Center Classification Date:</strong> {new Date(selectedEnforcement?.fulldate).toLocaleDateString()}</li>}
                            {selectedEnforcement?.termination_date && <li><strong>Termination Date:</strong> {new Date(selectedEnforcement?.termination_date).toLocaleDateString()}</li>}
                            {selectedEnforcement?.report_date && <li><strong>Report Date:</strong> {new Date(selectedEnforcement?.report_date).toLocaleDateString()}</li>}
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {selectedEnforcement?.voluntary_mandated && <p><strong>Voluntary Mandated:</strong> {selectedEnforcement?.voluntary_mandated}</p>}
                        </div>
                        <div className="col-md-6">
                            {selectedEnforcement?.initial_firm_notification && <p><strong>Initial Firm Notification:</strong> {selectedEnforcement?.initial_firm_notification}</p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {selectedEnforcement?.recalling_firm && <p><strong>Recalling Firm:</strong> {selectedEnforcement?.recalling_firm}</p>}
                        </div>
                        <div className="col-md-6">
                            {(selectedEnforcement?.city || selectedEnforcement?.state || selectedEnforcement?.country || selectedEnforcement?.postal_code) &&
                                <p><strong>Location:</strong> {
                                    [
                                        selectedEnforcement?.city ? selectedEnforcement?.city + ',' : null,
                                        selectedEnforcement?.state ? selectedEnforcement?.state + ',' : null,
                                        selectedEnforcement?.country ? selectedEnforcement?.country : null,
                                        selectedEnforcement?.postal_code
                                    ].filter(Boolean).join(' ')
                                }</p>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {selectedEnforcement?.product_type && <p><strong>Product Type:</strong> {selectedEnforcement?.product_type}</p>}
                        </div>
                        <div className="col-md-6">
                            {selectedEnforcement?.classification && <p><strong>Classification:</strong> {selectedEnforcement?.classification}</p>}
                        </div>
                    </div>
                    {selectedEnforcement?.product_description && <p><strong>Product Description:</strong> {selectedEnforcement?.product_description}</p>}
                    {selectedEnforcement?.product_quantity && <p><strong>Product Quantity:</strong> {selectedEnforcement?.product_quantity}</p>}
                    {selectedEnforcement?.distribution_pattern && <p><strong>Distribution Pattern:</strong> {selectedEnforcement?.distribution_pattern}</p>}
                    {selectedEnforcement?.reason_for_recall && <p><strong>Reason for Recall:</strong> {selectedEnforcement?.reason_for_recall}</p>}
                    {selectedEnforcement?.code_info && <p><strong>Code Info:</strong> {selectedEnforcement?.code_info}</p>}
                    {selectedEnforcement?.more_code_info && <p><strong>More Code Info:</strong> {selectedEnforcement?.more_code_info}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}
export default Enforcement;
