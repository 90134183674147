import ProfilePage from './Components/ProfilePage';



const SuperAdmin = () => {

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <ProfilePage />
        </div >

    );
};

export default SuperAdmin;