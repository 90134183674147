import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTIcon } from '../../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import axios from 'axios'

type Organization = {
  id: string
  name: string
}

const UsersListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [isactive, setIsactive] = useState<string | undefined>()
  const [locked, setLocked] = useState<string | undefined>()
  const [orgName, setOrgName] = useState<string | undefined>()
  const [role, setRole] = useState<string | undefined>()
  const [organizations, setOrganizations] = useState<Organization[]>([])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    axios.get('/reg/v1/superadmin/org')
      .then((response) => setOrganizations(response.data))
      .catch((error) => console.error(error))
  }, [])


  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { isactive, locked, orgName, role },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {/* begin::Input group */}
            <div className='mb-5'>
              <label className='form-label fs-6 fw-bold'>User Role:</label>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value=''>All</option>
                <option value='1'>User</option>
                <option value='2'>Org Admin</option>
                <option value='3'>Lexim Admin</option>

              </select>
            </div>
            <div className='mb-5'>
              <label className='form-label fs-6 fw-bold'>User Status:</label>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setIsactive(e.target.value)}
                value={isactive}
              >
                <option value=''>All</option>
                <option value='0'>Inactive</option>
                <option value='1'>Active</option>

              </select>
            </div>

            <div className='mb-5'>
              <label className='form-label fs-6 fw-bold'>User Locked:</label>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setLocked(e.target.value)}
                value={locked}
              >
                <option value=''>All</option>
                <option value='0'>Not locked</option>
                <option value='1'>Locked</option>

              </select>
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div>
              <label className='form-label fs-6 fw-bold'>Organization:</label>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setOrgName(e.target.value)}
                value={orgName}
              >
                <option value=''>All</option>
                {organizations.sort((a, b) => a.name.localeCompare(b.name)).map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div> */}

          </div>
          {/* begin::Actions */}
          <div className='px-2 pt-6 mb-1'>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                disabled={isLoading}
                onClick={resetData}
                className='btn btn-sm btn-light btn-active-light-primary fw-bold me-3 px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='reset'
              >
                Reset
              </button>
              <button
                disabled={isLoading}
                type='button'
                onClick={filterData}
                className='btn btn-sm btn-primary fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Apply
              </button>
            </div>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { UsersListFilter }
