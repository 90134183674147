import React, { FC, useState } from 'react'
import { Form, Field, ErrorMessage, Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const Step1: FC<{ onUserVerified: (email: string) => void }> = ({ onUserVerified }) => {
  const initialValues = { email: '', temporaryPassword: '' }
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    temporaryPassword: Yup.string().required('Required'),
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      const formData = new FormData();
      formData.append('email', values.email);
      formData.append('password', values.temporaryPassword);

      const response = await axios.post('/verifyotp', formData);


      if (response.status === 200) {
        setErrorMessage('');
        setSuccessMessage(response.data.message);
        onUserVerified(values.email)
      } else {
        setSuccessMessage('');
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error(error.message);
        setSuccessMessage('');
        setErrorMessage(error.response.data.message);
      } else {
        console.error(error);
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <Formik {...formik} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (<div className="w-100">
        {/* <div className="pb-10 pb-lg-15">
          <h2 className='fw-bolder text-dark'>Verify Temporary Password</h2>
        </div> */}
        {errorMessage && <div className="alert alert-danger mb-10">{errorMessage}</div>}
        {successMessage && <div className="alert alert-success mb-10">{successMessage}</div>}

        <Form>
          <div className="mb-10 fv-row">
            <label htmlFor="email" className="form-label mb-3">Email</label>
            <Field type="email" id="email" name="email" className="form-control form-control-lg form-control-solid" required />
            <ErrorMessage name="email" component="div" className="text-danger" />
          </div>

          <div className="mb-10 fv-row">
            <label htmlFor="temporaryPassword" className="form-label mb-3">Temporary Password</label>
            <div className="input-group mb-3 border-0">
              <Field type={showPassword ? "text" : "password"} id="temporaryPassword" name="temporaryPassword" className="form-control form-control-lg form-control-solid" required />
              <div className="input-group-append border-0">
                {/* <button type="button" className="btn btn-light border-0" onClick={togglePasswordVisibility}>
          <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}></i>
        </button> */}
                <button type="button" className="btn btn-light btn-lg border-0" style={{ borderStartStartRadius: 0 }} onClick={togglePasswordVisibility}><i className={`bi ${showPassword ? "bi-eye-slash border-0" : "bi-eye border-0"}`}></i></button>

              </div>
            </div>
            <ErrorMessage name="temporaryPassword" component="div" className="text-danger" />
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className='btn btn-lg btn-primary me-3 '>Submit {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}</button>
          </div>
        </Form>
      </div>
      )}
    </Formik>
  )
}

export { Step1 }