import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from 'react';
import axios from 'axios';

type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  title: string;
  labels: string[];
  cardHeight: string;
  agency: string;
}

const titleMapping = {
  'Dockets by Type': { url: 'reg/v1/home/dashboard/docket', columns: ['total_rulemaking_90', 'total_nonrulemaking_90'] },
  'Documents by Type': { url: 'reg/v1/home/dashboard/doc', columns: ['total_proposed_90', 'total_rule_90', 'total_notice_90', 'total_supporting_90', 'total_other_90'] },
  'Comments by Type': { url: 'reg/v1/home/dashboard/com', columns: ['total_anonymous_90', 'total_org_90', 'total_individual_90'] },
  'Dockets by Activity': { url: 'reg/v1/home/dashboard/docket', columns: ['total_docket_is_active_90', 'total_dockets_90'] },
  'Documents by Activity': { url: 'reg/v1/home/dashboard/doc', columns: ['total_active_90', 'total_doc_90'] },
  // 'Comments by Category': { url: 'home/dashboard/com', columns: [] },
}

const fetchData = async (url: string, agency: string) => {
  try {
    const response = await axios.get(url);
    return response.data.find(row => row.short_name === agency);
  } catch (error) {
    console.log(error);
  }
}

const MixedWidget15: React.FC<Props> = ({ className, chartHeight = "250px", title, chartColor, labels = [], cardHeight = "400px", agency = 'CMS' }) => {
  const [data, setData] = useState<number[]>([]);
  const [apiConfig, setApiConfig] = useState<{ url: string, columns: string[] }>({ url: "", columns: [] });

  useEffect(() => {
    setApiConfig(titleMapping[title]);
  }, [title, agency]);

  useEffect(() => {
    if (apiConfig.url) {
      fetchData(apiConfig.url, agency).then(agencyData => {
        if (agencyData) {
          let newData;
          if (title === 'Dockets by Activity') {
            const activeDockets = agencyData['total_docket_is_active_90'];
            const totalDockets = agencyData['total_dockets_90'];
            newData = [activeDockets, totalDockets - activeDockets];
          }
          else if (title === 'Documents by Activity') {
            const activeDocs = agencyData['total_active_90'];
            const totalDocs = agencyData['total_doc_90'];
            newData = [activeDocs, totalDocs - activeDocs];
          }
          else {
            newData = apiConfig.columns.map(column => agencyData[column]);
          }
          setData(newData);
        }
      });
    }
  }, [title, agency, apiConfig]);

  const chartData: ApexOptions = {
    chart: {
      type: 'pie',
      height: parseInt(chartHeight), // Ensure that chartHeight is converted to a number
    },
    labels: labels, // Use the labels from docketsdata
    legend: {
      show: true,
      position: 'bottom', // Position the legend at the top
    },
    colors: chartColor,
    dataLabels: {
      distributed: true,
    },

  };

  return (
    <div className={`card ${className}`} style={{ height: cardHeight }} >
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{agency} {title}</span>
          {/* <span className='text-muted fw-semibold fs-7'>Latest trends</span> */}
        </h3>
      </div>
      <div className='card-body d-flex flex-column py-0'>
        <ReactApexChart options={chartData} series={data} type='pie' height={parseInt(chartHeight)} />
      </div>
    </div>
  )
}

export { MixedWidget15 }