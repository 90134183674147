import React from 'react';



const CalendarFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters
}) => {

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5' style={{ maxHeight: '300px', overflowY: 'auto' }}>


                <div className='mb-5'>
                    <label className='form-label fw-bold ms-1'>Document Title:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Document Title'
                        value={filters.documenttitle}
                        onChange={(e) => onFilterChange('documenttitle', e.target.value)}
                    // maxLength="20"
                    />

                </div>
                <div className='mb-5'>
                    <label className='form-label fw-bold ms-1'>Document ID:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS-2023-****'
                        value={filters.documentid}
                        onChange={(e) => onFilterChange('documentid', e.target.value)}

                    />

                </div>
                <div className='mb-6'>
                    <label className='form-label fw-bold ms-1'>Program:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CDRH, ORA, etc.'
                        value={filters.program}
                        onChange={(e) => onFilterChange('program', e.target.value)}

                    />

                </div>
                <div className='mb-6'>
                    <label className='form-label fw-bold ms-1'>Agency:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS / FDA'
                        value={filters.agency}
                        onChange={(e) => onFilterChange('agency', e.target.value)}

                    />

                </div>
                <div className='mb-6'>
                    <label className='form-label fw-bold ms-1' htmlFor='dateRange'>
                        Posted Date (On/After):
                    </label>
                    <input
                        type='date'
                        id='startDate'
                        className='form-control form-control-solid'
                        placeholder='Posted Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                </div>

                <div >
                    <label className='form-label fw-bold ms-1' htmlFor='dateRange'>
                        Comments Due Date (On/After):
                    </label>
                    <input
                        type='date'
                        id='endDate'
                        className='form-control form-control-solid'
                        placeholder='Comments Due Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>
                {/* <div className='mb-4'>
                    <label className='form-label fw-bold'>Docket ID:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS-2023-****'
                        value={filters.docketid}
                        onChange={(e) => onFilterChange('docketid', e.target.value)}

                    />

                </div> */}
                {/* <div className='mb-4'>
                    <label className='form-label fw-bold'>Type:</label>

                    <div>
                        <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            value={filters.status}
                            onChange={(e) => onFilterChange('status', e.target.value)}
                        >

                            <option value=''>All</option>
                            <option value='Rulemaking'>Rulemaking</option>
                            <option value='Nonrulemaking'>Nonrulemaking</option>
                        </select>
                    </div>
                </div> */}
            </div>



            <div className='d-flex justify-content-end mb-5 mt-5'>
                <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-primary me-5'
                    data-kt-menu-dismiss='true'
                    onClick={onResetFilters}
                >
                    Reset
                </button>

                <button
                    type='button'
                    className='btn btn-sm btn-primary me-7'
                    data-kt-menu-dismiss='true'
                    onClick={onApplyFilters}
                >
                    Apply
                </button>
            </div>
        </div>

    );
};

export default CalendarFilter;