import React from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { Modal } from 'react-bootstrap';
// import CommentDetails from './CommentDetails';
// import { useState } from 'react';

const CommentsTable = ({ filteredComments, currentPage, COMMENTS_PER_PAGE, handleHeaderClick, sortConfig, currentUser, formatDate }) => {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const navigate = useNavigate();

    return (
        <div>
            <table className="table table-striped table-hover table-rounded border gy-4 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)'
                }}  >
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className="fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200">

                        {/* <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('id')}>
                        ID
                        {sortConfig.key === 'id' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th> */}
                        {/* <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                        Title
                    </th> */}
                        {/* <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('firstName')}>
                        First Name
                        {sortConfig.key === 'firstName' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th>
                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('lastName')}>
                        Last Name
                        {sortConfig.key === 'lastName' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th> */}
                        {/* <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('organization')}>
                        Organization
                        {sortConfig.key === 'organization' && (
                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                            </span>
                        )}
                    </th> */}
                        <th style={{ whiteSpace: 'nowrap', width: '20%', cursor: 'pointer' }} onClick={() => handleHeaderClick('category')}>
                            Category
                            {sortConfig.key === 'category' && (
                                <span className={`sorting-icon ${sortConfig.key === 'category' ? sortConfig.direction : ''}`}>
                                    {' '}<i className={`bi ${sortConfig.key === 'category' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                </span>
                            )}

                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '45%', cursor: 'pointer' }} onClick={() => handleHeaderClick('comment')}>
                            Comment
                            {sortConfig.key === 'comment' && (
                                <span className={`sorting-icon ${sortConfig.key === 'comment' ? sortConfig.direction : ''}`}>
                                    {' '}<i className={`bi ${sortConfig.key === 'comment' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                </span>
                            )}
                        </th>

                        <th style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer', textAlign: 'center' }} onClick={() => handleHeaderClick('sentiment')}>
                            Sentiment
                            {sortConfig.key === 'sentiment' && (
                                <span className={`sorting-icon ${sortConfig.key === 'sentiment' ? sortConfig.direction : ''}`}>
                                    {' '}<i className={`bi ${sortConfig.key === 'sentiment' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                </span>
                            )}
                        </th>

                        <th style={{ whiteSpace: 'nowrap', width: '9%', cursor: 'pointer', textAlign: 'center' }} onClick={() => handleHeaderClick('attach_count')}>
                            Attachments
                            {sortConfig.key === 'attach_count' && (
                                <span className={`sorting-icon ${sortConfig.key === 'attach_count' ? sortConfig.direction : ''}`}>
                                    {' '}<i className={`bi ${sortConfig.key === 'attach_count' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                </span>
                            )}
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '8%' }}>Action</th>
                    </tr>
                </thead>
                <tbody >
                    {filteredComments.length > 0 ? (
                        filteredComments
                            .slice((currentPage - 1) * COMMENTS_PER_PAGE, currentPage * COMMENTS_PER_PAGE)
                            .map((comment) => (
                                <tr key={comment.idcomment} style={{ cursor: 'pointer' }} onClick={() => navigate(`/comment/${comment.id}`, { state: { documentId: comment.commentOnDocumentId } })}>

                                    {/* <td style={{ whiteSpace: 'nowrap' }}>
                                    {comment.commentOnDocumentId && (
                                        <Link
                                            to={`/comment/${comment.id}`}
                                            state={{ documentId: comment.commentOnDocumentId }}
                                            style={{ color: 'black' }}
                                        >
                                            {comment.id}
                                        </Link>
                                    )}
                                </td> */}
                                    {/* <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`/comment/${comment.id}`} style={{ color: 'black' }}>
                                    {comment.title}
                                </Link>
                            </td> */}
                                    {/* <td style={{ whiteSpace: 'nowrap' }}> {comment.firstName || "N/A"} </td>
                                <td style={{ whiteSpace: 'nowrap' }}> {comment.lastName || "N/A"}</td> */}
                                    {/* <td style={{ whiteSpace: 'wrap' }}>{comment.organization || "N/A"}</td> */}
                                    <td className='text-dark fw-semibold fs-6' style={{ whiteSpace: 'wrap' }}> {comment.category}</td>
                                    <td className='text-dark fw-semibold fs-6' style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '200px'
                                    }}>
                                        {comment.comment}
                                    </td>
                                    {/* <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}> {comment.attach_count}</td> */}
                                    <td className='text-dark fw-semibold fs-6' style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                        {comment.sentiment === null ? (
                                            ''
                                        ) : comment.sentiment < -0.25 ? (
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id={`tooltip-top`}>Negative</Tooltip>}
                                            >
                                                <span><KTIcon iconName='arrow-down' iconType="solid" className=' fw-bold fs-1 text-danger' /></span>
                                            </OverlayTrigger>
                                        ) : comment.sentiment > 0.25 ? (
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id={`tooltip-top`}>Positive</Tooltip>}
                                            >
                                                <span><KTIcon iconName='arrow-up' iconType="solid" className=' fw-bold fs-1 text-success' /></span>
                                            </OverlayTrigger>
                                        ) : (
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id={`tooltip-top`}>Neutral</Tooltip>}
                                            >
                                                <span><KTIcon iconName='minus' iconType="solid" className=' fw-bold fs-1 text-warning' /></span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                    <td className='text-dark fw-semibold fs-6' style={{ whiteSpace: 'nowrap', textAlign: 'center' }}> {comment.attach_count}</td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6'
                                        style={{ cursor: 'default' }}
                                        onClick={(event) => event.stopPropagation()}
                                    >
                                        <Link to={`/comment/${comment.id}`}
                                            state={{ documentId: comment.commentOnDocumentId }}
                                            style={{ color: 'primary' }}>
                                            Details
                                        </Link>
                                        {/* <button className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={handleShow}>
                                    <i className='bi bi-three-dots fs-5'></i></button>  */}
                                        {/* <CommentDetails id={comment.id} documentId = {comment.commentOnDocumentId}/> */}
                                        {/* <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Comment Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className='scrollable'>
                                                <CommentDetails id={comment.id} documentId={comment.commentOnDocumentId} />
                                            </Modal.Body>
                                        </Modal> */}
                                    </td>
                                </tr>
                            ))) : (
                        <tr>
                            <td colSpan="8" style={{ textAlign: 'center', fontSize: '14px', paddingBottom: '5px', paddingTop: '13px' }}>
                                <p>No comments available for the selected document.</p>
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default CommentsTable;