import React from "react";
import Group from "./Groups/Groups";
const GroupWrapper =()=>{
    return(
        <>
           <Group/>
        </>
    )
}

export default GroupWrapper;