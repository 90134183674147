import { KTIcon } from "../../../../../_metronic/helpers";
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useAuth } from '../../../../modules/auth';
import * as Yup from 'yup';
import axios from 'axios';
import clsx from "clsx";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const AnnouncementsPage = ({ fetchAnnouncements }) => {
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const formik = useFormik({
        initialValues: {
            start_date: '',
            end_date: '',
            title: '',
            content: '',
            priority: '',
            is_active: true,
            created_by: currentUser?.id,
        },
        validationSchema: Yup.object({
            start_date: Yup.date().required('Required'),
            end_date: Yup.date().required('Required'),
            title: Yup.string().required('Required'),
            content: Yup.string().max(500, 'Must be 500 characters or less').required('Required'),
            priority: Yup.number().min(1, 'Minimum value is 1').max(3, 'Maximum value is 3').required('Required'),
            is_active: Yup.boolean().required('Required'),
            created_by: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            // console.log(values)
            axios.post(`/reg/v1/admin/announcement/${currentUser?.organization}`, values)
                .then(response => {
                    // console.log(response.data);
                    handleClose();
                    fetchAnnouncements();
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
    });


    return (
        <div>
            <div className='d-flex justify-content-end' data-kt-organization-table-toolbar='base'>
                <button type='button' className='btn btn-primary' onClick={handleShow}>
                    <KTIcon iconName='plus' className='fs-2' />
                    New Announcement
                </button>
                <Modal show={show} onHide={handleClose} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Announcement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>
                        <div className="form-container mx-3" >
                            <form id="announcmentform" className="form" onSubmit={formik.handleSubmit} >
                                <label className='required fw-bold fs-6 mb-2'>Announcement Title</label>
                                <input
                                    placeholder="Announcement Title"
                                    type="text"
                                    maxLength={100}
                                    {...formik.getFieldProps('title')}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0'
                                    )}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px', color: 'red' }}>{formik.errors.title}</div>
                                        </div>
                                    ) : (
                                        <div style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>&nbsp;</div> // Placeholder div
                                    )}
                                    <div className="text-end fs-6 me-3" style={{ color: '#6c757d', marginTop: '10px' }}>
                                        {formik.values.title.length}/100
                                    </div>
                                </div>

                                <label className='required fw-bold fs-6 mt-5 mb-2'>Content</label>
                                <textarea
                                    placeholder="Content"
                                    maxLength={1000}
                                    rows={5}
                                    {...formik.getFieldProps('content')}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0'
                                    )}
                                    style={{ overflowY: 'auto' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {formik.touched.content && formik.errors.content ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px', color: 'red' }}>{formik.errors.content}</div>
                                        </div>
                                    ) : (
                                        <div style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>&nbsp;</div> // Placeholder div
                                    )}
                                    <div className="text-end fs-6 me-3" style={{ color: '#6c757d', marginTop: '10px' }}>
                                        {formik.values.content.length}/1000
                                    </div>
                                </div>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            Priority levels: 3 (High), 2 (Medium), 1 (Low)
                                        </Tooltip>
                                    }
                                >
                                    <label className='required fw-bold fs-6 mt-5 mb-2'>Priority</label>
                                </OverlayTrigger>
                                <input
                                    placeholder="Priority"
                                    type="number"
                                    min="1"
                                    max="3"
                                    {...formik.getFieldProps('priority')}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0'
                                    )}
                                />
                                {formik.touched.priority && formik.errors.priority ?
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px', color: 'red' }}>
                                            {formik.errors.priority}
                                        </div>
                                    </div>
                                    : null}
                                <label className='required fw-bold fs-6 mt-8 mb-2'>Date Range:</label>
                                <input
                                    placeholder="Start Date"
                                    type="date"
                                    {...formik.getFieldProps('start_date')}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0'
                                    )}
                                />
                                {formik.touched.start_date && formik.errors.start_date ?
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px', color: 'red' }}>
                                            {formik.errors.start_date}
                                        </div>
                                    </div>
                                    : null}
                                <input
                                    placeholder="End Date"
                                    type="date"
                                    {...formik.getFieldProps('end_date')}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mt-3 mb-lg-0'
                                    )}
                                />
                                {formik.touched.end_date && formik.errors.end_date ?
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px', color: 'red' }}>
                                            {formik.errors.end_date}
                                        </div>
                                    </div>
                                    : null}
                                <div className="d-flex justify-content-end mt-7 mb-8">
                                    <button className="btn btn-primary me-12" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};
export default AnnouncementsPage;