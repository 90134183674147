import { FC, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { KTIcon } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface QAInterfaceProps {
    documentid: string;
    onFirstQuestionAsked: (question: string) => void;
    onConversationUpdate: (conversation: {}) => void;
    isNewReport: boolean;
    setIsNewReport: (isNewReport: boolean) => void;
    currentQuestion: string;
    conversations: { [key: string]: { qaPairs: QAPair[], domains: string[] } };
}

interface Answer {
    text: string;
    sources: string[];
    valid: boolean;
}

interface QAPair {
    question: string;
    answer: Answer;
    error: boolean;
}

const QAInterface: FC<QAInterfaceProps> = ({ onFirstQuestionAsked, onConversationUpdate, isNewReport, setIsNewReport, currentQuestion, conversations }) => {

    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>(conversations[currentQuestion]?.qaPairs || []);
    const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(currentQuestion !== '' ? true : false);
    const [sessionDomains, setSessionDomains] = useState<string[]>(isNewReport ? [] : conversations[currentQuestion]?.domains || []);

    const handleChange = (domainsString: string) => {
        const domainsArray = domainsString ? domainsString.split(', ').map(domain => domain) : [];
        setSessionDomains(domainsArray);
    };


    const handleDownload = (question, answer) => {
        // Ensure answer is an object for consistent data extraction
        if (typeof answer !== 'object') {
            console.error('Invalid answer format. Expected an object.');
            return;
        }

        // Construct answer content with clear formatting
        let answerContent = `Question: \n${question}\n\nAnswer:\n${answer?.text}\n\n`;

        // Extract and format sources with numbered list
        if (answer.sources && Array.isArray(answer.sources)) {
            answerContent += 'Sources:\n';
            answer.sources.forEach((source, index) => {
                if (typeof source === 'object') {
                    answerContent += `${index + 1}. ${source.title || 'Source'} \n(${source.url || 'No URL'})\n`;
                    answerContent += `Relevant Content: "${source.content || 'No content'}"\n\n`;
                } else {
                    answerContent += `${index + 1}. ${source}\n\n`;
                }
            });
        } else {
            answerContent += 'No sources provided.\n';
        }

        const blob = new Blob([answerContent], { type: 'text/plain' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = `${question}_answer.txt`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
    };


    const askQuestion = async () => {
        const newQuestion = question;
        if (!firstQuestionAsked) {
            onFirstQuestionAsked(newQuestion);
            setFirstQuestionAsked(true);
            setIsNewReport(false);
        }
        setTimeout(async () => {
            setQAPairs(prevQAPairs => [
                {
                    question: newQuestion,
                    answer: { text: 'Thinking ...', sources: [], valid: true },
                    error: false
                },
                ...prevQAPairs
            ]);
        }, 0);
        setQuestion('');
        try {
            // const response = await fetch(`http://localhost:8000/answer-research-query`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            // });

            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/answer-research-query`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            });

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    // Once the stream is fully read, parse the JSON
                    const jsonResponse = JSON.parse(text);
                    setQAPairs(prevQAPairs => {
                        const newQAPairs = [...prevQAPairs];

                        // Ensure newQAPairs[0] exists
                        newQAPairs[0] = { question: newQuestion, answer: { text: 'Thinking ...', sources: [], valid: true }, error: false };

                        // Safely assign values to answer
                        newQAPairs[0].question = newQuestion;
                        newQAPairs[0].answer.text = jsonResponse.answer || "No answer provided.";
                        newQAPairs[0].answer.sources = jsonResponse.results || [];

                        return newQAPairs;
                    });
                    return;
                }
                text += new TextDecoder().decode(value);
                await readStream();
            };

            await readStream();

        } catch (error) {
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs];
                newQAPairs[0].question = newQuestion;
                newQAPairs[0].answer['text'] = (error as Error).message;
                newQAPairs[0].answer['sources'] = [];
                newQAPairs[0].error = true;
                return newQAPairs;
            });
        }
    };

    useEffect(() => {
        if (firstQuestionAsked) {
            const updatedConversations = { ...conversations }; // Deep copy

            if (updatedConversations[currentQuestion]) {
                // Existing report: update existing entry
                updatedConversations[currentQuestion] = {
                    ...updatedConversations[currentQuestion],
                    qaPairs: [...qaPairs],
                    domains: [...sessionDomains]
                };
            } else {
                // New report: create a new entry
                updatedConversations[currentQuestion] = {
                    qaPairs: [...qaPairs],
                    domains: [...sessionDomains]
                };
            }

            onConversationUpdate(updatedConversations);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion, qaPairs, firstQuestionAsked]);

    useEffect(() => {
        // console.log('useEffect triggered with currentQuestion:', currentQuestion);
        // console.log(conversations[currentQuestion]);
        setQAPairs(conversations[currentQuestion]?.qaPairs || []);
        if (conversations[currentQuestion]) {
            setSessionDomains(conversations[currentQuestion]?.domains || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion]);

    useEffect(() => {
        if (isNewReport) {
            setQuestion('');
            //setQAPairs([]);
            setFirstQuestionAsked(false);
            setSessionDomains([]);
        }
    }, [isNewReport, setIsNewReport]);

    return (
        <div className={`card-body`} style={{ maxHeight: 'calc(100vh)', flex: 2.5, paddingLeft: '30px' }}>
            <div className='d-flex align-items-center justify-content-start mb-5'>
                <div className='d-flex align-items-center justify-content-end position-relative' style={{ flex: 2 }}>
                    <div className="fs-4 text-gray-900">Domains to Search:</div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter domains separated by commas (www.fda.gov, www.cms.gov, etc.)"
                        value={sessionDomains.join(', ')}
                        onChange={(e) => handleChange(e.target.value)}
                        style={{ width: '81%', marginLeft: '10px' }}
                    />
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <div className='d-flex position-relative' style={{ flex: 1 }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={firstQuestionAsked ? "Please ask a follow-up question" : "Please ask a research question"}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                askQuestion();
                                e.preventDefault(); // Prevent form submission
                            }
                        }}
                        style={{ paddingRight: '30px' }} // Make room for the icon
                    />
                    <button onClick={askQuestion} className='position-absolute end-0 top-50 translate-middle-y' style={{ background: 'none', border: 'none' }}>
                        <KTIcon
                            iconName='arrow-right'
                            iconType="duotone"
                            className='fw-bold fs-1 mx-2'
                        />
                    </button>
                </div>
                {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">Download full chat</Tooltip>}
                >
                    <Button className="btn btn-sm btn-primary d-flex align-items-center justify-content-center mx-4">
                        <i className="bi bi-download fs-1 ms-1"></i>
                    </Button>
                </OverlayTrigger> */}
            </div>
            <div className='mb-5 mt-2' style={{ maxHeight: 'calc(80vh)', overflowY: 'auto' }}>

                {!isNewReport && (
                    qaPairs.map((qaPair, index) => (
                        <div key={index} className='d-flex flex-column mt-3'>
                            <div className='d-flex justify-content-start mt-2 mb-2'>
                                <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-primary '}`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                    {qaPair.answer['text'] !== 'Thinking ...' && (
                                        <div className="d-flex justify-content-end">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}
                                            >
                                                <i
                                                    className="bi bi-download text-primary text-bold fs-1 me-1"
                                                    onClick={() => handleDownload(qaPair.question, qaPair.answer)}
                                                ></i>
                                            </OverlayTrigger>
                                        </div>
                                    )}
                                    <h1 className='text-dark fw-bold mb-5' style={{ fontSize: '1.5rem' }}>{qaPair.question}</h1>
                                    {qaPair.answer['text'] === 'Thinking ...' ?
                                        <>
                                            <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </>
                                        :
                                        <div>
                                            <h3 className='fw-bold mb-3 text-dark'>Answer:</h3>
                                            <ReactMarkdown className="mb-5">{qaPair.answer.text}</ReactMarkdown>
                                            <h3 className='fw-bold mb-3 text-dark'>Sources:</h3>
                                            <ReactMarkdown>
                                                {qaPair.answer.sources.length >= 1 ? qaPair.answer.sources.map(source => `* **${source['title']}**\n\n\t (*${source['url']}*)`).join('\n\n') : 'No sources available'}
                                            </ReactMarkdown>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )))}
            </div>
        </div>
    );
};

export { QAInterface };