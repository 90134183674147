import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import PDFAttachViewer from './PDFAttachViewer';
import DocxViewer from './DOCXAttachViewer';

const DocAttachWrapper: FC = () => {
    const [searchParams] = useSearchParams();
    const documentId = searchParams.get('id');
    const fileformat = searchParams.get('filetype');
    const type = searchParams.get('type');
    return (
        <>
            {fileformat === 'pdf' ? (
                <PDFAttachViewer documentId={documentId} type={type} />
            ) : (
                <DocxViewer documentId={documentId} type={type} />
            )}
        </>
    );
}

export default DocAttachWrapper;
