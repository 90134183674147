import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, User, UserInterests } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserById, LogOut } from './_requests'
import { ID, WithChildren } from '../../../../_metronic/helpers'
import Cookies from 'js-cookie'
// import { useDispatch } from 'react-redux'

type AuthContextProps = {
  auth: AuthModel | undefined | null
  saveAuth: (auth: AuthModel | undefined | null) => void
  currentUser: User | undefined | null
  setCurrentUser: Dispatch<SetStateAction<User | undefined | null>>
  userInterests: UserInterests | undefined | null
  setUserInterests: Dispatch<SetStateAction<UserInterests | undefined | null>>
  logout: () => void
  updateAvatarUrl: (avatarUrl: string) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  userInterests: undefined,
  setUserInterests: () => { },
  logout: () => { },
  updateAvatarUrl: () => { }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {

  const [auth, setAuth] = useState<AuthModel | undefined | null>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<User | undefined | null>()
  const [userInterests, setUserInterests] = useState<UserInterests | undefined | null>()
  // const dispatch = useDispatch();
  const saveAuth = (auth: AuthModel | undefined | null) => {

    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const updateAvatarUrl = (avatarUrl: string) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      avatar: avatarUrl,
    }));
  };

  // const logout = () => {
  //   LogOut()
  //   saveAuth(undefined)
  //   setCurrentUser(undefined)
  // }

  const logout = async () => {
    const response = await LogOut();

    if (response.status === 200) {
      Cookies.remove('selectedDays');
      Cookies.remove('selectedDocType');
      Cookies.remove('itemsPerPage');
      localStorage.removeItem('events');
      localStorage.removeItem('notices');
      localStorage.removeItem('debarment');
      localStorage.removeItem('warning_letters');
      localStorage.removeItem('proposedrules');
      localStorage.removeItem('proposedrulestracked');
      localStorage.removeItem('finalrules');
      localStorage.removeItem('finalrulestracked');
      localStorage.removeItem('guidance');
      localStorage.removeItem('guidancetracked');
      sessionStorage.clear();
      // dispatch({ type: 'LOGOUT' });
      saveAuth(null);
      setCurrentUser(null);
      // setUserInterests(null);


    } else {
      // handle error
      console.error('Logout failed');
    }
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, userInterests, setUserInterests, updateAvatarUrl }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (id: ID) => {
      try {
        if (!didRequest.current) {
          const user = await getUserById(id)
          if (user) {
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.userid) {
      requestUser(auth.userid)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }