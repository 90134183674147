import React from 'react';



const DocumentFilter = ({
    filterName,
    setFilterName,
    filterProgram,
    setFilterProgram,
    // filterStartDate,
    // setFilterStartDate,
    // filterEndDate,
    // setFilterEndDate,
    // filterTracked,
    // setFilterTracked,
    filterAgency,
    setFilterAgency,
    onApplyFilters,
    onResetFilters,


}) => {

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5' style={{ maxHeight: '300px', overflow: 'auto' }}>
                {/* <div className='mb-4'>
                    <label className='form-label fw-bold' for="flexCheckDefault">Tracked:</label>
                    <input
                        className='form-check-input mx-2'
                        type='checkbox'
                        id="filterStar"
                        checked={filterTracked}
                        onChange={(e) => {
                            setFilterTracked(e.target.checked)

                        }}
                    />
                </div> */}
                <div className='mb-4'>
                    <label className='form-label fw-bold'>Name:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Name'
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}

                    />

                </div>

                {/* <div className='mb-4'>
                    <label className='form-label fw-bold'>Docket:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS-2023-****'
                        value={filterDocket}
                        onChange={(e) => setFilterDocket(e.target.value)}

                    />

                </div> */}

                <div className='mb-4'>
                    <label className='form-label fw-bold'>Agency:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CMS, FDA, etc.'
                        value={filterAgency}
                        onChange={(e) => setFilterAgency(e.target.value)}

                    />

                </div>

                <div>
                    <label className='form-label fw-bold'>Program:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='CDER, CDRH, etc.'
                        value={filterProgram}
                        onChange={(e) => setFilterProgram(e.target.value)}

                    />

                </div>

                {/* <div className='mb-4'>
                    <label className='form-label fw-bold'>Document Type:</label>

                    <div>
                        <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >

                            <option value=''>All</option>
                            
                            <option value='Proposed Rule'>Proposed Rule</option>
                            <option value='Rule'>Rule</option>
                            
                        </select>
                    </div>
                </div> */}
                {/* <div className='mb-4'>
                    <label className='form-label fw-bold' htmlFor='dateRange'>
                        Comment Date Range:
                    </label>
                    <input
                        type='date'
                        id='startDate'
                        className='form-control form-control-solid my-2'
                        placeholder='Start Date'
                        value={filterStartDate}
                        onChange={(e) => setFilterStartDate(e.target.value)}
                    />
                    <input
                        type='date'
                        id='endDate'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filterEndDate}
                        onChange={(e) => setFilterEndDate(e.target.value)}
                    />
                </div> */}
            </div>
            <div className='px-7 pt-3 pb-7'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentFilter;
