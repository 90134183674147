import React, { useState, useMemo } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useListView } from "../user-management/users-list/core/ListViewProvider";


const rows: Array<{ title: string, members: number, permissions: string }> = [
    { title: 'Admin', members: 2, permissions: 'Administrator' },
    { title: 'Power User', members: 5, permissions: 'Custom' },
    { title: 'Device', members: 6, permissions: 'Custom'},
    {title: 'Pharma', members: 9, permissions: 'Custom'},
    { title: 'Health', members: 8, permissions: 'Custom' },
    { title: 'Guest', members: 3, permissions: 'Read Only' },
    { title: 'User', members: 20, permissions: 'Custom' },
]
const Group = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const {isAllSelected, onSelectAll} = useListView()
    const {selected, onSelect} = useListView()
    const id = 1
    const isSelected = useMemo(() => selected.includes(id), [id, selected])
    return (
        <div className="card custom">
            <div className='card-header border-0 pt-6'>
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-400px ps-14'
                            placeholder='Search Group'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="d-flex justify-content-end">
                    <button type='button' className='btn btn-primary'>
                        <KTIcon iconName='plus' className='fs-2' />
                        Add Group
                    </button>
                </div>
            </div>
            <div className="card-body mt-2">
                <div className="table-responsive">
                    <table className="table table-borderless align-middle">
                        <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th className='w-10px pe-2'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            data-kt-check={isAllSelected}
                                            data-kt-check-target='#kt_table_users .form-check-input'
                                            checked={isAllSelected}
                                            onChange={onSelectAll}
                                        />
                                    </div>
                                </th>
                                <th className="ps-0 pe-2">Group Name</th>
                                <th className="ps-0 pe-2">Number of Members</th>
                                <th className="ps-0 pe-2">Permissions</th>
                                <th className="ps-0 pe-2">Group Members</th>
                                <th className="ps-0 pe-2">Settings</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                            <tr className="fw-bold">
                                <td>
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        data-kt-check={isSelected}
                                        data-kt-check-target='#kt_table_users .form-check-input'
                                        checked={isSelected}
                                        onChange={() => onSelect(id)}
                                    />
                                </div>
                                </td>
                                <td className="ps-0 pe-2">{row.title}</td>
                                <td className="ps-0 pe-2">{row.members}</td>
                                <td className="ps-0 pe-2">{row.permissions}</td>
                                <td className="ps-0 pe-2">
                                    <a
                                        href='/'
                                        className='btn btn-light btn-active-light-primary btn-sm '
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Actions
                                        <KTIcon iconName='down' className='fs-5 m-0' />
                                    </a>
                                    {/* begin::Menu */}
                                    <div
                                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
                                        data-kt-menu='true'
                                    >
                                        {/* begin::Menu item */}
                                        <div className='menu-item px-3'>
                                            <a  href='/'  onClick={(e) => e.preventDefault()} className='menu-link px-3' >
                                            <KTIcon iconName='plus' className=' fs-2' /> Add Members
                                            </a>
                                        </div>
                                        {/* end::Menu item */}

                                        {/* begin::Menu item */}
                                        <div className='menu-item px-3'>
                                            <a
                                                href='/'
                                                onClick={(e) => e.preventDefault()}
                                                className='menu-link px-3'
                                                data-kt-users-table-filter='delete_row'
                                            >
                                                <KTIcon iconName='trash' className=' fs-2' /> Remove Members
                                            </a>
                                        </div>
                                        {/* end::Menu item */}
                                    </div>
                                </td>
                                <td className="ps-0 pe-2">
                                    <button className='btn btn-active-light-primary btn-sm'>
                                        <KTIcon iconName='pencil' className=' fs-2' /> Edit
                                    </button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Group;