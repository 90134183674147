import React, { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const VideoViewer = () => {
    const [language, setLanguage] = useState('English');
    const videoRef = useRef(null);

    const englishVideoLink = '/videos/training.mp4';
    const italianVideoLink = '/videos/italian_training.mp4';

    // const [videoLink, setVideoLink] = useState('https://player.hourone.ai/d491a88ae47349188ce79e5efa2dd59b');
    //const [videoKey, setVideoKey] = useState(Date.now());
    // const englishVideoLink = 'https://player.hourone.ai/d491a88ae47349188ce79e5efa2dd59b';
    // const germanVideoLink = 'https://player.hourone.ai/f9a508b1661349aa94c25e0d0fe95250';
    // useEffect(() => {
    //     if (language === 'German') {
    //         setVideoLink('https://player.hourone.ai/f9a508b1661349aa94c25e0d0fe95250');
    //     } else {
    //         setVideoLink('https://player.hourone.ai/d491a88ae47349188ce79e5efa2dd59b');
    //     }
    // }, [language]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [language]);

    return (
        <div style={{height: '100vh', overflow: 'hidden', position: 'relative'}}>
            <Dropdown onSelect={(e) => setLanguage(e)} style={{position: 'absolute', top: '10px', right: '10px'}} size="sm">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {language}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item eventKey="English">English</Dropdown.Item>
                    <Dropdown.Item eventKey="German">German</Dropdown.Item>
                    <Dropdown.Item eventKey="Spanish">Spanish</Dropdown.Item> 
                    <Dropdown.Item eventKey="French">French</Dropdown.Item>
                    <Dropdown.Item eventKey="Italian">Italian</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <video ref={videoRef} width='960' height='480' controls>
                <source 
                    src={language === 'Italian' ? italianVideoLink : englishVideoLink}  
                    type="video/mp4" 
                />
            </video>
            {/* <iframe 
                key={videoKey}
                title='video-player'
                src={language === 'German' ? germanVideoLink : englishVideoLink} 
                // src={videoLink} 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
                style={{ width: '100%', height: '100%'}}
            >
            </iframe> */}
        </div>
    );
}
export default VideoViewer;