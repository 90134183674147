import React, { FC } from 'react'
import AllComments from './AllComments'

const CommentsWrapper: FC = () => {
    return (
        <>
            {/* <PageTitle breadcrumbs={[]}>Dockets</PageTitle> */}
            <AllComments/>
        </>
    )
}

export default CommentsWrapper