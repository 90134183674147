import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios'; // Import axios
import { KTIcon } from '../../../_metronic/helpers';
import DocumentFilter from './DocumentFilter';
import { useAuth } from '../../modules/auth';
import SearchBar from './SearchBar';
// import { Link } from 'react-router-dom';
import DocumentTable from './DocumentTable';
import { useNavigate, useLocation } from 'react-router-dom';
import SortFilter from './SortFilter';
// import clsx from 'clsx';
import { DocumentsListPagination } from './DocumentsListPagination';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { MixedWidget10, MixedWidget11 } from '../../../_metronic/partials/widgets';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import Fuse from 'fuse.js';


const ProposedRules = () => {
    // Define your docket details or fetch them from an API

    const [documents, setDocuments] = useState(null);
    const { documentType } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const moment = require('moment-timezone');
    const location = useLocation();
    const stateData = location.state || { agency: '', period_code: 0 };

    const [filteredDocuments, setFilteredDocuments] = useState(null);
    const [filterDocument, setFilterDocument] = useState('');
    const [filterProgram, setFilterProgram] = useState('');
    const [filterStartDate, setFilterStartDate] = useState(stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterTracked, setFilterTracked] = useState(documentType ? true : false);
    const [filterGenAI, setFilterGenAI] = useState(false);
    const [filterAgency, setFilterAgency] = useState(stateData.agency || '');
    const [filterSubtype, setFilterSubtype] = useState('');
    const [searchInput, setSearchInput] = useState(location.state ? location.state.searchInput : '');
    const { currentUser, logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'postedDate', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    const [trackedDocuments, setTrackedDocuments] = useState([]);
    const limit = 18446744073709551615; // Set your desired limit here
    const navigate = useNavigate();
    const interests = useSelector(state => state.account.interests);
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    const [data, setData] = useState([]);
    const [isInsightsVisible, setIsInsightsVisible] = useState(false);
    const isFirstRender = useRef(true);

    const fetchData = useCallback(() => {
        axios.get(`reg/v1/home/insights/documents`)
            .then(response => {
                const sortedData = response.data;
                setData(sortedData);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login')
                }
                console.error('There was an error!', error);
            });

    }, [logout, navigate]);

    useEffect(() => {
        Promise.all([
            fetchData()
        ]).then(() => { }).catch((error) => {
            console.error(error.message);
        });
    }, [fetchData]);


    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    const fetchTrackedDocuments = useCallback(async () => {

        try {
            const response = await axios.get(`reg/v1/document/user/proposedrules/${currentUser?.id}`);
            setTrackedDocuments(response.data);
            return response.data;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            return [];
        }
    }, [currentUser?.id]);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);

    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment(timestamp);

        return date.tz("America/New_York").format('YYYY-MM-DD');
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp);
        return date.tz("America/New_York").format('YYYY-MM-DD');
    }, [moment]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);

        try {
            // Attempt to load cached sortedData from localStorage
            const cachedSortedData = localStorage.getItem('proposedrules');
            let sortedData;

            if (cachedSortedData) {
                sortedData = JSON.parse(cachedSortedData);
            } else {
                const response = await axios.get(`/reg/v1/document/all/proposedrules/${limit}/0`);
                sortedData = [...response.data];
                // Cache the sortedData in localStorage
                try {
                    localStorage.setItem('proposedrules', JSON.stringify(sortedData));
                } catch (e) {
                    if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                        console.error("LocalStorage quota exceeded. Consider optimizing the data or using IndexedDB for larger storage.");
                        // Optionally, implement fallback storage or notify the user
                    } else {
                        console.log(e); // Re-throw the error if it's not a QuotaExceededError
                    }
                }
            }

            let filteredData = documentType ? sortedData.filter(document => document.documentType === 'Proposed Rule') : sortedData;
            if (filterTracked && filteredData.length > 0) {
                const trackedDocuments = await fetchTrackedDocuments();
                const trackedDocumentIds = trackedDocuments
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);
                filteredData = filteredData.filter(document => trackedDocumentIds.includes(document.id));
            }

            const newPrograms = interests.programs.map(program => {
                const match = program ? program.match(/\((.*?)\)/) : undefined;
                return match && match.length > 1 ? match[1] : program;
            });

            const isCMSAgency = interests.agencies.includes('CMS');
            const isFDAAgency = interests.agencies.includes('FDA');

            if (interests.agencies.length > 0 || newPrograms.length > 0) {
                filteredData = filteredData.filter(document => {
                    // For FDA, apply both agency and program filters
                    if (isFDAAgency && document.agencyId === 'FDA') {
                        return newPrograms.length === 0 || newPrograms.includes(document.program);
                    }
                    // For CMS, do not filter by program
                    if (isCMSAgency && document.agencyId === 'CMS') {
                        return true;
                    }
                    // Default filtering for other cases
                    return (interests.agencies.length === 0 || interests.agencies.includes(document.agencyId)) &&
                        (newPrograms.length === 0 || newPrograms.includes(document.program));
                });
            }

            if (filterAgency) {
                filteredData = filteredData.filter((document) =>
                    document.agencyId.toLowerCase().includes(filterAgency.toLowerCase())
                );
            }

            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filteredData = filteredData.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filterGenAI) {
                filteredData = filteredData.filter((document) => document.is_genai_avilable === 1);
            }
            if (filterProgram) {
                filteredData = filteredData.filter((document) => document.program?.toLowerCase().includes(filterProgram.toLowerCase()));
            }

            setDocuments(filteredData);
            setFilteredDocuments(filteredData);
            setError(null);
        } catch (error) {
            setDocuments(null);
            setFilteredDocuments(null);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [documentType, fetchTrackedDocuments, interests, limit, filterTracked, filterStartDate, filterAgency, filterGenAI, filterProgram]); // Ensure 'limit' is included in the dependency array if it's used in the request URL


    const applyFilters = useCallback(() => {
        if (!loading && documents?.length > 0) {

            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterDocument) {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filterDocument.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.program?.toLowerCase().includes(filterProgram.toLowerCase()));
            }

            if (filterSubtype) {
                filtered = filtered.filter((document) => document.subtype.toLowerCase().includes(filterSubtype.toLowerCase()));
            }
            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filterEndDate) {
                const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentEndDate).getTime();
                    return modifyDateTimestamp <= endTimestamp
                });
            }
            if (filterTracked) {
                // fetchTrackedDocuments();

                const trackedDocumentIds = trackedDocuments
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);

                filtered = (filtered || []).filter((document) => {
                    const isTracked = trackedDocumentIds.includes(document.id);
                    // console.log('Document ID:', document.id, 'Is tracked:', isTracked);
                    return isTracked;
                });

            }

            if (filterAgency) {
                filtered = filtered.filter((document) =>
                    document.agencyId.toLowerCase().includes(filterAgency.toLowerCase())
                );
            }
            if (filterGenAI) {
                filtered = filtered.filter((document) => document.is_genai_avilable === 1);
            }
            setFilteredDocuments(filtered);
            //setCurrentPage(1);
            // setTimeout(() => {
            //     setLoading(false);
            // }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterTracked, filterDocument, filterSubtype, filterProgram, filterStartDate, filterEndDate, filterAgency, filterGenAI]);

    const handleApplyFilters = useCallback(() => {
        applyFilters();
        //setCurrentPage(1);
    }, [applyFilters]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchInput.trim() !== '') {
                setLoading(true);
                try {
                    // Make a GET request to fetch documents data if no cache is found
                    const response = await axios.post(`/reg/v1/search/search/proposed-rule?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchInput}`);
                    if (response.data) {
                        setDocuments(response.data);
                        setFilteredDocuments(response.data);
                        setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                    }
                } catch (error) {
                    console.error("Error fetching documents:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setDocuments([]);
                setFilteredDocuments([]);
                fetchDocuments();
                fetchTrackedDocuments();
            }
            handleApplyFilters();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDocuments, fetchTrackedDocuments, interests, searchInput, itemsPerPage, handleApplyFilters, currentPage]);

    // useEffect(() => {
    // if (!loading && documents?.length > 0) {
    //     const delayDebounceFn = setTimeout(() => {
    //         if (searchInput.trim() === '') {
    //             setFilteredDocuments(documents);
    //         } else {

    //             const options = {
    //                 includeScore: true,
    //                 // Add other options based on your requirements
    //                 keys: [{
    //                     name: 'id',
    //                     weight: 0.5,
    //                     getFn: (item, path) => {
    //                         // Normalize by removing hyphens and converting to lowercase for comparison
    //                         const normalizedItemId = item.id.replace(/-/g, '').toLowerCase();
    //                         const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
    //                         if (normalizedItemId.includes(normalizedSearchInput)) {
    //                             return [item.id]; // Return the 'id' in an array if it matches after normalization
    //                         }
    //                         return []; // Return an empty array if not a match
    //                     }
    //                 }, {
    //                     name: 'docketId',
    //                     weight: 0.4,
    //                     getFn: (item, path) => {
    //                         // Normalize by removing hyphens and converting to lowercase for comparison
    //                         const normalizedItemId = item.docketId.replace(/-/g, '').toLowerCase();
    //                         const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
    //                         if (normalizedItemId.includes(normalizedSearchInput)) {
    //                             return [item.docketId]; // Return the 'id' in an array if it matches after normalization
    //                         }
    //                         return []; // Return an empty array if not a match
    //                     }

    //                 }, {
    //                     name: 'document_title',
    //                     weight: 0.4 // Added weight for document_title
    //                 }, {
    //                     name: 'dk_title',
    //                     weight: 0.3 // Added weight for dk_title
    //                 }, 'subtype', 'program', 'dk_Abstract', 'dk_keywords', 'topics', 'cfrPart', 'subject', 'docAbstract',
    //                 {
    //                     name: 'dateFields',
    //                     weight: 0.1,
    //                     getFn: (item, path) => {
    //                         // Assuming formatDate and formatDate2 return dates in 'YYYY-MM-DD' format
    //                         let commentEndDateET = formatDate(item.commentEndDate);
    //                         let commentStartDateET = formatDate(item.commentStartDate);
    //                         let postedDate = formatDate2(item.postedDate);
    //                         let modifiedDate = formatDate2(item.modifyDate);

    //                         // Assuming searchInput is the date in 'YYYY-MM-DD' format
    //                         const normalizedSearchInput = searchInput.trim();

    //                         let matches = [];
    //                         // Check if any of the date fields include the search input and add them to matches array
    //                         if (commentStartDateET?.includes(normalizedSearchInput)) {
    //                             matches.push(commentStartDateET);
    //                         }
    //                         if (commentEndDateET?.includes(normalizedSearchInput)) {
    //                             matches.push(commentEndDateET);
    //                         }
    //                         if (postedDate?.includes(normalizedSearchInput)) {
    //                             matches.push(postedDate);
    //                         }
    //                         if (modifiedDate?.includes(normalizedSearchInput)) {
    //                             matches.push(modifiedDate);
    //                         }

    //                         return matches; // Return an array of matching date strings
    //                     }
    //                 }
    //                 ],
    //                 ignoreLocation: true, // This helps in making the search fuzzier
    //                 threshold: 0.3, // Adjust this threshold to make the search stricter or fuzzier
    //             };

    //             // Create a new instance of Fuse with the documents and options
    //             const fuse = new Fuse(documents, options);

    //             // Perform the search
    //             const result = fuse.search(searchInput.trim());


    //             // Map the result to get the original documents
    //             const filteredDocuments = result.map(resultItem => resultItem.item);



    //             setFilteredDocuments(filteredDocuments);
    //             setCurrentPage(1);
    //             // Server-side filtering
    //             // let agenciesArray = filterAgency ? [filterAgency] : interests.agencies;
    //             // let programsArray = filterProgram ? [filterProgram] : Object.keys(programs);


    //             // fetch(`${process.env.REACT_APP_DEEP_API_URL}/semantic-search`, {
    //             //     method: 'POST',
    //             //     headers: { 'Content-Type': 'application/json' },
    //             //     body: JSON.stringify({
    //             //         query: searchInput.trim(),
    //             //         document_type: "Proposed Rule",
    //             //         agencies: agenciesArray,
    //             //         programs: programsArray
    //             //     })
    //             // })
    //             //     .then(response => response.json())
    //             //     .then(data => {
    //             //         const serverFilteredDocumentIds = Array.isArray(data) ? data : [];

    //             //         const uniqueServerFilteredDocumentIds = serverFilteredDocumentIds.filter(id =>
    //             //             !filteredDocuments.some(fd => fd.id === id)
    //             //         );

    //             //         const serverFilteredDocuments = uniqueServerFilteredDocumentIds.map(id =>
    //             //             documents.find(doc => doc.id === id)
    //             //         ).filter(doc => doc);

    //             //         const combinedDocuments = [...filteredDocuments, ...serverFilteredDocuments];

    //             //         setFilteredDocuments(combinedDocuments);
    //             //         setCurrentPage(1)
    //             //     })
    //             //     .catch(error => {
    //             //         console.error('Error during server-side filtering:', error);

    //             //         setFilteredDocuments(filteredDocuments);
    //             //         setCurrentPage(1)
    //             //     });


    //         }
    //     }, 500)

    //     return () => clearTimeout(delayDebounceFn)
    // }
    // }, [searchInput, documents, loading, formatDate, formatDate2, interests.agencies, programs, filterAgency, filterProgram]);


    const handleResetFilters = () => {
        // Clear filter state variables
        setFilterDocument('');
        setFilterProgram('');
        setFilterStartDate('');
        setFilterEndDate('');
        setFilterAgency('');
        setFilterSubtype('');
        if (documentType) {
            setFilterTracked(true);
        } else {
            setFilterTracked(false);
        }
        setFilterGenAI(false);
        setFilteredDocuments(documents);
        setCurrentPage(1);
    };


    useEffect(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterDocument) {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filterDocument.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.program?.toLowerCase().includes(filterProgram.toLowerCase()));
            }

            if (filterSubtype) {
                filtered = filtered.filter((document) => document.subtype.toLowerCase().includes(filterSubtype.toLowerCase()));
            }
            if (filterStartDate) {
                const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filterEndDate) {
                const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentEndDate).getTime();
                    return modifyDateTimestamp <= endTimestamp
                });
            }
            if (filterTracked) {
                fetchTrackedDocuments();

                const trackedDocumentIds = trackedDocuments
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);

                filtered = (filtered || []).filter((document) => {
                    const isTracked = trackedDocumentIds.includes(document.id);
                    return isTracked;
                });

            }

            if (filterAgency) {
                filtered = filtered.filter((document) =>
                    document.agencyId.toLowerCase().includes(filterAgency.toLowerCase())
                );
            }

            if (filterGenAI) {
                filtered = filtered.filter((document) => document.is_genai_avilable === 1);
            }
            setFilteredDocuments(filtered);
            //setCurrentPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterTracked, filterDocument, filterSubtype, filterProgram, filterStartDate, filterEndDate, filterAgency, filterGenAI, loading]);



    const handleTrackDocument = (id) => {

        axios.post(`/reg/v1/document/track/${currentUser?.id}/${id}`)
            .then((response) => {

                setAlert({ message: response.data.message, type: 'success' });

                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {

                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });


            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    setError(error);
                }

            });

    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            setCurrentPage(1);
        }
    }, [searchInput]);

    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`;
        window.open(url, '_blank');

    };
    const handleViewButtonDOCClick = (documentId) => {
        const url = `/viewtxt?id=${documentId}`;
        window.open(url, '_blank');
    };

    const handleAttachmentClick = (id, filetype, type) => {

        const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
        window.open(url, '_blank');
    };



    const onApplySort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }

        let sortedDocuments = [...filteredDocuments];
        if (sortConfig.direction === 'asc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setFilteredDocuments(sortedDocuments);
    };

    // Define onResetSort function
    const onResetSort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }

        let sortedDocuments = [...filteredDocuments];
        sortedDocuments.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'postedDate', direction: 'desc' });
        setFilteredDocuments(sortedDocuments);

    };
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);
    useEffect(() => {
        if (searchInput.trim() === '') {
            setTotalPages(Math.ceil(filteredDocuments?.length / Math.max(1, itemsPerPage)));
            // if (currentPage > totalPages) {
            //     setCurrentPage(1);
            // }
        }
    }, [filteredDocuments, itemsPerPage, searchInput]);
    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='document' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span> Proposed Rules</span>
                                {loading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center justify-content-between">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    <button type="button" className="btn btn-sm btn-light-primary btn-active-light border border-1" onClick={() => setIsInsightsVisible(!isInsightsVisible)}>
                        <KTIcon iconName='chart-simple' className='fs-2' />
                        {isInsightsVisible ? 'Hide' : 'Show'} Insights
                    </button>
                </div>
                {interests.agencies && programs &&
                    <div className="d-flex flex-row-fluid px-10">
                        {interests.agencies.length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                                {Object.keys(programs).length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {Object.keys(programs).length > 0 && (
                            <div style={{ paddingLeft: interests.agencies.length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-md-block text-wrap mt-1`}>Programs: {' '}
                                    {Object.entries(programs).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                            </div>
                        )}
                    </div>
                }
                <div className="d-flex flex-column px-10 align-items-start">
                    {(filterTracked || filterDocument || filterProgram || filterStartDate || filterEndDate || filterAgency || filterSubtype || filterGenAI) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterTracked && <li><h6 className="d-md-block text-wrap">My Items</h6></li>}
                                {filterGenAI && <li><h6 className="d-md-block text-wrap">GenAI enabled documents</h6></li>}
                                {filterDocument && <li><h6 className="d-md-block text-wrap">Document Title: {filterDocument}</h6></li>}
                                {filterSubtype && <li><h6 className="d-md-block text-wrap">Document Subtype: {filterSubtype}</h6></li>}
                                {filterAgency && <li><h6 className="d-md-block text-wrap">Agency: {filterAgency}</h6></li>}
                                {filterProgram && <li><h6 className="d-md-block text-wrap">Program: {filterProgram}</h6></li>}
                                {(filterStartDate || filterEndDate) && (
                                    <li>
                                        <h6 className="d-md-block text-wrap">
                                            Comment Date {filterStartDate && filterEndDate ? 'Range' : (filterStartDate ? 'Start' : 'End')}: {' '}
                                            {filterStartDate ? filterStartDate : ''}
                                            {filterStartDate && filterEndDate ? ' to ' : ''}
                                            {filterEndDate ? filterEndDate : ''}
                                        </h6>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                {isInsightsVisible && (
                    <div className='row g-5 justify-content-center mt-1 mb-4 px-10'>
                        <div className='col-xxl-4'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="Total Documents By Agency"
                                columns={{
                                    "Total Documents": "doc_total"
                                }}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="FDA Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                }}
                                agency="FDA"
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="CMS Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                }}
                                agency="CMS"
                                activity={"all"}
                            />
                        </div>
                    </div>
                )}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                    <div className="col-7">
                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DocumentFilter
                            filterDocument={filterDocument}
                            setFilterDocument={setFilterDocument}
                            filterProgram={filterProgram}
                            setFilterProgram={setFilterProgram}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterTracked={filterTracked}
                            filterAgency={filterAgency}
                            filterGenAI={filterGenAI}
                            setFilterGenAI={setFilterGenAI}
                            filterSubtype={filterSubtype}
                            setFilterSubtype={setFilterSubtype}
                            setFilterAgency={setFilterAgency}
                            setFilterTracked={setFilterTracked}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                        </button>
                        <SortFilter
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                        <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>


                <DocumentTable loading={loading} error={error} filteredDocuments={filteredDocuments} currentPage={currentPage}
                    DOCUMENTS_PER_PAGE={Math.max(itemsPerPage, 1)} trackedDocuments={trackedDocuments}
                    handleTrackDocument={handleTrackDocument} handleViewButtonPDFClick={handleViewButtonPDFClick}
                    handleViewButtonDOCClick={handleViewButtonDOCClick} handleAttachmentClick={handleAttachmentClick}
                    formatDate={formatDate} formatDate2={formatDate2} searchInput={searchInput} />


                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">

                        <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                            <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                            <input
                                type="number"
                                value={itemsPerPage}
                                onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                className='form-control form-control-solid ms-2 border border-1'
                                style={{ width: '90px', backgroundColor: '#f1faff' }}
                            />
                        </div>
                        <DocumentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }

            </div>

        </div >



    );
};

export default ProposedRules;






