import React, { useState } from "react";
// import Question from "./Question";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface } from "./QAInterface";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const MyResearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [conversations, setConversations] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [isNewReport, setIsNewReport] = useState(true);

    const handleFirstQuestionUpdate = (question) => {
        setQuestions([...questions, question]);
        setCurrentQuestion(question);
        // Update qaPairs with the new data
        setIsLoading(false);
    };

    const handleDownload = (question) => {
        const conversation = conversations[question];
        if (!conversation) {
            console.error('Conversation not found');
            return;
        }

        // Construct the content to be downloaded
        let conversationText = `\n"${question}" Full Conversation Thread:\n\n`;
        conversationText += '================================================================================\n\n';
        conversationText += `Domains: ${conversation.domains.join(', ')}\n\n`;
        conversationText += '================================================================================\n\n';

        conversation.qaPairs.forEach((qaPair, index) => {
            conversationText += `Question ${index + 1}: ${qaPair.question}\n\n`;
            conversationText += `Answer ${index + 1}: ${qaPair.answer.text}\n\n`;
            if (qaPair.answer.sources && Array.isArray(qaPair.answer.sources)) {
                conversationText += 'Sources:\n';
                qaPair.answer.sources.forEach((source, sourceIndex) => {
                    conversationText += `${sourceIndex + 1}. ${source.title || 'Source'} \n(${source.url || 'No URL'})\n`;
                    conversationText += `Relevant Content: "${source.content || 'No content'}"\n\n`;
                });
                if (index < conversation.qaPairs.length - 1) {
                    conversationText += '================================================================================\n\n';
                }
            } else {
                conversationText += 'No sources provided.\n\n';
            }
        });

        const blob = new Blob([conversationText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${question}_fullchat.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };



    const onConversationUpdate = (conversation) => {
        setConversations(JSON.parse(JSON.stringify(conversation)));
    };



    return (
        <div>
            <div className="d-flex flex-column px-5 py-0" >
                <div className="d-flex flex-column-auto mb-2 mt-2 ">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName='search-list' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>My Research</span>
                            {isLoading && (
                                <div className="spinner-border text-primary ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </div>
                    </h1>
                </div>
            </div>

            <div className="container mt-3 p-3 text-wrap mx-auto vh-80 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                <div className={`card-body text-wrap col-6`}
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        whiteSpace: 'pre-wrap',
                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                        maxHeight: 'calc(80vh)',
                        paddingRight: '30px',
                        flex: 1
                    }}
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                >
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary d-flex align-items-center px-5 mb-10"
                            onClick={() => {
                                setIsNewReport(true);
                                setCurrentQuestion('New Chat');
                            }}
                        >
                            <KTIcon iconName='plus' className='fw-bold fs-3 me-1' /> New Report
                        </button>
                    </div>
                    <div className="fs-2 text-gray-900 mb-3">My Past Reports</div>
                    {questions.length > 0 && (
                        questions.slice().reverse().map((question, index) => (
                            <div key={index} onClick={() => setCurrentQuestion(question)}>
                                <div style={{ width: '100%', height: '0.5px', backgroundColor: '#808080', margin: 'auto' }}></div>
                                <div className="d-flex align-items-center justify-content-start fs-4 text-gray-700 my-1 p-4 py-5" style={{ backgroundColor: question === currentQuestion ? '#f0f0f0' : 'transparent' }}>
                                    <div className="d-flex align-items-center" style={{ width: '87%' }}>
                                        <KTIcon iconName='message-question' iconType="duotone" className='fw-bold fs-2 me-3 text-primary' />
                                        <div style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: 'block',
                                        }} title={question}>{question}</div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center" onClick={() => handleDownload(question)}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Download full chat</Tooltip>}>
                                            <i className="bi bi-download text-primary text-bold fs-2" style={{ marginLeft: '1rem' }} ></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        )))
                    }

                </div>
                <QAInterface
                    onFirstQuestionAsked={handleFirstQuestionUpdate}
                    onConversationUpdate={onConversationUpdate}
                    isNewReport={isNewReport}
                    setIsNewReport={setIsNewReport}
                    currentQuestion={currentQuestion}
                    conversations={conversations}
                    className='col-6' />
            </div>
        </div >
    )
}
export default MyResearch;
