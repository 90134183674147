/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import clsx from 'clsx'
// import { register } from '../core/_requests'
//import {getUserByToken, register} from '../core/_requests'
import { Link } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import { FirstTimeInterests } from './FirstTimeInterests'
import ProfilePicture from '../../accounts/ProfilePicture'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { updateAvatar } from '../../../../_metronic/redux/account/actions';
import { connect } from 'react-redux';
import axios from 'axios'

// Define your validation schema
const validationSchema = Yup.object({
  //title: Yup.string().required('Required'),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  //suffix: Yup.string(),
  //email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
});

interface FormValues {
  title: string;
  first_name: string;
  last_name: string;
  suffix: string;
  email: string | undefined;
  phone: string;
}


export function Registration(props) {
  const [loading, setLoading] = useState(false)
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  //const { saveAuth, setCurrentUser } = useAuth()
  const { saveAuth } = useAuth()
  const { currentUser } = useAuth()
  const [image] = useState(toAbsoluteUrl('/media/avatars/blank.png'));
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [formValues, setFormValues] = useState<FormValues | null>(null);

  const formik = useFormik({
    initialValues: {
      title: currentUser?.title || '',
      first_name: currentUser?.first_name || '',
      last_name: currentUser?.last_name || '',
      suffix: currentUser?.suffix || '',
      email: currentUser?.email,
      phone: currentUser?.phone || '',
      avatar: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        // const { data: auth } = await register(
        //   values.title,
        //   values.email,
        //   values.first_name,
        //   values.last_name,
        //   values.suffix,
        // )
        // saveAuth(auth)
        //const {data: user} = await getUserByToken(auth.api_token)
        //setCurrentUser(user)
        values.avatar = currentUser?.avatar || '';

        setFormValues(values);
        // setStatus(`Submitting form...`)
        setLoading(false)
        setIsProfileCreated(true);
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const handleSkipClick = async () => {
    try {
      const values = formik.values;
      await axios.patch(`/reg/v1/user/user/${currentUser?.id}`, {
        "title": values.title,
        "first_name": values.first_name,
        "last_name": values.last_name,
        "suffix": values.suffix,
        "phone": values.phone,
        "is_first_time_login": false,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = () => {
    //const profilePicture = image ? image : selectedAvatar;
    //props.updateAvatar(profilePicture);
  };

  // const handleAvatarClick = (avatar) => {
  //   setSelectedAvatar(avatar);
  // };

  const handleNextClick = () => {
    setSelectedAvatar(currentUser?.avatar || '');
  };

  // const handleAvatarUpdate = (avatarUrl) => {
  //   //props.updateAvatar(avatarUrl);
  //   console.log(avatarUrl);
  // };
  //console.log(currentUser)
  return (
    <div style={{ margin: '0px', width: '100%' }}>
      {isProfileCreated ? (
        <div>
          {/* <FirstTimeInterests onSubmit={handleSubmit} selectedAvatar={selectedAvatar} image={image} formValues={formValues} /> */}
          <FirstTimeInterests onSubmit={handleSubmit} selectedAvatar={selectedAvatar} image={image} formValues={formValues} />
          <div className='row justify-content-center text-center mb-6 col-lg-2 offset-lg-5'>
            <button
              type='button'
              id='kt_sign_up_back'
              className='btn btn-lg btn-light-primary w-75 ms-1 mb-4'
              onClick={() => setIsProfileCreated(false)}
            >
              Back
            </button>
          </div>
        </div>
      ) : (
        <div style={{ margin: '0px' }}>
          <div className='card-header bg-primary text-white text-center d-flex align-items-center justify-content-center py-8 mb-5 p-0' style={{ marginTop: '-10px', width: '100%' }}>
            <h2 className="card-title me-5">CREATE YOUR PROFILE</h2>
          </div>
          <div className='card-body border-top py-9'>
            <div className="mt-4 ms-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ProfilePicture></ProfilePicture>
            </div>
            <form
              className='form fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
              style={{ width: '100%' }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            >
              {/* {formik.status && (
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
                  </div>
                </div>
              </div>
            )} */}

              {/* begin::Form group Firstname */}
              <div className='row mt-7 mb-12' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-lg-4 text-end'>
                  <label className='col-form-label fw-bold fs-3'>Title</label>
                </div>
                <div className='col-lg-2 text-start offset-lg-1'>
                  <div className='fv-row'>
                    <select
                      className='form-select form-select-solid mb-3 mb-lg-0 border border-1'
                      {...formik.getFieldProps('title')}
                      style={{ backgroundColor: '#f2f2f2' }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    >
                      <option value='None'>None</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Miss">Miss</option>
                    </select>
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-lg-4 text-end'>
                  <label className='col-form-label fw-bold fs-3'>
                    <span className='required'>Full Name</span>
                  </label>
                </div>
                <div className='col-lg-4 text-start offset-lg-1'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-1'
                      placeholder='First name'
                      {...formik.getFieldProps('first_name')}
                      style={{ backgroundColor: '#f2f2f2' }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-12' style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='col-lg-1 text-end'>
                  {/* Empty label for alignment */}
                  <label className='col-form-label fw-bold fs-3'>&nbsp;</label>
                </div>
                <div className='col-lg-4 text-start offset-lg-1'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('last_name')}
                      style={{ backgroundColor: '#f2f2f2' }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-12' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-lg-4 text-end'>
                  <label className='col-form-label fw-bold fs-3'>Suffix</label>
                </div>
                <div className='col-lg-2 text-start offset-lg-1'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-1'
                      placeholder='Suffix'
                      {...formik.getFieldProps('suffix')}
                      style={{ backgroundColor: '#f2f2f2' }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.suffix && formik.errors.suffix && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.suffix}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-12' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-lg-4 text-end'>
                  <label className='col-form-label fw-bold fs-3'>Email</label>
                </div>
                <div className='col-lg-4 text-start offset-lg-1'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className='text-muted form-control form-control-lg form-control-solid border border-1'
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      value={currentUser?.email}
                      style={{ backgroundColor: '#f2f2f2' }}
                      disabled={true}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-12' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-lg-4 text-end'>
                  <label className='col-form-label fw-bold fs-3'>
                    <span className='required'>Contact Phone</span>
                  </label>
                </div>
                <div className='col-lg-4 text-start offset-lg-1'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid border border-1'
                    placeholder='Phone number'
                    {...formik.getFieldProps('phone')}
                    style={{ backgroundColor: '#f2f2f2' }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                      }
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Organization</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Organization website'
                {...formik.getFieldProps('org_name')}
                disabled={true}
              />
              {formik.touched.org_name && formik.errors.org_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.org_name}</div>
                </div>
              )}
            </div>
          </div> */}
              {/* begin::Meter */}
              {/* <div
            className='d-flex align-items-center mb-3'
            data-kt-phone-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div> */}
              {/* end::Meter */}
              {/* </div> */}
              {/* <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div> */}
              {/* </div> */}
              {/* end::Form group */}

              {/* begin::Form group */}
              {/* <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div> */}
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='row justify-content-center text-center mb-2'>
                <div className='col-lg-2 mx-auto'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={handleNextClick}
                  >
                    {!loading && <span className='indicator-label'>Next</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/home'>
                    <button
                      type='button'
                      id='kt_login_signup_form_cancel_button'
                      className='btn btn-lg btn-light-primary w-75'
                      onClick={handleSkipClick}
                    >
                      Skip
                    </button>
                  </Link>
                </div>
              </div>
              {/* end::Form group */}
            </form>
          </div>
        </div >
      )
      }
    </div >
  )
}
const mapDispatchToProps = {
  updateAvatar,
};

export const ConnectedRegistration = connect(null, mapDispatchToProps)(Registration);