/* eslint-disable jsx-a11y/anchor-is-valid */
interface DataItem {
  agency: string | null;
  period_code: string | null;
  [key: string]: any; // Add other properties as needed
}


type Props = {
  className: string;
  data?: DataItem[];
}


const ListsWidget26 = ({ className, data }: Props) => {
  const allData = data?.filter(item => item.period_code === 'all') || [];

  const docTotal = allData.reduce((sum, item) => sum + item.doc_total, 0);
  const proposedTotal = allData.reduce((sum, item) => sum + item.proposed_total, 0);
  const ruleTotal = allData.reduce((sum, item) => sum + item.rule_total, 0);
  const noticeTotal = allData.reduce((sum, item) => sum + item.notice_total, 0);
  const supportingTotal = allData.reduce((sum, item) => sum + item.supporting_total, 0);
  const otherTotal = allData.reduce((sum, item) => sum + item.other_total, 0);

  const commentTotal = allData.reduce((sum, item) => sum + item.comment_total, 0);
  const anonTotal = allData.reduce((sum, item) => sum + item.anon_total, 0);
  const orgTotal = allData.reduce((sum, item) => sum + item.org_total, 0);
  const individualTotal = allData.reduce((sum, item) => sum + item.individual_total, 0);

  const rows: Array<{ title: string, data: string[], color: string }> = [
    {
      title: `Document Breakdown (Total: ${docTotal.toLocaleString()})`,
      data: [
        `Proposed Rules: ${proposedTotal.toLocaleString()}`,
        `Rules: ${ruleTotal.toLocaleString()}`,
        `Notices: ${noticeTotal.toLocaleString()}`,
        `Supporting & Related Materials: ${supportingTotal.toLocaleString()}`,
        `Other: ${otherTotal.toLocaleString()}`
      ],
      color: '#009900'
    },
    {
      title: `Comment Breakdown (Total: ${commentTotal.toLocaleString()})`,
      data: [
        `Anonymous: ${anonTotal.toLocaleString()} `,
        `Organization: ${orgTotal.toLocaleString()} `,
        `Individual: ${individualTotal.toLocaleString()}`], color: '#ff8000'
    },
  ]

  return (
    <div className={`${className} d-flex flex-wrap justify-content-center align-items-center`}>
      {rows.map((row, index) => (
        <div
          key={`lw26 - rows - ${index} `}
          className={`card card-flush mb-2 mx-${index === 0 ? '16' : '19'}`}
          style={{
            width: '400px',
            height: '220px',
            backgroundColor: row.color,
          }}
        >
          <div className='card-body'>
            <a href='#' className='text-white fw-semibold me-2' style={{ pointerEvents: 'none', fontSize: '16px' }}>
              {row.title}
            </a>
            {row.data.map((item, i) => (
              <p key={i} className='text-white mt-3' style={{ fontSize: '12px' }}>
                {item}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export { ListsWidget26 }
