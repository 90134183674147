import React from 'react';
import { MixedWidget14, MixedWidget15 } from '../../../_metronic/partials/widgets';

const DocketDetailsCharts = ({ selectedAgency, documents, colors }) => {
    return (
        <div className='row gy-5 g-xl-8'>
            {selectedAgency && documents ? (
                <>
                    <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                        <MixedWidget14
                            className='card-xxl-stretch mb-xl-2'
                            chartColor={colors}
                            agency={selectedAgency.short_name}
                            chartHeight='275px'
                            cardHeight="400px"
                            labels={['Proposed Rule', 'Rule', 'Notice', 'Supporting & Related Material', 'Other']}
                            title="Documents"

                        />
                    </div>
                    <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                        <MixedWidget14
                            className='card-xxl-stretch mb-xl-2'
                            chartColor={colors}
                            agency={selectedAgency.short_name}
                            chartHeight='275px'
                            cardHeight="400px"
                            labels={['Anonymous', 'Organization', 'Individual']}
                            title="Comments"

                        />
                    </div>
                    <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                        <MixedWidget15
                            className='card-xxl-stretch mb-xl-3'
                            chartColor={colors}
                            chartHeight='400px'
                            cardHeight="400px"
                            agency={selectedAgency.short_name}
                            labels={['Proposed Rule', 'Rule', 'Notice', 'Supporting & Related Material', 'Other']}
                            title={`Documents by Type`}

                        />
                    </div>
                    <div className='col-xxl-3' style={{ paddingTop: '15px' }}>
                        <MixedWidget15
                            className='card-xxl-stretch mb-xl-3'
                            chartColor={colors}
                            chartHeight='400px'
                            cardHeight="400px"
                            labels={['Active', 'Inactive']}
                            agency={selectedAgency.short_name}
                            docketsdata={documents}
                            title={`Documents by Activity`}

                        />
                    </div>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>

    );
};
export default DocketDetailsCharts;