
// import AnnouncementDisplay from './AnnouncementDisplay';
// import { Link } from 'react-router-dom';
// import {useAuth} from '../../modules/auth'
// import axios from 'axios';
// import { useEffect,useState } from "react";
import HomeDashboard from './HomeDashboard';
// import { KTIcon } from '../../../_metronic/helpers';



const Home = () => {
    // const [announcements, setAnnouncements] = useState([]);
    // const {currentUser} = useAuth();
    // const userId = currentUser.id;
    // const orgId = currentUser.organization;
    // useEffect(() => {
    //     // console.log(userId);
    //     axios.get(`/reg/v1/user/announcement/${orgId}/${userId}`)
    //         .then(response => {
    //             // console.log(response.data);
    //             setAnnouncements(response.data);
    //         })
    //         .catch(error => {
    //             console.error('There was an error!', error);
    //         });
    // }, [userId]);
    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            {/* <AnnouncementDisplay announcements={announcements} userId={userId}/> */}
            <HomeDashboard />

        </div>

    );
}
export default Home;