import React, { FC, useState, useEffect } from 'react'
import { Field, ErrorMessage, Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'

const Step2: FC<{ email: string, loginEmail: string, prevStep }> = ({ email, loginEmail, prevStep }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const navigate = useNavigate()
  const [timer, setTimer] = useState(5);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const initialValues = { password: '', confirmPassword: '' }
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/,
        'Must contain 8 characters, at least one uppercase letter, one lowercase letter, and one number'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
      .required('Required'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      // console.log("submitting", loginEmail)
      const formData = new FormData();
      formData.append('email', loginEmail);
      formData.append('new_password', values.confirmPassword);

      const response = await axios.post('/changeotp', formData);
      // console.log(response)

      if (response.status === 200) {
        setErrorMessage('');
        setSuccessMessage(`Password changed successfully! You will be redirected to the login screen where you can log in with your new password in ${timer} seconds.`);
        setIsSubmitted(true);
      } else {
        console.error(response.data.message)
        setSuccessMessage('');
        setErrorMessage(response.data.message)

      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error(error.message);
        setSuccessMessage('');
        setErrorMessage(error.response.data);
      } else {
        console.error(error);
      }
    } finally {
      setSubmitting(false)
    }
  }


  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  useEffect(() => {
    if (isSubmitted && timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      setSuccessMessage(`Password changed successfully! You will be redirected to the login screen where you can log in with your new password in ${timer} ${timer === 1 ? 'second' : 'seconds'}...`);
      return () => clearTimeout(countdown);
    } else if (isSubmitted && timer === 0) {
      navigate('/auth/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, isSubmitted]);

  return (
    <>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (<div className="w-100">
          {errorMessage && <div className="alert alert-danger fw-bold mb-10">{errorMessage}</div>}
          {successMessage && <div className="alert alert-success fw-bold mb-10">{successMessage}</div>}

          <Form>
            <div className="mb-10 fv-row">
              <label htmlFor="password" className="form-label mb-3">
                New Password
              </label>
              <div className="input-group mb-3 border-0">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="form-control form-control-lg form-control-solid"
                  required
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-light btn-lg border-0" style={{ borderStartStartRadius: 0 }}
                    onClick={() => togglePasswordVisibility('password')}
                  >
                    <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                  </button>
                </div>
              </div>
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>

            <div className="mb-10 fv-row">
              <label htmlFor="confirmPassword" className="form-label mb-3">
                Confirm Password
              </label>
              <div className="input-group mb-3 border-0">
                <Field
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-control form-control-lg form-control-solid"
                  required

                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-light btn-lg border-0" style={{ borderStartStartRadius: 0 }}
                    onClick={() => togglePasswordVisibility('confirmPassword')}
                  >
                    <i className={`bi ${showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                  </button>
                </div>
              </div>
              <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
            </div>
            <div className="d-flex justify-content-end">
              <div className='mr-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-lg btn-light-primary me-10'
                  data-kt-stepper-action='previous'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>
              <button type="submit" className='btn btn-lg btn-primary me-3'>Submit {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}</button>

            </div>

          </Form>
        </div>
        )}
      </Formik>
    </>
  )
}

export { Step2 }