import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'

import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom'

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUserVerified, setIsUserVerified] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [hasAutoStepped, setHasAutoStepped] = useState(false);
  const [email, setEmail] = useState('');
  const formikRef = useRef<FormikHelpers<any>>(null);
  const location = useLocation();
  const loginEmail = location.state?.email;
  const loginPassword = location.state?.password;
  // console.log("loginEmail", loginEmail)
  const loadStepper = useCallback(() => {
    const newStepper = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    if (!newStepper) {
      return;
    }

    setStepper(newStepper);

    // Move to the next step if loginEmail and loginPassword are provided
    if (loginEmail && loginPassword && !isUserVerified && !hasAutoStepped) {
      newStepper.goNext();
      setHasAutoStepped(true);
    }
  }, [stepperRef, loginEmail, loginPassword, isUserVerified, hasAutoStepped]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  }
  useEffect(() => {
    if (stepper) {
      stepper.totalStepsNumber = 2; // Set the total number of steps to 2
    }
  }, [stepper]);

  const handleUserVerification = (verifiedEmail: string) => {
    setEmail(verifiedEmail);
    setIsUserVerified(true);
    // console.log("verified email", verifiedEmail)
    submitStep();
  }

  const prevStep = () => {
    if (!stepper) {
      return
    }

    stepper.goPrev()



    setSubmitButton(stepper.currentStepIndex === stepper.totalStepsNumber)
  }
  const submitStep = useCallback(() => {
    if (!stepper) {
      return;
    }

    if (stepper.currentStepIndex !== stepper.totalStepsNumber) {
      stepper.goNext();
    } else {
      stepper.goto(1);
    }

    setSubmitButton(stepper.currentStepIndex === stepper.totalStepsNumber);
  }, [stepper]);



  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef, loadStepper])


  useEffect(() => {
    if (loginEmail && loginPassword && stepper && !isUserVerified && !hasAutoStepped) {
      submitStep();
      setHasAutoStepped(true);
    }
  }, [loginEmail, loginPassword, stepper, submitStep, isUserVerified, hasAutoStepped]);

  return (
    <div className='card mt-5'>

      <div className='card-header bg-primary text-white text-center d-flex align-items-center justify-content-center py-3'>
        <h2 className="card-title">LEXIM GPT USER SETUP</h2>
      </div>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Verify Password</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Change Password</h3>
            </div>
          </div>
          <div className='mx-auto mw-600px w-100 pt-15 pb-10'>

            <div className='current' data-kt-stepper-element='content'>
              <Step1 onUserVerified={handleUserVerification} />
            </div>



            <div data-kt-stepper-element='content'>
              <Step2 email={email} loginEmail={loginEmail} prevStep={prevStep} />

              {/* <div>Step 2</div> */}

            </div>
            {/* <div className='d-flex flex-stack pt-15'>
              <div className='mr-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>

              <div>
                {isSubmitButton && (
                  <button type='submit' className='btn btn-lg btn-primary me-3' onClick={handleSubmit} >
                    <span className='indicator-label'>
                      {isSubmitButton && 'Submit'}
                    </span>
                  </button>
                )}
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export { Horizontal }