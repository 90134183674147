/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Organization } from '../../core/_models'

type Props = {
  Organization: Organization
}

const OrganizationLocationCell: FC<Props> = ({ Organization }) => {
  const formatLocation = () => {
    const parts = [Organization.address, Organization.city, Organization.state].map(part => part?.trim());
    const parts2 = [Organization.region, Organization.country, Organization.zip].map(part => part?.trim());
    const filterNonEmpty = (str: string | null | undefined) => str && str !== '';
    return [parts.filter(filterNonEmpty).join(', '), parts2.filter(filterNonEmpty).join(', ')];
  }

  const [location1, location2] = formatLocation();

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{location1}</span>
        <span>{location2}</span>
      </div>
    </div>
  )
}

export { OrganizationLocationCell }