// MyDocumentsTableHeader.tsx
import React from 'react';

const MyDocumentsTableHeader = ({ sortConfig, handleHeaderClick, compareDocuments }) => {
    return (
        <thead className="text-start text-muted fw-bolder fs-7 gs-0">
            <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                <th className="text-start mt-5" style={{ whiteSpace: 'nowrap', width: '15%', marginRight: '3px' }}>
                    <button className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }}
                        onClick={() => {
                            compareDocuments();
                        }}
                    >
                         <i className="bi bi-plus-slash-minus fs-4"></i>
                        Compare
                    </button>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '20%', cursor: 'pointer' }} onClick={() => handleHeaderClick('name')}>
                    File Name
                    <span className={`sorting-icon ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'name' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '50%', cursor: 'pointer' }} onClick={() => handleHeaderClick('description')}>
                    Title
                    <span className={`sorting-icon ${sortConfig.key === 'description' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'description' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'description' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th style={{ whiteSpace: 'nowrap', width: '9%', textAlign: 'right', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                    Upload Date
                    <span className={`sorting-icon ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'asc' : 'desc') : ''}`}>
                        <i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : 'bi-caret-up-fill'}`} style={{ visibility: sortConfig.key === 'create_date' ? 'visible' : 'hidden' }}></i>
                    </span>
                </th>
                <th className="text-end" style={{ whiteSpace: 'nowrap', width: '7%' }}></th>
                <th className="text-end" style={{ whiteSpace: 'nowrap', width: '8%' }}></th>
            </tr>
        </thead>
    );
};

export default MyDocumentsTableHeader;