import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { MutableRefObject, useRef } from 'react'
import { ToggleComponent } from '../../../assets/ts/components'
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {useAuth} from '../../../../app/modules/auth';

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {
  const { config } = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const orgLogo = useSelector((state: any) => state.account.organizationLogo);
  const [logo, setLogo] = useState(orgLogo);

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef]);

  useEffect(() => {
    setLogo(orgLogo);
}, [orgLogo]);

useEffect(() => {
    const fetchLogo = async () => {
      try {
       axios.get(`/reg/v1/admin/organization/logo/${currentUser?.organization}`)
        .then((response) => {
            if (response.status === 200) {
          setLogo(response.data[0].logo);
          // console.log("Logo",logo);
          dispatch({ type: 'UPDATE_ORGANIZATION_LOGO', organizationLogo: response.data[0].logo});
        //   console.log("Org logo", response.data[0].logo);
        }})
      } catch (error) {
        console.error('Failed to fetch logo:', error);
      }
    };
    fetchLogo();
    // console.log("Logo",logo);
},[currentUser?.organization, dispatch]);

  return (
    <div className='app-sidebar-logo px-5' id='kt_app_sidebar_logo' style={{ justifyContent: 'flex-start' }}>
      <Link to='/home'>
        {config.layoutType === 'dark-sidebar' ? (
          <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt='Logo'
            src={logo!=='none' ? logo: toAbsoluteUrl('/media/logos/lexim_logo.svg')}
            className='w-50px app-sidebar-logo-default theme-light-show bg-light mt-2'
          />
            <span className='ms-3 app-sidebar-logo-default text-white text-muted text-hover-white fw-semibold fs-4'>LEXIM REGINTEL<i className="fa-solid fa-trademark text-muted text-hover-white text-white fs-5"></i></span>
          </div>
          {(logo!=='none') && <span className='text-white app-sidebar-logo-default text-muted fw-semibold fs-6'>Powered by Lexim AI</span>}
          </div>  
        ) : (
          <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt='Logo'
                  src={logo!=='none'? logo: toAbsoluteUrl('/media/logos/lexim_logo.svg')}
                  className='h-25px app-sidebar-logo-default theme-light-show bg-light'
                />
                <img
                  alt='Logo'
                  src={logo!=='none'? logo: toAbsoluteUrl('/media/logos/lexim_logo.svg')}
                  className='h-25px app-sidebar-logo-default theme-dark-show bg-light'
                />
                <span className='ms-3 app-sidebar-logo-default text-dark text-muted text-hover-dark fw-semibold fs-4'>LEXIM REGINTEL<i className="fa-solid fa-trademark text-muted text-hover-dark text-dark fs-5"></i></span>
              </div>
          </>
        )}
        
        <img
          alt='Logo'
          src={logo!=='none'? logo: toAbsoluteUrl('/media/logos/lexim_logo.svg')}
          className='h-20px app-sidebar-logo-minimize theme-light-show bg-light'
        />
      </Link>




      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName='black-left-line' className='fs-3 rotate-180 ms-1' />
        </div>
      )}


    </div>
  )
}

export { SidebarLogo }