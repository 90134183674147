/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
// import { useSelector } from 'react-redux';
//import { Dropdown1 } from '../../../../_metronic/partials'
import axios from 'axios';
import { useAuth } from '../../../modules/auth';
import { useSelector, useDispatch } from 'react-redux';
// import { UPDATE_ORGANIZATION_LOGO } from '../../../../_metronic/redux/account/types';

const ProfileHeader2: React.FC = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const orgData = useSelector((state: any) => state.account.organization);
    // const avatarUrl = useSelector((state: any) => {
    //     const url = state.account.avatarUrl;
    //     return url && url !== 'none' ? url : toAbsoluteUrl('/media/svg/avatars/blank.svg');
    // });
    const avatarUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = React.useState(true);
    let orgLogo = useSelector((state: any) => state.account.organizationLogo);
    const [logo, setLogo] = useState(orgLogo!== 'none' ? orgLogo : toAbsoluteUrl('/media/avatars/lexim_logo.png'));

    useEffect(() => {
        setLogo(orgLogo!== 'none' ? orgLogo : toAbsoluteUrl('/media/avatars/lexim_logo.png'));
    }, [orgLogo]);

    useEffect(() => {
        const fetchLogo = async () => {
          try {
           axios.get(`/reg/v1/admin/organization/logo/${currentUser?.organization}`)
            .then((response) => {
                if (response.status === 200) {
            //   setLogo(response.data[0].logo);
              dispatch({ type: 'UPDATE_ORGANIZATION_LOGO', organizationLogo: response.data[0].logo});
            //   console.log("Org logo", response.data[0].logo);
            }})
          } catch (error) {
            console.error('Failed to fetch logo:', error);
          }
        };
        fetchLogo();
    },[currentUser?.organization, dispatch]);
    
    // Fetch organization data from the backend

    useEffect(() => {
        setIsLoading(true);
        if (!orgData || orgData.name === '' || orgData.name === undefined) {
            axios.get(`/reg/v1/admin/organization/${currentUser?.organization}`)
                .then((response) => {
                    const data = response.data[0];
                    dispatch({ type: 'UPDATE_ORGANIZATION', organization: data });
                    setIsLoading(false); // Move this line here
                })
                .catch((error) => {
                    console.error("Error fetching organization data:", error);
                    setIsLoading(false); // And here
                });
        } else {
            setIsLoading(false); // If orgData already exists, set loading to false immediately
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.organization]);

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={logo? logo: avatarUrl} alt='Metornic' />
                            {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className={`text-gray-800 text-hover-primary fw-bolder me-1 ${isLoading ? 'fs-4' : 'fs-2'}`}>
                                        {!isLoading ? orgData.name : 'Organization Details Loading...'}
                                    </a>
                                    {isLoading && (
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="spinner-border ms-1 me-3" role="status" style={{ width: '1.75rem', height: '1.75rem', borderWidth: '0.2em' }}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <a href='#'>
                                            <KTIcon iconName='verify' className='fs-1 text-primary' />
                                        </a>
                                    )}
                                </div>
                                {!isLoading && (
                                    <div>
                                        {
                                            orgData && orgData.industry ? (
                                                <div className='d-flex flex-wrap fw-bold fs-6 mb-1 pe-2'>
                                                    <a
                                                        href='#'
                                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                                    >
                                                        <KTIcon iconName='home' className='fs-4 me-2' />
                                                        Industry: {orgData.industry}
                                                    </a>
                                                </div>
                                            ) : null
                                        }

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-1 pe-2'>
                                            {/* <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                                        Primary User: {' '}
                                        {orgData ? orgData.fullname : ' '}
                                    </a> */}

                                            {
                                                orgData && (orgData.address || orgData.city || orgData.state || orgData.region || orgData.country) && (
                                                    () => {
                                                        const addressParts = [
                                                            orgData.address,
                                                            orgData.city,
                                                            orgData.state,
                                                            orgData.region,
                                                            orgData.country,
                                                        ]
                                                            .map((item) => item && item.trim()) // Remove whitespace from start and end of strings
                                                            .filter((item) => item); // Filter out null, empty, and whitespace-only items

                                                        const address = addressParts.join(', ');

                                                        return (
                                                            <a
                                                                href='#'
                                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                                            >
                                                                <KTIcon iconName='geolocation' className='fs-2 me-1' />
                                                                Address: {address}
                                                            </a>
                                                        );
                                                    }
                                                )()
                                            }
                                            {orgData && orgData.website && (
                                                <a
                                                    href='#'
                                                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                                >
                                                    <KTIcon iconName='sms' className='fs-2 me-2' />
                                                    {orgData.website}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                </div>

                <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/users' && 'active')
                                }
                                to='/admin/users'
                            >
                                Users
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/settings' && 'active')
                                }
                                to='/admin/settings'
                            >
                                Settings
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/preferences' && 'active')
                                }
                                to='/admin/preferences'
                            >
                                Preferences
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/add-ons' && 'active')
                                }
                                to='/admin/add-ons'
                            >
                                Add Ons
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/groups' && 'active')
                                }
                                to='/admin/groups'
                            >
                                Group Management
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/support' && 'active')
                                }
                                to='/admin/support'
                            >
                                Support
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/announcements' && 'active')
                                }
                                to='/admin/announcements'
                            >
                                Announcements
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { ProfileHeader2 }