import React, { useState, useEffect, useRef } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'
import styled from 'styled-components';
const StyledMarkdown = styled(ReactMarkdown)`
&.markdown table {
    width: 100%;
    border-collapse: collapse;
}

&.markdown th, &.markdown td {
    border: 1px solid #ddd;
    padding: 8px;
}

&.markdown th {
    background-color: #f2f2f2;
    text-align: left;
}
`;

const CompareDocumentModal = ({ compareIsOpen, compareOnClose, isCompleted, title1, filename1, title2, filename2, s3_link, setActiveTab }) => {
    const [showModal, setShowModal] = useState(compareIsOpen);
    const closeModal = () => {
        setShowModal(false);
        compareOnClose();
    }
    const [textData, setTextData] = useState('');
    const [loading, setLoading] = useState(false);
    const renderedDataRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                // console.log("is completed", isCompleted);
                if (s3_link) {
                    const textResponse = await axios.get(s3_link);
                    // console.log(s3_link, textResponse);
                    const formattedText = textResponse.data
                    setTextData(formattedText);
                    setLoading(false);
                    // if (textResponse.headers['content-type'] === 'application/json') {
                    //     const formattedText = textResponse.data
                    //     setTextData(formattedText);
                    //     setLoading(false);
                    // }
                    // else {
                    //     setLoading(false);
                    // }
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        }
        fetchData();
    }, [isCompleted, s3_link]);

    function generateTable(data) {
        const lines = data.split('\n');
        let html = '<table style="width: 100%; border-collapse: collapse;">';

        lines.forEach(line => {
            const parts = line.split('  ').filter(part => part.trim() !== '');
            if (parts.length === 2) {
                html += '<tr><td style="border: 1px solid #ddd; padding: 8px; padding-right: 10px;">' + parts[0].trim().replace(/\./g, '') + '</td><td style="border: 1px solid #ddd; padding: 8px;">' + parts[1].trim() + '</td></tr>';
            }
        });

        html += '</table>';
        return html;
    }

    const renderData = (data, level = 0) => {
        return Object.entries(data).map(([key, value], index) => {

            if (typeof value === 'string' && value.includes('..........')) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12} dangerouslySetInnerHTML={{ __html: generateTable(value) }}></Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
            else if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title fs-6  text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>{renderData(value, level + 1)}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold text-uppercase  text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `\u23F5  ${key}` : ' '}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title fs-6 text-justify text-gray-800 ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>

                                    <StyledMarkdown className="markdown" remarkPlugins={[gfm]} >{value.replace(/(\n)(?!\|)/g, '\n\n')}</StyledMarkdown>

                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
        });
    }

    const handleDownload = () => {
        let textString = renderedDataRef.current.innerText;

        // Split the text into lines
        let lines = textString.split('\n');

        // Process each line
        lines = lines.map(line => {
            // Remove ** from the line
            line = line.replace(/\*\*/g, '');

            // Trim the line
            line = line.trim();

            // Return the processed line
            return line;
        });

        // Join the lines back together, adding extra line breaks between them
        textString = lines.join('\n\n');

        // Add some space at the top of the file
        textString = '\n\n\n' + textString;

        const blob = new Blob([textString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${title1} ${title2} summary.txt`;
        link.click();
    };


    // console.log(title1, title2, typeof (filename1), filename2);
    return (
        <>
            <div
                className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
                id='kt_modal_add_user_1'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog mw-750px' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* begin::Modal content */}
                    <div className='modal-content position-relative'>
                        {/* begin::Modal header */}
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className='fw-bolder'>Document Difference</h2>
                                {textData && (
                                    <div className="d-flex mb-4 me-1 ms-3">
                                        <Button className="btn btn-primary" onClick={handleDownload}>
                                            <i className="bi bi-download fs-4"></i>
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-organizations-modal-action='close'
                                onClick={closeModal}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body scroll-y'>
                            <div className='card-body p-4 bg-light-primary border border-primary mb-6'>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-3"><span className='fw-bold'>First document:</span></div>
                                        <div className="col-4"><span className='fw-bold'>File Name:</span> {filename1}</div>
                                        <div className="col-5"><span className='fw-bold'>Title:</span> {title1}</div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-3"><span className='fw-bold'>Second document:</span></div>
                                        <div className="col-4"><span className='fw-bold'>File Name:</span> {filename2}</div>
                                        <div className="col-5"><span className='fw-bold'>Title:</span> {title2}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                !isCompleted && (
                                    <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                                        <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                            <i className="bi bi-info fs-2 me-2"></i>
                                            A comparison request for the two selected documents has been made and the system is working on it.
                                            Please check back later.
                                        </div>
                                        <div>
                                            For more info check compare requests <span className="text-primary" onClick={() => { closeModal(); setActiveTab('compareDocuments') }}>here</span>
                                        </div>
                                    </div>
                                )
                            }

                            {loading && isCompleted ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : textData ? (
                                <Container className="w-100 text-wrap mx-auto vh-100 mb-4 p-4"
                                    style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap' }}>
                                    <div className="mb-4 pt-3 pb-3 ps-1 bg-light-primary fw-bold text-primary fs-4">
                                        DOCUMENT DIFFERENCE
                                    </div>
                                    <div ref={renderedDataRef} className="ps-1">
                                        {renderData(textData)}
                                    </div>
                                </Container>
                            ) : (
                                // <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                                //     <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                                //     <i className="bi bi-info fs-4 me-2"></i>
                                //         A comparison request for the two selected documents has been made and the system is working on it.
                                //         Please check back later.
                                //     </div>
                                //     <div>
                                //         For more info check compare requests
                                //     </div>
                                // </div>
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CompareDocumentModal;