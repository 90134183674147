import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';

type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  data?: DataItem[];
  title: string;
  activity: string;
  agency: string;
  columns: { [key: string]: any; };
}

interface DataItem {
  agency: string | null;
  period_code: string | null;
  [key: string]: any; // Add other properties as needed
}

const MixedWidget10: React.FC<Props> = ({ className, chartHeight, title, chartColor, data = [], agency = "", columns = {}, activity = "" }) => {
  const filteredData = data.filter(item => item.agency === agency).sort((a, b) => a.agency!.localeCompare(b.agency!));
  const navigate = useNavigate();
  const isMonthMissing = filteredData.length === 4;

  const highchartsOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: parseInt(chartHeight),
      marginBottom: Object.keys(columns).length <= 2 ? 100 : 85,
      spacingBottom: 15,
    },
    title: {
      text: '',
    },
    tooltip: {
      valueSuffix: title.toLowerCase().includes("comments") ? ' comments' : ' documents',
    },
    xAxis: {
      categories: isMonthMissing ? ['1 Quarter', '6 Months', '1 Year', 'All'] : ['1 Month', '1 Quarter', '6 Months', '1 Year', 'All']
    },
    yAxis: [{
      type: 'linear',
      title: {
        text: 'Percentage (%)'
      },
    }],
    plotOptions: {
      column: {
        cursor: 'pointer',
        pointPadding: 0.2,
        groupPadding: 0.2,
        borderWidth: 0,
        grouping: false,
        stacking: 'percent',
        minPointLength: 5,
        cropThreshold: 1,
        point: {
          events: {
            click: function () {
              const clickedColumnCategory = this.category;
              const clickedSeriesName = this.series.name;
              let path = '';
              let stateData = {};
              let period = 0;
              switch (clickedColumnCategory) {
                case '1 Month':
                  period = 30;
                  break;
                case '1 Quarter':
                  period = 90;
                  break;
                case '6 Months':
                  period = 180;
                  break;
                case '1 Year':
                  period = 365;
                  break;
                case 'All':
                  period = 0;
                  break;
                default:
                  break;
              }


              switch (clickedSeriesName) {
                case 'Proposed Rules':
                  path = '/proposed-rules';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Final Rules':
                  path = '/final-rules';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Other':
                  path = '/guidances';
                  stateData = { agency: agency, period_code: period };
                  break;
                case 'Notices':
                  path = '/notices';
                  stateData = { agency: agency, period_code: period };
                  break;
                default:
                  break;
              }

              if (path) {
                navigate(path, { state: stateData });
              }
            },
          },
        },
      },
    },
    colors: chartColor,
    series: Object.entries(columns).map(([key, value], index) => ({
      name: key,
      data: filteredData.map(data => data[value]) || 0,
      type: 'column', // Specify the type of series
      zIndex: index
    })),
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '10px',
      },
      itemMarginBottom: 5,
    },
  };

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>

      <div className='card-body d-flex flex-column'>
        <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
      </div>
    </div>
  );
}

export { MixedWidget10 };

