import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import DataOpsToolbar from "./DataOpsToolbar";
import { Link } from "react-router-dom";

interface History {
    id: string;
    sentiment_magnitude: string;
    sentiment: number;
    summary: string;
    commentOnDocumentId: string;
    create_date: string;
}

const SentimentTable = () => {
    const [histories, setHistory] = useState<History[]>([]);
    const [currentHistory, setCurrentHistory] = useState<History[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const date = new Date();
    date.setDate(date.getDate() - 14);
    const twoWeeksAgo = date.toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        startDate: twoWeeksAgo,
        endDate: '',
        minRecords: '',
        maxRecords: '',
        id: ''
    });

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 0);
    }, [currentPage]);

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = histories;

        if (filters.id !== "") {
            filteredData = filteredData.filter(history =>
                history.id.toLowerCase().includes(filters.id.toLowerCase())
            );
        }

        if (filters.minRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.sentiment >= parseFloat(filters.minRecords)
            );
        }

        if (filters.maxRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.sentiment <= parseFloat(filters.maxRecords)
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) <= endDate
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: twoWeeksAgo,
            endDate: '',
            minRecords: '',
            maxRecords: '',
            id: ''
        });
        setCurrentPage(1);
    };

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/reg/v1/superadmin/sentiment/run_history/${filters.startDate}`);
            if (filters.endDate !== "") {
                const endDate = new Date(filters.endDate);
                response.data = response.data.filter(history =>
                    new Date(history.create_date) <= endDate
                );
            }
            if (filters.id !== "") {
                response.data = response.data.filter(history =>
                    history.id.toLowerCase().includes(filters.id.toLowerCase())
                );
            }
            if (filters.minRecords !== "") {
                response.data = response.data.filter(history =>
                    history.sentiment >= parseInt(filters.minRecords)
                );
            }
            if (filters.maxRecords !== "") {
                response.data = response.data.filter(history =>
                    history.sentiment <= parseInt(filters.maxRecords)
                );
            }
            const sortedHistory = response.data.sort((a: History, b: History) => {
                const startDateComparison = new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                if (startDateComparison !== 0) {
                    return startDateComparison;
                } else {
                    return new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                }
            });
            setHistory(sortedHistory);
            setFilteredHistory(sortedHistory);
        } catch (error) {
            console.error('Error fetching histories:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistory();
        // eslint-disable-next-line
    }, [filters.startDate, filters.endDate]);



    useEffect(() => {
        setTotalPages(Math.ceil(filteredHistory.length / itemsPerPage));
    }, [filteredHistory, itemsPerPage]);

    useEffect(() => {
        setCurrentHistory(filteredHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredHistory, currentPage, itemsPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedHistory = [...filteredHistory].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredHistory(sortedHistory);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);

    useEffect(() => {
        let filteredData = histories;

        if (searchInput !== "") {
            filteredData = filteredData.filter(history =>
                history.id.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.sentiment.toString().includes(searchInput.toLowerCase()) ||
                history.sentiment_magnitude.toString().includes(searchInput.toLowerCase()) ||
                history.summary.toLowerCase().includes(searchInput.toLowerCase()) ||
                parseDate(history.create_date).toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        if (filters.id !== "") {
            filteredData = filteredData.filter(history =>
                history.id.toLowerCase().includes(filters.id.toLowerCase())
            );
        }

        if (filters.minRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.sentiment >= parseFloat(filters.minRecords)
            );
        }

        if (filters.maxRecords !== "") {
            filteredData = filteredData.filter(history =>
                history.sentiment <= parseFloat(filters.maxRecords)
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) <= endDate
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Number(year), Number(month) - 1, Number(day)); // Months are 0-based
        const formattedDay = String(date.getDate()).padStart(2, '0');
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const formattedYear = date.getFullYear();
        return `${formattedMonth}-${formattedDay}-${formattedYear}`;
    };

    return (
        <div>
            <div className="d-flex flex-column px-10 align-items-start">
                {(Object.values(filters).some(value => value !== '')) && (
                    <div className="mt-n2 mb-1">
                        <h5>Active Filters:</h5>
                        <ul>
                            {filters.id !== '' && (
                                <li><h6 className="d-md-block text-wrap">Comment ID: {' '}
                                    {filters.id}
                                </h6></li>
                            )}
                            {filters.minRecords !== '' && filters.maxRecords !== '' && (
                                <li><h6 className="d-md-block text-wrap">Sentiment Range: {filters.minRecords} to {filters.maxRecords}</h6></li>
                            )}
                            {filters.minRecords !== '' && filters.maxRecords === '' && (
                                <li><h6 className="d-md-block text-wrap">Min Sentiment Value: {filters.minRecords}</h6></li>
                            )}
                            {filters.minRecords === '' && filters.maxRecords !== '' && (
                                <li><h6 className="d-md-block text-wrap">Max Sentiment Vlaue: {filters.maxRecords}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">Create Date Range: {formatDate(filters.startDate)} to {formatDate(filters.endDate)}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate === '' && (
                                <li><h6 className="d-md-block text-wrap">Start Create Date: {formatDate(filters.startDate)}</h6></li>
                            )}
                            {filters.startDate === '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">End Create Date: {formatDate(filters.endDate)}</h6></li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <DataOpsToolbar
                isLoading={loading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                activeTab="sentiment-analysis"
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
                objType={0}
            />
            {!loading &&
                <div>
                    <div className='table-responsive mb-5'>
                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                            style={tableStyle}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '20%', cursor: 'pointer' }} onClick={() => handleHeaderClick('id')}>
                                        Comment ID
                                        <span className={`sorting-icon ${sortConfig.key === 'id' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'id' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('sentiment')}>
                                        Sentiment
                                        <span className={`sorting-icon ${sortConfig.key === 'sentiment' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'sentiment' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('sentiment_magnitude')}>
                                        Magnitude
                                        <span className={`sorting-icon ${sortConfig.key === 'sentiment_magnitude' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'sentiment_magnitude' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '25%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('summary')}>
                                        Summary Link
                                        <span className={`sorting-icon ${sortConfig.key === 'summary' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'summary' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                                        Create Date
                                        <span className={`sorting-icon ${sortConfig.key === 'create_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody className='text-gray-600 fw-bold'>
                                    <tr>
                                        <td colSpan={5}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Loading...
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className='text-gray-600 fw-bold'>
                                    {currentHistory.length === 0 ? (
                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                            <td colSpan={5} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                        </tr>
                                    ) : (
                                        currentHistory.map((history) => (
                                            <tr key={history.id} className='align-items-center bg-hover-light-primary'>
                                                <td className="py-9 ps-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                                                    {history && (
                                                        <Link to={`/comment/${history.id}`} state={{ documentId: history.commentOnDocumentId }}>
                                                            {history.id}
                                                        </Link>
                                                    )}
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', paddingRight: '20px' }}>{history.sentiment}</td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'center' }}>{history.sentiment_magnitude}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left', paddingRight: '20px' }}>{history.summary}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left', paddingRight: '20px' }}>{parseDate(history.create_date)}</td>
                                            </tr>
                                        )))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {totalPages > 1 &&
                        <div className='card-footer mb-n7'>
                            <div className='row'>
                                <div className='d-flex justify-content-center mt-2'>
                                    <div id='kt_table_users_paginate' style={{ minWidth: '300px' }}>
                                        <ul className='pagination justify-content-center'>
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className='page-link' onClick={prevPage} disabled={currentPage === 1}>
                                                    Previous
                                                </button>
                                            </li>
                                            {Array.from({ length: totalPages }, (_, i) => i + 1)
                                                .slice(
                                                    currentPage <= 3 ? 0 : currentPage - 3,
                                                    currentPage <= 3 ? 5 : currentPage + 2
                                                )
                                                .map(pageNumber => (
                                                    <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                                        <button className='page-link' onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
                                                    </li>
                                                ))}
                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className='page-link' onClick={nextPage} disabled={currentPage === totalPages}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default SentimentTable;
