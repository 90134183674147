import React from 'react';

const DocumentSelector = ({ documents, selected, handleDocumentChange }) => {
    return (
        <div className="d-flex flex-column-auto mx-4">
            <div className="d-flex align-items-center " >
                <span className=" fw-bold fs-6 flex-shrink-0 pe-4 d-none d-md-block " style={{ color: '#3699ff' }}>
                    Documents:
                </span>
                <select
                    className="form-select form-select-sm w-175px form-select-solid"
                    data-control="select2"
                    data-placeholder="Select Document"
                    value={selected.documentIndex || ""}
                    onChange={handleDocumentChange}
                    style={{ backgroundColor: '#f1faff', height: '40px' }}
                >
                    <option value="">Select Document</option>
                    {Array.isArray(documents) && documents.length > 0 ?
                        documents.map((document, index) => (
                            <option key={document.id} value={index}>
                                {document.id}
                            </option>
                        )) : (
                            <option value="" disabled>
                                No Documents Found for Selected Docket
                            </option>
                        )}
                </select>
            </div>
        </div>
    );
};

export default DocumentSelector;