
import {  OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PreferencesComponent = ({ interests, programs, committees }) => {
    const navigate = useNavigate();
    return (
        <div className="accordion mb-2 me-2" id="accordionPreferences">
            <div className="accordion-item ">                <h2 className="accordion-header " id="headingPreferences">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePreferences" aria-expanded="false" aria-controls="collapsePreferences">
                    <div className="d-inline-flex align-items-center">
                        <h3 className="card-title text-gray-700 fs-2 fw-bold ">
                            Your Preferences
                        </h3>
                        <i className="fas fa-solid fa-pencil text-primary fs-3 ps-3 cursor-pointer" onClick={() => navigate('/account/preferences')}></i>
                    </div>
                </button>
            </h2>
                <div id="collapsePreferences" className="accordion-collapse collapse" aria-labelledby="headingPreferences" data-bs-parent="#accordionPreferences">
                    <div className="accordion-body  ">

                        {interests.agencies && programs && (
                            <div className="row">
                                {interests.agencies.length > 0 && (
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-2 text-nowrap">
                                            <h5 className="text-gray-700 fw-bold">Agencies:</h5>
                                        </div>
                                        <div className="col-10">
                                            {interests.agencies.map((agency, index) => (
                                                <span key={index} className="badge badge-light-primary fs-7 ms-1 mb-1">
                                                    {agency}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {Object.keys(programs).length > 0 && (
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-2 text-nowrap">
                                            <h5 className="text-gray-700 fw-bold">Programs:</h5>
                                        </div>
                                        <div className="col-10">
                                            {Object.entries(programs).map(([key, value], index) => (
                                                <OverlayTrigger
                                                    key={index}
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-${index}`}>
                                                            {value}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="badge badge-light-primary ms-1 fs-7 me-1 mb-1">
                                                        {key}
                                                    </span>
                                                </OverlayTrigger>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {committees && interests.device_classes && (
                            <div className="row">
                                {Object.keys(committees).length > 0 && (
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-2 text-nowrap">
                                            <h5 className="text-gray-700 fw-bold">Specialties:</h5>
                                        </div>
                                        <div className="col-9">
                                            {Object.entries(committees).map(([key, value], index) => (
                                                <span key={index} className="badge badge-light-primary fs-7 ms-1 mb-1">
                                                    {key}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {interests.device_classes.length > 0 && (
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-2 text-nowrap">
                                            <h5 className="text-gray-700 fw-bold">Device Classes:</h5>
                                        </div>
                                        <div className="col-10">
                                            {interests.device_classes.map((deviceClass, index) => (
                                                <OverlayTrigger
                                                    key={index}
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-deviceClass-${index}`}>
                                                            {deviceClass}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="badge badge-light-primary ms-1 fs-7 me-1 mb-1">
                                                        {deviceClass}
                                                    </span>
                                                </OverlayTrigger>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PreferencesComponent;