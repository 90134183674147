import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';

const SearchBar = ({ searchInput, setSearchInput, handleSearch }) => {
    // const handleKeyPress = (event) => {
    //     if (event.key !== 'Enter') {
    //         event.preventDefault();
    //     }
    // };
    return (
        <div className="d-flex flex-column-auto align-items-center">
            <div className="d-flex align-items-center position-relative my-1 ms-5 me-15">
                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                <form onSubmit={handleSearch}>
                    <input
                        type="search"
                        id="form1"
                        className="form-control form-control-solid w-500px ps-14"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onKeyDown={handleSearch}
                        // onKeyPress={handleKeyPress}
                        maxLength={300}
                        style={{ backgroundColor: '#f1faff', border: '1px solid #e0e0e0' }}
                    />
                    <button type="submit" style={{ display: 'none' }}>Search</button>
                </form>
            </div>
        </div>
    );
};

export default SearchBar;