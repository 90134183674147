import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

export function Documents({ documents, handleViewButtonPDFClick, handleViewButtonDOCClick, handleStarDocument, formatDate, formatDate2, handleAttachmentClick }) {
    // console.log(documents);
    return (

        <div className="d-flex flex-column flex-row-fluid mt-2 ">
            <table className="table">
                <tbody>
                    {documents.length === 0 ? (
                        <tr>
                            <td>
                                <p>No documents available for this docket.</p>
                            </td>
                        </tr>
                    ) : (
                        documents.map((document, index) => (
                            <tr key={index}>

                                <td colSpan="6">
                                    <div className="card card-custom">
                                        <div className="card-header bg-light bg-gradient pe-2">
                                            <h5 className="card-title d-flex align-items-center">
                                                <i className="bi bi-file-earmark-text me-3 fs-1"></i>
                                                Document ID: {'  '}
                                                <Link to={`/document/${document.id}`} style={{ marginLeft: '5px' }}>
                                                    {document.id}
                                                </Link>
                                            </h5>

                                            <div className="card-title m-6">


                                                <div className="d-flex justify-content-end align-items-center ">

                                                    <div className="d-flex align-items-center">
                                                        {/* {document.is_starred === null || document.is_starred === 0 ? (
                                                            <button
                                                                className="btn me-6 h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                                style={{ marginRight: 0, paddingRight: 0 }}
                                                                onClick={() => handleStarDocument(document.iddocument)}
                                                            >
                                                                <i className="bi bi-star fs-4"></i>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn me-6 h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                                style={{ marginRight: 0, paddingRight: 0 }}
                                                                onClick={() => handleStarDocument(document.iddocument)}
                                                            >
                                                                <i className="bi bi-star-fill fs-4"></i>
                                                            </button>
                                                        )} */}
                                                        <a href={document.fileUrl1} className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center" style={{ color: '#3699ff', paddingLeft: 0 }}>
                                                            Download
                                                        </a>
                                                    </div>
                                                    {/* Download button */}
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" size="sm" >
                                                            View
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {document.s3_pdf_link ? (
                                                                <Dropdown.Item onClick={() => handleViewButtonPDFClick(document.iddocument)}>
                                                                    PDF
                                                                </Dropdown.Item>
                                                            ) : null}
                                                            {document.s3_text_link ? (
                                                                <Dropdown.Item onClick={() => handleViewButtonDOCClick(document.iddocument)}>
                                                                    TXT
                                                                </Dropdown.Item>
                                                            ) : null}
                                                            {!document.s3_pdf_link && !document.s3_text_link ? (
                                                                <Dropdown.Item disabled>
                                                                    No links available
                                                                </Dropdown.Item>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                    <div className='me-4 mr-4'></div>

                                                    {/* Export button */}
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" id="exportDropdown" size="sm" >
                                                            Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item>
                                                                Export to Google Docs
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                Export to MS Word
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                Export to Slack
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                Export to Teams
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                {/* Export button */}

                                            </div>

                                        </div>
                                        <div className="card-body card-scroll  ">
                                            {/* Document content */}
                                            <div className="mb-3">
                                                <div className="flex ">
                                                    <span className="fw-bold">Title:</span>
                                                    <span className="fw-normal mx-2">{document.document_title}</span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="flex">
                                                    <span className="fw-bold">Document Type:</span>
                                                    <span className="fw-normal mx-2">{document.documentType}</span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="flex">
                                                    <span className="fw-bold">Page Count:</span>
                                                    <span className="fw-normal mx-2">{document.page_count}</span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="flex">
                                                    <span className="fw-bold">Number of Comments:</span>
                                                    <span className="fw-normal mx-2">{document.comm_count}</span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="flex">
                                                    <span className="fw-bold">Comment Start Date:</span>
                                                    <span className="fw-normal mx-2">{formatDate(document.commentStartDate)} {''}</span>
                                                    <span className="fw-bold">Comment End Date:</span>
                                                    <span className="fw-normal mx-2 ">{formatDate(document.commentEndDate)}</span>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <div className="flex ">
                                                    <span className="fw-bold">Posted Date:</span>
                                                    <span className="fw-normal mx-2">{formatDate2(document.postedDate)}</span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="flex">
                                                    <span className="fw-bold">Supporting materials:</span>
                                                    {/* <span className="fw-normal mx-2">{document.attachments}</span> */}
                                                    {document.attachments && (
                                                        <table>
                                                            <tbody>
                                                                {document.attachments.map((attachment, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {attachment.attachment_title ? (
                                                                                <span
                                                                                    onClick={() => handleAttachmentClick(attachment.idattach, attachment.fileFormat, "document")}
                                                                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                                                >
                                                                                    {attachment.attachment_title}
                                                                                </span>
                                                                            ) : (
                                                                                <span>None</span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

        </div>
    );
}