import { useState } from "react";
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { KTIcon } from "../../../_metronic/helpers";
import { useAuth } from '../../modules/auth'
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'

const AnnouncementDisplay = (
    // {announcements,userId}
) => {
    const [announcements, setAnnouncements] = useState([]);

    const { currentUser, logout } = useAuth();
    const orgId = currentUser.organization;
    const navigate = useNavigate();
    const userId = currentUser ? currentUser?.id : null;
    useEffect(() => {
        axios.get(`/reg/v1/user/announcement/${orgId}/${userId}`)
            .then(response => {
                const sortedData = response.data.sort((a, b) => {
                    const startDateComparison = new Date(b.start_date) - new Date(a.start_date);
                    if (startDateComparison !== 0) {
                        return startDateComparison;
                    } else {
                        return new Date(b.end_date) - new Date(a.end_date);
                    }
                });
                setAnnouncements(sortedData);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login')
                }
                console.error('There was an error!', error);
            });
    }, [userId, navigate, logout, orgId]);


    const [dismissed, setDismissed] = useState({});
    const handleDismiss = (index) => {
        setDismissed(prevState => ({ ...prevState, [index]: true }));
        const announcement_id = announcements[index].id;
        axios.post(`/reg/v1/user/announcement/${orgId}/${userId}`, { announcement_id })
            .then(response => {
                // console.log(response.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login')
                }
                console.error('There was an error!', error);
            });
    }

    return (
        <div>
            {announcements.length > 0 ? (
                announcements.map((announcement, index) => {
                    if (dismissed[index]) {
                        return null;
                    }
                    let variant;
                    switch (announcement.priority) {
                        case 1:
                            variant = 'success';
                            break;
                        case 2:
                            variant = 'info';
                            break;
                        case 3:
                            variant = 'danger';
                            break;
                        default:
                            variant = 'primary';
                    }

                    return (
                        <Alert key={index} variant={variant} dismissible onClose={() => handleDismiss(index)}>
                            <Alert.Heading><KTIcon iconName="information-5" className="fs-2 text-dark" /> New Announcement: {announcement.title}</Alert.Heading>
                            <hr />
                            <p>{announcement.content}</p>
                        </Alert>
                    );
                })
            ) : ('')}
        </div>
    );
}
export default AnnouncementDisplay;
