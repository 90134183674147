import React from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import RegulatoryFilter from './RegulatoryFilter';
import FDAFilter from './FDAFilter';
import ElasticSearchFilter from './ElasticSearchFilter';
import RegDetailsFilter from './RegDetailsFilter';
import GenAIFilter from './GenAIFilter';
import SentimentFilter from './SentimentFilter';
import SearchBar from './SearchBar';

const DataOpsToolbar = ({
    isLoading,
    searchInput,
    setSearchInput,
    filters,
    activeTab,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters,
    objType
}) => {
    // console.log(activeTab)
    return (
        <div className="d-flex flex-wrap gap-4 flex-row-fluid px-5 mb-7 align-items-center">
            <div className="col-7">
                <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
            </div>
            <div className="d-flex flex-column-auto">
                {activeTab === "reg-details" ?
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"


                    >
                        <KTIcon iconName='filter' className='fs-2' /> Filter
                    </button>
                    :
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'


                    >
                        <KTIcon iconName='filter' className='fs-2' /> Filter
                    </button>
                }
                {activeTab === 'reg-details' && <RegDetailsFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                    objType={objType}
                />}
                {activeTab === 'regulatory' && <RegulatoryFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters} />}
                {activeTab === 'fda' && <FDAFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters} />}
                {activeTab === 'elasticsearch' && <ElasticSearchFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters} />}
                {activeTab === 'gen-ai' && <GenAIFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters} />}
                {activeTab === 'sentiment-analysis' && <SentimentFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters} />}

            </div>
            {isLoading ?
                <div className="spinner-border spinner-border-lg text-primary ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                : null
            }
        </div>
    );
};

export default DataOpsToolbar;