import React, { FC } from 'react'
import ProposedRules from './ProposedRules'

const ProposedRulesWrapper: FC = () => {
    return (
        <>

            <ProposedRules />
        </>
    )
}

export default ProposedRulesWrapper
