import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';

const SearchBar = ({ searchInput, setSearchInput }) => {
    return (<div className=" flex-column-auto  align-items-center  ">
        <div className="d-flex align-items-center position-relative my-1">
            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
            <input
                type="search"
                id="form1"
                className="form-control form-control-solid w-100 ps-14 border border-1"
                placeholder="Search My Collection"
                aria-label="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                maxLength={200}
                style={{ backgroundColor: '#f1faff' }}
            />
        </div>
    </div >
    );
};

export default SearchBar;