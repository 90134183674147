import React, { useState, useEffect, useContext } from 'react'
import { IMyInterests } from '../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import { useSelector } from 'react-redux'
import axios from 'axios';
import Select from 'react-select'
import { useAuth } from '../core/Auth';
import { ProfileUpdateContext } from '../../../pages/Admin/Components/ProfileUpdateContext';
import { useNavigate } from 'react-router-dom';
import { UPDATE_INTERESTS } from '../../../../_metronic/redux/account/types';
import { useDispatch } from 'react-redux';

const myInterestsSchema = Yup.object().shape({
    regions: Yup.array().min(1, 'Please select at least one region.'),
    agencies: Yup.array().min(1, 'Please select at least one agency.'),
    programs: Yup.array().min(1, 'Please select at least one program.'),
    device_classes: Yup.array().min(1, 'Please select at least one device class.'),
    committees: Yup.array().min(1, 'Please select at least one committee.')
})

type OptionType = {
    value: string;
    label: string;
    id: number;
    isDisabled: boolean;
    focus_area: number;
};


export function FirstTimeInterests(props) {

    const [loading, setLoading] = useState(false);
    const [agenciesOptions, setAgenciesOptions] = useState<OptionType[]>([]);
    const [programsOptions, setProgramsOptions] = useState<OptionType[]>([]);
    const [committeeOptions, setCommitteeOptions] = useState<OptionType[]>([]);
    const [regionsOptions, setRegionsOptions] = useState<OptionType[]>([]);
    // const avatarUrl = useSelector((state: any) => state.account.avatarUrl);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { setIsProfileUpdated } = useContext(ProfileUpdateContext);

    const formik = useFormik<IMyInterests>({
        initialValues: {
            regions: [],
            agencies: [],
            programs: [],
            device_classes: [] as number[],
            committees: []
        },
        validationSchema: myInterestsSchema,
        onSubmit: async (values, { setStatus }) => {
            setLoading(true);
            try {
                const data = {
                    preferences: [
                        ...values.regions.flatMap(region => {
                            const selectedRegion = regionsOptions.find(option => option.label === region);
                            return selectedRegion ? [[1, selectedRegion.id]] : [];
                        }),
                        ...values.agencies.flatMap(agency => {
                            const selectedAgency = agenciesOptions.find(option => option.label === agency);
                            return selectedAgency ? [[2, selectedAgency.id]] : [];
                        }),
                        ...values.programs.flatMap(program => {
                            const selectedProgram = programsOptions.find(option => option.label === program);
                            return selectedProgram ? [[3, selectedProgram.id]] : [];
                        }),
                        ...values.device_classes.map(option => [4, option]),
                        ...values.committees.flatMap(committee => {
                            const selectedCommittee = committeeOptions.find(option => option.label === committee);
                            return selectedCommittee ? [[5, selectedCommittee.id]] : [];
                        })
                    ]
                };
                const jsonData = JSON.stringify(data);
                const response = await axios.post(`/reg/v1/user/preference/${currentUser?.id}`, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200 || response.status === 201) {
                    setIsProfileUpdated(true);
                } else {
                    console.error('Update failed:', response.data);
                }

            } catch (error) {
                console.error('Update failed:', error);
            } finally {
                // console.log(values)
                dispatch({ type: UPDATE_INTERESTS, interests: values });
                setLoading(false);
                setStatus(`Submitting form...`)
                navigate('/home');
            }

            try {
                await axios.patch(`/reg/v1/user/user/${currentUser?.id}`, {
                    "title": props.formValues.title,
                    "first_name": props.formValues.first_name,
                    "last_name": props.formValues.last_name,
                    "suffix": props.formValues.suffix,
                    "phone": props.formValues.phone,
                    "is_first_time_login": false,
                    "columns": {
                        "title": props.formValues.title,
                        "first_name": props.formValues.first_name,
                        "last_name": props.formValues.last_name,
                        "suffix": props.formValues.suffix,
                        "phone": props.formValues.phone,
                        "is_first_time_login": false,
                    }
                });

            } catch (error) {
                console.error(error);
            }
        },
    });

    const fetchInitialValues = async () => {
        axios.get(`/reg/v1/admin/org/preference/${currentUser?.organization}`)
            .then(response => {
                const focusAreas = response.data;
                // const focusAreaPairs = focusAreas.map(({ preference_type, preference_id }) => [preference_type, preference_id]);
                //console.log('focusAreas:', focusAreaPairs);

                const selectedRegions: string[] = [];
                const selectedAgencies: string[] = [];
                const selectedPrograms: string[] = [];
                const selectedCommittees: string[] = [];
                const selectedClasses: number[] = [];

                focusAreas.forEach(({ preference_type, preference_text }) => {
                    switch (preference_type) {
                        case 1: // Regions
                            selectedRegions.push(preference_text);
                            break;
                        case 2: // Agencies
                            selectedAgencies.push(preference_text);
                            break;
                        case 3: // Programs
                            selectedPrograms.push(preference_text);
                            break;
                        case 4:
                            selectedClasses.push(parseInt(preference_text, 10));
                            break;
                        case 5:
                            selectedCommittees.push(preference_text);
                            break;
                        default:
                            break;
                    }
                });

                const initialValues = {
                    regions: selectedRegions,
                    agencies: selectedAgencies,
                    programs: selectedPrograms,
                    device_classes: selectedClasses,
                    committees: selectedCommittees
                };

                formik.setValues(initialValues);
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false); // Set loading to false on error
            });
    }

    useEffect(() => {
        const fetchRegions = axios.get('/reg/v1/user/regions')
            .then(response => {
                const options = response.data.map(region => ({
                    value: region.region_code,
                    label: region.region_code,
                    id: region.region_id, // Save the id
                    isDisabled: region.is_active === 0,
                    preference_type: 1 // Set focus_area to 2 for agencies
                }));
                options.sort((a, b) => a.isDisabled - b.isDisabled);
                return options;
            });
        const fetchAgencies = axios.get('/reg/v1/user/agencies')
            .then(response => {
                const options = response.data.map(agency => ({
                    value: agency.short_name,
                    label: agency.short_name,
                    id: agency.id, // Save the id
                    isDisabled: agency.is_active === 0,
                    focus_area: 2 // Set focus_area to 2 for agencies
                }));
                options.sort((a, b) => a.isDisabled - b.isDisabled);
                return options;
            });

        const fetchPrograms = axios.get('/reg/v1/user/fdaprograms')
            .then(response => {
                const options = response.data.map(program => ({
                    value: program.code,
                    label: `${program.program} (${program.code})`,
                    id: program.program_id, // Save the id
                    isDisabled: program.is_active === 0,
                    focus_area: 3 // Set focus_area to 3 for programs
                }));
                options.sort((a, b) => a.isDisabled - b.isDisabled);
                return options;
            });

        const fetchCommittees = axios.get('/reg/v1/fda/advisory_committee')
            .then(response => {
                const options = response.data.map(committee => ({
                    value: committee.code,
                    label: `${committee.description} (${committee.code})`,
                    id: committee.id, // Save the id
                    isDisabled: 0,
                    focus_area: 5
                }));
                options.sort((a, b) => a.isDisabled - b.isDisabled);
                return options;
            });

        fetchInitialValues();
        Promise.all([fetchAgencies, fetchPrograms, fetchCommittees, fetchRegions])
            .then(([agenciesOptions, programsOptions, committeeOptions, regionsOptions]) => {
                setAgenciesOptions(agenciesOptions);
                setProgramsOptions(programsOptions);
                setCommitteeOptions(committeeOptions);
                setRegionsOptions(regionsOptions);

                // Fetch the initial values when the component mounts
                if (!currentUser?.organization) {
                    console.error('currentUser or currentUser.organization is undefined');
                    return;
                }
            });
    }, [currentUser?.organization]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className='card-header bg-primary text-white text-center align-items-center justify-content-center py-8 mb-5' style={{ marginTop: '-10px', width: '100%' }}>
                <div>
                    <h2 className="card-title mb-2">SELECT YOUR INTERESTS</h2>
                    <h4 className="card-title">Choose your preferences from the categories below</h4>
                </div>
            </div>

            {formik.status && (
                <div className='row justify-content-center'>
                    <div className='col-lg-8'>
                        <div className='mb-lg-5 alert alert-danger'>
                            <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
                        </div>
                    </div>
                </div>
            )}
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mt-2 mb-10' style={{ display: 'flex' }}>
                            <div className='col-lg-4 text-end'>
                                <label className='required col-form-label fw-bold fs-3 mt-3'>Regions</label>
                            </div>
                            <div className='col-lg-5 text-start'>
                                <div className='fv-row'>
                                    <Select
                                        isMulti
                                        className='form-control form-control-lg form-control-solid fw-bold'
                                        placeholder='Select Regions'
                                        options={regionsOptions.filter(option => !formik.values.regions.includes(option.value))}
                                        value={formik.values.regions ? formik.values.regions.map(region => ({ value: region, label: region })) : []}
                                        onChange={selectedOptions => {
                                            const selectedRegionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                            formik.setFieldValue('regions', selectedRegionIds);
                                        }}
                                        isDisabled={false}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menuList: base => ({ ...base, maxHeight: 175, overflowY: 'scroll' })
                                        }}
                                        menuPortalTarget={document.body}
                                    />

                                    <div className='fv-plugins-message-container ms-10 mt-n1'>
                                        {formik.touched.regions && formik.errors.regions && (
                                            <div className='fv-help-block'>{formik.errors.regions}</div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='row mt-5 mb-10' style={{ display: 'flex' }}>
                            <div className='col-lg-4 text-end'>
                                <label className='required col-form-label fw-bold mt-3 fs-3'>Agencies</label>
                            </div>
                            <div className='col-lg-5 text-start'>
                                <div className='fv-row'>
                                    <Select
                                        isMulti
                                        className='form-control form-control-lg form-control-solid fw-bold'
                                        placeholder='Agencies of Interest'
                                        options={agenciesOptions.filter(option => !formik.values.agencies.includes(option.label))}
                                        value={formik.values.agencies ? formik.values.agencies.map(agency => ({ value: agency, label: agency })) : []}
                                        onChange={selectedOptions => {
                                            const selectedAgencyLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                                            formik.setFieldValue('agencies', selectedAgencyLabels);
                                        }}
                                        isDisabled={false}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menuList: base => ({ ...base, maxHeight: 175, overflowY: 'scroll' })
                                        }}
                                        menuPortalTarget={document.body}
                                    // styles={{ control: (base) => ({ ...base, backgroundColor: '#ececec' }) }}
                                    />

                                    <div className='fv-plugins-message-container ms-10 mt-n1'>
                                        {formik.touched.agencies && formik.errors.agencies && (
                                            <div className='fv-help-block'>{formik.errors.agencies}</div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 mb-10' style={{ display: 'flex' }}>
                            <div className='col-lg-4 text-end'>
                                <label className='required col-form-label fw-bold mt-3 fs-3'>Programs</label>
                            </div>
                            <div className='col-lg-5 text-start'>
                                <div className='fv-row'>
                                    <Select
                                        isMulti
                                        className='form-control form-control-lg form-control-solid fw-bold'
                                        placeholder='Programs of Interest'
                                        options={programsOptions.filter(option => !formik.values.programs.includes(option.label))}
                                        value={formik.values.programs ? formik.values.programs.map(program => ({ value: program, label: program })) : []}
                                        onChange={selectedOptions => {
                                            const selectedProgramLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                                            formik.setFieldValue('programs', selectedProgramLabels);
                                        }}
                                        isDisabled={false}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menuList: base => ({ ...base, maxHeight: 175, overflowY: 'scroll' })
                                        }}
                                        menuPortalTarget={document.body}
                                    />

                                    <div className='fv-plugins-message-container ms-10 mt-n1'>
                                        {formik.touched.programs && formik.errors.programs && (
                                            <div className='fv-help-block'>{formik.errors.programs}</div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='row mt-5 mb-10' style={{ display: 'flex' }}>
                            <div className='col-lg-4 text-end'>
                                <label className='required col-form-label fw-bold mt-3 fs-3'>Specialties</label>
                            </div>

                            <div className='col-lg-5 text-start'>
                                <div className='fv-row'>
                                    <Select
                                        isMulti
                                        className='form-control form-control-lg form-control-solid fw-bold'
                                        placeholder='Committees of Interest'
                                        options={committeeOptions.filter(option => !formik.values.committees.includes(option.label))}
                                        value={formik.values.committees ? formik.values.committees.map(committee => ({ value: committee, label: committee })) : []}
                                        onChange={selectedOptions => {
                                            const selectedCommitteeLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
                                            formik.setFieldValue('committees', selectedCommitteeLabels);
                                        }}
                                        isDisabled={false}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menuList: base => ({ ...base, maxHeight: 150, overflowY: 'scroll' })
                                        }}
                                        menuPortalTarget={document.body}
                                    />

                                    <div className='fv-plugins-message-container ms-10 mt-n1'>
                                        {formik.touched.committees && formik.errors.committees && (
                                            <div className='fv-help-block'>{formik.errors.committees}</div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='row mt-5' style={{ display: 'flex' }}>
                            <div className='col-lg-4 text-end'>
                                <label className='required col-form-label fw-bold mt-n1 fs-3'>Device Classes</label>
                            </div>


                            <div className='col-lg-6 text-start mt-2'>
                                <div className='row ms-4'>
                                    <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className='form-check-input me-2 mb-2'
                                            type='checkbox'
                                            id='class1'
                                            name='device_classes'
                                            value={1}
                                            checked={formik.values.device_classes.includes(1)}
                                            onChange={() => {
                                                if (formik.values.device_classes.includes(1)) {
                                                    const nextValue = formik.values.device_classes.filter(value => value !== 1);
                                                    formik.setFieldValue('device_classes', nextValue);
                                                } else {
                                                    const nextValue = [...formik.values.device_classes, 1];
                                                    formik.setFieldValue('device_classes', nextValue);
                                                }
                                            }}
                                        />
                                        <label className='form-label fw-bold' htmlFor="class1" style={{ fontSize: '1.3rem' }}>Class I</label>
                                    </div>
                                    <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className='form-check-input me-2 mb-2'
                                            type='checkbox'
                                            id='class2'
                                            name='device_classes'
                                            value={2}
                                            checked={formik.values.device_classes.includes(2)}
                                            onChange={() => {
                                                if (formik.values.device_classes.includes(2)) {
                                                    const nextValue = formik.values.device_classes.filter(value => value !== 2);
                                                    formik.setFieldValue('device_classes', nextValue);
                                                } else {
                                                    const nextValue = [...formik.values.device_classes, 2];
                                                    formik.setFieldValue('device_classes', nextValue);
                                                }
                                            }}
                                        />
                                        <label className='form-label fw-bold' htmlFor="class2" style={{ fontSize: '1.3rem' }}>Class II</label>
                                    </div>
                                    <div className='col-lg-3' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className='form-check-input me-2 mb-2'
                                            type='checkbox'
                                            id='class3'
                                            name='device_classes'
                                            value={3}
                                            checked={formik.values.device_classes.includes(3)}
                                            onChange={() => {
                                                if (formik.values.device_classes.includes(3)) {
                                                    const nextValue = formik.values.device_classes.filter(value => value !== 3);
                                                    formik.setFieldValue('device_classes', nextValue);
                                                } else {
                                                    const nextValue = [...formik.values.device_classes, 3];
                                                    formik.setFieldValue('device_classes', nextValue);
                                                }
                                            }}
                                        />
                                        <label className='form-label fw-bold' htmlFor="class3" style={{ fontSize: '1.3rem' }}>Class III</label>
                                    </div>
                                </div>
                                <div className='fv-plugins-message-container ms-10 mt-n1'>
                                    {formik.touched.device_classes && formik.errors.device_classes && (
                                        <div className='fv-help-block'>{formik.errors.device_classes}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='row justify-content-center text-center mb-5'>
                        <div className='col-lg-2'>
                            <button
                                type='submit'
                                className='btn btn-lg btn-primary w-100'
                                disabled={loading}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
