import { FC } from 'react'

type Props = {
  role?: number
}

const roleMap = {
  1: 'User',
  2: 'Org Admin',
  3: 'Lexim Admin',
};

const UserTwoStepsCell: FC<Props> = ({ role }) => (
  <> {role && <div className='badge badge-light-primary fw-bolder'>{roleMap[role]}</div>}</>
)

export { UserTwoStepsCell }