import React from 'react';



const DocumentFilter = ({
    filters,
    onFilterChange,
    onApplyFilters,
    onResetFilters
}) => {

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-7'>
                    <label className='form-label fw-bold'>File Name:</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='File Name'
                        value={filters.name}
                        onChange={(e) => onFilterChange('fileName', e.target.value)}
                        maxLength="20"
                    />

                </div>
                <div className='mb-4'>
                    <label className='form-label fw-bold' htmlFor='dateRange'>
                        Uploaded Date Range:
                    </label>
                    <input
                        type='date'
                        id='startDate'
                        className='form-control form-control-solid my-2'
                        placeholder='Start Date'
                        value={filters.startDate}
                        onChange={(e) => onFilterChange('startDate', e.target.value)}
                    />
                    <input
                        type='date'
                        id='endDate'
                        className='form-control form-control-solid'
                        placeholder='End Date'
                        value={filters.endDate}
                        onChange={(e) => onFilterChange('endDate', e.target.value)}
                    />
                </div>



                <div className='d-flex justify-content-end mt-7 mb-3'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentFilter;