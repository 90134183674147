import React, { FC } from 'react'
import Notices from './Notices'

const NoticesWrapper: FC = () => {
    return (
        <>

            <Notices />
        </>
    )
}

export default NoticesWrapper
