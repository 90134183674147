import React, { FC, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
// import axios from 'axios';
// import { useAuth } from '../../modules/auth';

const translationSchema = Yup.object().shape({
    source_language: Yup.string().required('Source Language is required'),
    target_language: Yup.string().required('Target Language is required'),
    text: Yup.string(),
    simpleDocument: Yup.mixed(),
}).test(
    'fileOrText',
    'Either text or a file must be provided',
    function (value) {
        const { text, simpleDocument } = value;
        if (!text && !simpleDocument) {
            return this.createError({
                path: 'text',
                message: 'Either text or a file must be provided',
            });
        }
        return true;
    }
);

// const languageMap = {
//     'ar': 'Arabic',
//     'cs': 'Czech',
//     'de': 'German',
//     'en': 'English',
//     'es': 'Spanish',
//     'fi': 'Finnish',
//     'fr': 'French',
//     'hi': 'Hindi',
//     'it': 'Italian',
//     'ja': 'Japanese',
//     'ko': 'Korean',
//     'nl': 'Dutch',
//     'si': 'Sinhala',
//     'zh': 'Chinese',
//     'he': 'Hebrew',
//     'pt': 'Portuguese',
//     'sv': 'Swedish',
//     // Add more languages as needed
// };


const TranslationWrapper: FC = () => {
    // const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    // const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('write');
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    // const [showAlert, setShowAlert] = useState(false);
    const [translation, setTranslation] = useState('');
    const [originalFileName, setOriginalFileName] = useState('');

    const formik = useFormik({
        initialValues: {
            source_language: '',
            target_language: '',
            text: '',
            simpleDocument: undefined
        },
        validationSchema: translationSchema,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                // Prepare the data
                const data = new FormData();
                if (values.simpleDocument) {
                    data.append('file', values.simpleDocument);
                    // data.append('file_extension', values.simpleDocument.name.split('.').pop() || '');
                    formik.setFieldValue('text', ''); // Clear the text field
                } else {
                    // console.log(values.text)
                    data.append('text', values.text);
                }
                data.append('src_lang', values.source_language);
                data.append('tgt_lang', values.target_language);

                // Send the request
                const response = await axios.post(`${process.env.REACT_APP_DEEP_API_URL as string}/translate`, data);

                // Check if the request was successful
                if (response.status === 200) {
                    // Handle the response here
                    // console.log(response.data);
                    setTranslation(response.data.translated_text);
                } else {
                    console.error(`Request failed with status code ${response.status}`);
                }

                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                // setShowAlert(true);

                // setTimeout(() => {
                //     setShowAlert(false);
                // }, 15000);
            } catch (error) {
                console.error('Failed to submit feedback:', error);
            } finally {
                setLoading(false);
            }
        }
    });

    const downloadTranslation = () => {
        const element = document.createElement("a");
        const file = new Blob([translation], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);

        const now = new Date();
        const dateStr = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;

        const filename = originalFileName ? `${originalFileName}_translation.txt` : `translation_${formik.values.source_language}_to_${formik.values.target_language}_${dateStr}.txt`;
        element.download = filename;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }


    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid  px-5 mb-3">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                            <KTIcon iconName='geolocation' iconType="duotone" className='fw-bold fs-1 mx-2 text-primary ' />Translate Your Own Content
                        </h1>
                    </div>
                </div>
                {/* {showAlert && (
                    <div className="col-6 offset-3">
                        <div className='mb-lg-2 alert alert-success text-center'>
                            <div className='alert-text font-weight-bold'>Form Submitted Successfully</div>
                        </div>
                    </div>
                )} */}
                <div>
                    <div className='container' style={{ maxWidth: '95%' }}>
                        <div className='pt-9 pb-0'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row mb-6'>
                                    <label style={{ width: '15%' }} className='col-form-label required fw-bold fs-5 mb-1'>Source Language</label>
                                    <div style={{ width: '35%', marginTop: '0.25rem' }} className='fv-row' >
                                        <select
                                            name="source_language"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.source_language}
                                            className='form-control form-control-lg form-control-solid'
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                appearance: 'none',
                                                backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l3 3 3-3' /></svg>")`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right .5rem center',
                                                backgroundSize: '1.5em'
                                            }}
                                        >
                                            <option value="">Select...</option>
                                            <option value="Arabic">Arabic</option>
                                            <option value="Czech">Czech</option>
                                            <option value="German">German</option>
                                            <option value="English">English</option>
                                            <option value="Spanish">Spanish</option>
                                            <option value="Finnish">Finnish</option>
                                            <option value="French">French</option>
                                            <option value="Hindi">Hindi</option>
                                            <option value="Italian">Italian</option>
                                            <option value="Japanese">Japanese</option>
                                            <option value="Korean">Korean</option>
                                            <option value="Dutch">Dutch</option>
                                            <option value="Sinhala">Sinhala</option>
                                            <option value="Chinese">Chinese</option>
                                            <option value="Hebrew">Hebrew</option>
                                            <option value="Portuguese">Portuguese</option>
                                            <option value="Swedish">Swedish</option>
                                        </select>
                                        {formik.touched.source_language && formik.errors.source_language && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '7px', fontSize: '12px' }}>{formik.errors.source_language}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-8'>
                                    <label style={{ width: '15%' }} className='col-form-label required fw-bold fs-5 mb-1'>Target Language</label>
                                    <div style={{ width: '35%', marginTop: '0.25rem' }} className='fv-row' >
                                        <select
                                            name="target_language"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.target_language}
                                            className='form-control form-control-lg form-control-solid'
                                            style={{
                                                backgroundColor: '#f5f5f5',
                                                appearance: 'none',
                                                backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l3 3 3-3' /></svg>")`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right .5rem center',
                                                backgroundSize: '1.5em'
                                            }}
                                        >
                                            <option value="">Select...</option>
                                            <option value="Arabic">Arabic</option>
                                            <option value="Czech">Czech</option>
                                            <option value="German">German</option>
                                            <option value="English">English</option>
                                            <option value="Spanish">Spanish</option>
                                            <option value="Finnish">Finnish</option>
                                            <option value="French">French</option>
                                            <option value="Hindi">Hindi</option>
                                            <option value="Italian">Italian</option>
                                            <option value="Japanese">Japanese</option>
                                            <option value="Korean">Korean</option>
                                            <option value="Dutch">Dutch</option>
                                            <option value="Sinhala">Sinhala</option>
                                            <option value="Chinese">Chinese</option>
                                            <option value="Hebrew">Hebrew</option>
                                            <option value="Portuguese">Portuguese</option>
                                            <option value="Swedish">Swedish</option>
                                        </select>
                                        {formik.touched.target_language && formik.errors.target_language && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '7px', fontSize: '12px' }}>{formik.errors.target_language}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-8'>
                                    <label style={{ width: '15%' }} className='col-form-label required fw-bold fs-5 mb-1'>Content</label>
                                    <div style={{ width: '35%', marginTop: '0.25rem' }} className='fv-row' >
                                        <div className="btn-group" role="group" aria-label="Content options">
                                            <button type='button' className={`btn ${activeTab === 'write' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setActiveTab('write')}>
                                                Manual Entry
                                            </button>
                                            <button type='button' className={`btn ${activeTab === 'upload' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setActiveTab('upload')}>
                                                Upload document
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {activeTab === 'write' ? (
                                    <div>
                                        <div className='row mb-10'>
                                            <div className='col-12 fv-row'>
                                                <textarea
                                                    name="text"
                                                    onChange={(event) => {
                                                        // console.log(event.target.value);
                                                        formik.handleChange(event);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.text}
                                                    defaultValue={formik.values.text}
                                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                                    rows={10}
                                                    maxLength={5000}
                                                    style={{ backgroundColor: '#f5f5f5', color: '#000', overflow: 'auto' }}
                                                />
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {formik.touched.text && formik.errors.text ? (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>{formik.errors.text}</div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>&nbsp;</div> // Placeholder div
                                                    )}
                                                    <div style={{ color: '#6c757d', marginRight: '15px', marginTop: '10px', fontSize: '15px' }}>
                                                        {formik.values.text.length}/5000
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row' style={{ marginTop: '50px', marginBottom: '100px' }}>
                                        <div className="col-6" style={{ marginLeft: '20px' }}>
                                            <input
                                                ref={fileInputRef}
                                                id='simpleDocument'
                                                name='simpleDocument'
                                                type='file'
                                                onChange={(event) => {
                                                    if (event.currentTarget.files) {
                                                        formik.setFieldValue('simpleDocument', event.currentTarget.files[0]);
                                                        const fileNameWithoutExtension = event.currentTarget.files[0].name.split('.').slice(0, -1).join('.');
                                                        setOriginalFileName(fileNameWithoutExtension);
                                                    }
                                                }}
                                                className="form-control mb-10"
                                                accept=".txt,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className='d-flex justify-content-between px-6 mb-12'>
                                    <button type='button' className='btn btn-secondary btn-lg' style={{ width: '120px' }} onClick={() => {
                                        formik.resetForm();
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = '';
                                        }
                                        setOriginalFileName('');
                                        setTranslation('');
                                    }}>
                                        Clear
                                    </button>
                                    <button type='submit' className='btn btn-primary btn-lg' style={{ width: '120px' }} >
                                        {loading ? 'Loading...' : 'Submit'}
                                    </button>
                                </div>
                            </form>
                            <div className="separator separator-solid mt-3 mb-10"></div>
                            <div className='card mb-5 mb-xl-10 shadow-sm' id='kt_profile_details_view'>
                                <div className='card-header cursor-pointer bg-secondary text-white d-flex justify-content-between align-items-center'>
                                    <div className='card-title m-0'>
                                        <h3 className='fw-bolder m-0'>Translation to {formik.values.target_language}</h3>
                                        {loading && (
                                            <div className="spinner-border ms-4 mb-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </div>
                                    {!loading && (<button onClick={downloadTranslation} className='btn h-30px ml-0 fw-bold btn-md d-flex align-items-center justify-content-center' style={{ marginRight: '-10px', paddingLeft: 0 }}>
                                        <i className="fas fa-download mr-2" style={{ color: '#3699ff' }}></i>
                                        <span style={{ color: '#3699ff' }}>Download</span>
                                    </button>
                                    )}
                                </div>
                                {!loading && (
                                    <div className='card-body pt-9 pb-0'>
                                        <pre className="mb-10 text-wrap" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {translation.split('\n').map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </pre>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TranslationWrapper;