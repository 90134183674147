// import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { OrganizationsListWrapper } from './organizations-list/OrganizationList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/apps/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrganizationPage = () => {
  return (

    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Organization list</PageTitle>
      <OrganizationsListWrapper />
    </>


  )
}

export default OrganizationPage