import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';

type Props = {
  className: string;
  chartColor: string[];
  chartHeight: string;
  data?: DataItem[];
  title: string;
  activity: string;
  columns: { [key: string]: any; };
  scale: string;
};

interface DataItem {
  agency: string | null;
  period_code: string | null;
  [key: string]: any;
}

const MixedWidget11: React.FC<Props> = ({ className, chartHeight, title, chartColor, data = [], columns = {}, activity = "", scale = "logarithmic" }) => {
  const filteredData = (data as DataItem[])
    .filter(item => (activity !== "all" ? item.period_code === activity : true))
    .sort((a, b) => a.agency!.localeCompare(b.agency!));

  

  const categories = activity !== 'all' ? filteredData.map(item => item.agency!.toString()) : ['1 Month', '1 Quarter', '6 Months', '1 Year', 'All'];

  const navigate = useNavigate();

  let maxValue = -Infinity;
  let series;

  if (activity === "all") {
    series = ['CMS', 'FDA'].map(agency => {
      const agencyFilteredData = filteredData.filter(item => item.agency === agency);
      let dataSeries = agencyFilteredData.map(data =>
        title.includes("Documents") ? data.doc_total : data.comment_total
      );

      // Ensure the series length is 5
      const expectedLength = 5;
      const currentLength = dataSeries.length;
      if (currentLength < expectedLength) {
        // Calculate the number of 0s needed to prepend
        const zerosToPrepend = expectedLength - currentLength;
        // Prepend 0s to make the length 5
        dataSeries = Array(zerosToPrepend).fill(0).concat(dataSeries);
      }

      return {
        name: agency,
        data: dataSeries,
      };
    });
  } else {
    series = Object.entries(columns).map(([key, value]) => ({
      name: key,
      data: filteredData.map(data => data[value]),
    }));
  }
  // console.log(title);
  // console.log(series);
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: parseInt(chartHeight),
      marginBottom: Object.keys(columns).length > 2 ? 90 : chartHeight === "250px" ? 100 : 80,
      spacingBottom: Object.keys(columns).length > 2 ? 15 : chartHeight === "250px" ? 15 : 20
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: categories,
      crosshair: true,
    },
    yAxis: [{
      type: scale === "logarithmic" ? "logarithmic" : "linear",
      max: series.forEach(serie => {
        const seriesMax = Math.max(...serie.data);
        maxValue = Math.max(maxValue, seriesMax);
      }),
      title: {
        text: 'Quantity (#)'
      },
    }],
    tooltip: {
      valueSuffix: title.toLowerCase().includes("attachments") ? '' : (title.toLowerCase().includes("comments") ? ' comments' : ' documents'),
    },
    plotOptions: {
      column: {
        cursor: 'pointer',
        pointPadding: 0.2,
        borderWidth: 0,
        maxPointWidth: 30,
        point: {
          events: {
            click: function () {
              const clickedColumnCategory = this.category;
              const clickedSeriesName = this.series.name;
              let path = '';
              let stateData = {};
              let period = 0;
              if (activity === 'month') {
                period = 30;
              }
              if (activity === 'quarter') {
                period = 90;
              }
              if (activity === '6month') {
                period = 180;
              }
              if (activity === 'year') {
                period = 365;
              }

              switch (clickedSeriesName) {
                case 'Proposed Rules':
                  path = '/proposed-rules';
                  stateData = { agency: clickedColumnCategory, period_code: period };
                  break;
                case 'Final Rules':
                  path = '/final-rules';
                  stateData = { agency: clickedColumnCategory, period_code: period };
                  break;
                case 'Other':
                  path = '/guidances';
                  stateData = { agency: clickedColumnCategory, period_code: period };
                  break;
                case 'Notices':
                  path = '/notices';
                  stateData = { agency: clickedColumnCategory, period_code: period };
                  break;
                default:
                  break;
              }

              if (path) {
                navigate(path, { state: stateData });
              }
            },
          },
        },
      },
    },
    series: series,
    colors: chartColor,
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: Object.keys(columns).length > 2 ? '9px' : '10px',
      },
      margin: 5,
      itemMarginBottom: 2,
    },
  };

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  return (
    <div className={`card ${className} ms-2`} >
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body d-flex flex-column '>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div >
  );
};

export { MixedWidget11 };