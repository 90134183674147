// @ts-nocheck
import { Column } from 'react-table'
import { OrganizationInfoCell } from './OrganizationInfoCell'
import { OrganizationIndustryCell } from './OrganizationIndustryCell'
import { OrganizationLocationCell } from './OrganizationLocationCell'
import { OrganizationStatusCell } from './OrganizationStatusCell'
import { OrganizationActionsCell } from './OrganizationActionsCell'
import { OrganizationSelectionCell } from './OrganizationSelectionCell'
import { OrganizationCustomHeader } from './OrganizationCustomHeader'
import { OrganizationSelectionHeader } from './OrganizationSelectionHeader'
// import { OrganizationAdminCell } from './OrganizationAdminCell'
import { Organization } from '../../core/_models'

const OrganizationsColumns: ReadonlyArray<Column<Organization>> = [
  {
    Header: (props) => <OrganizationSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <OrganizationSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <OrganizationCustomHeader tableProps={props} title='Name' className='min-w-250px' />,
    id: 'name',
    Cell: ({ ...props }) => <OrganizationInfoCell Organization={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <OrganizationCustomHeader tableProps={props} title='Primary User' className='min-w-125px' />,
  //   id: 'primaryuser',
  //   Cell: ({ ...props }) => <OrganizationAdminCell Organization={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Industry' className='min-w-160px' />
    ),
    id: 'industry',
    Cell: ({ ...props }) => <OrganizationIndustryCell industry={props.data[props.row.index].industry} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    id: 'location',
    Cell: ({ ...props }) => <OrganizationLocationCell Organization={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Status' className='min-w-60px' />
    ),
    id: 'is_active',
    Cell: ({ ...props }) => <OrganizationStatusCell is_active={props.data[props.row.index].is_active} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <OrganizationActionsCell id={props.data[props.row.index].id} is_active={props.data[props.row.index].is_active} />,
  },
]

export { OrganizationsColumns }
